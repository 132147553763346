import {DataProvider} from "../data/dataprovider.js";

export const PushMessaging = {

    init : function(){
        if (window.JSAndroidBridge){
            console.log("init firebase from android");
        }
        else if (window.iOSConfig){
            console.log("init firebase from ios");
            PushMessagingIOS.init();
        }
        else{
            console.log("init firebase from web");
            PushMessagingWeb.init();
        }
    },

    getToken : async function(cb){
        if (window.JSAndroidBridge){
            var token = window.JSAndroidBridge.getFCMToken();
            cb(token);
        }
        else if (AppInstance.getConfig().appmode == "ios"){
            PushMessagingIOS.getToken();
        }
        else{
            PushMessagingWeb.getFirebaseToken(cb);
        }
    },

    subscribeMatch : function(matchId,cb){
        PushMessaging.getMessager().subscribeMatch(matchId,cb);
    },

    unSubscribeMatch : function(matchid,cb){
        PushMessaging.getMessager().unSubscribeMatch(matchid,cb);
    },

    subscribeTeam : function(teamid,cb){
        PushMessaging.getMessager().subscribeTeam(teamid,cb);
    },

    unSubscribeTeam : function(teamid,cb){
        PushMessaging.getMessager().unSubscribeTeam(teamid,cb);
    },

    subscribeLeague : function(key,cb){
        PushMessaging.getMessager().subscribeLeague(key,cb);
    },

    unSubscribeLeague : function(key,cb){
        PushMessaging.getMessager().unSubscribeLeague(key,cb);
    },

    getMessager : function(){
        if (window.JSAndroidBridge){
            return PushMessagingAndroid;
        }
        else if (window.iOSConfig){
            return PushMessagingIOS;
        }
        else{
            return PushMessagingWeb;
        }
    }
}

var PushMessagingAndroid = {
    init : function(){
        console.warn("no need to initialize firebase from android from web");
    },

    getToken : function(){
        return window.JSAndroidBridge.getFCMToken();
    },

    subscribeMatch : function(matchid,cb){
        if (!this.getToken()){
            console.warn("no token yet");
            if (cb) cb(false);
            return;
        }
        window.JSAndroidBridge.subscribeMatch(matchid);
        if (cb) cb(true);
    },

    unSubscribeMatch : function(matchid,cb){
        if (!this.getToken()){
            console.warn("no token yet");
            if (cb) cb(false);
            return;
        }
        window.JSAndroidBridge.unSubscribeMatch(matchid);
        if (cb) cb(true);
    },

    subscribeTeam : function(teamid,cb){
        if (!this.getToken()){
            console.warn("no token yet");
            if (cb) cb(false);
            return;
        }
        window.JSAndroidBridge.subscribeTeam(teamid);
        if (cb) cb(true);
    },

    unSubscribeTeam : function(teamid,cb){
        if (!this.getToken()){
            console.warn("no token yet");
            if (cb) cb(false);
            return;
        }
        window.JSAndroidBridge.unSubscribeTeam(teamid);
        if (cb) cb(true);
    },

    subscribeLeague : function(key,cb){
        if (!this.getToken()){
            console.warn("no token yet");
            if (cb) cb(false);
            return;
        }
        window.JSAndroidBridge.subscribeLeague(key);
        if (cb) cb(true);
    },

    unSubscribeLeague : function(key,cb){
        if (!this.getToken()){
            console.warn("no token yet");
            if (cb) cb(false);
            return;
        }
        window.JSAndroidBridge.unSubscribeLeague(key);
        if (cb) cb(true);
    },
}

var PushMessagingIOS = {

    init : function(){
        console.warn("no need to initialize firebase from ios from web");
        //check if we have a token stored in localstorage. it might be different than the one now
        //if so, we need to update the server
        //TODO
        var oldToken = localStorage.getItem("ios_token");
        this.oldToken = oldToken;
        localStorage.setItem("ios_token", iOSConfig.token);
    },

    getToken : function(){
        return iOSConfig.token;
    },

    hasToken : function(){
        return iOSConfig.token != null && iOSConfig.token != undefined && iOSConfig.token != "";
    },

    getPushPayload : function(extra,cb){
        Object.assign(extra, {
            regid : this.getToken(),
            oldregid : this.oldToken,
            lang : AppInstance.getConfig().userLocale
        });
        return extra;
    },

    subscribeMatch : function(matchid,cb){
        if (!this.hasToken()){
            if (cb) cb(false);
            return;
        }
        var url = "/footapi/registration_ios/subscribematch?"+DataProvider.getExtraUrlParts();
        DataProvider.postGeneric(url, this.getPushPayload({"matchid":matchid}), function(response){
            console.log("subscribed to match");
            if (cb) cb(response.isOk());
        },"subscribeMatch");
    },

    unSubscribeMatch : function(matchid,cb){
        if (!this.hasToken()){
            if (cb) cb(false);
            return;
        }
        var url = "/footapi/registration_ios/unsubscribematch?"+DataProvider.getExtraUrlParts();
        DataProvider.postGeneric(url, this.getPushPayload({"matchid":matchid}), function(response){
            console.log("unsubscribed to match");
            if (cb) cb(response.isOk());
        },"unsubscribeMatch");
    },

    subscribeTeam : function(teamid,cb){
        if (!this.hasToken()){
            if (cb) cb(false);
            return;
        }
        var url = "/footapi/registration_ios/subscribeteam?"+DataProvider.getExtraUrlParts();
        DataProvider.postGeneric(url, this.getPushPayload({"teamid":teamid}), function(response){
            console.log("subscribed to team");
            if (cb) cb(response.isOk());
        },"subscribeteam");
    },

    unSubscribeTeam : function(teamid,cb){
        if (!this.hasToken()){
            if (cb) cb(false);
            return;
        }
        var url = "/footapi/registration_ios/unsubscribeteam?"+DataProvider.getExtraUrlParts();
        DataProvider.postGeneric(url, this.getPushPayload({"teamid":teamid}), function(response){
            console.log("unsubscribed to team");
            if (cb) cb(response.isOk());
        },"unsubscribeteam");
    },

    subscribeLeague : function(leaguekey,cb){
        if (!this.hasToken()){
            if (cb) cb(false);
            return;
        }
        var url = "/footapi/registration_ios/subscribeleague?"+DataProvider.getExtraUrlParts();
        DataProvider.postGeneric(url, this.getPushPayload({"leaguekey":leaguekey}), function(response){
            console.log("subscribed to league");
            if (cb) cb(response.isOk());
        },"subscribeLeague");
    },

    unSubscribeLeague : function(key,cb){
        if (!this.hasToken()){
            if (cb) cb(false);
            return;
        }
        var url = "/footapi/registration_ios/unsubscribeleague?"+DataProvider.getExtraUrlParts();
        DataProvider.postGeneric(url, this.getPushPayload({"leaguekey":leaguekey}), function(response){
            console.log("unsubscribed to league");
            if (cb) cb(response.isOk());
        },"unsubscribeleague");
    },
}

var PushMessagingWeb = {

    init : async function(){

        var srcInit = 'https://www.gstatic.com/firebasejs/9.21.0/firebase-app.js';
        var srcMessaging = 'https://www.gstatic.com/firebasejs/9.21.0/firebase-messaging.js';

        let { initializeApp } = await import(srcInit);
        let { getMessaging,onMessage } = await import(srcMessaging);

        const firebaseConfig = {
            apiKey: "AIzaSyDCwWarGEjxiJGB1jT9tluIKocpfIYhLrw",
            authDomain: "admob-app-id-3193292840.firebaseapp.com",
            databaseURL: "https://admob-app-id-3193292840.firebaseio.com",
            projectId: "admob-app-id-3193292840",
            storageBucket: "admob-app-id-3193292840.appspot.com",
            messagingSenderId: "1041266175732",
            appId: "1:1041266175732:web:61ba5007d3e420a651b8a1",
            measurementId: "G-89667RKR4X"
        };

        // Initialize Firebase
        const FirebaseApp = initializeApp(firebaseConfig);
        // this.getFirebaseToken((token)=>{
        //     console.log("firebase token is ",token);
        // })

        const messaging = getMessaging();

        // messaging.onMessageHandler =  (payload)=> {
        //     console.log('---------------------------------------------------------------------> Message received. ', payload);
        //     // ...
        // };
        // messaging.onBackgroundMessageHandler =  (payload)=> {
        //     console.log('---------------------------------------------------------------------> background Message received. ', payload);
        //     // ...
        // }

        onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            // ...
        });
    },

    getPushPayload : async function(extra,cb){
        console.log("get push payload ",extra,cb);
        this.getFirebaseToken((token)=>{
            if (!token) return cb(null);
            Object.assign(extra, {
                regid : token,
                oldregid : this.oldToken,
                lang : AppInstance.getConfig().userLocale
            });
            this.oldToken = token;
            return cb(extra);
        });
    },

    subscribeMatch : function(matchid,cb){
        this.getPushPayload({"matchid":matchid},(payload)=>{
            if (!payload){
                console.log("no payload");
                if (cb) cb(false);
                return;
            }
            var url = "/footapi/registration_web/subscribematch?"+DataProvider.getExtraUrlParts();
            DataProvider.postGeneric(url, payload, (response)=>{
                console.log("subscribed to match");
                if (cb) cb(response.isOk());
            },"subscribeMatch");
        });

    },

    unSubscribeMatch : function(matchid,cb){
        this.getPushPayload({"matchid":matchid},(payload)=>{
            if (!payload){
                console.log("no payload");
                if (cb) cb(false);
                return;
            }
            var url = "/footapi/registration_web/unsubscribematch?"+DataProvider.getExtraUrlParts();
            DataProvider.postGeneric(url, payload, (response)=>{
                console.log("unsubscribed to match");
                if (cb) cb(response.isOk());
            },"unsubscribeMatch");
        });
    },

    subscribeTeam : function(teamid,cb){
        this.getPushPayload({"teamid":teamid},(payload)=>{
            if (!payload){
                console.log("no payload");
                if (cb) cb(false);
                return;
            }
            var url = "/footapi/registration_web/subscribeteam?"+DataProvider.getExtraUrlParts();
            DataProvider.postGeneric(url, payload, (response)=>{
                console.log("subscribed to team");
                if (cb) cb(response.isOk());
            },"subscribeTeam");
        });
    },

    unSubscribeTeam : function(teamid,cb){
        this.getPushPayload({"teamid":teamid},(payload)=>{
            if (!payload){
                console.log("no payload");
                if (cb) cb(false);
                return;
            }
            var url = "/footapi/registration_web/unsubscribeteam?"+DataProvider.getExtraUrlParts();
            DataProvider.postGeneric(url, payload, (response)=>{
                console.log("unsubscribed to team");
                if (cb) cb(response.isOk());
            },"unsubscribeTeam");
        });
    },

    subscribeLeague : function(leaguekey,cb){
        this.getPushPayload({"leaguekey":leaguekey},(payload)=>{
            if (!payload){
                console.log("no payload");
                if (cb) cb(false);
                return;
            }
            var url = "/footapi/registration_web/subscribeleague?"+DataProvider.getExtraUrlParts();
            DataProvider.postGeneric(url, payload, (response)=>{
                console.log("subscribed to league");
                if (cb) cb(response.isOk());
            },"subscribeleague");
        });
    },

    unSubscribeLeague : function(leaguekey,cb){
        this.getPushPayload({"leaguekey":leaguekey},(payload)=>{
            if (!payload){
                console.log("no payload");
                if (cb) cb(false);
                return;
            }
            var url = "/footapi/registration_web/unsubscribeleague?"+DataProvider.getExtraUrlParts();
            DataProvider.postGeneric(url, payload, (response)=>{
                console.log("unsubscribed to league");
                if (cb) cb(response.isOk());
            },"unsubscribeleague");
        });
    },

    getFirebaseToken : async function(cb){

        var srcInit = 'https://www.gstatic.com/firebasejs/9.21.0/firebase-app.js';
        var srcMessaging = 'https://www.gstatic.com/firebasejs/9.21.0/firebase-messaging.js';

        let { initializeApp } = await import(srcInit);
        let { getMessaging, getToken } = await import(srcMessaging);

        const firebaseConfig = {
            apiKey: "AIzaSyDCwWarGEjxiJGB1jT9tluIKocpfIYhLrw",
            authDomain: "admob-app-id-3193292840.firebaseapp.com",
            databaseURL: "https://admob-app-id-3193292840.firebaseio.com",
            projectId: "admob-app-id-3193292840",
            storageBucket: "admob-app-id-3193292840.appspot.com",
            messagingSenderId: "1041266175732",
            appId: "1:1041266175732:web:61ba5007d3e420a651b8a1",
            measurementId: "G-89667RKR4X"
        };

        // Initialize Firebase
        const FirebaseApp = initializeApp(firebaseConfig);

        const messaging = getMessaging();
        // Add the public key generated from the console here.
        getToken(messaging, {serviceWorkerRegistration :window.firebaseServiceWorker, vapidKey: "BLyCrmUFizRZO8W_szBQWo5N5N-A5GwNo5CqS1LR19n4Kl9OnLArxSwlCxC622drMSlPXme49Y4sQhoznXbiAMk"}).then((currentToken) => {
            if (currentToken) {
                console.log('currentToken',currentToken);
                cb(currentToken);
            } else {
                console.log('No registration token available. Request permission to generate one.');

                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        console.log('Notification permission granted.');
                        PushMessagingWeb.getFirebaseToken(cb);
                    } else {
                        console.log('Unable to get permission to notify.');
                        cb(null);
                    }
                });
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            cb(null);
        });
    }
}

if (typeof window !== 'undefined') {
    window.PushMessaging = PushMessagingWeb;
}