import {Panel} from "../../bananaframework/src/controls/Panel.js";
import {Image} from "../../bananaframework/src/controls/Image.js";

import {Localizer} from "../../util/localizer.js";
import {ThemeManager} from "../../util/theme.js";
import {Likelizer} from "../../util/likelizer.js";
import {DataProvider} from "../../data/dataprovider.js";

export class TeamHeader extends Panel{

    init () {
        super.init();
        this.addCssClass("MatchHeaderContainer TeamHeaderContainer");
        this.bounceEnabled = true;

        var homeContainer = new Panel();
        homeContainer.addCssClass("MatchHeaderHomeContainer");
        homeContainer.setStyle("width:100%;");

        this.backgroundImage = new Image();
        this.backgroundImage.setVisible(false);
        this.backgroundImage.addCssClass("MatchHeaderBackgroundImage");
        //this.backgroundImage.addCssClass("fade-in-image2");

        this.homeImage = new Image();
        this.homeImage.addCssClass("MatchHeaderTeamLogoContainer");
        this.homeImage.setStyle("height:90px;width:90px;position:relative;");


        if (window.location.host == "stg.holoduke.nl"){
            this.homeImage.bind("click",() => {
               //open url in new window
                window.open("https://tools.holoduke.nl/#id="+this.oldData.id_gs+"&section=TeamDetail");
                window.open("https://tools.holoduke.nl/#id="+this.oldData.id_gs+"&section=TeamDetail");
            });
        }

        this.teamFlag = new Image();
        this.teamFlag.setStyle("width:40px;height:40px; position:absolute; bottom:50px; left:calc(50% - 50%/2 - 40px);");

        this.shape = new Panel();
        this.shape.setStyle("width:130px; height:30px; position:absolute;top:3px; left:calc(50% + 50px);");
        this.shape.setStyle("width:130px; height:30px; position:absolute;top:77px; left:calc(50% - 65px);");
        this.shape.addCssClass("MatchStatsContainerFormStart flexHorizontalAlign");

        this.homeTitle = new Panel();
        this.homeTitle.addCssClass("MatchHeaderTeamText");

        this.teamLike = new Panel();
        this.teamLike.setStyle("position:absolute;bottom:50px;left:calc(50% + 50%/2);font-variant: all-petite-caps;    font-style: italic;");

        var heart = new Panel();
        this.heart = heart;
        heart.addCssClass("heart-wrapper");
        var c = `
  <div class="heart">
    <div class="tl"></div>
    <div class="tr"></div>
    <div class="bl"></div>
    <div class="br"></div>
  </div>
  <div class="ring"></div>
  <div class="circles"></div>
        `;

        heart.addControl(c);
        this.teamLike.addControl(heart);

        this.addControl(this.backgroundImage);
        this.addControl(homeContainer);

        homeContainer.addControl(this.homeImage);
        homeContainer.addControl(this.teamFlag);
        homeContainer.addControl(this.shape);
        homeContainer.addControl(this.homeTitle);
        this.homeImage.addControl(this.teamLike);
    }

    setPresetData (data,instantRender){

        if (!data){
            console.error("cannot set preset data without data");
            return;
        }
        if (ThemeManager.getCurrentTheme() != "light" && instantRender) {
            this.backgroundImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/venue/" + data.venueid + ".png");
            this.backgroundImage.setVisible(true);
        }

        this.homeTitle.clear();
        this.homeTitle.addControl(data.teamname,true);
        //this.teamFounded.addControl(data.founded,true);

        if (!this.oldData || this.oldData.id_gs != data.id_gs) {

            var teamImageSubFolder = "";
            var hideFlag = false;
            if (!AppInstance.getConfig().enabledFeatures.teamImages) {
                teamImageSubFolder = "country/";
                hideFlag = true;
            }

            this.homeImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/"+AppInstance.getConfig().url.teamPlayerImageUrl+"/teams_gs/"+teamImageSubFolder + data.id_gs + "_rnd.png", AppInstance.getConfig().sizes.matchHeaderImageSize, AppInstance.getConfig().sizes.matchHeaderImageSize);
            this.homeImage.setAttribute("alt",Localizer.getString("home team image"));

            if (data.fixtures && data.fixtures.length && data.fixtures[0].filegroup) {
                //data.country = data.fixtures[0].filegroup;
            }
            if (!hideFlag && data.country){
                this.teamFlag.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.country.replaceAll(" ", "-").toLowerCase() + ".png");
            }
            else{
                this.teamFlag.setVisible(false);
            }

            if (data.shape) {
                for (var index in data.shape) {
                    var letter = data.shape[index];
                    var item = new Panel();
                    if (letter == "W") {
                        item.addCssClass("MatchStatsContainerFormItem MatchStatsContainerFormWin");
                    } else if (letter == "D") {
                        item.addCssClass("MatchStatsContainerFormItem MatchStatsContainerFormDraw");
                    } else if (letter == "L") {
                        item.addCssClass("MatchStatsContainerFormItem MatchStatsContainerFormLost");
                    }
                    item.addControl(letter);
                    this.shape.addControl(item);
                }
                this.shape.invalidateDisplay();
            }
        }
        this.oldData = data;
        var clicked = false;

        if (Likelizer.didLikeTeam(this.oldData.id_gs)){
            this.heart.addCssClass("activated");
        }
        else{
            this.heart.bind("click",()=>{
                if (clicked) return;
                clicked = true;
                console.log("clicckk",this.oldData.id_gs);
                Likelizer.likeTeam(this.oldData.id_gs);
                this.heart.addCssClass("active");

                //get element and change content
                jQuery("#heartcount").html(parseInt(jQuery("#heartcount").html())+1);
            })
        }

        DataProvider.getLike("team",data.id_gs, (e) => {
            if (!e.isOk()) {
                console.error("error getting like",e);
                return;
            }
            var d = `<div id="heartcount" style=font-size:17px;position:absolute;width:100%;text-align:center;ont-weight: bold;color: #6d28cc;font-size: 17px;>
            `+e.getData().count+`
            </div>`
            this.teamLike.addControl(d,true);
        })
    }

    setData (data){
    }
};