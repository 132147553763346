import {DataControl} from "./DataControl.js";
import {Panel} from "./Panel.js";

export class Table extends DataControl {
    init() {
        super.init();
        this.addCssClass("table");
    }
};

Table.prototype.getTagName = function () {
    return 'table';
};

export class TableRow extends DataControl {
    init() {
        super.init();
    }
};

TableRow.prototype.getTagName = function () {
    return 'tr';
};

export class TableCol extends DataControl {
    init() {
        super.init();
    }
};

TableCol.prototype.getTagName = function () {
    return 'td';
};


export class TableHeaderCol extends TableCol {
    init() {
        super.init();
    }
};

TableHeaderCol.prototype.getTagName = function () {
    return 'th';
};

export class TableHead extends Panel {
    init() {
        super.init();
    }
};

TableHead.prototype.getTagName = function () {
    return 'thead';
};

export class TableBody extends Panel {
    init() {
        super.init();
    }
};

TableBody.prototype.getTagName = function () {
    return 'tbody';
};