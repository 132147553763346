
export const Socializer = {

    //there is a problem with the initSocialNetworks android native method
    //it is not sync and directly calling isloggedin after will return false
    init : function(){
        if (window.JSAndroidBridge){
            window.JSAndroidBridge.initSocialNetworks()
        }
    },

    setManiaToken : function(token){
        console.log("set mania token");
        localStorage.setItem("mania_token",token)
    },

    getManiaToken : function(){
        return localStorage.getItem("mania_token")
    },

    resetManiaToken : function(){
        localStorage.removeItem("mania_token");
    },

    requestManiaToken : function(){
        console.warn("not implemented");
    },

    isLoggedIn: function (str) {
        if (window.JSAndroidBridge){
            ///window.JSAndroidBridge.initSocialNetworks()
            return (window.JSAndroidBridge.isLoggedIn())
        }
        else{
            return (localStorage.getItem("socialloginweb"))
        }
    },

    requestLogin : function(cb){
        if (window.JSAndroidBridge){
            AndroidSocializer.requestLogin(cb);
        }
        else {
            WebSocializer.requestLogin(cb);
        }
    },

    requestLogout : function(cb){
        localStorage.removeItem("mania_token");
        if (window.JSAndroidBridge) {
            AndroidSocializer.requestLogout(cb);
        }
        else {
            localStorage.removeItem("socialloginweb");
            if (cb) cb();
        }

    },

    decodeJwt : function(token) {
        var base64Payload = token.split(".")[1];
        var payload = decodeURIComponent(
            atob(base64Payload)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );
        return JSON.parse(payload);
    },

    getSocialUser : function(){
        if (Socializer.isLoggedIn()){

            if (window.JSAndroidBridge){
                let user = localStorage.getItem("socialloginandroid");
                if (user){
                    var u = JSON.parse(user);
                    u.nw = 3;
                    u.maniatoken = Socializer.getManiaToken();
                    return u;
                }
            }
            else {
                let token = localStorage.getItem("socialloginweb");
                let u = Socializer.decodeJwt(token);

                return {
                    un: u.given_name,
                    image: u.picture,
                    pid: u.sub,
                    token: token,
                    nw : 10,
                    maniatoken : Socializer.getManiaToken(),
                }
            }
        }
        return null;
    },
};

var AndroidSocializer = {

    requestLogin : function(cb){

        window.removeEventListener("message",this.onWebviewMessagePosted);
        this.onWebviewMessagePosted = (response)=>{
            console.log("onWebviewMessagePosted login",response);
            window.removeEventListener("message",this.onWebviewMessagePosted);

            try{
                var responseData = JSON.parse(response.data);
                if (responseData.type == 0 && responseData.logged_in == 1){
                    console.log("succesfully login in",responseData.user);
                    localStorage.setItem("socialloginandroid",JSON.stringify(responseData.user));
                    if (cb) cb(responseData);
                }
                else{
                    console.log("login failed");
                    //still try to login
                    if (window.JSAndroidBridge.isLoggedIn()){
                        console.log("already logged in still",AndroidSocializer.getSocialUser());
                    }
                    //AndroidSocializer.getSocialUser();

                    if (cb) cb(null);
                }
                console.log("reponseData",responseData);
            }
            catch(e){
                console.log("error",e);
                if (cb) cb(null);
            }
        };
        window.addEventListener("message",this.onWebviewMessagePosted);

        window.JSAndroidBridge.initSocialNetworks();
        setTimeout(function(){
            window.JSAndroidBridge.requestLogin(3);
        },400);
    },

    getSocialUser : function(){
        window.removeEventListener("message",this.onWebviewMessagePosted);
        this.onWebviewMessagePosted = (response)=>{
            console.log("onWebviewMessagePosted get social user",response);
            window.removeEventListener("message",this.onWebviewMessagePosted);

            try{
                var responseData = JSON.parse(response.data);
                console.log(responseData);
            }
            catch(e){
                console.log("error",e);
                if (cb) cb(null);
            }
        };

        window.addEventListener("message",this.onWebviewMessagePosted);

        window.JSAndroidBridge.initSocialNetworks();
        setTimeout(function(){
            window.JSAndroidBridge.getSocialUser();
        },500);
    },

    requestLogout : function(cb){
        window.addEventListener("message",this.onWebviewMessagePosted);
        this.onWebviewMessagePosted = function(response){
            console.log("onWebviewMessagePosted logout",response);
            window.removeEventListener("message",this.onWebviewMessagePosted);
            if (cb) cb();
        };

        window.addEventListener("message",this.onWebviewMessagePosted);

        window.JSAndroidBridge.requestLogout(3);
    },
}

var WebSocializer = {

    requestLogin : function(cb){

        console.log("========> requestLogin");

        AppInstance.loadJsScript("https://accounts.google.com/gsi/client",function(){
            console.log("google.accounts.id.initialize");
            google.accounts.id.initialize({
                client_id: '1041266175732-q9244ltvieieqlshg7o13j6l07llio4u.apps.googleusercontent.com',
                auto_select: true,
                callback: function(response){
                    console.log("response",response);
                    if (response.credential){
                        var jwt = response.credential;
                        var decoded = Socializer.decodeJwt(jwt);
                        localStorage.setItem("socialloginweb",jwt);
                        console.log("decoded",decoded);
                        if (cb) cb(decoded);
                    }
                    else{
                        if (cb) cb(null);
                    }
                }
            });
            google.accounts.id.prompt((notification) => {
                console.log(notification);
                if (notification.isNotDisplayed()) {
                    console.log('Login prompt was not displayed');
                    if (cb) cb(null);
                } else if (notification.isSkippedMoment()) {
                    console.log('Login prompt was skipped');
                    if (cb) cb(null);
                } else {
                    console.log('Login prompt was displayed');
                }
            });
        },function(){
            console.log("google.accounts.id.initialize error. script not loaded");
            if (cb) cb(null);
        });
    }
}

//if window
if (typeof window !== 'undefined') {
    window.Socializer = Socializer;
}