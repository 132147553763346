import {Panel} from "../../bananaframework/src/controls/Panel.js";
import {Image} from "../../bananaframework/src/controls/Image.js";

import {Localizer} from "../../util/localizer.js";
import {MatchUtil} from "../../util/matchutil.js";
import {ThemeManager} from "../../util/theme.js";
import {Router} from "../../util/router.js";

export class MatchHeader extends Panel{

    init () {
        super.init();
        this.addCssClass("MatchHeaderContainer");
        this.bounceEnabled = true;

        var homeContainer = new Panel();
        homeContainer.addCssClass("MatchHeaderHomeContainer");
        var statusContainer = new Panel();
        statusContainer.addCssClass("MatchHeaderStatusContainer");
        var awayContainer = new Panel();
        awayContainer.addCssClass("MatchHeaderAwayContainer");

        this.backgroundImage = new Image();
        this.backgroundImage.setVisible(false);
        this.backgroundImage.addCssClass("MatchHeaderBackgroundImage");
        //this.backgroundImage.addCssClass("fade-in-image2");

        this.homeImage = new Image();
        this.homeImage.addCssClass("MatchHeaderTeamLogoContainer");

        this.awayImage = new Image();
        this.awayImage.addCssClass("MatchHeaderTeamLogoContainer");

        this.homeTitle = new Panel();
        this.homeTitle.addCssClass("MatchHeaderTeamText");

        this.awayTitle = new Panel();
        this.awayTitle.addCssClass("MatchHeaderTeamText");

        this.statusDate = new Panel();
        this.statusDate.addCssClass("MatchHeaderStatusDate");
        this.statusAggr = new Panel();
        this.statusAggr.addCssClass("MatchHeaderStatusAggregate");
        this.scoreTime = new Panel();
        this.scoreTime.addCssClass("MatchHeaderStatusScoreTime");
        this.statusMatch = new Panel();
        this.statusMatch.addCssClass("MatchHeaderStatusMatch");


        this.addControl(this.backgroundImage);
        this.addControl(homeContainer);
        this.addControl(statusContainer);
        this.addControl(awayContainer);

        homeContainer.addControl(this.homeImage);
        homeContainer.addControl(this.homeTitle);
        awayContainer.addControl(this.awayImage);
        awayContainer.addControl(this.awayTitle);

        statusContainer.addControl(this.statusDate);
        statusContainer.addControl(this.statusAggr);
        statusContainer.addControl(this.scoreTime);
        statusContainer.addControl(this.statusMatch);
        this.statusContainer = statusContainer;
    }

    setPresetData (data,instantRender){
        if (!data){
            console.error("cannot set preset data without data");
            return;
        }
        if (ThemeManager.getCurrentTheme() != "light" && instantRender) {
        //if (instantRender) {
            this.backgroundImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/venue/" + data.venueid + ".png");
            this.backgroundImage.setVisible(true);
        }
        //console.log("set preset daa",data,AppInstance.getConfig().url.imageBaseUrl+"/footapi/images/teams_gs/"+data.gs_localteamid+"_rnd.png");
        this.statusDate.clear();
        this.scoreTime.clear();
        this.statusMatch.clear();
        this.awayTitle.clear();
        this.homeTitle.clear();
        this.statusDate.clear();
        this.statusAggr.clear();


        this.statusDate.addControl(MatchUtil.getLocalizedDate(data),instantRender)
        this.scoreTime.addControl(MatchUtil.getLocalizedScoreTime(data),instantRender)
        this.statusMatch.addControl(MatchUtil.getFullStatus(data),instantRender)
        this.homeTitle.addControl(data.localteam,instantRender);
        this.awayTitle.addControl(data.visitorteam,instantRender);

        if (!this.oldData || this.oldData.gs_localteamid != data.gs_localteamid) {

            var teamImageSubFolder = "";
            if (!AppInstance.getConfig().enabledFeatures.teamImages) {
                teamImageSubFolder = "country/";
            }

            this.homeImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/"+AppInstance.getConfig().url.teamPlayerImageUrl+"/teams_gs/" +teamImageSubFolder+ data.gs_localteamid + "_rnd.png", AppInstance.getConfig().sizes.matchHeaderImageSize, AppInstance.getConfig().sizes.matchHeaderImageSize);
            this.homeImage.setAttribute("alt",Localizer.getString("home team image"));
            this.awayImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/"+AppInstance.getConfig().url.teamPlayerImageUrl+"/teams_gs/" +teamImageSubFolder+ data.gs_visitorteamid + "_rnd.png", AppInstance.getConfig().sizes.matchHeaderImageSize, AppInstance.getConfig().sizes.matchHeaderImageSize);
            this.awayImage.setAttribute("alt",Localizer.getString("away team image"));

            this.homeImage.unbind("click");
            this.homeImage.bind("click", () => {
                Router.showTeamScreen(data.gs_localteamid);
            });

            this.awayImage.unbind("click");
            this.awayImage.bind("click", () => {
                Router.showTeamScreen(data.gs_visitorteamid);
            });
        }

        // if (MatchUtil.isPlaying(data) || MatchUtil.isHalftime(data)){
        //     //this.statusDate.setVisible(false);
        //     this.statusMatch.addCssClass('scoretimeBlinker ScoreTimeLarge');
        // }
        if (MatchUtil.isHalftime(data)){
            this.statusDate.clear()
            this.statusMatch.addCssClass('scoretimeBlinker');
        }
        else if (MatchUtil.isPlaying(data)){
            this.statusDate.clear()
            this.statusMatch.addCssClass('scoretimeBlinker ScoreTimeLarge');
        }
        else{
            this.scoreTime.addCssClass('scoretimeNormal');
            this.statusMatch.removeCssClass('scoretimeBlinker ScoreTimeLarge');
            this.statusMatch.removeCssClass('scoretimeBlinker');
        }
        if (data.aggregateScore){
            var scoreParts = data.aggregateScore.split("-")
            if (data.aggregateWinner == 1) {
                this.statusAggr.addControl("(<span class=MatchHeaderAggregateWinnerColor>" + scoreParts[0] + "</span> - " + scoreParts[1] + ")",instantRender);
            }
            else if (data.aggregateWinner == 2){
                this.statusAggr.addControl("(" + scoreParts[0] + " - <span class=MatchHeaderAggregateWinnerColor>"+ scoreParts[1] + "</span>)",instantRender);
            }
            else{
                this.statusAggr.addControl("(" + scoreParts[0] + " - " + scoreParts[1] + ")",instantRender);
            }
        }
        this.oldData = data;
    }

    setData (data){

        //this.homeImage.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/images/teams_gs/"+this.data.gs_localteamid+"_small_rnd.png",AppInstance.getConfig().sizes.fixtureListImageSize,AppInstance.getConfig().sizes.fixtureListImageSize);
        //this.awayImage.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/images/teams_gs/"+this.data.gs_visitorteamid+"_small_rnd.png",AppInstance.getConfig().sizes.fixtureListImageSize,AppInstance.getConfig().sizes.fixtureListImageSize);
    }
};