///import 'https://flackr.github.io/scroll-timeline/dist/scroll-timeline.js';
//this control runs in combination with collapser (topbar) and tabiew
// when target scroller scrolls down it collapses the topbar and tabview scrollerContainers.
export const AnimatorTopTeamsLeagues = function(){

    var version = "1.0";

    var scrollerContainers = [];
    var scrollerMethods = [];
    var touchStartMethod = null;
    var touchEndMethod = null;
    var collapser = null;
    var tabview = null;
    var ignoreEvents = false;
    var ignoreEventForIndex = [];
    var unloaded = false;

    this.isTouching = false;
    this.reducerRatio = 0.5;
    this.transitionDuration = 100;
    this.alwaysCollapseOnBackScrolling = true;
    this.isCollapsed = false;
    this.lastRecalculateWasCollapsed = null;
    this.isVirtuallyCollapsed = false;
    this.lastScrollPositionAfterTouchStart = -1;
    this.lastYOffsetAfterTouchStart = 0;
    this.currentTranslateY = 0;
    this.currentActiveTab = -1;

    this.addScrollerContainer = function(tab){
        scrollerContainers.push(tab);
    }

    this.addCollapser = function(c){
        collapser = c;
    }

    this.addTabView = function(tab){
        tabview = tab;
    }

    this.unload = function(){
        unloaded = true;
        if (collapser) {
            collapser.setCss({"transform": "translate(0px,0px)", "transition-duration": "0ms"}) //reset
        }
        if (scrollerContainers) {
            for (var i = 0; i < scrollerContainers.length; i++) {
                var scrollEl = jQuery("#" + scrollerContainers[i].getClientId());

                if (scrollerMethods[i]) {
                    scrollEl.off("scroll", scrollerMethods[i]);
                }
            }
        }

        $('body').off({
            'touchstart': touchStartMethod,
            'touchend' : touchEndMethod});

        if (this.lastUsedScrollerElement && this.lastUsedScrollerElementListener){
            this.lastUsedScrollerElement.removeEventListener("scroll",this.lastUsedScrollerElementListener);
        }

        collapser = null;
        scrollerContainers = null;
    }

    this.onWindowResize = function(){
        this.recalculate(true);
    }

    this.tabChange = function(){

        return;
        console.log("TAB CHANGE",tabview.currentActiveTab,this.currentActiveTab);

        if (!FootballManiaConfig.graphics.animations) return;

        var topbar = this.getTabBar();
        var tabholder = this.getTabHolder();

        ///this.initAnimations();

        for (var i=0; i < scrollerContainers.length;i++) {
            jQuery("#" + scrollerContainers[i].getClientId()).scrollTop(0);
        }

        var style = window.getComputedStyle(tabholder);
        var matrixY = new DOMMatrix(style.transform).m42;

        var keyframes =  {
            transform: ['translateY('+matrixY+'px)', 'translateY(0px)'],
        };
        var options = {
            duration: 150,
            fill: "forwards",
        }

        if (this.tabholderAnimation){
            this.tabholderAnimation.commitStyles();
            this.tabholderAnimation.cancel();
        }
        if (this.topbarAnimation){
            this.topbarAnimation.commitStyles();
            this.topbarAnimation.cancel();
        }

        this.tabholderAnimation = tabholder.animate(keyframes,options);
        this.topbarAnimation = topbar.animate(keyframes,options);

        this.tabholderAnimation.onfinish = ()=>{
            this.initAnimations();
        }
    }

    this.recalculateTab = function(i){
    }

    this.recalculate = function(force,onlyTabIndex){
    }

    this.prepare = function(){

    }


    this.collapseHeader = function(){

    }

    this.expandHeader = function(){
        // if (this.tabholderAnimation){
        //     this.tabholderAnimation.commitStyles();
        //     this.tabholderAnimation.cancel();
        // }


        //
        // const tabholder = document.querySelector(".tabholder");
        // tabholder.animate(
        //     {   transform: ['translateY(-50px)', 'translateY(0px)'],
        //     },
        //     {
        //         duration: 150,
        //         fill: "backwards",
        //     },
        // )
        // //tabholder.style.transform = "translateY(-0px)";
    }

    this.getTabBar = function(){
        var topbar = this.cachedTopbarElement;
        if (!topbar) {
            var topbar = document.querySelector(".topBar");
            this.cachedTopbarElement = topbar;
        }
        return topbar;
    }
    this.getTabHolder = function(){
        var tabholder = this.cachedTabholderElement;
        if (!tabholder) {
            var tabholder = document.querySelector(".tabholder");
            this.cachedTabholderElement = tabholder;
        }
        return tabholder;
    }


    this.initAnimations = function(){

        return;
        console.trace();

        var topbar = this.getTabBar();
        var tabholder = this.getTabHolder();

        if (!this.cachedScrollerCollection){
            this.cachedScrollerCollection = [];
        }
        var cachedScroller = this.cachedScrollerCollection[tabview.currentActiveTab];
        if (!cachedScroller){
            var element = document.getElementById(scrollerContainers[tabview.currentActiveTab].getClientId());
            const timeline = new ScrollTimeline({
                source: element,
                axis: "block",
            });

            this.cachedScrollerCollection[tabview.currentActiveTab] = {
                element: element,
                timeline: timeline
            };
            cachedScroller = this.cachedScrollerCollection[tabview.currentActiveTab];
        }
        var timeline = cachedScroller.timeline;
        var currentScrollElement = cachedScroller.element;

        if (this.tabholderAnimation){
            this.tabholderAnimation.commitStyles();
            this.tabholderAnimation.cancel();
        }
        if (this.topbarAnimation){
            this.topbarAnimation.commitStyles();
            this.topbarAnimation.cancel();
        }


        var scrollControl = scrollerContainers[tabview.currentActiveTab];

        if (!scrollControl){
            console.error("no scroll element found");
            return;
        }

        var style = window.getComputedStyle(tabholder);
        var matrixY = new DOMMatrix(style.transform).m42;
        console.log(new DOMMatrix(style.transform));
        var collapsed = matrixY == -50;
        var expanded = matrixY == 0;

        if (this.isCollapsed == undefined) {
            if (!collapsed && !expanded) {
                console.log("not collapsed or expanded -> wait for auto collapse or open");
                return;
            }
        }
        else{
            collapsed = this.isCollapsed;
            expanded = !this.isCollapsed;
        }

        if (this.lastUsedScrollerElement && this.lastUsedScrollerElementListener){
            this.lastUsedScrollerElement.removeEventListener("scroll",this.lastUsedScrollerElementListener);
        }

        //also add scroll listener for the case when user scrolls with mouse. not with touch
        var handler = null;
        this.lastUsedScrollerElementListener = (e) => {
            clearTimeout(handler);
            if (this.mouseIsDown) return; ///mouse up will already trigger the auto collapse or open
            handler = setTimeout(()=>{
                this.autoCollapseOrOpen();
            },200);
        };
        this.lastUsedScrollerElement = currentScrollElement;
        currentScrollElement.addEventListener("scroll",this.lastUsedScrollerElementListener);

        var topOffset = currentScrollElement.scrollTop

        if (collapsed) {

            var keyframes =  {
                transform: ['translateY(0px)', 'translateY(-50px)'],
            };

            var options = {
                timeline,
                rangeStart: 'calc(0% + '+((topOffset)-50) + 'px)',
                rangeEnd: 'calc(0% + '+((topOffset)) + 'px)',
                linear: "both",
                fill: "backwards",
            }

            console.log("-------> add animation for expanding when scrolling up",options);
            this.tabholderAnimation = tabholder.animate(keyframes,options);
            this.topbarAnimation = topbar.animate(keyframes,options);
        }
        else if (expanded){
            console.log("add animation for collapsing when scrolling down")

            var keyframes =  {
                transform: ['translateY(0px)', 'translateY(-50px)'],
            };

            var options = {
                timeline,
                rangeStart: 'calc(0% + '+((topOffset)) + 'px)',
                rangeEnd: 'calc(0% + '+((topOffset)+50) + 'px)',
                linear: "both",
                fill: "forwards",
            }

            this.tabholderAnimation = tabholder.animate(keyframes,options);
            this.topbarAnimation = topbar.animate(keyframes,options);
        }
    }


    this.autoCollapseOrOpen = function(){

        return;

        var scroller = scrollerContainers[tabview.currentActiveTab];
        if (!scroller){
            console.error("no scroll element found");
            return;
        }

        var topbar = this.getTabBar();
        var tabholder = this.getTabHolder();

        var style = window.getComputedStyle(tabholder);
        var matrixY = new DOMMatrix(style.transform).m42;
        var collapsed = matrixY == -50;
        var expanded = matrixY == 0;

        var scrollElement = document.getElementById(scroller.getClientId());
        var topOffset = scrollElement.scrollTop;

        if (this.tabholderAnimation){
            this.tabholderAnimation.commitStyles();
            this.tabholderAnimation.cancel();
        }
        if (this.topbarAnimation){
            this.topbarAnimation.commitStyles();
            this.topbarAnimation.cancel();
        }

        console.log("auto collapse or open",collapsed,expanded,topOffset,matrixY);


        //auto collapse
        if (collapsed || expanded){
            //console.log("no auto collapse or open",collapsed,expanded);
            this.initAnimations();
            return;
        }

        //console.log("topOffset",topOffset);
        if (topOffset < 25){
            //console.log("no auto collapse or open but scroll");
            //scroll to zero
            scrollElement.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });

            var keyframes =  {
                transform: ['translateY('+matrixY+'px)', 'translateY(0px)'],
            };
            var options = {
                duration: 150,
                fill: "forwards",
            }
            this.tabholderAnimation = tabholder.animate(keyframes,options);
            this.topbarAnimation = topbar.animate(keyframes,options);

            this.tabholderAnimation.onfinish = ()=>{
                this.initAnimations();
            }
            return;
        }
        else if (topOffset < 50){
            scrollElement.scroll({
                top: 50,
                left: 0,
                behavior: 'smooth'
            });

            var keyframes =  {
                transform: ['translateY('+matrixY+'px)', 'translateY(-50px)'],
            };
            var options = {
                duration: 150,
                fill: "forwards",
            }
            this.tabholderAnimation = tabholder.animate(keyframes,options);
            this.topbarAnimation = topbar.animate(keyframes,options);

            this.tabholderAnimation.onfinish = ()=>{
                this.initAnimations();
            }
            return;
        }

        if (matrixY < -25){
            var target = -50;
        }
        else{
            var target = 0;
        }

        var keyframes =  {
            transform: ['translateY('+matrixY+'px)', 'translateY('+target+'px)'],
        };
        var options = {
            duration: 150,
            fill: "forwards",
        }
        this.tabholderAnimation = tabholder.animate(keyframes,options);
        this.topbarAnimation = topbar.animate(keyframes,options);

        this.tabholderAnimation.onfinish = ()=>{
            this.initAnimations();
        }
    }

    this.isCollapsedCalculated = function(){

        var scroller = scrollerContainers[tabview.currentActiveTab];
        var tabholder = document.querySelector(".tabholder");

        if (!scroller){
            console.error("no scroll element found");
            return;
        }

        var style = window.getComputedStyle(tabholder);
        var matrixY = new DOMMatrix(style.transform).m42;
        return matrixY == -50;
    }


    this.run = function(){


        if (!FootballManiaConfig.graphics.animations) return;

        console.log("RUN ANIMATOR FOR LEAGUESCREEN "+version);

        touchStartMethod = (e) => {
            this.mouseIsDown = true;
            this.initAnimations();
        };

        touchEndMethod = ()=>{
            this.mouseIsDown = false;
            this.autoCollapseOrOpen();
        }

        $('body').on({
            'touchstart': touchStartMethod,
            'touchend' : touchEndMethod});

        this.initAnimations();
    }
}