
//this control runs in combination with collapser (topbar) and tabiew
// when target scroller scrolls down it collapses the topbar and tabview scollers.
export const AnimatorMatch = function(){

    var version = "1.0";

    var scollers = [];
    var scrollerMethods = [];
    var touchStartMethod = null;
    var touchEndMethod = null;
    var collapser = null;
    var tabview = null;
    var topbar = null;
    var ignoreEvents = false;
    var ignoreEventForIndex = [];
    var lastListIndexScrolling = -1
    var unloaded = false;

    var touchEndTimeoutHandler = null;
    var scrollReleaseTimeoutHandler = null;


    this.matchStatus; //we use this for collapser differnt ways
    this.isTouching = false;
    this.reducerRatio = 0.3;
    this.transitionDuration = 100;
    this.alwaysCollapseOnBackScrolling = true;
    this.isCollapsed = false;
    this.lastRecalculateWasCollapsed = null;
    this.isVirtuallyCollapsed = false;
    this.lastScrollPositionAfterTouchStart = 0;
    this.lastYOffsetAfterTouchStart = 0;
    this.currentTranslateY = 0;
    this.currentActiveTab = -1;
    this.shrinkType = 1;
    this.scrollerBottomMargin = [];

    this.addScrollerContainer = function(tab){
        if (scollers) {
            scollers.push(tab);
        }
        else{
            console.warn("cannot add, no scroll container found");
        }
    }



    this.getScrollerContainer = function(i){
        return scollers[i];
    }

    this.setScrollerBottomMargin = function(i,margin){
        this.scrollerBottomMargin[i] = margin;
    }

    this.addCollapser = function(c){
        collapser = c;
    }

    this.addTopBar = function(tb){
        topbar = tb;
    }

    this.addTabView = function(tab){
        tabview = tab;
    }

    this.unload = function(){

        unloaded = true;
        if (topbar) {
            topbar.setCss({"transform": "translate(0px,0px)", "transition-duration": "0ms"});
        }
        if (collapser) {
            collapser.setCss({"transform": "translate(0px,0px)", "transition-duration": "0ms"});
        }
        if (scollers) {
            for (var i = 0; i < scollers.length; i++) {
                var scrollEl = jQuery("#" + scollers[i].getClientId());
                if (scrollerMethods[i]) {
                    scrollEl.off("scroll", scrollerMethods[i]);
                }
            }
        }

        scrollerMethods = null;

        $('body').off({
            'touchstart': touchStartMethod,
            'touchend' : touchEndMethod});

        collapser = null;
        scollers = null;
        topbar = null;
    }

    this.onWindowResize = function(){
        this.recalculate(true);
    }

    this.allowEvent = function(i){
        ignoreEvents = false;
        ignoreEventForIndex = [];
    }

    this.tabChange = function(){
        if (!FootballManiaConfig.graphics.animations) return;

        if (this.currentActiveTab == -1){
            console.log("nononono")
            this.currentActiveTab = tabview.currentActiveTab;
            //return;
        }

        var collaped = this.isCollapsed;
        var virtuallyCollapsed = this.isVirtuallyCollapsed;
        // if (this.isVirtuallyCollapsed){
        //     this.isCollapsed = true;
        // }

        ignoreEventForIndex.splice(ignoreEventForIndex.indexOf(tabview.currentActiveTab),1);

        console.log(tabview.currentActiveTab,"TAB CHANGE ",collaped,virtuallyCollapsed);

        if (collapser) {
            var collapserHeight = collapser.getDimensions().height;
        }
        //console.log("RECALCULATE TAB ",i,scrollEl[0].scrollHeight,contentWindowHeight,collapserHeight)

        var height = collapserHeight

        console.log("scroll top ",$("#"+scollers[tabview.currentActiveTab].getClientId()).scrollTop());

        if (!$("#"+scollers[tabview.currentActiveTab].getClientId()).isVScrollable()) {
            console.log("no scrollable content. so expand header");
            this.expandHeader();
            virtuallyCollapsed = false; //this is needed to make sure other tabs which are scrolled to zero have the also the scroll to zero
        }
        if (virtuallyCollapsed){
            this.colapseHeader();
        }

        for (var i=0; i < scollers.length;i++) {
            if (i == tabview.currentActiveTab) continue;
            ignoreEvents = true;
            if (ignoreEventForIndex.indexOf(i) == -1) {
                ignoreEventForIndex.push(i);
            }

            this.recalculate();
            clearTimeout(scrollReleaseTimeoutHandler); //otherwise during scrolling other events will clash
            clearTimeout(touchEndTimeoutHandler);

            console.log("do something with ",i,collaped,virtuallyCollapsed,(collaped || virtuallyCollapsed) ? height : 0);
            (function (i) {
                var scrollElement = scollers[i];
                document.querySelector("#" + scrollElement.getClientId()).scrollTo({
                    top: (virtuallyCollapsed) ? height : 0,
                    //top: 0,
                    left: 0,
                });
            })(i)
            ignoreEvents = false;
        }
        this.currentActiveTab = tabview.currentActiveTab;
    }

    this.recalculateTab = function(i){

        if (!FootballManiaConfig.graphics.animations) return;

        if (!scollers || !scollers[i]){
            console.warn("cannot recalcuate without scollers ",i);
            return;
        }
        if (!tabview || !tabview.tabContents || !tabview.tabContents.controls[i] || !tabview.tabContents.controls[i].controls[1] || !tabview.tabContents.controls[i].controls[1].controls[0]){
            console.warn("cannot recalcuate without tabview elements");
            return;
        }

        var contentWindowHeight = tabview.tabContents.getDimensions().height;
        var collapserHeight = 0;
        if (collapser) {
            collapserHeight = collapser.getDimensions().height;
        }
        var scrollEl = jQuery("#"+scollers[i].getClientId());
        var top  = collapserHeight;

        if (!scrollEl || !scrollEl[0]){
            console.warn("no scroller for tab ",i)
            return;
        }

        console.log(i,"RECALCULATE TAB ","scrollHeight",scrollEl[0].scrollHeight,"content height",contentWindowHeight)

        if (!scollers[tabview.currentActiveTab]){
            console.warn("no scroller for tab ",tabview.currentActiveTab)
            return;
        }

        if (!$("#"+scollers[tabview.currentActiveTab].getClientId()).isVScrollable()){
            console.log(i,"content is not scrollable");

            $("#" + scollers[i].getClientId()).css({"box-sizing": "border-box"});
            $("#" + scollers[i].parent.getClientId()).css({"top": "-" +top+"px"});

            if (scollers[i].forceMarginTop){ //for the chat screen this is needed to lower the chat elements to the bottom
                $("#" + scollers[i].controls[0].getClientId()).css({"margin-top":scollers[i].forceMarginTop , "height": ""});
            }
            else {
                $("#" + scollers[i].controls[0].getClientId()).css({"margin-top": "+" + top + "px", "height": ""});
            }
            $("#" + scollers[i].parent.getClientId()).css({"height":"calc(100% + "+top+"px)"});
        }
        else{
            $("#" + scollers[i].getClientId()).css({"box-sizing": "border-box"});
            $("#" + scollers[i].parent.getClientId()).css({"top": "-" +top+"px"});
            $("#" + scollers[i].controls[0].getClientId()).css({"margin-top": "+" + top + "px", "height": ""});

            $("#" + scollers[i].parent.getClientId()).css({"height":"calc(100% + "+top+"px)"});

            console.log(i,"content is scrollable",this.isCollapsed);
        }
    }

    this.recalculate = function(force,onlyTabIndex){

        if (!FootballManiaConfig.graphics.animations) return;

        //return;
        if (!force && this.lastRecalculateWasCollapsed!= null && this.isCollapsed == this.lastRecalculateWasCollapsed){
            console.log("NO NEED TO RECALCULATE POSITIONS");
            return;
        }
        console.log("RECALCULATE POSITIONS","isCollapsed",this.isCollapsed);
        this.lastRecalculateWasCollapsed = this.isCollapsed;
        var collapserHeight = collapser.getDimensions().height;

        if (this.isCollapsed){
            this.colapseHeader();
        }
        else{
            this.expandHeader();
        }

        if (scollers) {
            console.log("recalculate scollers length",scollers.length);
            for (var i = 0; i < scollers.length; i++) {
                var scrollEl = jQuery("#" + scollers[i].getClientId());

                ///console.log("XXXXXXXX",scrollEl[0].scrollHeight,contentWindowHeight)
                this.recalculateTab(i);

                if (this.isCollapsed || this.isVirtuallyCollapsed) {
                    if (lastListIndexScrolling != i && scrollEl.scrollTop() <= collapserHeight) {
                        //console.log("scroll #1 manually tab", i, "to ", collapserHeight)
                        var scrollElement = scollers[i];
                        ignoreEvents = true;
                        if (ignoreEventForIndex.indexOf(i) == -1) {
                            ignoreEventForIndex.push(i);
                        }
                        document.querySelector("#" + scrollElement.getClientId()).scrollTo({
                            top: collapserHeight,
                            left: 0,
                        });
                        ignoreEvents = false;
                    }
                } else {
                    if (lastListIndexScrolling != i && scrollEl.scrollTop() <= collapserHeight) {
                        //console.log("scroll #2 manually tab", i, " to ", 0)
                        var scrollElement = scollers[i];
                        ignoreEvents = true;
                        if (ignoreEventForIndex.indexOf(i) == -1) {
                            ignoreEventForIndex.push(i);
                        }
                        document.querySelector("#" + scrollElement.getClientId()).scrollTo({
                            top: 0,
                            left: 0,
                        });
                        ignoreEvents = false;
                    }
                }
            }
        }
    }

    this.autoCollapseOrOpen = function(scrollPosition){

        if (!FootballManiaConfig.graphics.animations) return;

        console.log("autoCollapseOrOpen",scrollPosition,this.currentTranslateY,this.isAutoCollapsing,this.isCollapsed);
        //return;
        if (this.isAutoCollapsing){
            console.log("dont do twice ");
            return;
        }

        this.isAutoCollapsing = true;

        if (this.currentTranslateY == 0){
            this.isCollapsed = false;
        }
        else if (this.currentTranslateY == -collapser.getDimensions().height){
            this.isCollapsed = true;
        }

        if (this.currentTranslateY == 0 || this.currentTranslateY == -collapser.getDimensions().height){
            //console.log("no auto collapse when already open or closed",this.currentTranslateY);
            this.isAutoCollapsing = false;
            this.recalculate();
            return;
        }

        console.log("AUTO COLLAPSE",this.currentTranslateY)

        var collapserHeight = collapser.getDimensions().height;
        var that = this;
        var delay = 300;

        if ((scrollPosition) > (collapserHeight * (1/this.reducerRatio))/2){

            var scrollElement = scollers[tabview.currentActiveTab];

            this.isCollapsed = true;
            document.querySelector("#" + scrollElement.getClientId()).scrollTo({
                top: collapserHeight,
                left: 0,
                behavior: "smooth"
            });
        }
        else {
            var scrollElement = scollers[tabview.currentActiveTab];

            document.querySelector("#" + scrollElement.getClientId()).scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });

            that.currentTranslateY = 0;
            this.isCollapsed = false;
            setTimeout(function(){
                //that.currentTranslateY = 0;
                that.recalculate();
            },delay)
        }
    }

    this.ensureStartPositions = function(){

        if (!FootballManiaConfig.graphics.animations) return;

        if (this.isTouching){
            console.log("NO PREPARE WHEN TOUCHING");
            return;
        }
        ///collapser.setVisible(false)
        console.log("PREPARE RUN ANIMATOR FOR MATCHSCREEN "+version);
        var collapserHeight = collapser.getDimensions().height;

        collapser.awayImage.setVisible(true);
        collapser.homeImage.setVisible(true);

        //make sure we start with a collapsed one before everything gets rendered. causes flickering
        if (this.isCollapsed){
            $("#"+collapser.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
            $("#"+tabview.tabHolder.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
            $("#"+topbar.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});

            var scrollElement = scollers[tabview.currentActiveTab];

            var el = document.querySelector("#" + scrollElement.getClientId());
            //console.log("scroll to 0 from start",el.scrollHeight,el.scrollTop);

            //only scroll to zero when user not scrolled
            if (el.scrollTop < collapserHeight) {
                el.scrollTo({
                    top: collapserHeight,
                    left: 0,
                });
            }
        }
    }

    this.prerun = function(collapsed){

        console.log("prerrunnn")
        if (!FootballManiaConfig.graphics.animations) return;

        console.log("PRE RUN ANIMATOR FOR MATCHSCREEN "+version);
        if (collapsed){
            this.currentTranslateY = -collapser.getDimensions().height
            this.autoCollapseOrOpen(-collapser.getDimensions().height)
        }
        else{
            this.autoCollapseOrOpen(0)
        }

        collapser.awayImage.setVisible(false);
        collapser.homeImage.setVisible(false);
    },

    this.run = function(){

        if (!FootballManiaConfig.graphics.animations) return;

        console.log("RUN ANIMATOR (scollers:"+scollers.length+") FOR MATCHSCREEN "+version);
        lastListIndexScrolling = tabview.currentActiveTab;

        $('body').off({
            'touchstart': touchStartMethod,
            'touchend' : touchEndMethod});

        for (var i=0; i < scrollerMethods.length;i++){
            var scrollEl = jQuery("#"+scollers[i].getClientId());
            scrollEl.off("scroll",scrollerMethods[i]);
        }

        this.recalculate();

        var that = this;
        var homeImage = collapser.homeImage;
        var awayImage = collapser.awayImage;
        var statusContainer = collapser.statusContainer;
        var homeTitle = collapser.homeTitle;
        var awayTitle = collapser.awayTitle;
        var backgroundImage = collapser.backgroundImage;
        var startOpacityBackground = $("#"+backgroundImage.getClientId()).css("opacity");
        var isFirstTimeScroll = true;

        var currentActiveScrollElementHeight = 0;
        var windowHeight = $(window).height();

        touchStartMethod = function (e) {
            that.isTouching = true;
            var scrollElement = scollers[tabview.currentActiveTab];
            if (!scrollElement){
                console.error("no scroll element in tab "+tabview.currentActiveTab+" found. do nothing?");
                return;
            }
            var topOffset = document.querySelector("#" + scrollElement.getClientId()).scrollTop
            that.lastScrollPositionAfterTouchStart = topOffset;
            that.lastYOffsetAfterTouchStart = that.currentTranslateY;
            //alert(currentActiveScrollElementHeight)
        };

        touchEndMethod = function(){
            //console.log("touch end")

            that.isTouching = false;
            if (that.currentTranslateY > -collapser.getDimensions().height){
                //that.isCollapsed = false;
            }

            clearTimeout(touchEndTimeoutHandler);

            touchEndTimeoutHandler = setTimeout(function() {
                if (unloaded) return;
                if (!scollers){
                    console.error("no scollers found why??"); return;
                }

                that.currentActiveTab = tabview.currentActiveTab;

                var scrollElement = scollers[tabview.currentActiveTab];
                if (!scrollElement){
                    console.error("no scroll element in tab "+tabview.currentActiveTab+" found. do nothing?");
                    return;
                }

                var topOffset = document.querySelector("#" + scrollElement.getClientId()).scrollTop
                that.autoCollapseOrOpen(topOffset);
            },100);
        }

        $('body').on({
        'touchstart': touchStartMethod,
        'touchend' : touchEndMethod});

        //what if dynamic height?
        var collapserHeight = collapser.getDimensions().height;

        function runTabSCrollLogic(index){

            var scrollEl = jQuery("#"+scollers[index].getClientId());

            var prevScroll = null;
            var ticking = false;
            var topOffset = 0;
            var prevTopOffset = 0;

            scrollerMethods[index] = function(e){
                //console.log("scroll event",index,that.isCollapsed,that.isVirtuallyCollapsed);
                if (ticking) return;

                //if (currentActiveScrollElementHeight == null){
                    currentActiveScrollElementHeight = $("#"+scollers[tabview.currentActiveTab].getClientId())[0].scrollHeight
                //}

                if (currentActiveScrollElementHeight < windowHeight){
                    console.log("do nothing scroll element smaller than window height",currentActiveScrollElementHeight,windowHeight);
                    //return;
                }
                //console.log(currentActiveScrollElementHeight,windowHeight);

                clearTimeout(touchEndTimeoutHandler);
                window.requestAnimationFrame(function() {


                    ticking = true;
                    if (ignoreEvents || index != tabview.currentActiveTab) {
                        console.log("ignore scroll event if not this active tab ", index)
                        ticking = false;
                        return;
                    }
                    if (ignoreEventForIndex.indexOf(index) != -1) {
                        console.log("ignore scroll event this time", index, ignoreEventForIndex);
                        //ignoreEventForIndex = -1;
                        ignoreEventForIndex.splice(ignoreEventForIndex.indexOf(index), 1);
                        ticking = false;
                        return;
                    }

                    var topOffset = e.originalEvent.target.scrollTop;
                    if (prevScroll == topOffset) {
                        ticking = false;
                        console.log("do nothing no scroll change");
                        //return;
                    }

                    // if (!that.isTouching && scollers[index].ignoreAnimationsOnProgrammaticScroll) {
                    //     ticking = false;
                    //     console.log("do nothing no animations on programmatic scroll");
                    //     return;
                    // }

                    lastListIndexScrolling = index;
                    clearTimeout(scrollReleaseTimeoutHandler);

                    scrollReleaseTimeoutHandler = setTimeout(function () {
                        that.isAutoCollapsing = false;
                        currentActiveScrollElementHeight = null;
                        var topOffset = e.originalEvent.target.scrollTop;
                        if (!that.isTouching) {
                            that.autoCollapseOrOpen(topOffset);
                        }
                        that.recalculate();
                        console.log("scroll done");
                    }, 150);

                    topOffset = e.originalEvent.target.scrollTop;

                    prevTopOffset = topOffset;

                    if (false && !that.alwaysCollapseOnBackScrolling) {
                        scrollDiff = Math.max(-topOffset, -collapserHeight);

                    } else {

                        if (that.isCollapsed) {
                            if (topOffset <= collapserHeight) {
                                var t = -topOffset;
                                scrollDiff = Math.max(-collapserHeight, Math.min(0, t));
                            }
                            else{
                                //console.log("do other")
                                scrollDiff = Math.max(-topOffset, -collapserHeight);
                            }
                        } else {
                            //console.log("is not collapsed scroll",topOffset,collapserHeight);
                            if (topOffset >= collapserHeight){
                                scrollDiff = -collapserHeight;
                            }
                            else if (that.lastScrollPositionAfterTouchStart !=-1) {
                                //var t = ((that.lastScrollPositionAfterTouchStart - topOffset) * that.reducerRatio) + that.lastYOffsetAfterTouchStart;
                                var t = (((collapserHeight-topOffset)-collapserHeight) * that.reducerRatio);
                                var scrollDiff = Math.min(0, Math.max(-collapserHeight, t));
                                var scrollDiff = t;
                            }
                            else{
                                scrollDiff = 0;
                            }
                        }
                    }
                    that.currentTranslateY = scrollDiff;

                    //this virtualcollapsed value is a little different than iscollapsed
                    //when user is collapsing and no touchend event is fired then the state of
                    //iscollapsed remains the same. the virtualcollapsed one changes
                    //this is needed in the recalculation of positions
                    if (scrollDiff == -collapserHeight) {
                        that.isVirtuallyCollapsed = true;
                    } else {
                        that.isVirtuallyCollapsed = false;
                    }

                    if (isFirstTimeScroll) {
                        var transformDuration = 0;
                        isFirstTimeScroll = false;
                    } else {
                        var transformDuration = 80;
                    }

                    that.shrinkOrExpand(scrollDiff,
                        collapserHeight,
                        homeImage,
                        awayImage,
                        backgroundImage,
                        homeTitle,
                        awayTitle,
                        statusContainer,
                        startOpacityBackground,
                        transformDuration
                    );

                    prevScroll = e.originalEvent.target.scrollTop;
                    ticking = false;
                });
            }

            if (scrollerMethods[index]){
                scrollEl.off("scroll",scrollerMethods[index]);
            }

            scrollEl.scroll(scrollerMethods[index]);
        }

        for (var i =0; i < scollers.length;i++){
            //if (i == this.disableAnimationsForIndex) continue;
            runTabSCrollLogic(i);
        }
    },

    this.colapseHeader = function(){
        console.log("collapse header");
        var collapserHeight = collapser.getDimensions().height;

        this.isCollapsed = true;
        this.isVirtuallyCollapsed = true;

        $("#"+collapser.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});

        if (topbar){
            $("#"+topbar.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
        }

        if (tabview){
            $("#"+tabview.tabHolder.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
        }

        //this.recalculate();

        this.shrinkOrExpand(-collapser.getDimensions().height,
            collapser.getDimensions().height,
            collapser.homeImage,
            collapser.awayImage,
            collapser.backgroundImage,
            collapser.homeTitle,
            collapser.awayTitle,
            collapser.statusContainer,
            $("#"+collapser.backgroundImage.getClientId()).css("opacity"),
            0
        );
    }

    this.expandHeader = function(){
        console.log("expand header");
        this.isCollapsed = false;
        this.isVirtuallyCollapsed = false;

        $("#"+collapser.getClientId()).css({"transform":"translate(0px,0px)","transition-duration": "0ms"});

        if (topbar){
            $("#"+topbar.getClientId()).css({"transform":"translate(0px,0px)","transition-duration": "0ms"});
        }

        if (tabview){
            $("#"+tabview.tabHolder.getClientId()).css({"transform":"translate(0px,0px)","transition-duration": "0ms"});
        }

        //this.recalculate();

        this.shrinkOrExpand(0,
            collapser.getDimensions().height,
            collapser.homeImage,
            collapser.awayImage,
            collapser.backgroundImage,
            collapser.homeTitle,
            collapser.awayTitle,
            collapser.statusContainer,
            $("#"+collapser.backgroundImage.getClientId()).css("opacity"),
            0
        );
    },

    this.shrinkOrExpand = function(scrollDiff,
                                   collapserHeight,
                                   homeImage,
                                   awayImage,
                                   backgroundImage,
                                   homeTitle,
                                   awayTitle,
                                   statusContainer,
                                   startOpacityBackground,
                                   transformDuration){


        if (!collapser || !tabview || !tabview.tabHolder) return;

        //collapser.setCss({"transform":"translate(0px,"+scrollDiff+"px)","transition-duration": this.transitionDuration+"ms"});
        collapser.setCss({
            "transform": "translate(0px," + Math.round(scrollDiff)+ "px)",
            "transition-duration": "80ms"
        });
        tabview.tabHolder.setCss({"transform":"translate(0px,"+scrollDiff+"px)","transition-duration": this.transitionDuration+"100ms"});
        tabview.tabHolder.setCss({
            "transform": "translate(0px," + Math.round(scrollDiff)+ "px)",
            "transition-duration": "80ms"
        });

        var progression = -scrollDiff/collapserHeight;
        var opacity = 1-(progression);
        //var opacity = 1;
        var scaleFontY = 1 - (0.40*progression);
        var scaleFontX = 1 - (0.38*progression);
        var scaleImage = 1 - (0.47*progression);

        //1 == finished, 2 == live with aggregate score, 3 == live with individual score
        if (this.shrinkType == 1){
            var statusContainerProgression = progression * 27;
            scaleFontY = 1 - (0.22*progression);
            scaleFontX = 1 - (0.22*progression);
        }
        else if (this.shrinkType == 2){
            var statusContainerProgression = progression * 36;
            scaleFontY = 1 - (0.25*progression);
            scaleFontX = 1 - (0.25*progression);
        }
        else if (this.shrinkType == 3){
            var statusContainerProgression = progression * 50;
            scaleFontY = 1 - (0.40*progression);
            scaleFontX = 1 - (0.45*progression);
        }
        else if (this.shrinkType == 4){
            var statusContainerProgression = progression *20;
            scaleFontY = 1 - (0.17*progression);
            scaleFontX = 1 - (0.17*progression);
        }
        else{
            var statusContainerProgression = progression * 90;
        }

        var imageVerticalMove = progression * 105;
        var imageHorizontalMove = progression * 40;


        homeImage.setCss({
            "transform": "scale("+scaleImage+") translate("+imageHorizontalMove+"px," + imageVerticalMove+ "px)",
            "transition-duration": transformDuration+"ms"
        });

        awayImage.setCss({
            "transform": "scale("+scaleImage+") translate("+-imageHorizontalMove+"px," + imageVerticalMove+ "px)",
            "transition-duration": transformDuration+"ms"
        });

        backgroundImage.setCss({
            "transform": "translateX(" + tabview.cachedParllaxBgXOffset+ "%) translateY(" + (-50 + (progression*30))+ "px)",
            "transition-duration": transformDuration+"ms",
            "opacity": (startOpacityBackground - (progression*startOpacityBackground)),
        });

        homeTitle.setCss({
            "opacity": opacity,
        })

        awayTitle.setCss({
            "opacity": opacity,
        });

        topbar.setCss({
            "transform": "translate(0px," + Math.round(scrollDiff)+ "px)",
            "transition-duration": transformDuration+"ms"
        });

        statusContainer.setCss({
            "transform": "scaley("+scaleFontY+") scalex("+scaleFontX+")   translate(0px," + statusContainerProgression+ "px)",
            "transition-duration": transformDuration+"ms"
        });
    }
}