import { BouncePanel} from "./bouncepanel.js";

import { Panel} from "../bananaframework/src/controls/Panel.js";
import { UiControl} from "../bananaframework/src/controls/UiControl.js";
import { Label } from "../bananaframework/src/controls/Label.js";
import { Image } from "../bananaframework/src/controls/Image.js";

export class TabViewTitleHolder2 extends Panel{

    init (){

        this.currentActiveTab = -1;
        this.maxVisibleTabs = 5;

        super.init();
        this.tabTitles = [];
        this.addCssClass("tabholder2");

        var tabIndicator = new Panel();
        tabIndicator.addCssClass("tabviewindicator");
        this.addControl(tabIndicator);

        this.tabIndicator =tabIndicator;
    }

    recalculateSizes(){
        var w = this.getDimensions().width;
        var c = this.maxVisibleTabs;

        var tabw = Math.floor(w/c);

        this.calculatedTabWidth = tabw;

        for (var i=0; i < this.controls.length;i++){
            if (this.controls[i] instanceof UiControl){
                if (this.controls[i].hasCssClass("tabholder-title-container2")) {
                    this.controls[i].setCss({'width':tabw+"px"});
                    //this.controls[i].setCss({'width':tabw+"px"});
                }
            }
        }

        var indicatorOffset  = this.currentActiveTab * tabw;
        var speed = 0;

        $("#"+this.tabIndicator.getClientId()).css({"transform":"translate("+indicatorOffset+"px)","transition-duration": speed+"ms"});
    }

    renderTabs (tabs){

        var w = this.getDimensions().width;
        var c = this.maxVisibleTabs;

        var tabw = Math.floor(w/c);

        this.calculatedTabWidth = tabw;

        this.tabIndicator.setCss({'width':tabw-20+"px","margin-left":"10px"});

        var initialScrollPos = 0;
        for (var i =0; i < tabs.length;i++) {

            var tabTitleContainer = new Panel();
            tabTitleContainer.addCssClass("tabholder-title-container2");
            tabTitleContainer.setStyle("width:" + tabw + "px");
            this.addControl(tabTitleContainer);

            var tabTitleContainerBg = new Panel();
            tabTitleContainerBg.addCssClass("tabholder-title-container-bg");
            tabTitleContainer.addControl(tabTitleContainerBg);

            var tabTitle = new Panel();
            tabTitle.addControl(tabs[i].title);
            tabTitle.addCssClass("tabholder-title");
            tabTitleContainer.addControl(tabTitle);

            this.tabTitles.push(tabTitle);

            var that = this;
            var startClickTime = 0;
            (function (index, tabTitleContainer, tabTitleContainerBg) {
                tabTitleContainer.bind("touchstart", function () {
                    tabTitleContainerBg.addCssClass("tabholder-title-container-bg-touch")
                    tabTitleContainerBg.addCssClass("tabholder-title-container-bg-touch2")
                    startClickTime = new Date().getTime();
                    //console.log("tap")
                })
                tabTitleContainer.bind("touchend", function () {
                    if (new Date().getTime() - startClickTime > 300) {
                        tabTitleContainerBg.removeCssClass("tabholder-title-container-bg-touch2")
                    } else {
                        setTimeout(function () {
                            tabTitleContainerBg.removeCssClass("tabholder-title-container-bg-touch2")
                        }, 300)
                    }

                    if (new Date().getTime() - startClickTime > 100) {
                        tabTitleContainerBg.removeCssClass("tabholder-title-container-bg-touch")
                    } else {
                        setTimeout(function () {
                            tabTitleContainerBg.removeCssClass("tabholder-title-container-bg-touch")
                        }, 100)
                    }
                    //console.log("tap end");
                    that.tabClicked(index, 150);
                });
                tabTitleContainer.bind("click", function () {
                    // console.log('click');
                    that.tabClicked(index, 150);
                });
            })(i, tabTitleContainer, tabTitleContainerBg);
        }

        console.log("scroll to ",initialScrollPos);
    }

    moveToTab (index,speed){

        if (this.tabTitles[this.currentActiveTab]) {
            this.tabTitles[this.currentActiveTab].removeCssClass("active");
        }
        this.currentActiveTab = index;

        console.log("---------------------------> move to tab ",this.currentActiveTab,this.tabTitles[this.currentActiveTab].getClientId());
        console.log(this.tabTitles[this.currentActiveTab])
        setTimeout(()=>{
        this.tabTitles[this.currentActiveTab].addCssClass("active");
        },0)

        var w = this.getDimensions().width;
        var c = this.maxVisibleTabs;

        var tabw = Math.floor(w/c);
        var indicatorOffset  = index * tabw;

        $("#"+this.tabIndicator.getClientId()).css({"transform":"translate("+indicatorOffset+"px)","transition-duration": speed+"ms"});

        document.querySelector("#" + this.getClientId()).scrollTo({
            top: 0,
            left: (index * tabw) - (Math.floor(this.maxVisibleTabs /2) * tabw) ,
            behavior : speed === 0 ? "instant" : "smooth"
        });
    }

    translateTabIndicator (progression){
        var indicatorOffset  = (this.currentActiveTab * this.calculatedTabWidth) + (1*(this.calculatedTabWidth * progression));
        $("#"+this.tabIndicator.getClientId()).css({"transition-duration":"0ms","transform": "translate("+indicatorOffset+"px)"})
    }

    tabClicked (index,speed){
        this.triggerEvent("onTabClicked",{index:index,speed:speed});
    }
};

export class TabView2 extends Panel{


    init (){

        this.currentActiveTab = 0;
        this.enableScrolling = true;
        this.cachedElements = [];
        this.maxVisibleTabs = 5;
        this.cachedParllaxBgXOffset = 0;
        this.maxRenderedContent = 3;

        super.init();

        this.addCssClass("tabview");
        this.tagName = "nav";

        var tabHolder = new TabViewTitleHolder2();
        this.addControl(tabHolder);

        var tabHolderBg = new Panel();
        tabHolder.addControl(tabHolderBg);
        tabHolderBg.addCssClass("tabholder-bg");

        var tabContents = new Panel();
        tabContents.tagName = "main";
        tabContents.addCssClass("tabContents");
        this.addControl(tabContents);

        this.tabHolder = tabHolder;
        this.tabContents = tabContents;
    }

    unload (){
        this.cachedElements = null;
        this.tabs = null;
    }

    onWindowResize (){
        console.log("on window resize")
        var delay = 20;
        if (!this.windowResizeHandler) delay = 0;
        clearTimeout(this.windowResizeHandler);
        this.recalculateSizes();
    }

    createComponents (){
        this.tabHolder.maxVisibleTabs = this.maxVisibleTabs;
    }

    setTabTitles(tabs){
        this.tabs = tabs;
        for (var i=0;i<tabs.length;i++){
            tabs[i].tabIndex = i;
        }
    }

    renderTabs (){

        if (this.parallaxBg) {
            if (this.tabs.length > 1) {
                this.parallaxBg.setCss({'width': '120%'});
            }
            else{
                this.parallaxBg.setCss({'width': '100%'});
            }
        }

        this.tabHolder.renderTabs(this.tabs);
        this.tabHolder.bind("onTabClicked",(e,data)=>{
            this.moveToTab(data.index,data.speed);
        });
        this.tabHolder.invalidateDisplay();
        //
        var windowHeight = jQuery(window).height();
        var top = $("#"+this.tabHolder.getClientId()).height();
        var height = windowHeight - top - $("#"+this.tabHolder.getClientId()).offset().top;

        this.tabContents.setCss({'height':height+"px"});

        //calculate width and create tabs
        var w = this.getDimensions().width;

        //add the conent items
        var left = - (Math.floor(this.maxRenderedContent/2) * w);

        for (var i=0; i < this.maxRenderedContent;i++){
            var p = new BouncePanel();
            p.enableBounce();
            p.enableFastScroll = this.enableFastScroll;
            p.addCssClass("tab-content-item");
            //p.setStyle("width:"+w+"px;height:100%;left:"+left+"px;background-color:"+colors[i]+";");
            p.setStyle("width:100%;height:100%;left:"+left+"px;");
            this.tabContents.addControl(p);
            var tabContents = new Panel();
            tabContents.setStyle("width:100%;height:100%;");
            p.addControl(tabContents);
            left += w;
            var dataIndex = i - Math.floor(this.maxRenderedContent/2) + this.currentActiveTab;

            this.triggerEvent("requestTabContent",{
                dataIndex:dataIndex,
                elementIndex:i,
                target:tabContents});

            //we override this method to make sure the bouncepanel understands
            //which scroll element it needs
            p.getContentScrollContainer = function(){
                return this.controls[1].controls[0]; //hack assumption. for now ok
            };
        }

        this.triggerEvent("newContentTabVisible",this.tabContents.controls[Math.floor(this.maxRenderedContent/2)]);

        this.tabContents.invalidateDisplay();
        this.registerSwipe();

        this.triggerEvent("onReady");

        this.moveToTab(this.currentActiveTab, 0,true);
    };

    recalculateSizes(){

        var w = this.getDimensions().width;
        var left = - (Math.floor(this.maxRenderedContent/2) * w);
        var tabw = Math.floor(w/this.tabs.length);

        var windowHeight = jQuery(window).height();
        var top = $("#"+this.tabHolder.getClientId()).height();
        //we set the hight of the content here. we take the tabholder top and offsettop (when in collapsed mode)
        var height = windowHeight - top - $("#"+this.tabHolder.getClientId())[0].offsetTop;

        this.tabContents.setCss({'height':height+"px"});

        console.log("recalulalte tab size height",height," window height",windowHeight," tab holder top",$("#"+this.tabHolder.getClientId()).offset(),$("#"+this.tabHolder.getClientId())[0].offsetTop);

        for (var i=0; i < this.tabContents.controls.length;i++){
            console.log("order ",i,this.tabContents.controls[i].id);
            this.tabContents.controls[i].recalculate();
            this.tabContents.controls[i].setCss({"width":w+"px","left":left+"px"});
            this.tabContents.controls[i].repositionScrollbar();
            left += w;
        }
        this.tabHolder.recalculateSizes(tabw);

        //calculate tab contents height
        this.tabContents.setCss({'height':height+"px"});
        console.log("set height tab content ",height);
    }

    updateDisplay(){
        if (!this.tabsRendered) {
            this.renderTabs();
            this.tabsRendered = true;
            this.triggerEvent("tabsReady");
        }
    }

    moveToTab (index,speed,force){

        console.log("render tabss",index,speed)
        if (speed == null) speed = 240;
        if (!force && this.currentActiveTab == index){
            console.warn("cannot move to tab ",index,"already selected")
            return;
        }
        var trigger = false;
        if (this.currentActiveTab != index){
            trigger =true;
        }

        //console.log("move to tabber ",index,speed,this.currentActiveTab);

        this.tabHolder.moveToTab(index,speed)

        var w = this.getDimensions().width;
        var offset;

        var diff = 0;
        var fullRequest = false;
        if (index == this.currentActiveTab){
            offset = 0;
            console.log("no tab change")
        }
        else if (Math.abs(index - this.currentActiveTab) > 5){
            offset = index - this.currentActiveTab < 0 ? this.maxRenderedContent*w : -this.maxRenderedContent*w ;
            fullRequest = true;
            console.log("request full tab content request")
        }
        else{
            diff = index - this.currentActiveTab;
            console.log("request partial tab content request",diff,diff*w);
            offset = (diff) * w;
        }

        this.currentActiveTab = index;

        $("#"+this.tabContents.getClientId()).unbind("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd",this.transitionMethod);

        this.transitionMethod = ()=>{
            $("#"+this.tabContents.getClientId()).unbind("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd");
            $("#"+this.tabContents.getClientId()).css({"transform":"translate("+-0+"px)","transition-duration": 0+"ms"});

            if (fullRequest){
                for (var i=0; i < this.tabContents.controls.length;i++){

                    dataIndex = this.currentActiveTab + Math.floor(this.maxRenderedContent/2) + i - (0-1);
                    this.triggerEvent("requestTabContent",{
                        dataIndex:this.currentActiveTab+i-1,
                        elementIndex:i,
                        target:this.tabContents.controls[i].controls[1]});
                }
            }
            else if (diff > 0) {
                var rem = this.tabContents.controls.splice(0, diff);
                for (var i=0; i < rem.length;i++){
                    var dataIndex = this.currentActiveTab + Math.floor(this.maxRenderedContent/2) + i - (diff-1);
                    //console.log("request tab data index:",dataIndex," @element",this.maxRenderedContent-(rem.length-i);
                    this.triggerEvent("requestTabContent",{
                        dataIndex:dataIndex,
                        elementIndex:this.maxRenderedContent-(rem.length-i),
                        target:rem[i].controls[1]});
                }
                this.tabContents.controls = this.tabContents.controls.concat(rem);
            }
            else if (diff < 0){
                diff *=-1;
                //console.log('removed',diff,this.tabContents.controls.length - diff,diff);
                var rem = this.tabContents.controls.splice(Math.max(0,this.tabContents.controls.length - diff),diff);
                for (var i=0; i < rem.length;i++){
                    var dataIndex = this.currentActiveTab - Math.floor(this.maxRenderedContent/2) + i;
                    //console.log("request tab data index:",dataIndex," @element",this.maxRenderedContent-(rem.length-i));
                    this.triggerEvent("requestTabContent",{
                        oldIndex : this.tabContents.controls.length+i,
                        dataIndex:dataIndex,elementIndex:i,
                        target:rem[i].controls[1]});
                    //console.log("request new content for ",i+index+(Math.floor(this.maxRenderedContent/2))-diff);
                }
                this.tabContents.controls = rem.concat(this.tabContents.controls);

            }
            var left = - (Math.floor(this.maxRenderedContent/2) * w);
            for (var i=0; i < this.tabContents.controls.length;i++){
                //console.log("order no ",i,this.tabContents.controls[i].id);
                if (trigger) {
                    this.tabContents.controls[i].recalculate();
                    //we need to call update again to make sure new elements are
                    //binded in the bouncepanel.
                    this.tabContents.controls[i].updateDisplay();
                    this.tabContents.controls[i].setCss({"width": w + "px", "left": left + "px"});
                }
                left += w;
            }

            if (trigger){
                this.triggerEvent("newContentTabVisible",this.tabContents.controls[Math.floor(this.maxRenderedContent/2)]);
                this.triggerEvent("tabChanged");
            }
        };

        //this is called when the transition animation is finished
        //we then reorder the visible tab content items
        $("#"+this.tabContents.getClientId()).bind("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd",this.transitionMethod);

        //move the tab
        $("#"+this.tabContents.getClientId()).css({"transform":"translate("+-offset+"px)","transition-duration": speed+"ms"});

        //delete some caching
        this.cachedElements = [];
    }

    registerSwipe() {

        var that = this;
        var w = this.getDimensions().width;
        var cancelSpeed = 350;
        var stopSwiping = false;
        var stopScrolling = false;
        var finchingY = false;
        var finchingX = false;
        var lastScrollPosition = 0;
        var disableLeftRightAction = false;

        var tabContentsElem = jQuery("#"+that.tabContents.getClientId());
        that.cachedElements = [];

        $("#"+this.tabContents.getClientId()).swipe( {
            allowPageScroll:"vertical",
            //Generic swipe handler for all directions
            swipe:function(event, direction, distance, duration, fingerCount, fingerData,currentDirection) {

                //console.log(that.currentActiveTab,that.maxRenderedContent-1 % that.currentActiveTab);
                //that.tabs[that.maxRenderedContent-1 % that.currentActiveTab].parent.enableBounce();

                disableLeftRightAction = false;
                //console.log("swiped ","dir",direction,"dis",distance,"dur",duration,"w",w,disableLeftRightAction,currentDirection);

                if (finchingY){
                    that.tabContents.controls[Math.floor(that.maxRenderedContent/2)].setCss({"transform":"scaleY(1)"});
                    $("#"+that.tabContents.controls[Math.floor(that.maxRenderedContent/2)].getClientId()).addClass( "tabContentsScaleBack" ).one("webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend", function(){
                        $(this).removeClass( "tabContentsScaleBack" );
                        $(this).css("transform","scaleY(1)");
                    });

                    setTimeout(function(){
                        that.tabContents.controls[Math.floor(that.maxRenderedContent/2)].removeCssClass("tabContentsScaleBack")
                    },200)
                    finchingY = false;
                }
                else if (finchingX){
                    $("#"+that.tabContents.controls[Math.floor(that.maxRenderedContent/2)].getClientId()).addClass( "tabContentsScaleBackX" ).one("webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend", function(){
                        $(this).removeClass( "tabContentsScaleBackX" );
                        $(this).css("transform","scaleX(1)");
                    });

                    setTimeout(function(){
                        that.tabContents.controls[Math.floor(that.maxRenderedContent/2)].removeCssClass("tabContentsScaleBackX")
                    },200)
                    finchingX = false;
                }

                stopSwiping=  false;
                stopScrolling = false;
                if (direction == "left") {

                    if (disableLeftRightAction) return;

                    var targetIndex = that.currentActiveTab+1;
                    var speed = 200;

                    if (duration > 200) {
                        if (distance / w <= 0.50){
                            console.log("cancel",(distance / w));
                            targetIndex = that.currentActiveTab;
                            speed = cancelSpeed;
                        }
                    }

                    if (targetIndex > that.tabs.length-1){
                        targetIndex = that.tabs.length-1;
                        speed = cancelSpeed;
                    }

                    that.moveToTab(targetIndex,speed,true);
                }

                else if (direction == "right") {

                    if (disableLeftRightAction) return;

                    var targetIndex = that.currentActiveTab-1;
                    var speed = 200;

                    if (duration > 200) {
                        if (distance / w <= 0.50){
                            console.log("cancel");
                        //if (distance <= w - (w*0.2)){
                            targetIndex = that.currentActiveTab;
                            speed = cancelSpeed;
                        }
                    }

                    if (targetIndex < 0){
                        targetIndex = 0;
                        speed = cancelSpeed;
                    }
                    that.moveToTab(targetIndex,speed,true);
                }
                else{
                    speed = cancelSpeed;
                    that.moveToTab(that.currentActiveTab,speed,true);
                }
            },
            swipeStatus:function(event, phase, direction, distance, duration, fingers, fingerData, currentDirection)
            {

                var activeElementIndex = Math.floor(that.maxRenderedContent/2);

                //that.tabs[that.currentActiveTab].parent.disableBounce();
                if (!that.cachedElements[that.tabContents.controls[activeElementIndex].getClientId()]){
                    that.cachedElements[that.tabContents.controls[activeElementIndex].getClientId()]
                        = document.querySelector("#"+that.tabContents.controls[activeElementIndex].controls[1].controls[0].getClientId());
                }
                var elem = that.cachedElements[that.tabContents.controls[activeElementIndex].getClientId()];
                var speed = 0;
                var translation = 0;

                if (elem && elem.scrollTop == 0 && direction == "down"){
                    var t = Math.min(1,distance/100)
                    translation = 1+ (0.02 * t);
                   // console.log("set translation",duration)
                    that.tabContents.controls[activeElementIndex].setCss({
                        "transform":"scaleY("+translation+")",
                        "transform-origin": "top right"
                    });
                    finchingY = true;
                    return;
                }
                else if (elem && direction == "up" && elem.scrollHeight - elem.scrollTop == elem.offsetHeight) {
                    //console.log("up",elem.scrollHeight,elem.scrollTop)
                    var t = Math.min(1,distance/100)
                    translation = 1+ (0.02 * t);
                    that.tabContents.controls[activeElementIndex].setCss({
                        "transform":"scaleY("+translation+")",
                        "transform-origin": "bottom right"
                    });
                    finchingY = true;
                }

                if (elem) {
                    lastScrollPosition = elem.scrollTop;
                }

                if (phase=="cancel"){
                    speed = cancelSpeed;
                    console.log("cancel");
                    that.moveToTab(that.currentActiveTab,speed,true);
                    //stopSwiping=  false;
                    stopScrolling = false;
                    //str +="<br/>Handler not triggered. <br/> One or both of the thresholds was not met "
                    return;
                }
                else if (phase=="end"){
                    //console.log("end");
                    speed = cancelSpeed;
                    //that.moveToTab(that.currentActiveTab,speed,true);
                    //stopSwiping=  false;
                    stopScrolling = false;
                    //str +="<br/>Handler was triggered."
                    return;
                }

                //var xDistance = Math.abs(fingerData[0].start.x - fingerData[0].last.x);
                var xDistance = Math.abs(distance);

                //disable scrolling
                if (direction == "up" || direction == "down") {
                    disableLeftRightAction = true;
                    if (!stopScrolling) {
                        stopSwiping = true;
                    }
                }

                if (!disableLeftRightAction && !stopSwiping && direction == "right") {

                    stopScrolling = true;

                    if (fingerData[0].start.x < 35){
                        return;
                    }

                    t = 0

                    var tNonNormalised = xDistance;
                    if (that.currentActiveTab != 0) {
                        t = Math.min(w, distance);
                    }

                    var progression = t/(w);

                    tabContentsElem.css({"transition-duration":"0ms","transform": "translate("+t+"px)"})

                    that.tabHolder.translateTabIndicator(-progression);

                    if (progression == 0 && fingerData[0].start.x > 30){
                        var translation = 1+ (0.0005 * Math.min(40,tNonNormalised));
                        that.tabContents.controls[activeElementIndex].setCss({
                            "transform":"scaleX("+translation+")",
                            "transform-origin": "bottom left"
                        });
                        finchingX = true;
                    }
                }
                else if (!disableLeftRightAction && !stopSwiping && direction == "left"){
                    stopScrolling = true;
                    //event.preventDefault();
                    t = 0;
                    if (that.currentActiveTab != that.tabs.length-1){
                        var t = (-(Math.min(w,distance)));
                    }
                    var progression = t/(w);

                    tabContentsElem.css({"transition-duration":"0ms","transform": "translate("+t+"px)"})

                    that.tabHolder.translateTabIndicator(-progression);

                    if (progression == 0 && fingerData[0].start.x > 10){
                        var translation = 1+ (0.0005 * Math.min(20,xDistance));
                        that.tabContents.controls[activeElementIndex].setCss({
                            "transform":"scaleX("+translation+")",
                            "transform-origin": "bottom right"
                        });
                        finchingX = true;
                    }
                }
                else{

                }
            },
            //Default is 75px, set to 0 for demo so any distance triggers swipe
            threshold:30,
        });
    }
};
export class TabViewTab2 extends Panel{

    hideLoader(text,tab){
        if (!this.wrapper){
            //return console.error("cannot hide loader. never shown");
            return;
        }
        this.wrapper.setVisible(false);
    }
    
    showLoader(text,tab){

        if (this.wrapper){
            if (text) this.loaderText.setData(text);
            this.wrapper.setVisible(true);
            return;
        }

        this.loaderContainer = new Panel();
        this.loaderContainer.addCssClass("loader_container");

        this.wrapper = new Panel();
        this.wrapper.setStyle("margin-top:-50px;")
        this.wrapper.addCssClass("loader_wrapper")
        this.wrapper.addControl(this.loaderContainer);
        this.loaderSpinner = new Image();
        this.loaderSpinner.addCssClass("loader_spinner");
        this.loaderSpinner.setImage("images/loader1235.svg");
        this.loaderContainer.addControl(`
        <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
   <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
</svg>  
        `);

        this.loaderText = new Label();
        this.loaderText.setData();
        if (text) this.loaderText.setData(text);
        this.loaderText.addCssClass("loader_text");
        this.loaderContainer.addControl(this.loaderText);

        var fade = new Panel();
        fade.addCssClass("loader_fade");
        this.wrapper.addControl(fade);

        var background = new Panel();
        //background.addCssClass("loader_background");
        //this.loaderContainer.addControl(background);

        this.loaderfade = fade;
        this.addControl(this.wrapper,true);
    }
};