import {Panel} from "../../bananaframework/src/controls/Panel.js";
import {Modal} from "../modal.js";
import {Localizer} from "../../util/localizer.js";

export class OddsModal extends Modal{

    init (urlIdentifier,data) {
        super.init(urlIdentifier);
        this.data = data;
    }

    unload (){
        clearTimeout(this.commentaryRefreshHandler);
    }

    createComponents () {

        super.createComponents();

        var dim = this.getPage().getDimensions();

        var p = new Panel();
        p.setStyle("height:" + (dim.height - 50) + "px;justify-content: flex-start;align-items: flex-start;overflow-y:scroll;");
        p.addCssClass("flexVerticalAlign");

        var title = new Panel();
        title.addCssClass("addFavoriteModalTitle");
        title.addControl(Localizer.getString("odd_title"));
        this.addControl(title);

        this.addControl(p)
        this.setVisible(false);

        setTimeout(()=>{
            this.setVisible(true);
            this.reValidateSize();
        },50);

        for (var i=0; i < this.data.odds.bookmakers.length;i++){
            var bookmaker = this.data.odds.bookmakers[i];
            this.renderOddItem(bookmaker,p)
        }
    }

    renderOddItem (bookmaker,target){
        var mainContainer = new Panel();
        mainContainer.addCssClass("flexVerticalAlign");
        mainContainer.setStyle("width:100%;height:auto;");
        var topTitle = new Panel();
        topTitle.addControl("<i>"+bookmaker.name+"</i>");
        topTitle.addCssClass("MatchStatsTitleText MatchH2HTitle")
        var topContainer = new Panel();
        topContainer.addCssClass("flexHorizontalAlign");
        topContainer.setStyle("justify-content: space-around;margin-bottom:10px;width:100%;");

        var leftContainer = new Panel();
        var middleContainer = new Panel();
        var rightContainer = new Panel();

        leftContainer.addControl(bookmaker.homeValue);
        middleContainer.addControl(bookmaker.drawValue);
        rightContainer.addControl(bookmaker.awayValue);

        topContainer.addControl(leftContainer);
        topContainer.addControl(middleContainer);
        topContainer.addControl(rightContainer);

        mainContainer.addControl(topTitle);
        mainContainer.addControl(topContainer);

        target.addControl(mainContainer);
    }
};