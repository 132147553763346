import {Page} from "../bananaframework/src/controls/Page.js";
import {Panel} from "../bananaframework/src/controls/Panel.js";
import {Label} from "../bananaframework/src/controls/Label.js";
import {Image} from "../bananaframework/src/controls/Image.js";
import {UrlManager} from "../bananaframework/src/util/UrlManager.js";

import {Router} from "../util/router.js";
import {Localizer} from "../util/localizer.js";
import {ManiaAds} from "../util/ads.js";

export class BasePage extends Page{

    init (){
        super.init();
    }

    onVisibilityChange (){
        if (document.hidden){
            Router.hideBanner();
        }
        else{
            Router.showBanner();
        }
    }

    //called when page controller wants to load a new page.
    onPageDetached (){
        this.isDetached = true;
    }

    isReferalOtherPage (){

        var referalData = UrlManager.getModule("referalData");
        if (referalData){
            console.log("ref data",referalData);
            referalData = decodeURIComponent(JSON.stringify(referalData));
            console.log("ref data",referalData);
            if (referalData.origin_section == UrlManager.getModule("section")){
                return false;
            }
            return true;
        }
        return true;
    }

    showLoader (text,bgOpacity){

        if (this.wrapper){
            if (text) this.loaderText.setData(text);
            this.wrapper.setVisible(true);
            return;
        }

        this.loaderContainer = new Panel();
        this.loaderContainer.addCssClass("loader_container");

        this.wrapper = new Panel();
        this.wrapper.addCssClass("loader_wrapper")
        this.wrapper.addControl(this.loaderContainer);
        this.loaderSpinner = new Image();
        this.loaderSpinner.addCssClass("loader_spinner");
        this.loaderSpinner.setImage(new URL("/images/loader1235.svg", import.meta.url));
        this.loaderContainer.addControl(`
        <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
   <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
</svg>
        `);

        this.loaderText = new Label();
        this.loaderText.setData();
        if (text) this.loaderText.setData(text);
        this.loaderText.addCssClass("loader_text");
        this.loaderContainer.addControl(this.loaderText);

        var fade = new Panel();
        fade.addCssClass("loader_fade");
        this.wrapper.addControl(fade);

        var background = new Panel();
        background.addCssClass("loader_background");
        this.loaderContainer.addControl(background);

        this.loaderfade = fade;
        this.getPage().addControl(this.wrapper,true);
    }

    showSucces (message,timeout){

        console.log("show succes")
        if (this.feedbackPanel) this.feedbackPanel.remove();

        var feedbackPanel = new Panel();
        feedbackPanel.addControl(message);
        feedbackPanel.addCssClass("alert alert-success");
        feedbackPanel.addCssClass("feedbackPanel");
        feedbackPanel.setStyle("margin-top:-16px;");
        this.feedbackPanel = feedbackPanel;

        setTimeout(()=>{
            feedbackPanel.addCssClass("feedbackPanelFadeOut");
            setTimeout(this.getProxy(function(){
                feedbackPanel.remove();
            }),1000);
        },timeout);

        this.feedback.addControl(feedbackPanel,true);
    }

    showError (message,cb){
        if (this.errorWrapper) this.errorWrapper.remove();

        var errorWrapper = new Panel();
        errorWrapper.addCssClass("error");

        var errorImage = new Panel();
        errorImage.addCssClass("no-internet");
        errorWrapper.addControl(errorImage);
        errorWrapper.addControl(Localizer.getString("nointernet")+"<br>");
        errorWrapper.addControl(Localizer.getString("taptotryagain"));

        this.errorWrapper = errorWrapper;

        this.errorWrapper.unbind("click");
        this.errorWrapper.bind("click",()=>{
            this.errorWrapper.remove()
            this.errorWrapper = null;
            cb();
        });

        this.getPage().addControl(this.errorWrapper,true);
    }

    showPageDataDoesNotExist (message,cb){
        if (this.errorWrapper) this.errorWrapper.remove();

        var errorWrapper = new Panel();
        errorWrapper.addCssClass("error");

        var errorImage = new Panel();
        errorImage.addCssClass("no-internet");
        errorWrapper.addControl(errorImage);
        errorWrapper.addControl(Localizer.getString("no_data")+"<br>");
        errorWrapper.addControl(Localizer.getString("tap_to_return"));

        this.errorWrapper = errorWrapper;

        this.errorWrapper.unbind("click");
        this.errorWrapper.bind("click",()=>{
            this.errorWrapper.remove()
            this.errorWrapper = null;
            history.back();
        });

        this.getPage().addControl(this.errorWrapper,true);
    }

    showCriticalError (message,timeout){
        if (this.feedbackPanel) this.feedbackPanel.remove();
        if (this.errorImage) this.errorImage.remove();

        var feedbackPanel = new Panel();
        feedbackPanel.addControl(message);
        feedbackPanel.addCssClass("alert alert-danger");
        feedbackPanel.addCssClass("feedbackPanel");
        feedbackPanel.setStyle("margin-top:-16px;");
        this.feedbackPanel = feedbackPanel;

        setTimeout(()=>{
            feedbackPanel.addCssClass("feedbackPanelFadeOut");
            setTimeout(()=>{
                feedbackPanel.remove();
            },1000);
        },timeout);
        this.feedback.addControl(feedbackPanel,true);

        var image = new Image();
        image.addCssClass("error_image");
        //image.setImage(new URL("/images/oeps.svg", import.meta.url));
        this.errorImage = image;
        this.addControl(image,true);
    }

    setLoaderText (text,color){
        if (this.loaderText) this.loaderText.setData(text);
        if (color) this.loaderText.setCss({'color':color});
    }

    hideLoader (){
        setTimeout(()=>{
            if (this.wrapper){
                this.wrapper.remove();
                this.wrapper = null;
            }
        },1);
    }
};