import { Panel } from "../bananaframework/src/controls/Panel.js";
import { Image } from "../bananaframework/src/controls/Image.js";
import { Label } from "../bananaframework/src/controls/Label.js";
import { Control } from "../bananaframework/src/controls/Control.js";
import { DataGrid } from "../bananaframework/src/controls/listcontrols/datagrids/Datagrid.js";
import { UrlManager } from "../bananaframework/src/util/UrlManager.js";
import { BasePage } from "./basepage.js";
import { Router } from "../util/router.js";
import { ManiaPreferences } from "../util/preferences.js";
import { Localizer } from "../util/localizer.js";
import { ManiaCache } from "../util/cache.js";
import { Favorites } from "../util/favorites.js";
import { ManiaAds } from "../util/ads.js";
import { Subscriber } from "../util/subscriber.js";
import { Processors } from "../util/processors.js";
import { DragHelper } from "./helpers/home_favourites_draghelper.js";
import { AnimatorHome } from "./animators/animatorhome.js";
import { DataProvider } from "../data/dataprovider.js";
import { StateManager } from "../data/stateManager.js";
import { TabView, TabViewTab } from "../components/tabview.js";
import { ErrorMessage } from "../components/errormessage.js";
import { LazyTileGridRenderer } from "../components/lazytilegridrenderer.js";
import { FavoriteLeagueAdderModal } from "../components/home/favoriteleagueaddermodal.js";
import { FavoriteTeamAdderModal } from "../components/home/favoriteteamaddermodal.js";
import {
    MatchItemRender,
    FavoriteLeagueAdderItemRender,
    HeaderLeagueFavoriteItemRender,
    HeaderTeamFavoriteItemRender,
    FavoriteTeamAdderItemRender,
    FavoriteLeagueItemRender,
    AgendaItemRender,
    SpecialItemItemRender,
    HeaderItemRender,
    CountryItemRender,
    FavoriteTeamItemRender,
    MatchHeaderItemRender,
    MatchSuggestionsItemRender
} from "../components/home/listrendershome.js";
import {Accessibility} from "../util/accessibility.js";

export class Home extends BasePage {

    homeLoadHandler = null;
    liveLoadHandler = null;
    todayLoadHandler = null;

    init() {
        super.init();

        this.pageName = "Home";
        this.version = "1.0.0";

        this.addCssClass("HomePage");

        //make sure that fresh new page always go to start screen
        //on android nly
        if (window.JSAndroidBridge && !window.firstIsPageLoaded) {
            StateManager.flushPageState(this.pageName);
            window.firstIsPageLoaded = true;
            this.isFirstTimeBeforeLoading = true;
        } else if (!window.firstIsPageLoaded) {
            window.firstIsPageLoaded = true;
            this.isFirstTimeBeforeLoading = true;
        }
        this.enableFastScroll = true;

        //here we know if we come from the same page or another.
        //if we navigate from back history or refresh we are not from the outside.
        //if we navigate to this page from another and the referaldata is passed in the url params
        //then we are from the outside.
        this.isNavigatedFromOutside = Router.navigationIsFromOutside();
        Router.updateCurrentReferalData();
        if (this.isNavigatedFromOutside) {
            StateManager.flushPageState(this.pageName);
        }
        console.log("OPENING PAGE " + this.pageName + " VERSION " + this.version + " is from outside " + this.isNavigatedFromOutside);
        this.createPageLayout();
        this.coordinatoranimator = new AnimatorHome();
        this.bind("onFavoriteChanged", (e, ignoreHome) => {

            console.log("Favorite changed => reload lists, including home=", ignoreHome);
            if (!ignoreHome) {
                this.loadFeedAppStart(false, true, false, true);
            }
            this.loadLive(false, true, false, true);
            this.loadToday(false, true, false, true);
        });
        this.bind("searchActivated",()=>{

        })
        this.bind("searchDeactivated",()=>{

        });

        if (AppInstance.workersInitialized) {
            this.wrapper1 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper1.addEventListener("message", this.wrapper1);
            this.wrapper2 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper2.addEventListener("message", this.wrapper2);
            this.wrapper3 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper3.addEventListener("message", this.wrapper3);
        }

        ManiaPreferences.bind("showOnlyFavoritesAgendaChanged", () => {
            this.reApplyDataSourceInListRenders();
        });
        ManiaPreferences.bind("userpref_matchlistTypeChanged", () => {
            this.reApplyDataSourceInListRenders();
        });

        setTimeout(() => {
            ManiaCache.prune();
            StateManager.purgeNonImportant();
        }, 8000);
        Router.allowBackButtonQuitApp();

        if (AppInstance.isOnline()) {
            DataProvider.iamhere();
        }

        Accessibility.init(this);
    }
    onWorkerMessageReceived(e) {
        if (e.data[0] == 1) {
            if (this.unloaded) {
                return console.warn("page is unloaded abort further actions");
            }

            this.foundUnfilteredLiveData = e.data[2];
            ManiaCache.setMemoryCache("fixtureLive", e.data[1]);
            this.liveListrender.containerTarget.hideLoader();
            this.liveListrender.setDataSource(e.data[1]);
        } else if (e.data[0] == 0) {
            if (this.unloaded) {
                return console.warn("page is unloaded abort further actions");
            }
            ManiaCache.setMemoryCache("homeData", e.data[1]);
            this.homeListrender.containerTarget.hideLoader();
            this.homeListrender.setDataSource(e.data[1]);
        } else if (e.data[0] == 2) {
            if (this.unloaded) {
                return console.warn("page is unloaded abort further actions");
            }
            ManiaCache.setMemoryCache("fixtureToday", e.data[1]);
            this.todayListrender.containerTarget.hideLoader();
            this.todayListrender.setDataSource(e.data[1]);
        }
    }
    storeState() {
        if (this.unloaded || !this.tabview) return;

        var d = {
            "tabIndex": this.tabview.currentActiveTab,
            "homeScrollPosition": this.homeListrender.getScroll(),
            "liveScrollPosition": this.liveListrender.getScroll(),
            "todayScrollPosition": this.todayListrender.getScroll(),
            "headerCollapsed": this.coordinatoranimator.isVirtuallyCollapsed
        };
        if (this.teamSelectModal) {
            d.teamSelectModalOpen = 1;
        }
        if (this.leagueSelectModal) {
            d.leagueSelectModalOpen = 1;
        }
        //console.log("store state ",d,this.coordinatoranimator.isCollapsed,this.coordinatoranimator.isVirtuallyCollapsed);
        StateManager.savePageState(this.pageName, d);
    }
    unload() {
        if (!this.ignoreStateSaveOnUnload) {
            this.storeState();
        }
        this.unloaded = true;
        $.xhrPool.abortAll();
        this.coordinatoranimator.unload();
        clearTimeout(this.liveLoadHandler);
        clearTimeout(this.todayLoadHandler);
        clearTimeout(this.homeLoadHandler);
        ManiaPreferences.unbind("showOnlyFavoritesAgendaChanged");
        ManiaPreferences.unbind("userpref_matchlistTypeChanged");

        if (AppInstance.workersInitialized) {
            AppInstance.workerHelper1.removeEventListener("message", this.wrapper1);
            AppInstance.workerHelper2.removeEventListener("message", this.wrapper2);
            AppInstance.workerHelper3.removeEventListener("message", this.wrapper3);
        }

        Accessibility.unload();
    }
    getActiveListRender() {
        //console.log(this.tabview.tabs);
        //console.log(this.tabview.tabs[this.tabview.currentActiveTab].controls[0].controls[0]);
        return this.tabview.tabs[this.tabview.currentActiveTab].controls[0].controls[0];
    }
    createPageLayout() {
        var row1 = new Panel();
        this.addControl(row1);
        this.contentContainer = row1;
    }
    createComponents() {
        // this.createMenuItems();
        this.getPageTemplate().hideTitle();
    }
    createMenuItems() {
        if (FootballManiaConfig.isRtl && (this.tabview.currentActiveTab == 1 || this.tabview.currentActiveTab == 0)) {
            this.getPageTemplate().createMenuHome(true,true);
        } else if (!FootballManiaConfig.isRtl && (this.tabview.currentActiveTab == 1 || this.tabview.currentActiveTab == 2)) {
            this.getPageTemplate().createMenuHome(true,true);
        } else {
            this.getPageTemplate().createMenuHome();
        }
        if (UrlManager.getModule("search") == "open") {
            this.getPageTemplate().activateSearch();
        }
    }
    onVisibilityChange() {
        super.onVisibilityChange();
        if (this.isDetached) {
            console.log("page is detached abort further actions");
            return;
        }
        //page is becoming visible after hide
        if (this.previousDocumentHidden = true && !document.hidden) {
            this.autoRefreshHome(1200);
            this.autoRefreshLive(200);
            this.autoRefreshToday(500);
            Router.showBanner();
        }
        if (!document.hidden) {
            Router.allowBackButtonQuitApp();
        }
        if (document.hidden && this.homeListrender) {
            this.autoScrollToFromState(100);
            this.getPageTemplate().deActivateSearch();
            this.homeListrender.triggerEvent("requestDeActivateDrag", true);
        }
        this.previousDocumentHidden = document.hidden;
    }
    onWindowResize() {
        if (this.unloaded) {
            return console.warn("page is unloaded abort further actions");
        }
        if (this.isDetached) {
            console.log("page is detached abort further actions");
            return;
        }
        if (this.ignoreResizeEvents) {
            console.log("page is ignoring resize events");
            return;
        }

        //this.getPageTemplate().topBar.addControl(this.i,true);
        console.log("onwindow resize");
        super.onWindowResize();
        if (this.tabview) {
            this.tabview.onWindowResize();
        }
        if (this.coordinatoranimator) {
            this.coordinatoranimator.onWindowResize();
        }
        if (this.homeListrender) {
            this.homeListrender.onWindowResize();
            this.liveListrender.onWindowResize();
            this.todayListrender.onWindowResize();
        }
    }
    onOffline() {
        console.log("on offline");
        this.autoRefreshHome(0);
        this.autoRefreshLive(22);
        this.autoRefreshToday(44);
        this.createMenuItems();
    }
    onOnline() {
        console.log("on online");
        this.autoRefreshHome(0);
        this.autoRefreshLive(22);
        this.autoRefreshToday(44);
        this.createMenuItems();
    }
    updateDisplay() {
        this.runApp();
    }
    autoScrollToFromState(thresshold) {
        var previousState = StateManager.getCurrentPageState(this.pageName);
        if (previousState) {
            this.tabview.currentActiveTab = previousState.tabIndex;
            //this.tabview.moveToTab(previousState.tabIndex);
            if (previousState.homeScrollPosition < (thresshold || 50)) {
                previousState.homeScrollPosition = 0;
            }
            if (previousState.liveScrollPosition < (thresshold || 50)) {
                previousState.liveScrollPosition = 0;
            }
            if (previousState.todayScrollPosition < (thresshold || 50)) {
                previousState.todayScrollPosition = 0;
            }
            if (this.homeListrender) {
                this.homeListrender.scrollTo(previousState.homeScrollPosition || 0);
                this.liveListrender.scrollTo(previousState.liveScrollPosition || 0);
                this.todayListrender.scrollTo(previousState.todayScrollPosition || 0);
                this.coordinatoranimator.isCollapsed = previousState.headerCollapsed;
            }
        }
    }
    runApp() {
        this.showLoader();
        AppInstance.setup(result => {

            console.log("setup complete");
            if (this.unloaded) {
                return console.warn("page is unloaded abort further actions");
            }
            ;

            if (!result) {
                this.hideLoader();
                console.error("error app setup");
                return this.showError("", () => {
                    this.runApp();
                });
            }
            this.hideLoader();
            this.createTabs();
            this.tabview.bind("tabChanged", () => {

                this.storeState();
                this.coordinatoranimator.tabChange();
                setTimeout(() => {
                    this.createMenuItems();
                }, 50);
                setTimeout(() => {
                    Router.showBanner();
                }, 1000);
                this.autoSetAccessiblity();
            });
            var storeHandler = null;
            this.homeListrender.bind("scroll", (e, scroll) => {
                clearTimeout(storeHandler);
                storeHandler = setTimeout(() => {
                    this.storeState();
                }, 200);
            });
            this.liveListrender.bind("scroll", (e, scroll) => {
                clearTimeout(storeHandler);
                storeHandler = setTimeout(() => {
                    this.storeState();
                }, 200);
            });
            this.todayListrender.bind("scroll", (e, scroll) => {
                clearTimeout(storeHandler);
                storeHandler = setTimeout(() => {
                    this.storeState();
                }, 200);
            });
            if (FootballManiaConfig.defaultHomeScreen == "comp") {
                this.tabview.currentActiveTab = FootballManiaConfig.isRtl ? 2 : 0;
            } else if (FootballManiaConfig.defaultHomeScreen == "live") {
                this.tabview.currentActiveTab = 1;
            } else if (FootballManiaConfig.defaultHomeScreen == "today") {
                this.tabview.currentActiveTab = FootballManiaConfig.isRtl ? 0 : 2;
            }

            this.autoScrollToFromState();
            this.contentContainer.invalidateDisplay();
            this.coordinatoranimator.prepare();
            this.coordinatoranimator.onCollapseChanged = () => {
                this.storeState();
            };
            this.loadFeedAppStart(false, false, false, false);
            this.loadLive(false, false, false, false);
            this.loadToday(false, false, false, false);
            this.isFirstTimeBeforeLoading = false;
            this.bind("onModalOpen", (e, modal) => {
                console.log(modal.urlIdentifier);
                this.teamSelectModal = null;
                this.leagueSelectModal = null;
                if (modal.urlIdentifier == "teamSelectModal") {
                    this.teamSelectModal = modal;
                } else if (modal.urlIdentifier == "leagueSelectModal") {
                    this.leagueSelectModal = modal;
                }
                this.storeState();
                Router.disallowBackButtonQuitApp();
            });
            this.bind("onModalClose", () => {
                this.teamSelectModal = null;
                this.leagueSelectModal = null;
                this.storeState();
                Router.allowBackButtonQuitApp();
            });
            var previousState = StateManager.getCurrentPageState(this.pageName);
            if (previousState && previousState.teamSelectModalOpen) {
                setTimeout(() => {
                    var urlIdentifier = "teamSelectModal";
                    var modal = new FavoriteTeamAdderModal(urlIdentifier);
                    this.addControl(modal, true);
                });
            } else if (previousState && previousState.leagueSelectModalOpen) {
                setTimeout(() => {
                    var urlIdentifier = "leagueSelectModal";
                    var modal = new FavoriteLeagueAdderModal(urlIdentifier);
                    this.addControl(modal, true);
                });
            }
            this.autoSetAccessiblity();
        });
    }
    autoSetAccessiblity() {
        // if (this.tabview.currentActiveTab == 0 || (FootballManiaConfig.isRtl && this.tabview.currentActiveTab == 2)) {
        //     Accessibility.setTitle(Localizer.getString("title_home_start"));
        // } else if (this.tabview.currentActiveTab == 1) {
        //     Accessibility.setTitle(Localizer.getString("title_home_live"));
        // } else if (this.tabview.currentActiveTab == 2 || (FootballManiaConfig.isRtl && this.tabview.currentActiveTab == 0)) {
        //     Accessibility.setTitle(Localizer.getString("title_home_today"));
        // }
    }
    createTabs() {
        var tabview = new TabView();
        this.tabview = tabview;
        tabview.enableFastScroll = true;
        tabview.parallaxBg = this.bg_image;
        this.contentContainer.addControl(tabview);
        var tabs = [];

        var tab1 = new TabViewTab();
        tab1.addCssClass("listViewScrollableContainer");
        tab1.title = Localizer.getString("start");
        var tab2 = new TabViewTab();
        tab2.addCssClass("listViewScrollableContainer");
        tab2.title = Localizer.getString("live");
        var tab3 = new TabViewTab();
        tab3.addCssClass("listViewScrollableContainer");
        tab3.title = Localizer.getString("todaysmatches");
        this.coordinatoranimator.addCollapser(this.getPageTemplate().topBar);
        this.coordinatoranimator.addTabView(tabview);
        if (FootballManiaConfig.isRtl) {
            tabs.push(tab3);
            tabs.push(tab2);
            tabs.push(tab1);
            this.coordinatoranimator.addScrollerContainer(tab3);
            this.coordinatoranimator.addScrollerContainer(tab2);
            this.coordinatoranimator.addScrollerContainer(tab1);
            this.createHome(tab1, 2);
            this.createLive(tab2, 1);
            this.createToday(tab3, 0);
            tabview.currentActiveTab = 2;
        } else {
            tabs.push(tab1);
            tabs.push(tab2);
            tabs.push(tab3);
            this.coordinatoranimator.addScrollerContainer(tab1);
            this.coordinatoranimator.addScrollerContainer(tab2);
            this.coordinatoranimator.addScrollerContainer(tab3);
            this.createHome(tab1, 0);
            this.createLive(tab2, 1);
            this.createToday(tab3, 2);
        }
        tabview.setTabs(tabs);
        tabview.bind("onReady", () => {
            if (this.unloaded) {
                return console.warn("page is unloaded abort further actions");
            }
            ;
            this.contentContainer.addCssClass("fadeIn").setStyle("margin-top:0;");
            this.coordinatoranimator.run();
            console.log("tab view ready", tabview.currentActiveTab);
            this.createMenuItems();
        });
        tabview.bind("swipeStart",() => {
           console.log("swipe start");
        });
        tabview.bind("swipeEnd",() => {
            console.log("swipe end");
            this.applyDelayedDataSource();
        });
        //triggered from page template
        this.bind("stickyBannerLoadFail", () => {
            console.error("sticky banner load fail -> set height to 100%");
            tab1.setCss({'height': '100%'});
            tab2.setCss({'height': '100%'});
            tab3.setCss({'height': '100%'});
        });
        //triggered from page template
        this.bind("stickyBannerLoadSuccess", () => {
            console.log("sticky banner load success -> set height to 100% - 50px");
            tab1.setCss({'height': 'calc(100% - 50px)'});
            tab2.setCss({'height': 'calc(100% - 50px)'});
            tab3.setCss({'height': 'calc(100% - 50px)'});
        });
        this.getPageTemplate().loadStickyBannerAd();
    }
    createHome(tab, index) {
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");
        var listRender = new LazyTileGridRenderer();
        listRender.enableFastScroll = true;
        listRender.containerTarget = tab;
        listRender.tabview = this.tabview;
        listRender.setItemRender(function (data) {
            if (data.type == 6) {
                return {
                    typeId: 6,
                    render: new AgendaItemRender()
                };
            }
            if (data.type == 3) {
                return {
                    typeId: 3,
                    render: new SpecialItemItemRender()
                };
            } else if (data.type == 2) {
                return {
                    typeId: 2,
                    render: new HeaderItemRender()
                };
            } else if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new CountryItemRender()
                };
            } else if (data.type == 4) {
                return {
                    typeId: 4,
                    render: new FavoriteTeamItemRender()
                };
            } else if (data.type == 5) {
                return {
                    typeId: 5,
                    render: new FavoriteLeagueItemRender()
                };
            } else if (data.type == 7) {
                return {
                    typeId: 7,
                    render: new FavoriteTeamAdderItemRender()
                };
            } else if (data.type == 8) {
                return {
                    typeId: 8,
                    render: new HeaderTeamFavoriteItemRender()
                };
            } else if (data.type == 9) {
                return {
                    typeId: 9,
                    render: new HeaderLeagueFavoriteItemRender()
                };
            } else if (data.type == 10) {
                return {
                    typeId: 10,
                    render: new FavoriteLeagueAdderItemRender()
                };
            } else if (data.type == 11) {
                return {
                    typeId: 11,
                    render: new MatchSuggestionsItemRender()
                };
            }
        });
        listRender.emptyTemplate = function () {
            var empty = new Panel();
            empty.addCssClass("listViewEmptyData");
            empty.addControl(Localizer.getString("no_data"));
            return empty;
        };
        listRender.errorTemplate = function (cb) {
            return new ErrorMessage(cb);
        };
        listRender.noInternetTemplate = function () {
            return new ErrorMessage();
        };
        listRender.bind('createdList', () => {
            this.coordinatoranimator.recalculateTab(index);
        });
        listRender.bind("fastScrollIndicatorClicked", (e, item) => {
            //console.log("not implemented. not needed now");
        });
        var dragHelper;
        listRender.bind("requestActivateDrag", (e, item) => {
            console.log("long press item", item);
            dragHelper = new DragHelper();
            dragHelper.setDragItem(item);
            dragHelper.setListrender(listRender);
            dragHelper.activate();
            listRender.stopOnDatasourceChangeUpdates();
        });
        listRender.bind("requestReflowDrag", (e, item) => {
            console.log("requestReflowDrag", item);
            var previousWasStopped = listRender.stopOnDatasourceChangeUpdatesFlag;
            listRender.continueOnDatasourceChangeUpdates();
            this.triggerEvent("onFavoriteChanged");
            setTimeout(()=>{
                if (dragHelper) {
                    dragHelper.activate();
                }
                if (previousWasStopped) {
                    listRender.stopOnDatasourceChangeUpdates();
                }
            },200);
        });
        listRender.bind("onDrag", (e, item) => {
            this.tabview.disableSwipe();
        });
        listRender.bind("requestDeActivateDrag", (e, ignoreFavoriteChange) => {
            this.tabview.enableSwipe();
            listRender.continueOnDatasourceChangeUpdates();
            if (dragHelper) {
                dragHelper.deactivate();
                dragHelper = null;
            }
            console.log("requestDeActivateDrag", ignoreFavoriteChange);
            if (!ignoreFavoriteChange) {
                Favorites.cachedTeamFavorites = null;
                Favorites.cachedLeagueFavorites = null;
                this.triggerEvent("onFavoriteChanged");
            }
        });
        listRender.bind("requestDeleteItem",(e,data)=>{
            console.log("request delete item",data);
        });
        listRender.fastScrollElementTemplate = () => {
            var panel = new Control();
            var icon = new Image();
            var label = new Label();
            panel.addControl(icon);
            panel.addControl(label);
            return panel;
        };
        listRender.bind('requestFastScrollIndicatorItem', (e, data) => {
            if (!data.item) {
                return;
            }
            var icon = data.element.controls[0];
            var label = data.element.controls[1];
            if (data.item.type === 1) {
                icon.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.item.country.replaceAll(" ", "-").toLowerCase() + ".png", 30, 30);
            } else if (data.item.type === 4) {
                icon.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/" + data.item.id + "_small_rnd.png", 30, 30);
            } else {
                icon.setImage("");
            }
            label.setData(data.item.countryLocalized || data.item.name || data.item.title);
        });
        listRender.setPlaceHolderWidth("100%;");
        var previousState = StateManager.getCurrentPageState(this.pageName);
        if (previousState) {
            listRender.scrollTo(previousState.homeScrollPosition || 0);
        }
        container.addControl(listRender);
        this.homeListrender = listRender;
        tab.addControl(container);
    }
    createToday(tab, index) {
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");
        var listRender = new LazyTileGridRenderer();
        listRender.enableFastScroll = true;
        //listRender.enablePullToRefresh = true;
        listRender.tabview = this.tabview;
        listRender.containerTarget = tab;
        listRender.setItemRender(function (data) {
            if (data.type == 2) {
                return {
                    typeId: 1,
                    render: new MatchHeaderItemRender()
                };
            }
            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new MatchItemRender(false,undefined,true)
                };
            }
        });
        listRender.emptyTemplate =  ()=> {
            var empty = new Panel();
            empty.addCssClass("listViewEmptyData");
            var attention = new Panel();
            attention.addCssClass("icon-attention");
            attention.setStyle("width:60px;height:60px;");
            empty.addControl(attention);
            empty.addControl("<p style='padding:5px;'>" + Localizer.getString("currently_no_matches") + "</p>");
            let set = ManiaPreferences.getShowOnlyFavoritesAgenda();
            if (set) {
                empty.addControl("<div style='color:orange;margin-top:20px;margin-left:50px;margin-right:50px;' class='text-small'>" + Localizer.getString("you_have_enabled_favorite_filter") + "</div>");
            }

            this.coordinatoranimator.recalculateTab(index);

            //empty.addControl("<div style='margin-top:20px;' class='text-small'>"+Localizer.getString("you_have_enabled_favorite_filter")+"</div>");
            return empty;
        };
        listRender.errorTemplate = function (cb) {
            return new ErrorMessage(cb);
        };
        listRender.noInternetTemplate = function () {
            return new ErrorMessage();
        };
        listRender.bind('createdList', () => {
            this.coordinatoranimator.recalculateTab(index);
        });
        listRender.bind("fastScrollIndicatorClicked", (e, item) => {
            //console.log("not implemented. not needed now");
        });
        listRender.fastScrollElementTemplate = () => {
            var panel = new Control();
            var icon = new Image();
            var label = new Panel();
            panel.addControl(icon);
            panel.addControl(label);
            return panel;
        };
        listRender.bind('requestFastScrollIndicatorItem', (e, data) => {
            if (!data.item) {
                return;
            }
            var icon = data.element.controls[0];
            var label = data.element.controls[1];
            icon.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.item.country.replaceAll(" ", "-").toLowerCase() + ".png", 30, 30);
            label.clear();
            label.addControl("<b>" + data.item.localizedCountry + "</b><br>" + "<p style='font-size:13px;'>" + data.item.league + "</p>", true);
        });
        listRender.bind('pullToRefreshAction', () => {
            this.loadToday(false, true, false, true);
        });
        listRender.setPlaceHolderWidth("100%;");
        var previousState = StateManager.getCurrentPageState(this.pageName);
        if (previousState) {
            listRender.scrollTo(previousState.todayScrollPosition || 0);
        }
        container.addControl(listRender);
        this.todayListrender = listRender;
        tab.addControl(container);
    }
    createLive(tab, index) {
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");
        var grid = new DataGrid();
        var listRender = new LazyTileGridRenderer();
        listRender.enableFastScroll = true;
        listRender.tabview = this.tabview;
        //listRender.enablePullToRefresh = true;
        listRender.containerTarget = tab;
        listRender.setItemRender(function (data) {
            if (data.type == 2) {
                return {
                    typeId: 1,
                    render: new MatchHeaderItemRender()
                };
            }
            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new MatchItemRender(false,undefined,true)
                };
            }
        });
        listRender.emptyTemplate = function () {
            var empty = new Panel();
            empty.addCssClass("listViewEmptyData");
            var attention = new Panel();
            attention.addCssClass("icon-attention");
            attention.setStyle("width:60px;height:60px;");
            empty.addControl(attention);
            empty.addControl("<p style='padding:5px;'>" + Localizer.getString("no_live_matches") + "</p>");
            let set = ManiaPreferences.getShowOnlyFavoritesAgenda();
            if (set) {
                empty.addControl("<div style='color:orange;margin-top:20px;margin-left:50px;margin-right:50px;' class='text-small'>" + Localizer.getString("you_have_enabled_favorite_filter") + "</div>");

                // var but = new Panel();
                // but.setStyle("margin-top:10px;")
                // but.addCssClass("button warning");
                // but.addControl("Ok thats clear");
                // empty.addControl(but);
            }

            return empty;
        };
        listRender.errorTemplate = function (cb) {
            return new ErrorMessage(cb);
        };
        listRender.noInternetTemplate = function () {
            return new ErrorMessage();
        };
        listRender.bind('createdList', () => {
            this.coordinatoranimator.recalculateTab(index);
        });
        listRender.bind("fastScrollIndicatorClicked", (e, item) => {
            //console.log("not implemented. not needed now");
        });
        listRender.bind('pullToRefreshAction', () => {
            this.loadLive(false, true, false, true);
        });
        listRender.fastScrollElementTemplate = () => {
            var panel = new Control();
            var icon = new Image();
            var label = new Panel();
            panel.addControl(icon);
            panel.addControl(label);
            return panel;
        };
        listRender.bind('requestFastScrollIndicatorItem', (e, data) => {
            if (!data.item) {
                return;
            }
            var icon = data.element.controls[0];
            var label = data.element.controls[1];
            icon.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.item.country.replaceAll(" ", "-").toLowerCase() + ".png", 30, 30);
            label.clear();
            label.addControl("<b>" + data.item.localizedCountry + "</b><br>" + "<p style='font-size:13px;'>" + data.item.league + "</p>", true);
        });
        listRender.setPlaceHolderWidth("100%;");
        var previousState = StateManager.getCurrentPageState(this.pageName);
        if (previousState) {
            listRender.scrollTo(previousState.liveScrollPosition || 0);
        }
        grid.setListRender(listRender);
        container.addControl(listRender);
        this.liveListrender = listRender;
        tab.addControl(container);
    }
    loadFeedAppStart(ignoreCache, dontShowLoader, isFromAutoRefresh, ignoreMemoryCache, forceWorkers) {

        if (!this.homeListrender){
            console.error("homeListrender not set");
            return;
        }

        if (FootballManiaConfig.isRtl) {
            var tabIndex = 2;
        } else {
            var tabIndex = 0;
        }
        var timeout = 0;
        if (this.tabview.currentActiveTab != tabIndex) {
            forceWorkers = true;
            timeout = 700;
        }

        if (!AppInstance.workersInitialized){
            forceWorkers = false;
        }

        var extraTimeout = 0;
        if (!ignoreMemoryCache && ManiaCache.getMemoryCache("homeData")) {
            if (this.tabview.currentActiveTab != tabIndex) {
                if (!dontShowLoader) this.tabview.tabs[tabIndex].showLoader();
            }
            setTimeout(() => {
                if (this.unloaded) {
                    return console.warn("page is unloaded abort further actions");
                }

                if (this.tabview.currentActiveTab == tabIndex) {

                    console.log("use mem cache");
                    this.homeListrender.setDataSource(ManiaCache.getMemoryCache("homeData"));
                    if (!dontShowLoader) this.tabview.tabs[tabIndex].hideLoader();
                    console.log("use mem cache complete");
                    extraTimeout = 800;
                }
                return this.autoRefreshHome(80 + extraTimeout, true);
            }, timeout);
            return;
        }
        if (document.hidden) {
            return this.autoRefreshHome();
        }

        //when page is already loaded then we dont load when page is hidden. but not first time, because it can happen that interstitial hides the screen first time
        if (this.firstTimeLoadingHome && document.hidden) {
            return this.autoRefreshHome();
        }
        this.firstTimeLoadingHome = true;
        if (!dontShowLoader) this.tabview.tabs[tabIndex].showLoader();
        setTimeout(() => {
            DataProvider.getAppStart(response => {
                if (this.unloaded) {
                    return console.warn("page is unloaded abort further actions");
                };

                if (!response.isOk()) {
                    this.tabview.tabs[tabIndex].hideLoader();
                    if (isFromAutoRefresh) {
                        //dont show error when i.e no internet
                        this.autoRefreshHome();
                        return;
                    }
                    var str = Localizer.getString("nointernet") + "<br>" + Localizer.getString("taptotryagain");
                    this.homeListrender.showError(str, () => {
                        this.homeListrender.hideError();
                        this.loadFeedAppStart(ignoreCache);
                    });
                    return;
                }
                var options = {
                    methodType: 4,
                    responseData: response.getData(),
                    enabledFeatures : AppInstance.getConfig().enabledFeatures,
                    fixedRegions: AppInstance.getConfig().fixedRegions,
                    favoriteLeagues: Favorites.getFavoriteLeagues(),
                    favoriteTeams: Favorites.getFavoriteTeams(),
                    translations: Localizer.getLoadedLocale(),
                    locale: AppInstance.getLocale(),
                    localeFull: FootballManiaConfig.userLocaleFull,
                    userRegionLocale: FootballManiaConfig.userRegionLocale,
                    userCountry: FootballManiaConfig.detectedUserCountry,
                    defaultTranslations: Localizer.DefaultTranslations,
                    availableNewsLocales: AppInstance.getConfig().availableNewsLocales,
                    newsLocaleRemap: AppInstance.getConfig().newsLocaleRemap,
                    fontSize: FootballManiaConfig.size
                };
                console.log("options", options);
                if (!forceWorkers && !FootballManiaConfig.useWebWorkers) {
                    var list = Processors.prepareHomeFeedData(options);
                    var data = {
                        data: [0, list]
                    };
                    this.onWorkerMessageReceived(data);
                } else {
                    AppInstance.workerHelper1.postMessage(options);
                }
            }, ignoreCache);
        }, timeout);
        this.autoRefreshHome();
    }
    loadLive(ignoreCache, dontShowLoader, isFromAutoRefresh, ignoreMemoryCache, forceWorkers) {

        if (!this.liveListrender){
            console.error("liveListrender not set");
            return;
        }
        var tabIndex = 1;
        var timeout = 0;
        if (this.tabview.currentActiveTab != tabIndex) {
            forceWorkers = true;
            timeout = 850;
        }

        if (!AppInstance.workersInitialized){
            forceWorkers = false;
        }

        var extraTimeout = 0;
        if (!forceWorkers && !ignoreMemoryCache && ManiaCache.getMemoryCache("fixtureLive")) {
            if (this.tabview.currentActiveTab != tabIndex) {
                if (!dontShowLoader) this.tabview.tabs[tabIndex].showLoader();
            }
            setTimeout(() => {
                if (this.unloaded) {
                    return console.warn("page is unloaded abort further actions");
                }
                //only set memory cache when we are on the live tab
                if (this.tabview.currentActiveTab == tabIndex) {

                    console.log("use mem cache");
                    this.liveListrender.setDataSource(ManiaCache.getMemoryCache("fixtureLive"));
                    if (!dontShowLoader) this.tabview.tabs[tabIndex].hideLoader();
                    console.log("use mem cache complete");
                    extraTimeout = 100;
                }
                return this.autoRefreshLive(100 + extraTimeout, true);
            }, timeout);
            return;
        }

        //when page is already loaded then we dont load when page is hidden. but not first time, because it can happen that interstitial hides the screen first time
        if (this.firstTimeLoadingLive && document.hidden) {
            return this.autoRefreshLive();
        }
        this.firstTimeLoadingLive = true;
        if (!dontShowLoader) this.tabview.tabs[tabIndex].showLoader();
        setTimeout(() => {
            DataProvider.getLiveNow(response => {
                if (this.unloaded) {
                    return console.warn("page is unloaded abort further actions");
                }
                if (!response.isOk()) {
                    this.tabview.tabs[tabIndex].hideLoader();
                    if (isFromAutoRefresh) {
                        //dont show error when i.e no internet
                        this.autoRefreshLive();
                        return;
                    }
                    var str = Localizer.getString("nointernet") + "<br>" + Localizer.getString("taptotryagain");
                    this.liveListrender.showError(str, () => {
                        this.liveListrender.hideError();
                        this.loadLive(ignoreCache);
                    });
                    return;
                }
                this.liveListrender.loadedDatasource = response.getData();
                var options = {
                    methodType: 5,
                    responseData: response.getData(),
                    fixedRegions: AppInstance.getConfig().fixedRegions,
                    fixedLeagueOrder: AppInstance.getConfig().fixedLeaguesOrder,
                    favoriteLeagues: Favorites.getFavoriteLeagues(),
                    favoriteTeams: Favorites.getFavoriteTeams(),
                    translations: Localizer.getLoadedLocale(),
                    locale: AppInstance.getLocale(),
                    enabledFeatures : AppInstance.getConfig().enabledFeatures,
                    userRegionLocale: FootballManiaConfig.userRegionLocale,
                    userCountry: FootballManiaConfig.detectedUserCountry,
                    defaultTranslations: Localizer.DefaultTranslations,
                    hiddenTitles: FootballManiaConfig.hideCountryTitleFor,
                    showOnlyFavorites: ManiaPreferences.getShowOnlyFavoritesAgenda(),
                    subscribedTeams: Subscriber.getSubscribedTeams(),
                    subscribedLeagues: Subscriber.getSubscribedLeagues(),
                    subscribedMatches: Subscriber.getSubscribedMatches(),
                    fontSize: FootballManiaConfig.size,
                    listType : ManiaPreferences.getMatchListType()
                };
                if (!forceWorkers && !FootballManiaConfig.useWebWorkers) {
                    var list = Processors.prepareLiveFeedData(options);
                    var data = {
                        data: [1, list.listData, list.foundUnfiltered]
                    };
                    this.onWorkerMessageReceived(data);
                } else {
                    AppInstance.workerHelper2.postMessage(options);
                }
            }, ignoreCache);
        }, timeout);
        this.autoRefreshLive();
    }
    loadToday(ignoreCache, dontShowLoader, isFromAutoRefresh, ignoreMemoryCache, forceWorkers) {

        if (!this.todayListrender){
            console.error("todayListrender not set");
            return;
        }

        if (FootballManiaConfig.isRtl) {
            var tabIndex = 0;
        } else {
            var tabIndex = 2;
        }
        var timeout = 0;
        if (this.tabview.currentActiveTab != tabIndex) {
            forceWorkers = true;
            timeout = 1000;
        }

        if (!AppInstance.workersInitialized){
            forceWorkers = false;
        }

        var extraTimeout = 0;
        if (!ignoreMemoryCache && ManiaCache.getMemoryCache("fixtureToday")) {
            if (this.tabview.currentActiveTab != tabIndex) {
                if (!dontShowLoader) this.tabview.tabs[tabIndex].showLoader();
            }
            setTimeout(() => {
                if (this.unloaded) {
                    return console.warn("page is unloaded abort further actions");
                }
                if (this.tabview.currentActiveTab == tabIndex) {

                    this.todayListrender.setDataSource(ManiaCache.getMemoryCache("fixtureToday"));
                    extraTimeout = 800;
                    if (!dontShowLoader) this.tabview.tabs[tabIndex].hideLoader();
                }
                return this.autoRefreshToday(80 + extraTimeout, true);
            }, timeout);
            return;
        }
        //when page is already loaded then we dont load when page is hidden. but not first time, because it can happen that interstitial hides the screen first time
        if (this.firstTimeLoadingToday && document.hidden) {
            return this.autoRefreshToday();
        }
        this.firstTimeLoadingToday = true;

        if (!dontShowLoader){
            console.log("show loader");
            this.tabview.tabs[tabIndex].showLoader();
        }
        setTimeout(() => {
            DataProvider.getToday(response => {
                if (this.unloaded) {
                    return console.warn("page is unloaded abort further actions");
                }
                if (!response.isOk()) {
                    this.tabview.tabs[tabIndex].hideLoader();
                    if (isFromAutoRefresh) {
                        //dont show error when auto refreshing and i.e no internet
                        this.autoRefreshToday();
                        return;
                    }
                    var str = Localizer.getString("nointernet") + "<br>" + Localizer.getString("taptotryagain");
                    this.todayListrender.showError(str, () => {
                        this.todayListrender.hideError();
                        this.loadToday(ignoreCache);
                    });
                    return;
                }
                this.todayListrender.loadedDatasource = response.getData();
                var options = {
                    methodType: 6,
                    responseData: response.getData(),
                    fixedRegions: AppInstance.getConfig().fixedRegions,
                    fixedLeagueOrder: AppInstance.getConfig().fixedLeaguesOrder,
                    favoriteLeagues: Favorites.getFavoriteLeagues(),
                    favoriteTeams: Favorites.getFavoriteTeams(),
                    translations: Localizer.getLoadedLocale(),
                    locale: AppInstance.getLocale(),
                    enabledFeatures : AppInstance.getConfig().enabledFeatures,
                    userRegionLocale: FootballManiaConfig.userRegionLocale,
                    userCountry: FootballManiaConfig.detectedUserCountry,
                    defaultTranslations: Localizer.DefaultTranslations,
                    hiddenTitles: FootballManiaConfig.hideCountryTitleFor,
                    showOnlyFavorites: ManiaPreferences.getShowOnlyFavoritesAgenda(),
                    subscribedTeams: Subscriber.getSubscribedTeams(),
                    subscribedLeagues: Subscriber.getSubscribedLeagues(),
                    subscribedMatches: Subscriber.getSubscribedMatches(),
                    fontSize: FootballManiaConfig.size,
                    listType : ManiaPreferences.getMatchListType()
                };

                //if we dont want to use web workers
                if (!forceWorkers && !FootballManiaConfig.useWebWorkers) {
                    var list = Processors.prepareTodayFeedData(options);
                    var data = {
                        data: [2, list.listData, list.foundUnfiltered]
                    };
                    this.onWorkerMessageReceived(data);
                } else {
                    AppInstance.workerHelper3.postMessage(options);
                }
            }, ignoreCache);
        }, timeout);
        this.autoRefreshToday();
    }

    applyDelayedDataSource() {
        console.log("apply delayed datasource");
        if (this.delayedHomeDataSource){
            this.homeListrender.containerTarget.hideLoader();
            this.homeListrender.setDataSource(this.delayedHomeDataSource);
            this.delayedHomeDataSource = null;
        }
        if (this.delayedLiveDataSource){
            this.liveListrender.containerTarget.hideLoader();
            this.liveListrender.setDataSource(this.delayedLiveDataSource);
            this.delayedLiveDataSource = null;
        }
        if (this.delayedTodayDataSource){
            this.todayListrender.containerTarget.hideLoader();
            this.todayListrender.setDataSource(this.delayedTodayDataSource);
            this.delayedTodayDataSource = null;
        }
    }
    /**
     * call this when you want to set datasource in listrender again
     * for example after you enabled filter. worker is using filter data
     */
    reApplyDataSourceInListRenders() {
        console.log("reapply datasource in listrender");
        var listRender = this.liveListrender;
        if (!listRender.loadedDatasource) {
            console.warn("no datasource in listrender");
            return;
        }
        listRender.scrollTo(0);
        var options = {
            methodType: 5,
            responseData: listRender.loadedDatasource,
            enabledFeatures : AppInstance.getConfig().enabledFeatures,
            fixedRegions: AppInstance.getConfig().fixedRegions,
            fixedLeagueOrder: AppInstance.getConfig().fixedLeaguesOrder,
            favoriteLeagues: Favorites.getFavoriteLeagues(),
            favoriteTeams: Favorites.getFavoriteTeams(),
            translations: Localizer.getLoadedLocale(),
            locale: AppInstance.getLocale(),
            userRegionLocale: FootballManiaConfig.userRegionLocale,
            userCountry: FootballManiaConfig.detectedUserCountry,
            defaultTranslations: Localizer.DefaultTranslations,
            hiddenTitles: FootballManiaConfig.hideCountryTitleFor,
            showOnlyFavorites: ManiaPreferences.getShowOnlyFavoritesAgenda(),
            subscribedTeams: Subscriber.getSubscribedTeams(),
            subscribedLeagues: Subscriber.getSubscribedLeagues(),
            subscribedMatches: Subscriber.getSubscribedMatches(),
            fontSize: FootballManiaConfig.size,
            listType : ManiaPreferences.getMatchListType()
        };
        AppInstance.workerHelper2.postMessage(options);
        var listRender = this.todayListrender;
        if (!listRender.loadedDatasource) {
            console.warn("no datasource in listrender");
            return;
        }
        listRender.scrollTo(0);
        options = {
            methodType: 6,
            responseData: listRender.loadedDatasource,
            fixedRegions: AppInstance.getConfig().fixedRegions,
            fixedLeagueOrder: AppInstance.getConfig().fixedLeaguesOrder,
            favoriteLeagues: Favorites.getFavoriteLeagues(),
            favoriteTeams: Favorites.getFavoriteTeams(),
            translations: Localizer.getLoadedLocale(),
            locale: AppInstance.getLocale(),
            enabledFeatures : AppInstance.getConfig().enabledFeatures,
            userRegionLocale: FootballManiaConfig.userRegionLocale,
            userCountry: FootballManiaConfig.detectedUserCountry,
            defaultTranslations: Localizer.DefaultTranslations,
            hiddenTitles: FootballManiaConfig.hideCountryTitleFor,
            showOnlyFavorites: ManiaPreferences.getShowOnlyFavoritesAgenda(),
            subscribedTeams: Subscriber.getSubscribedTeams(),
            subscribedLeagues: Subscriber.getSubscribedLeagues(),
            subscribedMatches: Subscriber.getSubscribedMatches(),
            fontSize: FootballManiaConfig.size,
            listType : ManiaPreferences.getMatchListType()
        };
        AppInstance.workerHelper3.postMessage(options);
    }
    autoRefreshHome(overrideInterval, forceWorkers) {
        clearTimeout(this.homeLoadHandler);
        this.homeLoadHandler = setTimeout(() => {
            ManiaCache.deleteMemoryCache("homeData");
            this.loadFeedAppStart(true, true, true, true, forceWorkers);
        }, overrideInterval != null ? overrideInterval : AppInstance.getConfig().refreshIntervals.home);
    }
    autoRefreshToday(overrideInterval, forceWorkers) {
        clearTimeout(this.todayLoadHandler);
        this.todayLoadHandler = setTimeout(() => {
            ManiaCache.deleteMemoryCache("fixtureToday");
            this.loadToday(true, true, true, true, forceWorkers);
        }, overrideInterval != null ? overrideInterval : AppInstance.getConfig().refreshIntervals.today);
    }
    autoRefreshLive(overrideInterval, forceWorkers) {
        clearTimeout(this.liveLoadHandler);
        this.liveLoadHandler = setTimeout(() => {
            ManiaCache.deleteMemoryCache("fixtureLive");
            this.loadLive(true, true, true, true, forceWorkers);
        }, overrideInterval != null ? overrideInterval : AppInstance.getConfig().refreshIntervals.live);
    }
};