
//this control runs in combination with collapser (topbar) and tabiew
// when target scroller scrolls down it collapses the topbar and tabview scrollers.
export const AnimatorPlayer = function(){

    var version = "2.0";

    var scrollers = [];
    var scrollerMethods = [];
    var touchStartMethod = null;
    var touchEndMethod = null;
    var collapser = null;
    var tabview = null;
    var topbar = null;
    var ignoreEvents = false;
    var ignoreEventForIndex = [];
    var lastListIndexScrolling = -1
    var unloaded = false;

    this.matchStatus; //we use this for collapser differnt ways
    this.isTouching = false;
    this.reducerRatio = 0.3;
    this.transitionDuration = 100;
    this.alwaysCollapseOnBackScrolling = true;
    this.isCollapsed = false;
    this.lastRecalculateWasCollapsed = null;
    this.isVirtuallyCollapsed = false;
    this.lastScrollPositionAfterTouchStart = -1;
    this.lastYOffsetAfterTouchStart = 0;
    this.currentTranslateY = 0;
    this.currentActiveTab = -1;

    this.addScrollerContainer = function(tab,index){
        if (scrollers) {
            if (scrollers[index]) {
                console.warn("scroller already added");
                return;
            }
            scrollers[index] = tab;
            this.activateScroller(tab);
            this.recalculateTab(index);
        }
        else{
            console.warn("cannot add, no scroll container found");
        }
    }

    this.addCollapser = function(c){
        collapser = c;
    }

    this.addTopBar = function(tb){
        topbar = tb;
    }

    this.addTabView = function(tab){
        tabview = tab;
    }

    this.unload = function(){

        unloaded = true;
        if (topbar) {
            topbar.setCss({"transform": "translate(0px,0px)", "transition-duration": "0ms"});
        }
        if (collapser) {
            collapser.setCss({"transform": "translate(0px,0px)", "transition-duration": "0ms"});
        }
        if (scrollers) {
            for (var i = 0; i < scrollers.length; i++) {
                if (!scrollers[i]) continue;
                var scrollEl = jQuery("#" + scrollers[i].getClientId());
                if (scrollerMethods[i]) {
                    scrollEl.off("scroll", scrollerMethods[i]);
                }
            }
        }
        scrollers = null;
        scrollerMethods = null;

        $('body').off({
            'touchstart': touchStartMethod,
            'touchend' : touchEndMethod});

        collapser = null;
        scrollers = null;
        topbar = null;
    }

    this.onWindowResize = function(){
        this.recalculate(true);
    }

    this.tabChange = function(){

        if (!FootballManiaConfig.graphics.animations) return;

        if (this.currentActiveTab == -1){
            console.log("ignore tab change ",this.currentActiveTab,tabview.currentActiveTab);
            this.currentActiveTab = tabview.currentActiveTab;
            //return;
        }
        var collaped = this.isCollapsed;
        var virtuallyCollapsed = this.isVirtuallyCollapsed;

        console.log("tab changed",tabview.currentActiveTab,collaped,virtuallyCollapsed);
        if (collapser) {
            var collapserHeight = collapser.getDimensions().height;
        }
        //console.log("RECALCULATE TAB ",i,scrollEl[0].scrollHeight,contentWindowHeight,collapserHeight)
        console.log("---------------------------------------->>>> tab changedd")

        var height = collapserHeight
        var that = this;

        for (var i=0; i < scrollers.length;i++) {
           // if (i == tabview.currentActiveTab) continue;
            ignoreEvents = true;
            if (ignoreEventForIndex.indexOf(i) == -1) {
                ignoreEventForIndex.push(i);
            }
            (function (i) {
                setTimeout(function() {
                    var scrollElement = scrollers[i];

                    if (!scrollElement){
                        console.error("no scroller for tab index "+i+" change logic");
                        return;
                    }

                    document.querySelector("#" + scrollElement.getClientId()).scrollTo({
                        top: collaped || virtuallyCollapsed ? height : 0,
                        left: 0,
                    });
                    that.recalculateTab(i)
                },0);
            })(i)
            ignoreEvents = false;
        }
        this.currentActiveTab = tabview.currentActiveTab;
    }

    this.recalculateTab = function(i){


        if (!scrollers || !scrollers[i]){
            console.warn("cannot recalcuate without tabs");
            return;
        }
        if (!tabview || !tabview.tabContents || !tabview.tabContents.controls[i] || !tabview.tabContents.controls[i].controls[0]){
            console.warn("cannot recalcuate without tabview elements");
            return;
        }

        var contentWindowHeight = tabview.tabContents.getDimensions().height;
        var collapserHeight = 0;
        if (collapser) {
            collapserHeight = collapser.getDimensions().height;
        }

        var scrollEl = jQuery("#"+scrollers[i].getClientId());
        var top  = collapserHeight;

        if (!scrollEl || !scrollEl[0]){
            console.warn("no scroller")
            return;
        }



        // var isScrollable = scrollEl.isVScrollable();
        //
        // console.log("RECALCULATE TAB ",i,isScrollable,scrollEl[0].scrollHeight,contentWindowHeight,collapserHeight)
        // return;
        var height = contentWindowHeight + collapserHeight;

        if (scrollEl[0].scrollHeight > contentWindowHeight) {
            //console.log("scrollheight is higher than screenheight"+contentWindowHeight+"--"+scrollEl[0].scrollHeight);

            $("#" + scrollers[i].getClientId()).css({"top": "-" +top+"px"});

            var contentOverFlow = scrollEl[0].scrollHeight-contentWindowHeight;

            //if scrollheight is just slightly larger we increase till minimum collapserheight
            if (contentOverFlow <= collapserHeight) {
                //$("#" + scrollers[i].controls[0].getClientId()).css({"height": scrollers[i].controls[0].getDimensions().height + collapserHeight + "px"});
            }

            $("#" +scrollers[i].controls[0].getClientId()).css({
                "position": "absolute",
                "top": top+"px",
                "width" : "100%",
                // "height" : height+"px"
            });

            $("#" + tabview.tabContents.controls[i].controls[0].getClientId()).css({"height": height+"px"});
        }
        else{

            console.log(i,"not needed")
            //$("#" + tabview.tabContents.controls[i].getClientId()).css({"overflow": "hidden"});
            // console.log("tab ",i,' content is not needed for scroll',contentWindowHeight)
            if (this.isCollapsed || this.isVirtuallyCollapsed){
                //if collapsed and scrollheight is smaller than screenheight we put to minus offset
                //because otherwise it canot be scrolled up (towards the topbar)
                $("#" + scrollers[i].getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
                //$("#" + scrollers[i].getClientId()).css({"top": "-" +top+"px"});
            }
            else{
                $("#" + scrollers[i].getClientId()).css({"transform":"translate(0px,0px)","transition-duration": "0ms"});
            }
        }
    }

    this.recalculate = function(force,onlyTabIndex){
        console.log("recalculate");
        for (var i = 0; i < scrollers.length; i++) {
            //var scrollEl = jQuery("#" + scollers[i].getClientId());

            ///console.log("XXXXXXXX",scrollEl[0].scrollHeight,contentWindowHeight)
            this.recalculateTab(i);
        }
    }

    this.autoCollapseOrOpen = function(scrollPosition){
        //return;
        if (this.isAutoCollapsing){
            console.log("dont do twice ");
            return;
        }

        this.isAutoCollapsing = true;

        if (this.currentTranslateY == 0){
            this.isCollapsed = false;
        }
        else if (this.currentTranslateY == -collapser.getDimensions().height){
            this.isCollapsed = true;
        }

        if (this.currentTranslateY == 0 || this.currentTranslateY == -collapser.getDimensions().height){
            console.log("no auto collapse when already open or closed",this.currentTranslateY);
            this.isAutoCollapsing = false;
            this.recalculate();
            return;
        }

        console.log("AUTO COLLAPSE",this.currentTranslateY)

        var collapserHeight = collapser.getDimensions().height;
        var that = this;
        var delay = 300;

        if ((scrollPosition) > (collapserHeight * (1/this.reducerRatio))/2){

            var scrollElement = scrollers[tabview.currentActiveTab];

            this.isCollapsed = true;
            document.querySelector("#" + scrollElement.getClientId()).scrollTo({
                top: collapserHeight,
                left: 0,
                behavior: "smooth"
            });
        }
        else {
            var scrollElement = scrollers[tabview.currentActiveTab];

            document.querySelector("#" + scrollElement.getClientId()).scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });

            that.currentTranslateY = 0;
            this.isCollapsed = false;
            setTimeout(function(){
                //that.currentTranslateY = 0;
                that.recalculate();
            },delay)
        }
    }

    this.ensureStartPositions = function(){

        //alert("a")
        if (!FootballManiaConfig.graphics.animations) return;

        if (!collapser || !tabview || !topbar){
            console.error("NO COLLAPSER OR TABVIEW OR TOPBAR");
            return;
        }

        if (this.isTouching){
            return;
        }

        console.log("PREPARE RUN ANIMATOR FOR MATCHSCREEN "+version);
        var collapserHeight = collapser.getDimensions().height;

        collapser.homeImage.setVisible(true);

        //make sure we start with a collapsed one before everything gets rendered. causes flickering
        if (this.isCollapsed){
           /// alert("collapsed");
            $("#"+collapser.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
            $("#"+tabview.tabHolder.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
            $("#"+topbar.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
        }
    }

    this.prerun = function(collapsed){

        if (!FootballManiaConfig.graphics.animations) return;
        //collapser.remove();
        console.log("PRE RUN ANIMATOR FOR MATCHSCREEN "+version,collapsed);
        if (collapsed){
            this.currentTranslateY = -collapser.getDimensions().height
            this.autoCollapseOrOpen(-collapser.getDimensions().height)
        }
        else{
            this.autoCollapseOrOpen(0)
        }
        collapser.homeImage.setVisible(false);
    },

    this.run = function() {

        if (!FootballManiaConfig.graphics.animations) return;

        console.log("RUN ANIMATOR (scrollers:" + scrollers.length + ") FOR TEAMSCREEN " + version);
        lastListIndexScrolling = tabview.currentActiveTab;

        $('body').off({
            'touchstart': touchStartMethod,
            'touchend': touchEndMethod
        });

        for (var i = 0; i < scrollerMethods.length; i++) {
            console.log("scroller", scrollers[i].controls[0].controls[0].controls[0].getClientId());
            var scrollEl = jQuery("#" + scrollers[i].controls[0].controls[0].controls[0].getClientId());
            scrollEl.off("scroll", scrollerMethods[i]);
        }

        this.recalculate();

        var that = this;

        touchStartMethod = function (e) {
            that.isTouching = true;
            var scrollElement = scrollers[tabview.currentActiveTab];
            if (!scrollElement) {
                console.error("no scroll element in tab " + tabview.currentActiveTab + " found. do nothing?");
                return;
            }
            var topOffset = document.querySelector("#" + scrollElement.getClientId()).scrollTop
            that.lastScrollPositionAfterTouchStart = topOffset;
            that.lastYOffsetAfterTouchStart = that.currentTranslateY;


        };

        touchEndMethod = function () {
            that.isTouching = false;
            if (that.currentTranslateY > -collapser.getDimensions().height) {
                that.isCollapsed = false;
            }

            clearTimeout(timeoutHandler);

            timeoutHandler = setTimeout(function () {
                if (unloaded) return;
                if (!scrollers) {
                    console.error("no scrollers found why??");
                    return;
                }

                that.currentActiveTab = tabview.currentActiveTab;

                var scrollElement = scrollers[tabview.currentActiveTab];
                if (!scrollElement) {
                    console.error("no scroll element in tab " + tabview.currentActiveTab + " found. do nothing?");
                    return;
                }

                var topOffset = document.querySelector("#" + scrollElement.getClientId()).scrollTop
                that.autoCollapseOrOpen(topOffset);
            }, 100);
        }

        $('body').on({
            'touchstart': touchStartMethod,
            'touchend': touchEndMethod
        });
    };

    var timeoutHandler;
    var timeoutHandler2;

    this.activateScroller = function(scroller){

        if (!FootballManiaConfig.graphics.animations) return;

        var that = this;

        var homeImage = collapser.homeImage;
        var backgroundImage = collapser.backgroundImage;
        var startOpacityBackground = $("#" + backgroundImage.getClientId()).css("opacity");

        //what if dynamic height?
        var collapserHeight = collapser.getDimensions().height;

        var index = scrollers.indexOf(scroller);

        var scrollEl = jQuery("#"+scroller.getClientId());

        var prevScroll = null;
        var ticking = false;

        scrollerMethods[index] = function(e){
            if (ticking) return;

            clearTimeout(timeoutHandler);
            window.requestAnimationFrame(()=> {

                ticking = true;
                if (ignoreEvents || index != tabview.currentActiveTab) {
                    console.log("ignore scroll event if not this active tab ", index)
                    ticking = false;
                    return;
                }
                if (ignoreEventForIndex.indexOf(index) != -1) {
                    console.log("ignore scroll event this time", index, ignoreEventForIndex);
                    //ignoreEventForIndex = -1;
                    ignoreEventForIndex.splice(ignoreEventForIndex.indexOf(index), 1);
                    ticking = false;
                    return;
                }

                var topOffset = e.originalEvent.target.scrollTop;
                if (prevScroll == topOffset) {
                    ticking = false;
                    console.log("do nothing no scroll change");
                    return;
                }
                lastListIndexScrolling = index;
                clearTimeout(timeoutHandler2);
                timeoutHandler2 = setTimeout( ()=> {
                    that.isAutoCollapsing = false;
                    var topOffset = e.originalEvent.target.scrollTop;
                    if (!that.isTouching) {
                        that.autoCollapseOrOpen(topOffset);
                    }
                }, 150);

                var topOffset = e.originalEvent.target.scrollTop;

                if (false && !that.alwaysCollapseOnBackScrolling) {
                    console.log("always collapse");
                    scrollDiff = Math.max(-topOffset, -collapserHeight);

                } else {

                    if (that.isCollapsed) {
                        if (topOffset <= collapserHeight) {
                            var t = -topOffset;
                            scrollDiff = Math.max(-collapserHeight, Math.min(0, t));
                        }
                        else{
                            scrollDiff = Math.max(-topOffset, -collapserHeight);
                        }
                    } else {
                        if (topOffset >= collapserHeight){
                            scrollDiff = -collapserHeight;
                        }
                        else if (that.lastScrollPositionAfterTouchStart !=-1) {
                            var t = (((collapserHeight-topOffset)-collapserHeight) * that.reducerRatio);
                            var scrollDiff = Math.min(0, Math.max(-collapserHeight, t));
                            var scrollDiff = t;
                        }
                        else{
                            scrollDiff = 0;
                        }
                    }
                }
                that.currentTranslateY = scrollDiff;

                //this virtualcollapsed value is a little different than iscollapsed
                //when user is collapsing and no touchend event is fired then the state of
                //iscollapsed remains the same. the virtualcollapsed one changes
                //this is needed in the recalculation of positions
                if (scrollDiff == -collapserHeight) {
                    that.isVirtuallyCollapsed = true;
                } else {
                    that.isVirtuallyCollapsed = false;
                }

                //collapser.setCss({"transform":"translate(0px,"+scrollDiff+"px)","transition-duration": this.transitionDuration+"ms"});
                collapser.setCss({
                    "transform": "translate(0px," + Math.round(scrollDiff)+ "px)",
                    "transition-duration": "80ms"
                });
                tabview.tabHolder.setCss({"transform":"translate(0px,"+scrollDiff+"px)","transition-duration": this.transitionDuration+"100ms"});
                tabview.tabHolder.setCss({
                    "transform": "translate(0px," + Math.round(scrollDiff)+ "px)",
                    "transition-duration": "80ms"
                });

                var progression = -scrollDiff/collapserHeight;

                homeImage.setCss({
                    "opacity": (1 - (progression*1))
                });
                backgroundImage.setCss({
                    "transform": "translateX(" + tabview.cachedParllaxBgXOffset+ "%) translateY(" + (-50 + (progression*30))+ "px)",
                    "transition-duration": "80ms",
                    "opacity": (startOpacityBackground - (progression*startOpacityBackground)),
                });

                topbar.setCss({
                    "transform": "translate(0px," + Math.round(scrollDiff)+ "px)",
                    "transition-duration": "80ms"
                });

                prevScroll = e.originalEvent.target.scrollTop;
                ticking = false;
            });

        }

        if (scrollerMethods[index]){
            scrollEl.off("scroll",scrollerMethods[index]);
        }

        scrollEl.scroll(scrollerMethods[index]);
    }
}