import {Router} from "./router.js";

export const Voter = {

    storageKey : "votedMatches",

    getVotedMatches : function(){
        if (this.cachedVotedMatches) return this.cachedVotedMatches;
        var data = [];
        try {
            var raw = localStorage.getItem(this.storageKey);
            data = JSON.parse(raw);
            if (!data) data = {};
        }
        catch (e) {
            console.warn("error getting subscribed matches ",e);
        }
        this.cachedVotedMatches = data;
        return data;
    },

    getVote : function(matchid){
        return this.getVotedMatches()[matchid];
    },

    voteMatch : function (matchid,side) {
        this.cachedVotedMatches = null;
        var data = this.getVotedMatches();
        if (!data[matchid]){
            data[matchid] = side;
            Router.voteMatch(matchid,side);
        }
        localStorage.setItem(this.storageKey,JSON.stringify(data));
        this.cachedVotedMatches = null;
    },

    migrateAndroidMatchVotes :  function(androidData){
        console.log("migrate");
        //"{"3280364":1,"3283743":0,"3263563":2}"
        androidData = JSON.parse(androidData);
        var newData = {};
        for (var key in androidData) {
            newData[key] = androidData[key];
        }
        localStorage.setItem(this.storageKey,JSON.stringify(newData));
    }
};