import {Panel} from "../bananaframework/src/controls/Panel.js";
import {Localizer} from '../util/localizer.js';

export class ErrorMessage extends Panel {

    init(options) {
        super.init();
        this.addCssClass("error");

        var errorImage = new Panel();
        errorImage.addCssClass("no-internet");
        this.addControl(errorImage);
        this.addControl(options.message);

        this.bind("click", () => {
            if (options.cb) {
                return options.cb();
            }
            console.error("no callback specified for error");
        });

    }
};

export class NoDataMessage extends Panel {


    init(options) {
        super.init();
        this.addCssClass("error");

        var errorImage = new Panel();
        errorImage.addCssClass("no-internet");
        this.addControl(errorImage);
        this.addControl(options.message);
        this.addControl("<br><p class='noDataTapText'>" + Localizer.getString("taptoreturn") + "</p>");

        this.bind("click", () => {
            if (options.cb) {
                return options.cb();
            }
            console.error("no callback specified for error");
        });

    }
};

export class NoDataMessage2 extends Panel {


    init(options) {
        super.init();
        this.addCssClass("error");

        var errorImage = new Panel();
        errorImage.addCssClass("no-internet");
        this.addControl(errorImage);
        this.addControl(options.message);

        this.bind("click", () => {
            if (options.cb) {
                return options.cb();
            }
            console.error("no callback specified for error");
        });

    }
};

export class NoDataMessage3 extends Panel {


    init(options) {
        super.init();
        this.addCssClass("error");

        var errorImage = new Panel();
        errorImage.addCssClass("icon-chat");
        errorImage.setStyle("height:100px;width:100px;");
        this.addControl(errorImage);
        this.addControl(options.message);
        this.addControl("<br><p class='noDataTapText'>" + Localizer.getString("type_below_to_be_first") + "</p>");

        this.bind("click", () => {
            if (options.cb) {
                return options.cb();
            }
            console.error("no callback specified for error");
        });

    }
};
