/**
 * @author Gillis Haasnoot <gillis.haasnoot@gmail.com>
 * @package Banana
 * @summary Base Control component
 */


import {generateUniqueId} from "../util/Utils.js";

/**
 * Eventtypes used in Banana
 */
export const EventTypes = {
    'DOM_EVENT':1,
    'CUSTOM_EVENT':2
}


/** @namespace Banana.Control */
export class Control {

    constructor(...args) {
        this.init.apply(this, args);
    }

    init () {
        this.generateUniqueId();
        this.customId = false; // flag if page sets id
        this.binds = [];
        this.controls = [];
    }

    createComponents () {
    }
    updateDisplay () {
    }
    onPreInvalidateContents () {
    }
    unload () {
    }
    onWindowResize () {
    }
    onVisibilityChange () {
    }
    onOffline () {
    }
    onOnline () {
    }

    applyToChildren (fn) {
        var args = this.applyToChildren.arguments;
        args.shift();
        var i, len;
        for (i = 0, len = this.controls.length; i < len; i++) {
            fn.apply(this, args);
        }
    };

    setId (id) {
        this.id = id;
        this.customId = true;
        return this;
    }

    getId () {
        return this.id;
    }

    setClientId (cid) {
        this.clientId = cid;
    };

    getClientId () {
        return this.clientId;
    };

    generateUniqueId () {
        this.id = generateUniqueId();
    }

    render (control, index) {
        this.getPage().initRender(control, this, null, null, false, index);
    }

    setPage (page) {
        this.page = page;
    }

    getPage () {
        return this.page;
    }

    getProxy (fn) {
        return jQuery.proxy(fn, this);
    }

    setParent (parent) {
        this.parent = parent;
    }

    getParent () {
        return this.parent;
    }

    getEnabled () {
    };

    addControl (c) {
        this.controls.push(c);
        return this;
    }

    addControlAt (c, at) {
        this.controls.splice(at, 0, c);
        return this;
    }

    getControls () {
        return this.controls;
    }

    findControl (id) {
        if (id === this.getId()) {
            return this;
        } else {
            var childs = this.getControls();

            if (!childs) {
                return null;
            }

            var i, len;
            for (i = 0, len = childs.length; i < len; i++) {
                if (childs[i] instanceof Control) {
                    var foundcontrol = childs[i].findControl(id);

                    if (foundcontrol) {
                        return foundcontrol;
                    }
                }
            }
        }
        return null;
    }

    remove () {
        console.error("this is not finished");
        this.unregisterEvents();
    };

    clear () {
        this.controls = [];
    };

    invalidateDisplay () {
        var page = this.getPage();
        if (page && page.isRendered) {
            page.rerender(this.getFirstUiControl());
        }
    }

    triggerEvent (name, params) {
        jQuery(this).trigger(name, params);
    }

    getDomEventTypes () {
        return ['mousedown',
            'mousemove',
            'touchstart',
            'touchend',
            'touchmove',
            'drag',
            'dragstart',
            'dragenter',
            'dragover',
            'dragleave',
            'dragend',
            'drop',
            'resize',
            'mouseup',
            'ready',
            'click',
            'dblclick',
            'error',
            'ready',
            'select',
            'submit',
            'focusin',
            'focusout',
            'blur',
            'focus',
            'change',
            'mouseover',
            'mouseleave',
            'mouseenter',
            'mouseout',
            'keypress',
            'keyup',
            'keydown'
        ];
    };

    bind (name, func, data) {
        if (!this.binds) {
            this.binds = [];
        }

        //dont bind duplicate name and functions
        if (this.hasBind(name, func)) {
            return false;
        }

        if (this.getDomEventTypes().indexOf(name) !== -1) {
            //collect the dom events. which can only be registered after rendering
            this.binds.push({'name': name, 'func': func, 'data': data, 'type': EventTypes.DOM_EVENT});

            if (this.isRendered) {
                jQuery('#' + this.getClientId()).bind(name, data, func);
            }
        }
            //custom events can be binded directly. no need to put these in an array for later bind
        //since we also call unbind on all objects,
        else {
            this.binds.push({'name': name, 'func': func, 'data': data, 'type': EventTypes.CUSTOM_EVENT});
            jQuery(this).bind(name, data, func);
        }

        return this;
    };

    hasBind (name, func) {
        if (!this.binds) {
            this.binds = [];
        }

        var i, len;
        for (i = 0, len = this.binds.length; i < len; i++) {
            var b = this.binds[i];

            if (func) {
                //dont bind duplicate name and functions
                if (b.name === name && (func.guid === b.func.guid || b.func === func)) {
                    return true;
                }
            } else {
                if (b.name === name) {
                    return true;
                }
            }
        }
        return false;
    };

    unbind (name, func) {
        var i, len;
        //reverse loop
        for (i = this.binds.length - 1; i >= 0; i--) {
            var b = this.binds[i];

            if (!func) {
                if (this.getDomEventTypes().indexOf(name) !== -1) {
                    jQuery('#' + this.getClientId()).unbind(name);
                } else {
                    jQuery(this).unbind(name);
                }

                this.binds.splice(i, 1);
            } else if (b.name === name && (func.guid === b.func.guid || b.func === func)) {
                if (this.getDomEventTypes().indexOf(name) !== -1) {
                    jQuery('#' + this.getClientId()).unbind(name, func);
                } else {
                    jQuery(this).unbind(name, func);
                }

                jQuery(this).unbind(name, func);
                this.binds.splice(i, 1);
            }
        }
    }

    registerEvents () {
        if (!this.binds) {
            return;
        }

        var i, len;
        for (i = 0, len = this.binds.length; i < len; i++) {
            var name = this.binds[i].name;
            var func = this.binds[i].func;
            var data = this.binds[i].data;
            var type = this.binds[i].type;

            //there is a difference between dom and data events. dom events can be registered only
            //when the dom elements are rendered. data events can be registered right when object are instantiated
            //if we bind an custom event during construction (before dom render, no rerender has occured) we want it instant to be registered
            //because this function is called after rendering and rerendering we only need to bind the custom events
            //after a RE-rerender, cause rerendering always starts with unbinding ALL events.
            if (type === EventTypes.CUSTOM_EVENT && this.getPage().isRerendering) {
                if (data) {
                    jQuery(this).bind(name, data, func);
                } else {
                    jQuery(this).bind(name, func);
                }
            } else {
                //no need to bind this event,
            }
        }
    };

    unregisterEvents () {
        if (!this.binds) {
            return;
        }

        jQuery(this).unbind(); //and all custom events
    };

    getHtml (markAsRendered) {
        var html = "";

        var childs = this.getControls();

        var i, len;
        for (i = 0, len = childs.length; i < len; i++) {
            if (childs[i] instanceof Control) {
                html += childs[i].getHtml(markAsRendered);
            } else if (typeof (childs[i]) === 'string') {
                html += childs[i];
            }
        }

        return html;
    };

    getFirstUiControl () {
        if (this.parent) {
            return this.parent.getFirstUiControl();
        }
        return null;
    };
}