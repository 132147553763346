import { Panel } from "../../bananaframework/src/controls/Panel.js";
import { Image } from "../../bananaframework/src/controls/Image.js";
import { DataGridTileItemRender } from "../../bananaframework/src/controls/listcontrols/datagrids/listrenders/DataGridTileItemRender.js";
import { Router } from "../../util/router.js";
import { Localizer } from "../../util/localizer.js";
import {TableHeaderCol,TableCol, TableRow} from "../../bananaframework/src/controls/Table.js";
import {ImagePlaceHolder, teamPlaceholder} from "../imageplaceholder.js";


export class LeagueTrophiesListRender extends  DataGridTileItemRender{
    init () {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("flexHorizontalAlign");
        this.addControl(this.panel);
    }
    setData (data) {


        this.panel.addControl("<table class='tableStandings'>");

        for (var i = 0; i < data.data.trophies.seasons.length; i++) {

            var row = new TableRow();
            var row2 = new TableRow();
            var th = new TableHeaderCol();
            th.setStyle("font-size:var(--text-size-xxlarge);");
            th.setAttribute("colspan",2);

            if (data.data.trophies.seasons[i].season){

                //split by space and /
                //try to translate it if country
                //if not, do nothing
                //var seasonTitleItems = data.data.trophies.seasons[i].season.split(/[\s/]+/);

                // var newContructedSeasonTitle = "";
                //
                // for (var i=0; i < seasonTitleItems.length; i++){
                //     ///newContructedSeasonTitle CountryUtil.getLocalizedCountryByName(seasonTitleItems[i]);
                // }
                //
                // console.log(seasonTitleItems);
                //
                // if (data.data.trophies.seasons[i].season.search("/")){
                //     console.log("we have years. do nothing")
                // }

            }

            th.addControl(data.data.trophies.seasons[i].season);
            row.addControl(th);
            this.panel.addControl(row);

            row = new TableRow();

            th = new TableCol();
            th.addCssClass("flexHorizontalAlign");
            th.setStyle("justify-content: flex-start;text-align: start;padding-inline-start: 4px;")

            if (AppInstance.getConfig().enabledFeatures.teamImages) {
                var image = new ImagePlaceHolder();
                image.placeholderData = teamPlaceholder;
                image.setCss({
                    'height': 36 + "px",
                    'width': 36 + "px"
                });
                image.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/" + data.data.trophies.seasons[i].winner_team_id_gs + "_small_rnd.png", 36, 36);
                th.addControl(image);
            }
            var teamname = new Panel();
            teamname.setStyle("padding-inline-start: 8px;");
            teamname.addControl(data.data.trophies.seasons[i].winner_name);

            th.addControl(teamname);
            row.addControl(th);
            th = new TableCol();
            th.setStyle("text-align: end;width:30%;padding-inline-end: 6px;");
            th.addControl(Localizer.getString("trophyfirst"));
            row.addControl(th);
            this.panel.addControl(row);

            row2 = new TableRow();

            th = new TableCol();
            th.addCssClass("flexHorizontalAlign");
            th.setStyle("justify-content: flex-start;text-align: start;padding-inline-start: 4px;")

            if (AppInstance.getConfig().enabledFeatures.teamImages) {
                var image = new ImagePlaceHolder();

                image.placeholderData = teamPlaceholder;
                image.setCss({
                    'height': 36 + "px",
                    'width': 36 + "px"
                });
                image.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/" + data.data.trophies.seasons[i].runnerup_team_id_gs + "_small_rnd.png", 36, 36);
                th.addControl(image);
            }

            var teamname = new Panel();
            teamname.setStyle("padding-inline-start: 8px;");
            teamname.addControl(data.data.trophies.seasons[i].runnerup_name);

            th.addControl(teamname);
            row2.addControl(th);

            th = new TableCol();
            th.setStyle("text-align: end;width:30%;padding-inline-end: 6px;");
            th.addControl(Localizer.getString("trophysecond"));
            row2.addControl(th);

            if (data.data.trophies.seasons[i].runnerup_name) {
                this.panel.addControl(row2);
            }

            (function (winnerid, secondid) {
                row.bind("click", function () {
                        Router.showTeamScreen(winnerid);
                });
                row2.bind("click", function () {
                    Router.showTeamScreen(secondid);
                });
            })(data.data.trophies.seasons[i].winner_team_id_gs,data.data.trophies.seasons[i].runnerup_team_id_gs);
        }
        this.panel.addControl("</table>");
    }
};