import {Panel} from "../bananaframework/src/controls/Panel.js";
import {UrlManager} from "../bananaframework/src/util/UrlManager.js";

import {BasePage} from "./basepage.js";

import {Router} from "../util/router.js";
import {Localizer} from "../util/localizer.js";
import {Processors} from "../util/processors.js";

import {ErrorMessage, NoDataMessage2, NoDataMessage} from "../components/errormessage.js";

import {AnimatorTopTeamsLeagues} from "./animators/animatortopteamleagues.js";
import {DataProvider} from "../data/dataprovider.js";
import {StateManager} from "../data/stateManager.js";

import {TabView, TabViewTab} from "../components/tabview.js";
import {LazyTileGridRenderer} from "../components/lazytilegridrenderer.js";

import {PlayerMarketValueItemRender} from "../components/player/listrendersplayermarketvalues.js";

import {Playermarktvaluesfiltermodal} from "../components/player/playermarktvaluesfiltermodal.js";
import {TopTeamListRender} from "../components/topteamsleagues/listrenderstopteamsleagues.js";


export class TopTeamsLeagues extends BasePage {

    init(params) {
        this.pageName = "PlayerMarketValue";
        this.version = 1;

        super.init();

        this.pageName = "PlayerMarketValues";

        this.addCssClass("PlayerMarketValuePage");

        this.isNavigatedFromOutside = Router.navigationIsFromOutside();
        Router.updateCurrentReferalData();

        if (this.isNavigatedFromOutside) {
            StateManager.flushPageState(this.pageName);
        }
        console.log("OPENING PAGE " + this.pageName + " VERSION " + this.version + " is from outside " + this.isNavigatedFromOutside)

        this.coordinatoranimator = new AnimatorTopTeamsLeagues();
        window.an = this.coordinatoranimator;

        Router.disallowBackButtonQuitApp();

        this.createPageLayout();

        if (AppInstance.workersInitialized) {
            this.wrapper1 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper1.addEventListener("message", this.wrapper1);
        }
    }

    onWorkerMessageReceived(e) {
        if (this.unloaded) {
            return console.warn("page is unloaded abort further actions");
        }
        console.log("worker received ",e.data[1]);
        this.tabview.tabs[e.data[0]].hideLoader();

        if (e.data[0] == 0) {
            this.topTeamsListrender.setDataSource(e.data[1]);
        }
        else if (e.data[0] == 1) {
            this.topPlayersListrender.setDataSource(e.data[1]);
        }
        this.autoInterval();
    }

    storeState() {

        var d = {
            "tabIndex": this.tabview.currentActiveTab,
            "topTeamScrollPosition": this.topTeamsListrender  && this.topTeamsListrender.getScroll(),
            "headerCollapsed": this.coordinatoranimator.isCollapsedCalculated()
        }

        console.log("store state ", d);
        StateManager.savePageState(this.pageName, d);
    }

    unload() {
        this.unloaded = true;
        $.xhrPool.abortAll();
        this.coordinatoranimator.unload();
        clearTimeout(this.topValueLoadHandler);
        if (AppInstance.workersInitialized) {
            AppInstance.workerHelper1.removeEventListener("message", this.wrapper1);
        }
    }

    onVisibilityChange() {
        //page is becoming visible after hide
        if (this.previousDocumentHidden = true && !document.hidden) {
            this.autoInterval(0);
        }
        this.previousDocumentHidden = document.hidden;
        //console.log("visibility change ",document.hidden,this);
    }

    createPageLayout() {
        var row2 = new Panel();
        this.addControl(row2);
        this.contentContainer = row2;
    }

    createComponents() {
        this.getPageTemplate().showTitle();
    }


    onWindowResize() {
        super.onWindowResize();
        if (this.tabview) {
            this.tabview.onWindowResize();
        }
        if (this.coordinatoranimator) {
            this.coordinatoranimator.onWindowResize();
        }

        if (this.topTeamsListrender) {
            this.topTeamsListrender.onWindowResize();
        }
    }

    updateDisplay() {

        this.runApp();
    }

    runApp() {
        this.showLoader();

        if (!AppInstance.setupAlreadyDone) {
            this.showLoader();
        }

        AppInstance.setup((result)=> {

            if (this.unloaded) {
                return console.warn("page is unloaded abort further actions");
            }

            this.hideLoader();
            if (!result) {
                console.error("error app setup");
                return this.showError("", ()=> {
                    this.runApp();
                });
            }

            var previousState = StateManager.getCurrentPageState(this.pageName);

            this.createTitle();
            this.createTabs(previousState);

            this.loadTopTeams(0);
            this.loadTopPlayers(1);

            //  this.getPageTemplate().createMenuPlayerMarketValues(response.getData());

            this.tabview.bind("tabChanged", ()=> {
                this.storeState();
                console.log("################# tab changed");
                this.coordinatoranimator.tabChange();
            });

            this.tabview.bind("onReady", this.getProxy(function () {
                this.coordinatoranimator.isCollapsed = previousState && previousState.headerCollapsed;
                setTimeout(()=> {
                    console.log("################# onread");
                    this.coordinatoranimator.run();
                },1500);
            }));

            var storeHandler = null;

            //this event is triggered when user scrolls the listrender.
            //it is not called always when scrollTo is called (when prevent boolean)
            this.topTeamScrollListener = (e, scroll)=> {
                clearTimeout(storeHandler);
                ///console.log("=======> received scroll event");
                storeHandler = setTimeout(()=> {
                    if (!this.preventScrollEventStoreStateSaveOnce) {
                        this.storeState();
                    }
                    this.preventScrollEventStoreStateSaveOnce = false;
                }, 200);
            }

            //this event is triggered when scrollTo method on listrender is called.
            //we use this to restore header state to stored state. this method is called after the page is loaded.
            this.onscrollToCompleteListener = (e) => {
                ///console.log("=====> scroll to complete",e);
                if (!previousState.headerCollapsed) {
                    setTimeout(() => {
                        this.coordinatoranimator.expandHeader();
                    }, 1);
                }
                //
                // e.preventDefault();
                // e.stopPropagation();
                // e.stopImmediatePropagation();
            };

            this.topTeamsListrender.bind("scroll", this.topTeamScrollListener);
            this.topPlayersListrender.bind("scroll", this.topTeamScrollListener);
            this.topTeamsListrender.bind("onScrollToComplete", this.onscrollToCompleteListener);
            this.topPlayersListrender.bind("onScrollToComplete", this.onscrollToCompleteListener);


            console.log("previousState", previousState);
            if (previousState) {
                this.tabview.currentActiveTab = previousState.tabIndex;
                this.coordinatoranimator.isCollapsed = previousState.headerCollapsed;

                if (this.topTeamsListrender) {
                    console.log("scroll to ", previousState.topTeamScrollPosition || 0);
                    this.topTeamsListrender.scrollTo(previousState.topTeamScrollPosition || 0,true);
                }
                if (this.topPlayersListrender){
                    console.log("scroll to ", previousState.topPlayerScrollPosition || 0);
                    this.topPlayersListrender.scrollTo(previousState.topPlayerScrollPosition || 0,true);
                }
            }

            this.contentContainer.invalidateDisplay();



        }, false);
    }

    createTitle(data) {
        this.getPageTemplate().topTitleContainer.setVisible(false);
    }

    createTabs(previousState) {
        var tabview = new TabView();
        tabview.tabHolder.addCssClass("animateHeaderCollapse")
        this.contentContainer.addControl(tabview);

        var tabs = [];

        var tab1;
        tab1 = new TabViewTab();
        tab1.addCssClass("listViewScroller"); //enableCollapserTimeLine
        tab1.title = Localizer.getString("Top Teams");
        tabs.push(tab1);

        this.createTopTeamsControls(tab1, 0);
        this.coordinatoranimator.addScrollerContainer(tab1);

        var tab2;
        tab2 = new TabViewTab();
        tab2.addCssClass("listViewScroller");
        tab2.title = Localizer.getString("Top Players");
        tabs.push(tab2);
        this.createTopPlayerControls(tab2, 1);
        this.coordinatoranimator.addScrollerContainer(tab2);


        this.coordinatoranimator.addTabView(tabview);

        tabview.setTabs(tabs);


        this.tabview = tabview;

        //tab1.showLoader();



        this.bind("stickyBannerLoadFail", () => {
            console.error("sticky banner load fail -> set height to 100%");
            //if (tab1) tab1.setCss({'height': 'calc(100svh - 50px)'});
        });
        //triggered from page template
        this.bind("stickyBannerLoadSuccess", () => {
            if (this.preventStickBannerAdOnce) {
                this.preventStickBannerAdOnce = false;
                this.hideStickBannerAd();
                return;
            }
            console.log("sticky banner load success -> set height to 100% - 50px");
            if (tab1){
                //tab1.setCss({'height': 'calc(100svh - 100px)'});
                //below is logic to make sure when scroll is at the end it remains at the end and not jumps up a bit.
                //its not a perfect solution, but ok for now.
                var scrollRemaining = this.topTeamsListrender.scrollRemaining();
                if (scrollRemaining <= 50){
                    this.topTeamsListrender.scrollTo(this.topTeamsListrender.getScroll()+scrollRemaining,true);
                }
            }
        });
        this.bind("stickyBannerHide", () => {
            console.error("sticky banner hide -> set height to 100%");
            //if (tab1) tab1.setCss({'height': 'calc(100svh - 100px)'});
        });
        this.getPageTemplate().loadStickyBannerAd();

        // setTimeout(()=>{
        //     this.topTeamsListrender.scrollTo(1500);
        // },4000);
    }

    createTopTeamsControls(tab, index) {

        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new LazyTileGridRenderer();
        listRender.containerTarget = tab;
        listRender.enableFastScroll = true;
        listRender.setItemRender(function (data) {
            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new TopTeamListRender(),
                };
            }
        })

        listRender.emptyTemplate = function (cb) {
            return new NoDataMessage({
                cb: function () {
                    history.back();
                },
                message: Localizer.getString("no_data")
            });
        }

        listRender.errorTemplate = function (cb) {
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function () {
            return new ErrorMessage();
        }

        listRender.bind('createdList', this.getProxy(function () {
            this.coordinatoranimator.recalculateTab(index);
        }))

        listRender.setPlaceHolderWidth("100%;");

        var previousState = StateManager.getCurrentPageState(this.pageName
        );
        if (previousState) {
            console.log("scroll to ", previousState.fixturesScrollPosition || 0);
            ///listRender.scrollTo(previousState.fixturesScrollPosition || 0);
        }

        this.topTeamsListrender = listRender;

        tab.addControl(listRender);
    }

    createTopPlayerControls(tab, index) {

        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new LazyTileGridRenderer();
        listRender.containerTarget = tab;
        listRender.enableFastScroll = true;
        listRender.setItemRender(function (data) {
            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new TopTeamListRender(),
                };
            }
        })

        listRender.emptyTemplate = function (cb) {
            return new NoDataMessage({
                cb: function () {
                    history.back();
                },
                message: Localizer.getString("no_data")
            });
        }

        listRender.errorTemplate = function (cb) {
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function () {
            return new ErrorMessage();
        }

        listRender.bind('createdList', this.getProxy(function () {
            this.coordinatoranimator.recalculateTab(index);
        }))

        listRender.setPlaceHolderWidth("100%;");

        var previousState = StateManager.getCurrentPageState(this.pageName
        );
        if (previousState) {
            console.log("scroll to ", previousState.fixturesScrollPosition || 0);
            ///listRender.scrollTo(previousState.fixturesScrollPosition || 0);
        }

        this.topPlayersListrender = listRender;

        tab.addControl(listRender);
    }
    
    loadTopTeams(tabIndex, ignoreCache, dontShowLoader, isFromAutoRefresh, ignoreMemoryCache, forceWorkers){
        if (!this.topTeamsListrender){
            console.error("topTeamsListrender not set");
            return;
        }

        var timeout = 0;
        console.log("current active tab ",this.tabview.currentActiveTab,tabIndex);
        if (this.tabview.currentActiveTab != tabIndex) {
            forceWorkers = true;
            timeout = 850;
        }

        if (!AppInstance.workersInitialized){
            forceWorkers = false;
        }

        if (!dontShowLoader) this.tabview.tabs[tabIndex].showLoader();
        setTimeout(() => {
            DataProvider.getTopTeams(response => {
                if (this.unloaded) {
                    return console.warn("page is unloaded abort further actions");
                }
                if (!response.isOk()) {
                    this.tabview.tabs[tabIndex].hideLoader();
                    var str = Localizer.getString("nointernet") + "<br>" + Localizer.getString("taptotryagain");
                    this.topTeamsListrender.showError(str, () => {
                        this.topTeamsListrender.hideError();
                        this.loadTopTeams(ignoreCache);
                    });
                    return;
                }

                var options = {
                    methodType: 14,
                    responseData: response.getData(),
                    tabIndex: tabIndex,
                };
                if (!forceWorkers && !FootballManiaConfig.useWebWorkers) {
                    console.log("a");
                    var list = Processors.prepareTopTeams(options);
                    var data = {
                        data: [tabIndex,list],
                    };
                    this.onWorkerMessageReceived(data);
                } else {
                    console.log("b");
                    AppInstance.workerHelper1.postMessage(options);
                }
            }, ignoreCache);
        }, timeout);
    }

    loadTopPlayers(tabIndex, ignoreCache, dontShowLoader, isFromAutoRefresh, ignoreMemoryCache, forceWorkers){
        if (!this.topPlayersListrender){
            console.error("loadTopPlayers not set");
            return;
        }

        var timeout = 0;
        console.log("current active tab ",this.tabview.currentActiveTab,tabIndex);
        if (this.tabview.currentActiveTab != tabIndex) {
            forceWorkers = true;
            timeout = 850;
        }

        if (!AppInstance.workersInitialized){
            forceWorkers = false;
        }

        if (!dontShowLoader) this.tabview.tabs[tabIndex].showLoader();
        setTimeout(() => {
            DataProvider.getTopTeams(response => {
                if (this.unloaded) {
                    return console.warn("page is unloaded abort further actions");
                }
                if (!response.isOk()) {
                    this.tabview.tabs[tabIndex].hideLoader();
                    var str = Localizer.getString("nointernet") + "<br>" + Localizer.getString("taptotryagain");
                    this.topPlayersListrender.showError(str, () => {
                        this.topPlayersListrender.hideError();
                        this.loadTopPlayers(ignoreCache);
                    });
                    return;
                }

                var options = {
                    methodType: 15,
                    responseData: response.getData(),
                    tabIndex: tabIndex,
                };
                if (!forceWorkers && !FootballManiaConfig.useWebWorkers) {
                    console.log("load playrers");
                    var list = Processors.prepareTopTeams(options);
                    var data = {
                        data: [tabIndex,list]
                    };
                    this.onWorkerMessageReceived(data);
                } else {
                    console.log("load playrers 2");
                    AppInstance.workerHelper1.postMessage(options);
                }
            }, ignoreCache);
        }, timeout);
    }

    autoInterval(overrideInterval) {

    }
};