/**
 * @author Gillis Haasnoot <gillis.haasnoot@gmail.com>
 * @package Banana
 * @summary Page
 */

import {Panel} from "./Panel.js";
import {Control} from "./Control.js";
import {DomHelper} from "../util/DomHelper.js";
import {arrayInteratorAsync} from "../util/Utils.js";
import {flattenTreeDepthFirst} from "../util/Utils.js";

export class Page extends Panel {
    init() {
        super.init();

        this.addCssClass('BPage');

        this.uniqueId = 0;
        this.validators = [];
        this.dataSets = [];

        if (!this.resizefunc) {
            this.resizefunc = () => {
                this.onWindowResize(this);
            };
        }
        window.addEventListener("resize", this.resizefunc);

        if (!this.visibilityChangeFunc) {
            this.visibilityChangeFunc = () => {
                this.onVisibilityChange(this);
            };
        }
        document.addEventListener("visibilitychange", this.visibilityChangeFunc);

        if (!this.offlineFunc) {
            this.offlineFunc = () => {
                this.onOffline(this);
            };
        }
        addEventListener("offline", this.offlineFunc);

        if (!this.onlineFunc) {
            this.onlineFunc = () => {
                this.onOnline(this);
            };
        }
        addEventListener("online", this.onlineFunc);
    }

};

/**
 * reference to application Default = Banana.Application
 * @param {Banana.Application} app
 */
Page.prototype.setApplication = function (app) {
    this.application = app;
};

/**
 * @return {Banana.Application}
 */
Page.prototype.getApplication = function () {
    return this.application;
};


/**
 * Starting point to initialize new page render.
 * The application is responsible for calling this method
 *
 * @param {mixed} target could be a string or object. in case of string we assume we have a dom id.
 */
Page.prototype.run = function (target) {
    this.validationControls = {};

    this.initRender(this, target || this.getApplication().settings.renderTarget);
};


/**
 * initialize rendering process
 *
 * @param {Banana.Control} control
 * @param {Banana.UiControl} target
 * @param {Banana.UiControl} place optional replace control
 * @param {boolean} wasRendering true if this control is already in a rerender phase
 * @param {boolean} parentRerendering true when parent control is currently rerendering
 * @param {int} parentRerendering index of control
 */
Page.prototype.initRender = function (control, target, place, wasRerendering, parentRendering, index) {
    this.rendering = true;

    if (wasRerendering) {
        //if the page was already rerendering we dont need to render this action again
        //we still need to rerender and register events on the control.
        this.renderControl(control, target, place, index);
        this.rendering = false;
        this.recursiveRegisterEvents(control);
        return;
    }

    this.isRendered = false;

    this.initializeControl(control, target);

    this.renderControl(control, target, place, index);

    this.isRendered = true;
    this.setVisible(true);

    //some controls are not Banana.Controls but plain text
    if (control && control.triggerEvent) {
        control.triggerEvent('renderComplete', this);
    }


    //if parent is already rendering we dont have to register events
    //parent control will do that for us
    if (!parentRendering) {
        this.recursiveRegisterEvents(control);

        //only set this boolean to false when parent is not rerendering. then the parent will eventualy make sure that
        //this bool will be false
        this.rendering = false;

        this.parentFirstTimeRendering = false;
    }
//	console.log("------> page run -> rupdatedisplay");
    this.recursiveUpdateDisplay(control);
//	console.log("------> page run -> rupdatedisplay complte");
};

/**
 * rerenders control
 *
 * @param {Banana.Control} control
 */
Page.prototype.rerender = function (control) {
    ///this bool is needed in the following situation:
    // call method updatedisplay on child controls from current method updatedisplay
    // creates a situation that the the isRerendering bool is set to false in the first updatedisplay
    // updatedisplay A -> isRerendering = true
    // updateDisplay B -> isRerendering = true
    // updateDisplay B is finished we set isRerendering to false
    // updateDisplay A is not finished yet and still needs to register events. but isRerendering is false
    // to prevent this situation we added this bool
    var parentRerendering = this.isRerendering;
    var parentRendering = this.rendering;

    //exception case
    if (parentRendering && !parentRerendering) {
        this.parentFirstTimeRendering = true;
    }

    this.isRerendering = true;

    if (!parentRendering) {
        this.recursiveUnRegisterEvents(control);
    }

    var orig = control.getFirstUiControl();
    var parentControl = orig.getParent();

    this.initRender(control, parentControl, orig, parentRerendering, parentRendering);

    // Restore this.isRerendering
    this.isRerendering = parentRerendering;
};

/**
 * sets content placeholder page
 *
 * @param {PageTemplate} ph
 */
Page.prototype.setContentPlaceHolder = function (ph) {
    this.contentPlaceHolder = ph;
};

/**
 * gets content placeholder
 *
 * @return  {PageTemplate}
 */
Page.prototype.getContentPlaceHolder = function () {
    return this.contentPlaceHolder;
};

/**
 * sets content placeholder page
 *
 * @param {PageTemplate} ph
 */
Page.prototype.setPageTemplate = function (ph) {
    this.contentPlaceHolder = ph;
};

/**
 * gets content placeholder
 *
 * @return  {PageTemplate}
 */
Page.prototype.getPageTemplate = function () {
    return this.contentPlaceHolder;
};


/**
 * Hides loader
 */
Page.prototype.hideLoader = function () {
    if (!this.loader) {
        return;
    }
    this.loader.hide();
};

/**
 * Datasets are to centralize data storage of your controls
 * You can either use stand alone datasets and manually assign them to your controls or
 * add datasets to the page and let the page automatically handle the data.
 *
 * @param {String} id for the dataset
 * @param {Banana.Data.DataSet} the dataset
 */
Page.prototype.addDataSet = function (id, d) {
    if (this.dataSets[id]) {
        return;
    }
    d.id = id;

    this.dataSets[id] = d;
};

/**
 * @return Banana.Data.DataSet
 *
 * @param string id of the dataset
 */
Page.prototype.getDataSet = function (id) {
    return this.dataSets[id];
};

/**
 *    removes all datasets from page
 */
Page.prototype.removeDataSets = function () {
    var index;
    for (index in this.dataSets) {
        if (typeof (this.dataSets[index]) === 'function') {
            continue;
        }

        this.dataSets[index].clear();
    }

    this.dataSets = [];
};

/**
 * validates all controls
 *
 */
Page.prototype.isValid = function () {
    return Banana.Util.ValidationManager.validateAll();
};

/**
 * initialize controls
 * this method walks through all controls and sets the following things
 *
 * clientId -> for all Banana.Controls
 * parent -> parent control holding this control
 * page -> reference to the page
 *
 * Page also automatically binds controls to their datasets
 *
 * @param {Banana.Control} control
 * @param {Banana.Control} target control used to determine parent
 */
Page.prototype.initializeControl = function (control, target) {
    //only handle banana controls
    if (!(control instanceof Control)) {
        return;
    }

    //if the target is not a ui control we fetch the first anchester which is a ui control
    if (target instanceof Control) {
        if (!control.clientId) {
            //control.setClientId(target.getClientId()+"-"+this.uniqueId++);
            control.setClientId("b" + (++window.domuuid));
            // if (window.domuuid > 100000){
            // 	window.domuuid = 0;
            // }
        }
    }
    //if the target is just a string then we assume it the first element
    else {
        var sn = this.getApplication().settings.applicationName;

        if (sn) {
            control.setClientId(sn + this.uniqueId++);
        } else {
            control.setClientId(this.uniqueId++);
        }
    }

    control.setParent(target);
    control.setPage(this);

    if (!control.isInitialized) {
        /*
        @createComponents Method is applied on all controls during creation of the page.
        In this state thi
        */
        control.createComponents();
        control.isInitialized = true;

        //bs/bd[0] = dataset name
        //bs/bd[1] = dataset property name
        var bs = control.bindedDataSource;
        if (bs) {
            if (this.getDataSet(bs[0])) {
                this.getDataSet(bs[0]).bindControlToDataSource(control);
            }
        }

        var bd = control.bindedData;
        if (bd) {
            if (this.getDataSet(bd[0])) {
                this.getDataSet(bd[0]).bindControlToData(control);
            }
        }
    }

    // Because in the create components other controls can be added, we
    // call this function after the createComponents
    var childs = control.getControls();

    var i, len;
    for (i = 0, len = childs.length; i < len; i++) {
        this.initializeControl(childs[i], control);
    }
};

/**
 * We render the control by fetching all html data from the control.
 *
 * @param {Banana.Control|String} control Control which should be rendered.
 * @param {Banana.Control} target The target where the control should be rendered in.
 * @param {Banana.Control} place (optional) If given we replace the old control html data with new data
 * @param {int} place (optional) If given we insert data at given index.
 */
Page.prototype.renderControl = function (control, target, place, index) {
    if (target instanceof Control) {
        target.cachedElement = undefined;
    }

    if (control instanceof Control) {
        control.cachedElement = undefined;
        var data = control.getHtml(true);
    } else {
        data = control;
    }

    if (index != undefined) {
        if (target.controls[index]) {
            DomHelper.renderBefore(data, target.controls[index]);
        } else {
            console.error("cannot add control at this index ", index);
            DomHelper.render(data, target);
        }
    } else if (place) {
        DomHelper.replace(data, place);
    } else {
        DomHelper.render(data, target);
    }
};


/**
 * Call updateDisplay on all controls in the hierarchy
 *
 * Controls can perform post-render actions in this function. These
 * will also be called on rerender.
 *
 * As a side-effect, the isRendered-property of all controls will be set.
 * This should happen in the {@link #renderControl}-function, but we don't
 * have a recursive pass there.
 *
 * Events are not registered yet in updateDisplay!
 *
 * @param {Banana.Control|string} control Control which is rendered
 */
Page.prototype.recursiveUpdateDisplay = function (control) {
    if (control instanceof Control) {
        // TODO: a parent control asking a child control to be rendered returns false.
        // but in reality it is rendered. This flag should be moved to earlier phase
        control.isRendered = true;

        control.updateDisplay();

        var childs = control.getControls();

        var i, len;

        for (i = 0, len = childs.length; i < len; i++) {
            this.recursiveUpdateDisplay(childs[i]);
        }
    }
};


/**
 * Register all binded events to control
 *
 * @param {Banana.Control|string} control
 */
Page.prototype.recursiveRegisterEvents = function (control) {
    if (control instanceof Control) {
        control.registerEvents();
        var childs = control.getControls();

        var i, len;
        for (i = 0, len = childs.length; i < len; i++) {
            this.recursiveRegisterEvents(childs[i]);
        }
    }
};

/**
 * unregister events from control
 *
 * @param {Banana.Control} control
 */
Page.prototype.recursiveUnRegisterEvents = function (control) {
    if (control instanceof Control) {
        control.onPreInvalidateContents();
        control.unregisterEvents();

        if (control instanceof Control) {
            var childs = control.getControls();

            var i, len;
            for (i = 0, len = childs.length; i < len; i++) {
                this.recursiveUnRegisterEvents(childs[i]);
            }

        }
    }
};

/**
 * completely removes a control from dom and data model
 *
 * @param Banana.Control
 * @param bool dont remove dom when true. this is an optimalisation.
 * from the controls which are getting removed, the root items (the one user want to remove) should
 * be treated differently.
 *
 * <Root item>                               -  <ChildItems>
 * parent control collection altered            parent control collection stays
 * call dom remove                              dont call dom remove (done in root)
 *
 */
Page.prototype.removeControl = function (control, dontRemoveDom) {
    if (!(control instanceof Control)) {
        return;
    }

    var childs = control.controls.slice(); //slice to clone

    var i;
    for (i = childs.length - 1; i >= 0; i--) {
        this.removeControl(childs[i], true);
        childs.pop();
    }

    control.unregisterEvents();

    control.unload();

    if (!dontRemoveDom) {
        DomHelper.remove(control);
    }

    //if our control got a parent, then we also need to remove it from the parent array controls
    //we only do this is we directly call remove on this control, otherwise we remove the controls array anywayy
    var parent = control.parent;

    if (parent) {
        var indexInParent = parent.controls.indexOf(control);

        parent.controls.splice(indexInParent, 1);
    }

    control = undefined;
};


/**
 * clears a control by removing all children
 *
 * @param {Banana.Control} control
 */
Page.prototype.clearControl = function (control) {
    var childs = control.getControls().slice();

    var totalCleared = 0;

    var i, len;
    for (i = 0, len = childs.length; i < len; i++) {
        totalCleared++;
        this.removeControl(childs[i], true);
    }

    control.controls = null;

    childs = null;

    //TODO added this to also remove non object controls ie strings.
    //move this to domhelper.js
    if (control.cachedElement) {
        control.cachedElement.empty()
    } else {
        jQuery('#' + control.getClientId()).empty();
    }
};

/**
 * removes complete page plus all its controls/events/binds and dom data
 * We traverse through child controls in a async operation. This is needed
 * to prevent slow script running detects by various browsers
 *
 * @param {boolean} keepDom when true we dont remove the dom
 */
Page.prototype.remove = function (keepPageDom, cb) {
    //get flatten depth first collection of control hierargie
    var flat = flattenTreeDepthFirst(this, 'controls');

    flat.pop(); //remove the last page which is {this} we handle {this} is the complete event

    arrayInteratorAsync(flat, (control, i) => {

            if (!(control instanceof Control)) {
                return;
            }

            control.unregisterEvents();
            control.unload();

            var parent = control.parent;

            if (parent && parent.controls) {
                parent.controls.splice(parent.controls.indexOf(control), 1);
            }
            control = undefined;
        },
        0, //timeout for each chuck
        () => { //callback called after completion

            this.unload();

            this.clearIds();

            //if we clear the page we also need to unregister the resize event. otherwise the callback will always
            //be called after screensize changes.
            window.removeEventListener("resize", this.resizefunc);
            document.removeEventListener("visibilitychange", this.visibilityChangeFunc);
            removeEventListener("online", this.onlineFunc);
            removeEventListener("offline", this.offlineFunc);

            //jQuery(window).unbind();

            this.unbind();
            this.removeDataSets();

            clearTimeout(this.timer);

            if (!keepPageDom) {
                DomHelper.remove(this);
            }

            if (cb) {
                cb();
            }
        }
        , () => {

            //not implemented here
        });
};

/**
 * used internally -> should move to another location
 * @ignore
 */
Page.prototype.removeDom = function () {
    jQuery('#cleared').remove();
};

/**
 * @ignore
 */
Page.prototype.clearProps = function () {
//	var prop;
//	for (prop in this)
//	{
//		//this[prop] = undefined;
//	}
};

/**
 *
 * @param Banana.UiControl
 */
Page.prototype.onWindowResize = function (control) {
    if (control instanceof Control) {
        control.onWindowResize();

        var childs = control.getControls();

        var i, len;
        for (i = 0, len = childs.length; i < len; i++) {
            this.onWindowResize(childs[i]);
        }
    }
};

/**
 *
 * @param Banana.UiControl
 */
Page.prototype.onVisibilityChange = function (control) {
    if (control instanceof Control) {
        control.onVisibilityChange();

        var childs = control.getControls();

        var i, len;
        for (i = 0, len = childs.length; i < len; i++) {
            this.onVisibilityChange(childs[i]);
        }
    }
};

/**
 *
 * @param Banana.UiControl
 */
Page.prototype.onOffline = function (control) {
    if (control instanceof Control) {
        control.onOffline();

        var childs = control.getControls();

        var i, len;
        for (i = 0, len = childs.length; i < len; i++) {
            this.onOffline(childs[i]);
        }
    }
};

/**
 *
 * @param Banana.UiControl
 */
Page.prototype.onOnline = function (control) {
    if (control instanceof Control) {
        control.onOnline();

        var childs = control.getControls();

        var i, len;
        for (i = 0, len = childs.length; i < len; i++) {
            this.onOnline(childs[i]);
        }
    }
};

/**
 * Clears all ids in the page.
 * Used by application during page transition.
 */
Page.prototype.clearIds = function (control) {
    if (!control) {
        control = this;
    }
    DomHelper.clearIdsFrom(control.getClientId());
};
