import {Panel} from "../../bananaframework/src/controls/Panel.js";
import {InputControl} from "../../bananaframework/src/controls/InputControl.js";
import {Image} from "../../bananaframework/src/controls/Image.js";

import {Modal} from "../modal.js";
import {Localizer} from "../../util/localizer.js";
import {Favorites} from "../../util/favorites.js";
import {CountryUtil} from "../../util/country.js";
import {DataProvider} from "../../data/dataprovider.js";
import {LazyTileGridRenderer} from "../lazytilegridrenderer.js";
import {TopVoterUserVoteItemRender,TopVoterUserVoteHeaderItemRender} from "./listrenders.js";
import {ErrorMessage, NoDataMessage} from "../errormessage.js";
import {ImagePlaceHolder} from "../imageplaceholder.js";
import {StateManager} from "../../data/stateManager.js";
import {Processors} from "../../util/processors.js";

export class Uservotesmodal extends Modal{

    init (urlIdentifier){
        super.init(urlIdentifier);
    }

    createComponents () {
        super.createComponents();

        var nameContainer = new Panel();
        nameContainer.setStyle("font-size:15px;height:30px;font-variant: small-caps;");
        this.addControl(nameContainer);

        var titleBarContainer = new Panel();
        titleBarContainer.setStyle("vertical-align: baseline;;display:flex;align-items: centerwidth:100%;height:80px;");
        this.addControl(titleBarContainer);

        var titleBarHitScore = new Panel();
        titleBarHitScore.addCssClass("RatingGood");
        titleBarHitScore.setStyle("width:100%;display: flex;align-items: center;justify-content: center");
        titleBarContainer.addControl(titleBarHitScore);

        var userImage = new ImagePlaceHolder();
        userImage.setStyle("width:70px;height:70px;");
        userImage.image.setStyle("border-radius: 40px;overflow: hidden;");
        titleBarContainer.addControl(userImage);

        var titleBarMissScore = new Panel();
        titleBarMissScore.addCssClass("RatingBad");
        titleBarMissScore.setStyle("width:100%;display: flex;align-items: center;justify-content: center");
        titleBarContainer.addControl(titleBarMissScore);

        var scrollContainer = new Panel();
        scrollContainer.setStyle("margin:5px;height:100%;position:relative;overflow-y: scroll;overflow-x:hidden;transform: translate(0,0);");
        this.scrollContainer = scrollContainer;

        var p = new Panel();
        //p.setStyle("height:"+(dim.height-50)+"px;justify-content: flex-start;");
        p.addCssClass("flexVerticalAlign");
        scrollContainer.addControl(p);
        this.addControl(scrollContainer);

        this.titleBarContainer = titleBarContainer;
        this.titleBarHitScore = titleBarHitScore;
        this.titleBarMissScore = titleBarMissScore;
        this.userImage = userImage;
        this.nameContainer = nameContainer;

        this.createList(p);

        this.loadUserVotes();
    }

    loadUserVotes (){
        console.log("load user votes",this.urlIdentifier);
        var id = this.urlIdentifier.split("_")[1];
        DataProvider.getTopVoterUser(id, (response)=>{
            console.log("get topvoter user",response.getData());

            var data = Processors.prepareUserVotesData(response.getData());
            console.log("===>",data);

            if (data.hits) {
                this.titleBarHitScore.addControl(data.hits+"", true);
                this.titleBarMissScore.addControl(data.misses+"", true);
                this.userImage.setImage(data.userdetails.social_profile_image, 70, 70);
                this.nameContainer.addControl(data.userdetails.social_username, true)
            }

            console.log("set datasource",data.listData);
            if (!data.listData) {
                this.titleBarContainer.setVisible(false);
            }
            this.listRender.setDataSource(data.listData || []);
        });
    }

    destroy (){
        super.destroy();
        StateManager.flushPageState("userVoteModal");
    }

    storeState () {
        var d = {
            "scrollPosition": this.listRender.getScroll(),
        };
        StateManager.savePageState("userVoteModal", d);
    }

    createList (container){

        var listRender = new LazyTileGridRenderer();
        listRender.containerTarget = this.scrollContainer;

        listRender.setItemRender(function(data){
            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new TopVoterUserVoteItemRender(),
                };
            }
            else if (data.type == 2) {
                return {
                    typeId: 2,
                    render: new TopVoterUserVoteHeaderItemRender(),
                };
            }
        })

        listRender.emptyTemplate = function(cb){
            return new NoDataMessage({
                cb : function(){
                    history.back();
                },
                message : Localizer.getString("no_data")
            });
        }

        listRender.errorTemplate = function(cb){
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function(){
            return new ErrorMessage();
        }

        listRender.bind('createdList',this.getProxy(function(){
        }))

        var storeHandler;
        listRender.bind("scroll",(e,scroll)=> {
            clearTimeout(storeHandler);
            storeHandler = setTimeout(() => {
                this.storeState();
            }, 200);
        });

        listRender.setPlaceHolderWidth("100%;");

        this.listRender = listRender;

        container.addControl(listRender);

        var state = StateManager.getCurrentPageState("userVoteModal");
        if (state) {
            console.log("set scroll",state.scrollPosition);
            listRender.scrollTo(state.scrollPosition);
        }

        return listRender;
    }
};
