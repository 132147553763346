import {Panel} from "../../bananaframework/src/controls/Panel.js"
import {Image} from "../../bananaframework/src/controls/Image.js"
import {DataGridTileItemRender} from "../../bananaframework/src/controls/listcontrols/datagrids/listrenders/DataGridTileItemRender.js"
import {Localizer} from "../../util/localizer.js";
import {Router} from "../../util/router.js";

export class ForumIndexHeaderItemRender extends DataGridTileItemRender{

    init (data)
    {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("listViewHeader");
        this.panel.setStyle("justify-content: space-between;padding-inline-end:0;");

        this.addControl(this.panel);
        this.contents = new Panel();
        this.panel.addControl(this.contents)
    }

    setData (data){

        this.data = data;
        this.contents.clear();
        this.panel.setCss({"height":data.style.height+"px"});
        this.contents.addControl(data.title,true);
    }
};

export class ForumIndexItemRender extends DataGridTileItemRender{

    init (data)
    {
        super.init();
        this.data = data;
        this.panel = new Panel();
        this.panel.addCssClass("listViewItem");

        this.addControl(this.panel);
        //
        this.content = new Panel();
        this.content.addCssClass("flexHorizontalAlign");
        this.content.setStyle("align-items: flex-start;width: 100%;");

        this.panel.addControl(this.content);

        this.itemImage = new Image();
        this.itemImage.setStyle("margin-inline-start:8px;width:40px; height;40px;align-self: center;")
        this.content.addControl(this.itemImage);


        this.forumIndexName = new Panel();
        this.forumIndexName.setStyle("align-self: center;margin-inline-start: 8px;line-height: 21px;");
        this.content.addControl(this.forumIndexName);

        this.lastMessage = new Panel();

        var margin = "margin-left:auto;text-align:right;";
        if (FootballManiaConfig.isRtl){
            margin = "margin-right:auto;";
        }

        this.lastMessage.setStyle("font-size:10px;"+margin+"align-self: center;line-height: 22px;margin-inline-end:4px;")
        this.content.addControl(this.lastMessage);

        this.content.bind("click",()=>{
            Router.showForumChat(this.data.link,this.data.name);
        });
    }

    setData (data){
        this.data = data;

        this.panel.setCss({"height":data.style.height+"px"});
        this.forumIndexName.clear();
        this.lastMessage.clear();

        //this.title.addControl(data.title+"",true);
        //this.playerImage.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/images/playerimages/"+data.id+"_small_rnd.png",40,40);
        console.log(data);
        if (data.image) {
            this.itemImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.image.replace(/\s|\'/g, '-').toLowerCase() + ".png");
        }
        this.forumIndexName.addControl(data.name,true);

        if (data.last_message_at){
            //var date = new DateTimecode(data.last_message_at);/
            this.lastMessage.addControl(data.last_message_by);
            this.lastMessage.addControl("<br/>");
            var formatted = Intl.DateTimeFormat(FootballManiaConfig.userLocale, {
                weekday: 'short', month: 'short', day: 'numeric'
            }).format(new Date(data.last_message_at));
            this.lastMessage.addControl(formatted,true  );

        }

        //this.lastMessage.addControl(this.valueFormatter.format(data.marketvalue),true);
    }
};

export class ChatMessageItemRender extends DataGridTileItemRender{

    init (data)
    {
        super.init();
        this.mainContainer = new Panel();
        this.mainContainer.addCssClass("ChatMessageMainContainer");
        this.container = new Panel();
        this.container.addCssClass("ChatMessageContainer");

        this.image = new Image();
        this.image.setAttribute("width","40px");
        this.image.setAttribute("height","40px");
        this.image.addCssClass("ChatMessageImage");

        this.contentContainer = new Panel();
        this.contentContainer.addCssClass("ChatMessageContentContainer");
        this.topContainer = new Panel();
        this.topContainer.addCssClass("ChatMessageTopContainer")
        this.name = new Panel();
        this.name.addCssClass("ChatMessageName");
        this.votes = new Panel();
        this.votes.addCssClass("ChatMessageRating");
        this.text = new Panel();
        this.bottomContainer = new Panel();
        this.bottomContainer.addCssClass("ChatMessageBottom");
        this.date = new Panel();
        this.deliveryStatus = new Panel();
        this.bottomContainer.addControl(this.date)
        this.bottomContainer.addControl(this.deliveryStatus)

        this.container.addControl(this.image);
        this.topContainer.addControl(this.name);
        this.topContainer.addControl(this.votes);
        this.contentContainer.addControl(this.topContainer);
        this.contentContainer.addControl(this.text);
        this.contentContainer.addControl(this.bottomContainer);
        this.container.addControl(this.contentContainer);
        this.mainContainer.addControl(this.container);
        this.addControl(this.mainContainer);

        this.container.bind("click",(e)=>{
            //prevent other click events. currently used to disable the side menu bar
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            this.listRender.triggerEvent("itemClick",this.data);
        })
    }

    blur (){
        this.container.addCssClass("ChatMessageContainerBlur");
    }

    unblur (){
        this.container.removeCssClass("ChatMessageContainerBlur");
    }

    setData (data){
        this.data = data;
        if (data.removed == 1){
            data.m = Localizer.getString("removed");
            this.container.setStyle("opacity: 0.3;");
        }
        this.image.setImage(data.social_profile_image || data.image);
        this.name.addControl(data.un);

        if (data.rating > 0 || data.rating < 0) {
            this.votes.addControl((data.rating > 0 ? "+" : "") + data.rating + "");

            if (data.rating < -2) {
                this.votes.addCssClass("ChatRatingSuperBad");
                this.container.setCss({"opacity":"0.1"});
            } else if (data.rating < 0) {
                this.votes.addCssClass("ChatRatingBad");
                this.container.setCss({"opacity":"0.3"});
            } else if (data.rating > 0) {
                this.votes.addCssClass("MChatRatingGood");
            } else if (data.rating > 2) {
                this.votes.addCssClass("ChatRatingBetter");
            } else {
                this.votes.addCssClass("ChatRatingExcellent");
            }
        }

        this.text.addControl(data.m);

        var today12 = new Date();
        today12.setHours(0,0,0,0);
        var messageDate = new Date(data.time*1000);
        if (messageDate > today12) {
            var formatted = Intl.DateTimeFormat(FootballManiaConfig.userLocale, {
                weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'
            }).format(data.time * 1000);
            this.date.addControl(formatted);
        }
        else {
            var formatted = Intl.DateTimeFormat(FootballManiaConfig.userLocale, {
                weekday: 'short', month: 'short', day: 'numeric'
            }).format(data.time * 1000);
            this.date.addControl(formatted);
        }

        if (data.delivered) {
            this.deliveryStatus.addCssClass("icon-verify_both");
            this.deliveryStatus.setStyle("width:20px;height:20px;margin-inline-start:20px;")
        }
        else if (data.delivered === false) {
            this.deliveryStatus.addCssClass("icon-verify_single");
            this.deliveryStatus.setStyle("width:20px;height:20px;margin-inline-start:20px;")
        }
    }
};
