import {Panel} from "../bananaframework/src/controls/Panel.js";
import {Localizer} from "../util/localizer.js";
import {Modal} from "./modal.js";

export class Iframe extends Panel{
    getTagName()
    {
        return 'iframe';
    }
};

export class ChatMenuPopup extends Panel{

    init  () {
        super.init();
        this.isSlidedOut = true;
        this.voteEnabled = true;
    }

    createComponents  () {
        this.addCssClass("ChatMenuPopup");

        if (this.isAdmin){
            this.setCss({"margin-bottom":"400px"});
        }
        else{
            this.setCss({"margin-bottom":"200px"});
        }

        this.thumbup = new Panel();
        this.thumbup.addCssClass("icon-thumbup menuchaticon");
        this.addControl(this.thumbup);

        this.thumbdown = new Panel();
        this.thumbdown.addCssClass("icon-thumbdown menuchaticon");
        this.addControl(this.thumbdown);

        this.thumbup.bind("click",()=>{
            if (!this.voteEnabled){
                this.getPage().getPageTemplate().showToast(Localizer.getString("already_voted"));
                return;
            }
            this.triggerEvent("thumbup");
        });

        this.thumbdown.bind("click",()=>{
            if (!this.voteEnabled){
                this.getPage().getPageTemplate().showToast(Localizer.getString("already_voted"));
                return;
            }
            this.triggerEvent("thumbdown");
        });

        if (!this.isAdmin) return;

        this.adminMenu = new Panel();
        this.adminMenu.addCssClass("icon-admin menuchaticon");
        this.addControl(this.adminMenu);

        this.adminMenu.bind("click",()=>{
            this.triggerEvent("requestAdminMenu");
        });
    }

    enableVote(toggle){
        this.voteEnabled = toggle;
        if (!toggle){
            this.thumbdown.addCssClass("icon-thumb-disabled");
            this.thumbup.addCssClass("icon-thumb-disabled");
        }
        else{
            this.thumbdown.removeCssClass("icon-thumb-disabled");
            this.thumbup.removeCssClass("icon-thumb-disabled");
        }
    }

    slideIn(){
        this.isSlidedOut = false;
        this.addCssClass("chatmenuanimatein");
        this.removeCssClass("chatmenuanimateout");
    }

    slideOut() {
        //return;
        if (this.isSlidedOut) return;
        this.isSlidedOut = true;
        this.addCssClass("chatmenuanimateout");
        this.removeCssClass("chatmenuanimatein");
    }
};

export class ChatAdminMenuModal extends Modal{

    init (urlIdentifier) {
        super.init(urlIdentifier);
    }

    unload () {
        clearTimeout(this.commentaryRefreshHandler);
    }

    setData(data){
        this.data = data;
    }

    createComponents () {
        var username = new Panel();
        username.addControl(this.data.un);
        username.addCssClass("adminmenuusername");
        this.addControl(username);

        var removeMessage = new Panel();
        removeMessage.addCssClass("adminmenuitem");
        removeMessage.setCss({"margin-top":"27px"});
        removeMessage.addControl(Localizer.getString("Remove message"));
        removeMessage.bind("click",()=>{ this.triggerEvent("removeMessage"); });
        this.addControl(removeMessage);

        // var editMessage = new Panel();
        // editMessage.addCssClass("adminmenuitem");
        // editMessage.addControl(Localizer.getString("Edit message"));
        // editMessage.bind("click",()=>{ this.triggerEvent("editMessage"); });
        // this.addControl(editMessage);

        var banUser1day = new Panel();
        banUser1day.addCssClass("adminmenuitem");
        banUser1day.addControl(Localizer.getString("Ban user 1 day"));
        banUser1day.bind("click",()=>{ this.triggerEvent("banUser1day"); });
        this.addControl(banUser1day);

        var banUser7day = new Panel();
        banUser7day.addCssClass("adminmenuitem");
        banUser7day.addControl(Localizer.getString("Ban user 7 days"));
        banUser7day.bind("click",()=>{ this.triggerEvent("banUser7days"); });
        this.addControl(banUser7day);

        var banUser356day = new Panel();
        banUser356day.addCssClass("adminmenuitem");
        banUser356day.addControl(Localizer.getString("Ban user 365 days"));
        banUser356day.bind("click",()=>{ this.triggerEvent("banUser365days"); });
        this.addControl(banUser356day);

        var unbanUser = new Panel();
        unbanUser.addCssClass("adminmenuitem");
        unbanUser.addControl(Localizer.getString("Unban user"));
        unbanUser.bind("click",()=>{ this.triggerEvent("unbanuser"); });
        this.addControl(unbanUser);

        var userMessage = new Panel();
        userMessage.addControl(this.data.m);
        userMessage.addCssClass("adminmenumessage");
        this.addControl(userMessage);
    }
};