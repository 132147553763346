import {Panel} from "../../bananaframework/src/controls/Panel.js";
import {Image} from "../../bananaframework/src/controls/Image.js";

import {Localizer} from "../../util/localizer.js";
import {ImagePlaceHolder} from "../imageplaceholder.js";
import {PlayerHeader} from "../player/playerheader.js";

export class CoachHeader extends PlayerHeader{

    init () {
        super.init();
    }

    setPresetData (data,instantRender){

        if (!data){
            console.error("cannot set preset data without data");
            return;
        }
        if (instantRender) {
            if (data.nationality) {
                this.backgroundImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.nationality.replaceAll(" ", "-").toLowerCase() + ".png");
                this.backgroundImage.setVisible(true);
            }
        }

        this.homeTitle.clear();
        if (!AppInstance.getConfig().isRtl) {
            this.homeTitle.addControl(data.firstname + " " + data.lastname, true);
        }
        else{
            this.homeTitle.addControl(data.lastname + " " + data.firstname, true);
        }


        if (!this.oldData || this.oldData.id != data.id) {

            if (AppInstance.getConfig().enabledFeatures.playerImages) {

                this.homeImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/"+AppInstance.getConfig().url.teamPlayerImageUrl+"/coachimages/" + data.id + "_rnd.png", 100, 100);
                this.homeImage.setAttribute("alt",Localizer.getString("player image"));

                if (data.nationality) {

                    this.teamFlag.imageObjectStyle = "margin-top:-15px;margin-left:-16px;";
                    this.teamFlag.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.nationality.replaceAll(" ", "-").toLowerCase() + ".png", 70, 70);
                } else {
                    this.teamFlag.remove();
                }

                if (data.teamid) {
                    this.image.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/"+AppInstance.getConfig().url.teamPlayerImageUrl+"/teams_gs/" + data.teamid + "_small_rnd.png", AppInstance.getConfig().sizes.fixtureListImageSize, AppInstance.getConfig().sizes.fixtureListImageSize);
                }
                else{
                    this.image.remove();
                }
            }
            else{
                this.teamFlag.remove();
                this.image.remove();
                this.homeImage.imageObjectStyle = "margin-top:-40px;margin-left:-40px;";
                this.homeImage.setCss({'overflow':"hidden","border-radius":"50%"});
                if (data.nationality) {
                    this.homeImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.nationality.replaceAll(" ", "-").toLowerCase() + ".png", 180, 180);
                }
                //this.image.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/"+AppInstance.getConfig().url.teamPlayerImageUrl+"/teams_gs/" + data.teamid + "_small_rnd.png", AppInstance.getConfig().sizes.fixtureListImageSize, AppInstance.getConfig().sizes.fixtureListImageSize);
            }
        }
        this.oldData = data;
    }

    setData (data){
    }
};