import {Panel} from "../bananaframework/src/controls/Panel.js";
import {TextBox} from "../bananaframework/src/controls/TextBox.js";
import {UrlManager} from "../bananaframework/src/util/UrlManager.js";

import {BasePage} from "./basepage.js";

import {Router} from "../util/router.js";
import {Localizer} from "../util/localizer.js";
import {Socializer} from "../util/socializer.js";

import {StateManager} from "../data/stateManager.js";

import {TabView,TabViewTab} from "../components/tabview.js";
import {ErrorMessage,NoDataMessage3} from "../components/errormessage.js";

import {ChatAdminMenuModal,ChatMenuPopup} from "../components/various.js";
import {ChatSocketIO} from "../components/chat/chatsocketio.js";
import {ChatMessageItemRender} from "../components/chat/listrenderschat.js";
import {AnimatorLeague} from "./animators/animatorleague.js";

export class ForumChat extends BasePage{

    init () {
        this.pageName = "ForumChat";
        this.version = 1;
        super.init();

        Socializer.init();

        this.setStyle("height:100%");

        this.pageName = "ForumChat";

        this.addCssClass("ForumChat");

        this.isNavigatedFromOutside = Router.navigationIsFromOutside();
        Router.updateCurrentReferalData();

        if (this.isNavigatedFromOutside){
            StateManager.flushPageState(this.pageName);
        }

        console.log("OPENING PAGE "+this.pageName+" VERSION "+this.version+" is from outside "+this.isNavigatedFromOutside)

        this.coordinatoranimator = new AnimatorLeague();

        Router.disallowBackButtonQuitApp();

        this.createPageLayout();

        if (AppInstance.workersInitialized) {
            this.wrapper1 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper1.addEventListener("message", this.wrapper1);
        }

        window.addEventListener("message",this.onWebviewMessagePosted);
    }

    onWebviewMessagePosted (e){
        console.log("on webview message posted",e);
    }

    onWorkerMessageReceived (e){
        if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };
        //this.tabview.tabs[0].hideLoader();
        //this.chatListrender.setDataSource(e.data);
    }

    storeState (){

        var d = {
            "tabIndex":this.tabview.currentActiveTab,
            "topValueScrollPosition" : this.chatListrender.getScroll(),
            "headerCollapsed" : this.coordinatoranimator.isCollapsed
        }

        StateManager.savePageState(this.pageName,d);
    }

    unload (){
        this.unloaded = true;
        $.xhrPool.abortAll();
        this.coordinatoranimator.unload();
        clearTimeout(this.topValueLoadHandler);
        clearTimeout(this.menuSlideInTimeouthandler);
        if (AppInstance.workersInitialized) {
            AppInstance.workerHelper1.removeEventListener("message", this.wrapper1);
        }
        //window.removeEventListener("message",this.onWebviewMessagePosted);
    }

    onVisibilityChange (){
        //page is becoming visible after hide
        if (this.previousDocumentHidden = true && !document.hidden){
            //this.autoInterval(0);
        }
        this.previousDocumentHidden = document.hidden;
        //console.log("visibility change ",document.hidden,this);
    }

    createPageLayout (){
        var row2 = new Panel();
        this.addControl(row2);
        this.contentContainer = row2;
    }

    createComponents (){
        this.getPageTemplate().showTitle();
    }


    onWindowResize (){

        super.onWindowResize();

        if (this.tabview) {
            this.tabview.onWindowResize();
        }
        if (this.coordinatoranimator) {
            this.coordinatoranimator.onWindowResize();
        }

        if (this.chatListrender) {
            this.chatListrender.onWindowResize();
        }
    }

    updateDisplay (){

        this.runApp();
    }

    runApp (){

        console.log("run app")

        this.showLoader();

        if (!AppInstance.setupAlreadyDone) {
            this.showLoader();
        }

        AppInstance.setup((result)=>{

            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

            if (!result){
                this.hideLoader();
                console.error("error app setup");
                return this.showError("",()=>{
                    this.runApp();
                });
            }

            this.hideLoader();

            this.createTitle();
            this.createTabs();

            this.getPageTemplate().createMenuForumChat();

            this.tabview.bind("tabChanged",()=>{
                this.storeState();
                this.coordinatoranimator.tabChange();
            });

            var storeHandler = null;

            this.chatListrender.bind("scroll",(e,scroll)=> {
                clearTimeout(storeHandler);
                clearTimeout(this.menuSlideInTimeouthandler);
                if (this.chatMenuPopup) {
                    this.chatMenuPopup.slideOut();
                    for (var i=0; i < this.chatListrender.indexRenderedItemRenderMap.length; i++) {
                        this.chatListrender.indexRenderedItemRenderMap[i].unblur();
                    }
                    this.inBlurMode = false;
                }
                storeHandler = setTimeout(()=> {
                    this.storeState();
                },200)
            });

            var previousState = StateManager.getCurrentPageState(this.pageName);
            if (previousState) {
                this.tabview.currentActiveTab = previousState.tabIndex;
                this.chatListrender.scrollTo(previousState.topValueScrollPosition || 0);
                this.coordinatoranimator.isCollapsed = previousState.headerCollapsed;
            }

            this.contentContainer.invalidateDisplay();
            this.coordinatoranimator.prepare();

            this.initChat();
        });
    }

    createTitle (data){
        this.getPageTemplate().topTitleContainer.setVisible(false);
    }

    createTabs (data){
        var tabview = new TabView();
        this.contentContainer.addControl(tabview);

        var tabs = [];

        var tab1;
        var tab2;
        var tab3;

        tab1 = new TabViewTab();
//        tab1.setStyle("width:100%;height:100%;position:relative;overflow-y: scroll;transform: translate(0,0);");
        tab1.title = Localizer.getString(decodeURI(UrlManager.getModule("title")));
        tabs.push(tab1);


        var con = new Panel();
        con.setStyle("width: 100%;position:absolute;");
        var bottom = new Panel();
        bottom.addCssClass("tableFooter")

        var input = new TextBox();
        input.addCssClass("ChatInput")
        input.setData(Localizer.getString("chattypehint"));

        this.chatMenuPopup = new ChatMenuPopup();
        this.chatMenuPopup.isAdmin = true;
        this.chatMenuPopup.bind("thumbup",() =>{
            console.log(this.selectedChatData);
            if (!this.selectedChatData) {
                console.error("no selected chat data");
                return;
            }
            this.chatListrender.rateMessage(this.selectedChatData,1);
        });
        this.chatMenuPopup.bind("thumbdown",() =>{
            console.log(this.selectedChatData);
            if (!this.selectedChatData) {
                console.error("no selected chat data");
                return;
            }
            this.chatListrender.rateMessage(this.selectedChatData,-1);
        });
        this.chatMenuPopup.bind("requestAdminMenu",() =>{
            console.log(this.selectedChatData);
            if (!this.selectedChatData) {
                console.error("no selected chat data");
                return;
            }

            var adminPanel = new ChatAdminMenuModal();
            adminPanel.setData(this.selectedChatData);
            this.adminPanel = adminPanel;
            this.getPage().addControl(adminPanel,true);
            clearTimeout(this.menuSlideInTimeouthandler);

            this.getPage().bind("onModalClose",() =>{
                this.chatMenuPopup.slideOut();
                for (var i=0; i < this.chatListrender.indexRenderedItemRenderMap.length; i++) {
                    this.chatListrender.indexRenderedItemRenderMap[i].unblur();
                }
                this.inBlurMode = false;
            });

            adminPanel.bind("removeMessage",() =>{
                this.chatListrender.adminRemoveMessage(this.selectedChatData);
            });
            adminPanel.bind("banUser1day",() =>{
                this.chatListrender.adminBanUser(this.selectedChatData,1);
            });
            adminPanel.bind("banUser7days",() =>{
                this.chatListrender.adminBanUser(this.selectedChatData,7);
            });
            adminPanel.bind("banUser365days",() =>{
                this.chatListrender.adminBanUser(this.selectedChatData,365);
            });
            adminPanel.bind("unbanuser",() =>{
                this.chatListrender.adminUnbanUser(this.selectedChatData,365);
            });

        });

        bottom.addControl(this.chatMenuPopup);

        bottom.addControl(input);
        bottom.setVisible(false);

        var arrow = new Panel();
        arrow.addCssClass("arrow-right")
        bottom.addControl(arrow);

        var scroller = new Panel();
        scroller.addCssClass("scroller");
        con.addControl(scroller);
        con.addControl(bottom);

        //tab2.setStyle("width:100%;height:100%;position:relative;overflow-y: scroll;transform: translate(0,0);");

        tab1.addControl(con);
        //tab2.setStyle("width:100%;height:100%;position:relative;overflow-y: scroll;transform: translate(0,0);");


        this.coordinatoranimator.addScrollerContainer(scroller);

        var heightFooter = 0;

        heightFooter +=50;

        ///set fixed height of scroller container table items.
        //this is needed because bottom description container is dynamic in height
        bottom.setStyle("height:"+heightFooter+"px;bottom:inherit;display: flex;    flex-direction: row;align-items: center;justify-content: flex-start;")
        var offset = 50;
        scroller.setStyle("width:100%;height:calc(100% - "+offset+"px);position:relative;overflow-y: scroll;transform: translate(0,0);");


        this.createChatControls(scroller,0);
        //this.coordinatoranimator.addScrollerContainer(tab1);

        this.coordinatoranimator.addCollapser(this.getPageTemplate().topBar);
        this.coordinatoranimator.addTabView(tabview);

        tabview.setTabs(tabs);

        tabview.bind("onReady",()=>{
            this.coordinatoranimator.run();
        });

        this.tabview =tabview;
        this.bottomInputContainer = bottom;
        this.bottomInput = input;
        this.bottomArrow = arrow;

        tab1.showLoader();

        this.bind("stickyBannerLoadFail", () => {
            console.error("sticky banner load fail -> set height to 100%");
            if (tab1) tab1.setCss({'height': '100%'});
        });
        //triggered from page template
        this.bind("stickyBannerLoadSuccess", () => {
            console.log("sticky banner load success -> set height to 100% - 50px");
            //if (tab1) tab1.setCss({'height': 'calc(100% - 50px)'});
            //scroller.setStyle("width:100%;height:calc(100% - 150px);position:relative;overflow-y: scroll;transform: translate(0,0);");
            scroller.setCss({"height": "calc(100% - "+(offset+50)+"px)"});
            bottom.setCss({"bottom": "50px"});
        });
        this.getPageTemplate().loadStickyBannerAd();
    }

    createChatControls (tab,index){
        var container = new Panel();
        //container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new ChatSocketIO();
        listRender.chatIndex = UrlManager.getModule("index");
        listRender.containerTarget = tab;

        listRender.setItemRender(function(data){
            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new ChatMessageItemRender(),
                };
            }
        })

        listRender.emptyTemplate = function(cb){
            return new NoDataMessage3({
                cb : function(){
                    history.back();
                },
                message : Localizer.getString("no_chat_messages")
            });
        }

        listRender.errorTemplate = function(cb){
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function(){
            return new ErrorMessage();
        }
        listRender.bind("click", (data) => {
            console.log("click");
            this.chatMenuPopup.slideOut();
            for (var i=0; i < listRender.indexRenderedItemRenderMap.length; i++) {
                listRender.indexRenderedItemRenderMap[i].unblur();
            }
            this.inBlurMode = false;
        });
        listRender.bind('createdList',()=>{
            this.coordinatoranimator.recalculateTab(index);
        });
        listRender.bind("authentication_start", (data) => {
            this.getPageTemplate().hideStickBannerAd();
        });
        listRender.bind("authentication_end", (data) => {
            this.getPageTemplate().loadStickyBannerAd();
        });
        listRender.bind('refresh_chat',()=>{
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };
            this.tabview.tabs[0].hideLoader();
            this.bottomInputContainer.setVisible(true);
        });
        listRender.bind("accepted", () => {
            console.log("accepted");
           this.acceptInteraction();
        });
        listRender.bind("incoming_message", (data) => {
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };
            clearTimeout(this.menuSlideInTimeouthandler);
            this.chatMenuPopup.slideOut();
            for (var i=0; i < listRender.indexRenderedItemRenderMap.length; i++) {
                listRender.indexRenderedItemRenderMap[i].unblur();
            }
        });
        listRender.bind("voteFailed", (data) => {
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };
            clearTimeout(this.menuSlideInTimeouthandler);
            this.chatMenuPopup.slideOut();
            for (var i=0; i < listRender.indexRenderedItemRenderMap.length; i++) {
                listRender.indexRenderedItemRenderMap[i].unblur();
            }
        });
        listRender.bind("authentication_error", (data) => {
            this.getPageTemplate().createMenuForumChat(); //in case we auto logout
        });
        listRender.bind("adminActionFailed", (data) => {
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };
            clearTimeout(this.menuSlideInTimeouthandler);
            this.chatMenuPopup.slideOut();
            for (var i=0; i < listRender.indexRenderedItemRenderMap.length; i++) {
                listRender.indexRenderedItemRenderMap[i].unblur();
            }
            if (this.adminPanel) this.adminPanel.destroy();
        });
        listRender.bind("itemClick", (e,data) => {
            console.log("itemClick");
            if (!this.chatMenuPopup.isAdmin && data.removed == 1){
                console.log("message removed");
                return;
            }

            if (this.inBlurMode){
                clearTimeout(this.menuSlideInTimeouthandler);
                this.chatMenuPopup.slideOut();
                console.log("slideout");
                for (var i=0; i < listRender.indexRenderedItemRenderMap.length; i++) {
                    listRender.indexRenderedItemRenderMap[i].unblur();
                }
                this.inBlurMode = false;
                return;
            }
            else{
                this.chatMenuPopup.enableVote(data.userVote != 1 && data.userVote != -1);
            }

            console.log("slidein");
            this.chatMenuPopup.slideIn();

            clearTimeout(this.menuSlideInTimeouthandler);
            this.menuSlideInTimeouthandler = setTimeout(() => {
                console.log("timeout for slideout");
                this.chatMenuPopup.slideOut();
                this.inBlurMode = false;
                for (var i=0; i < listRender.indexRenderedItemRenderMap.length; i++) {
                    listRender.indexRenderedItemRenderMap[i].unblur();
                }
                this.selectedChatData = null;

            }, 5000);

            for (var i=0; i < listRender.indexRenderedItemRenderMap.length; i++) {
                if (!this.inBlurMode && listRender.indexRenderedItemRenderMap[i].data != data){
                    listRender.indexRenderedItemRenderMap[i].blur();
                    this.selectedChatData = data;
                }
                else{
                    listRender.indexRenderedItemRenderMap[i].unblur();
                }
            }
            this.inBlurMode = !this.inBlurMode;
        });

        listRender.setPlaceHolderWidth("100%;");

        var previousState = StateManager.getCurrentPageState(this.pageName
        );
        if (previousState) {
            listRender.scrollTo(previousState.fixturesScrollPosition || 0);
        }

        container.addControl(listRender);

        this.chatListrender = listRender;

        tab.addControl(container);
    }

    acceptInteraction (data){

        var message = "";

        // this.bottomArrow.unbind('click');
        // this.bottomInput.unbind('click');
        // this.bottomInput.unbind('keyup');
        this.bottomInput.bind('click', () =>{
            this.bottomInput.setData("");
        });
        this.bottomArrow.bind('click', () =>{
            this.chatListrender.addMessage(this.bottomInput.getData());
            this.bottomInput.setData("");
        });
        this.bottomInput.bind('keyup', (e) =>{
            if (e.keyCode == 13) {
                this.chatListrender.addMessage(this.bottomInput.getData());
                this.bottomInput.setData("");
            }
            else{
                message = this.bottomInput.getData();
                console.log("the current message = "+message);
            }
        });
    }

    initChat (data,ignoreCache){

    }
};