import {PushMessaging} from "./pushmessaging.js";

export const Subscriber = {

    storageKeyMatch : "matchSubscriptions",
    storageKeyTeam : "teamSubscriptions",
    storageKeyLeague : "leagueSubscriptions",

    getSubscribedMatches : function(){
        if (this.cachedSubscribedMatches) return this.cachedSubscribedMatches;
        var data = [];
        try {
            var raw = localStorage.getItem(this.storageKeyMatch);
            data = JSON.parse(raw);
            if (!data) data = [];
        }
        catch (e) {
            console.warn("error getting subscribed matches ",e);
        }
        this.cachedSubscribedMatches = data;
        return data;
    },

    getSubscribedTeams : function(){
        if (this.cachedSubscribedTeams) return this.cachedSubscribedTeams;
        var data = [];
        try {
            var raw = localStorage.getItem(this.storageKeyTeam);
            data = JSON.parse(raw);
            if (!data) data = [];
        }
        catch (e) {
            console.warn("error getting subscribed teams ",e);
        }
        this.cachedSubscribedTeams = data;
        return data;
    },

    getSubscribedLeagues : function(){
        if (this.cachedSubscribedLeagues) return this.cachedSubscribedLeagues;
        var data = [];
        try {
            var raw = localStorage.getItem(this.storageKeyLeague);
            data = JSON.parse(raw);
            if (!data) data = [];
        }
        catch (e) {
            console.warn("error getting subscribed leagues ",e);
        }
        this.cachedSubscribedLeagues = data;
        return data;
    },

    hasMatchSubscription : function(matchid){
        return this.getSubscribedMatches().indexOf(matchid) != -1
    },

    hasTeamSubscription : function(teamid){
        return this.getSubscribedTeams().indexOf(teamid) != -1
    },

    hasLeagueSubscription : function(key){
        return this.getSubscribedLeagues().indexOf(key) != -1
    },

    unsubscribeMatch : function (matchid,cb) {
        PushMessaging.unSubscribeMatch(matchid,(result)=>{
            if (!result){
                console.warn("error usubscribing match ",matchid);
                if (cb) cb(false);
                return;
            }
            this.cachedSubscribedMatches = null;
            var data = this.getSubscribedMatches();
            data.splice(data.indexOf(matchid),1)
            localStorage.setItem(this.storageKeyMatch,JSON.stringify(data));
            if (cb) cb(true);
        });
    },

    unsubscribeTeam : function (teamid,cb) {
        PushMessaging.unSubscribeTeam(teamid,(result)=>{
            if (!result){
                console.warn("error unsubscribing team ",teamid);
                if (cb) cb(false);
                return;
            }
            this.cachedSubscribedTeams = null;
            var data = this.getSubscribedTeams();
            data.splice(data.indexOf(teamid),1)
            localStorage.setItem(this.storageKeyTeam,JSON.stringify(data));
            if (cb) cb(true);
        });
    },

    unsubscribeLeague : function (key,cb) {
        PushMessaging.unSubscribeLeague(key,(result)=>{
            if (!result){
                console.warn("error unsubscribing league ",key);
                if (cb) cb(false);
                return;
            }
            this.cachedSubscribedLeagues = null;
            var data = this.getSubscribedLeagues();
            data.splice(data.indexOf(key),1)
            localStorage.setItem(this.storageKeyLeague,JSON.stringify(data));
            if (cb) cb(true);
        });
    },

    subscribeMatch : function (matchid,cb) {
        PushMessaging.subscribeMatch(matchid,(result)=>{
            if (!result){
                console.warn("error subscribe match ",matchid);
                if (cb) cb(false);
                return;
            }
            this.cachedSubscribedMatches = null;
            var data = this.getSubscribedMatches();
            data.push(matchid);
            localStorage.setItem(this.storageKeyMatch,JSON.stringify(data));
            if (cb) cb(true);
        });
    },

    subscribeTeam : function (teamid,cb) {
        PushMessaging.subscribeTeam(teamid,(result)=>{
            if (!result){
                console.warn("error subscribe team ",teamid);
                if (cb) cb(false);
                return;
            }
            this.cachedSubscribedTeams = null;
            var data = this.getSubscribedTeams();
            data.push(teamid);
            localStorage.setItem(this.storageKeyTeam,JSON.stringify(data));
            if (cb) cb(true);
        });
    },

    subscribeLeague : function (key,cb) {
        PushMessaging.subscribeLeague(key,(result)=>{
            if (!result){
                console.warn("error subscribe league ",key);
                if (cb) cb(false);
                return;
            }
            this.cachedSubscribedLeagues = null;
            var data = this.getSubscribedLeagues();
            data.push(key);
            localStorage.setItem(this.storageKeyLeague,JSON.stringify(data));
            if (cb) cb(true);
        });
    },

    migrateAndroidMatchSubscriptions :  function(newData){
        localStorage.setItem(this.storageKeyMatch,newData);
    },

    migrateAndroidTeamSubscriptions :  function(newData){
        localStorage.setItem(this.storageKeyTeam,newData);
    },

    migrateAndroidLeagueSubscriptions :  function(newData){
        localStorage.setItem(this.storageKeyLeague,newData);
    }
};