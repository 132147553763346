import {Panel} from "../bananaframework/src/controls/Panel.js";
import {Image} from "../bananaframework/src/controls/Image.js";
import {UrlManager} from "../bananaframework/src/util/UrlManager.js";

import {BasePage} from "./basepage.js";

import {Router} from "../util/router.js";
import {ManiaPreferences} from "../util/preferences.js";
import {Localizer} from "../util/localizer.js";

import {AnimatorLeague} from "./animators/animatorleague.js";
import {DataProvider} from "../data/dataprovider.js";
import {StateManager} from "../data/stateManager.js";

import {TabView,TabViewTab} from "../components/tabview.js";
import {ErrorMessage} from "../components/errormessage.js";
import {SimpleTileGridRenderer} from "../components/simpletilegridrenderer.js";

import {NewsListRender} from "../components/home/listrendershome.js";

export class News extends BasePage{

    pageName = "News";
    version = 1;

    init () {
        super.init();
        this.setStyle("height:100%");

        this.addCssClass("NewsPage");

        this.isNavigatedFromOutside = Router.navigationIsFromOutside();
        Router.updateCurrentReferalData();

        if (this.isNavigatedFromOutside){
            StateManager.flushPageState(this.pageName);
        }

        console.log("OPENING PAGE "+this.pageName+" VERSION "+this.version+" is from outside "+this.isNavigatedFromOutside)

        this.coordinatoranimator = new AnimatorLeague();

        Router.disallowBackButtonQuitApp();
        this.createPageLayout();

        if (AppInstance.workersInitialized) {
            this.wrapper1 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper1.addEventListener("message", this.wrapper1);
        }

        ManiaPreferences.bind("userpref_newslocaleChanged",this.getProxy(function(e,locale){
            this.reloadNews(locale);
        }));
    }

    onWorkerMessageReceived (e){
        if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };
        this.tabview.tabs[0].hideLoader();
        this.newsListrender.setDataSource(e.data);
        this.autoInterval();
    }

    storeState (){

        var d = {
            "tabIndex":this.tabview.currentActiveTab,
            "newsScrollPosition" : this.newsListrender.getScroll(),
            "headerCollapsed" : this.coordinatoranimator.isCollapsed
        }
        console.log("store state",d);
        StateManager.savePageState(this.pageName,d);
    }

    unload (){
        this.unloaded = true;
        $.xhrPool.abortAll();
        this.coordinatoranimator.unload();
        clearTimeout(this.newsLoadHandler);
        UrlManager.unlistenModule('cid');
        if (AppInstance.workersInitialized) {
            AppInstance.workerHelper1.removeEventListener("message", this.wrapper1);
        }
        ManiaPreferences.unbind("userpref_newslocaleChanged");
    }

    onVisibilityChange (){
        //page is becoming visible after hide
        if (this.previousDocumentHidden = true && !document.hidden){

        }
        this.previousDocumentHidden = document.hidden;
        //console.log("visibility change ",document.hidden,this);
    }

    createPageLayout (){
        var row2 = new Panel();
        this.addControl(row2);
        this.contentContainer = row2;
    }

    createComponents (){
        this.getPageTemplate().showTitle();
    }


    onWindowResize (){
        super.onWindowResize();
        if (this.tabview) {
            this.tabview.onWindowResize();
        }
        if (this.coordinatoranimator) {
            this.coordinatoranimator.onWindowResize();
        }

        if (this.newsListrender) {
            this.newsListrender.onWindowResize();
        }
    }

    updateDisplay (){

        this.runApp();
    }

    runApp (){

        this.createTitle();

        this.showLoader();

        AppInstance.setup(this.getProxy(function(result){

            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

            if (!result){
                this.hideLoader();
                console.error("error app setup");
                return this.showError("",this.getProxy(function(){
                    this.runApp();
                }))
            }

            var locale = AppInstance.getConfig().userLocale;

            if (AppInstance.getConfig().userLocaleFull && AppInstance.getConfig().availableNewsLocales.indexOf(AppInstance.getConfig().userLocaleFull) != -1){
                locale = AppInstance.getConfig().userLocaleFull;
            }
            //locale = "es";

            DataProvider.getNews(locale,this.getProxy(function(response){

                this.hideLoader();

                if (this.unloaded){ return console.warn("page is unloaded abort further actions"); }

                if (!response.isOk()){

                    if (response.getStatus() == 404) {
                        return this.showPageDataDoesNotExist("", this.getProxy(function () {
                            this.runApp();
                        }))
                    }
                    else{
                        return this.showError("", this.getProxy(function () {
                            this.runApp();
                        }))
                    }
                }

                var data = response.getData();

                this.createTabs(data);

                this.getPageTemplate().createMenuNews(response.getData());

                this.tabview.bind("tabChanged",this.getProxy(function(){
                    this.storeState();
                    this.coordinatoranimator.tabChange();
                }));

                var storeHandler = null;
                this.newsListrender.bind("scroll",this.getProxy(function(e,scroll) {
                    clearTimeout(storeHandler);
                    storeHandler = setTimeout(this.getProxy(function () {
                        this.storeState();
                    }),100)
                }));

                var previousState = StateManager.getCurrentPageState(this.pageName);
                if (previousState) {
                    this.tabview.currentActiveTab = previousState.tabIndex;
                    this.newsListrender.scrollTo(previousState.newsScrollPosition || 0);
                    console.log("restored scroll ",previousState.newsScrollPosition)
                    this.coordinatoranimator.isCollapsed = previousState.headerCollapsed;
                }

                this.contentContainer.invalidateDisplay();
                this.coordinatoranimator.prepare();

                this.loadNews(data);
            }),true);
        }))
    }

    createTitle (){

        var image = new Image();
        image.setStyle("width:28px;")
        //image.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/images/flags/"+country.replaceAll(" ","-").toLowerCase()+".png");
        var title = new Panel();
        title.addControl(Localizer.getString("news"));

        //this.getPageTemplate().topTitleContainer.addControl(image);
        //this.getPageTemplate().topTitleContainer.addControl(title);
        this.getPageTemplate().topTitleContainer.invalidateDisplay();
        this.getPageTemplate().topTitleContainer.setVisible(false);
    }

    createTabs (data){
        var tabview = new TabView();
        this.contentContainer.addControl(tabview);

        var tabs = [];

        var tab1;
        tab1 = new TabViewTab();
        tab1.setStyle("width:100%;height:100%;position:relative;overflow-y: scroll;transform: translate(0,0);");
        tab1.title = Localizer.getString("news");
        tabs.push(tab1);

        this.createNewsControls(tab1,0);
        this.coordinatoranimator.addScrollerContainer(tab1);

        this.coordinatoranimator.addCollapser(this.getPageTemplate().topBar);
        this.coordinatoranimator.addTabView(tabview);

        tabview.setTabs(tabs);

        tabview.bind("onReady",this.getProxy(function(){
           this.coordinatoranimator.run();
        }));

        this.tabview =tabview;

        tab1.showLoader();

        this.bind("stickyBannerLoadFail", () => {
            console.error("sticky banner load fail -> set height to 100%");
            if (tab1) tab1.setCss({'height': '100%'});
        });
        //triggered from page template
        this.bind("stickyBannerLoadSuccess", () => {
            console.log("sticky banner load success -> set height to 100% - 50px");
            if (tab1) tab1.setCss({'height': 'calc(100% - 50px)'});
        });
        this.getPageTemplate().loadStickyBannerAd();
    }

    createNewsControls (tab,index){
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new SimpleTileGridRenderer();

        listRender.containerTarget = tab;

        listRender.setItemRender(function(data){

            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new NewsListRender(),
                };
            }
        })

        listRender.errorTemplate = function(cb){
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function(){
            return new ErrorMessage();
        }

        listRender.bind('createdList',this.getProxy(function(){
            this.coordinatoranimator.recalculateTab(index);
        }))

        var previousState = StateManager.getCurrentPageState(this.pageName);
        if (previousState) {
            console.log("restore scroll #2 : ",previousState.newsScrollPosition)
            listRender.scrollTo(previousState.newsScrollPosition || 0);
        }

        container.addControl(listRender);
        this.newsListrender = listRender;

        tab.addControl(container);
    }

    reloadNews (locale){
        this.tabview.tabs[0].showLoader();
        DataProvider.getNews(locale,this.getProxy(function(response) {
            this.hideLoader();
            console.log(response.getData());

            var data = response.getData();
            for (var i = 0; i < data.items.length; i++) {
                data.items[i].type = 1;
            }
            this.tabview.tabs[0].hideLoader();
            this.newsListrender.setDataSource(data.items);
        }));
    }

    loadNews (data,ignoreCache){

        if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

        for (var i = 0; i < data.items.length; i++) {
            data.items[i].type = 1;
        }

        this.tabview.tabs[0].hideLoader();
        this.newsListrender.setDataSource(data.items);

        Router.disallowBackButtonQuitApp();
    }
};