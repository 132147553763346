import {
    DataGridTileItemRender
} from "../bananaframework/src/controls/listcontrols/datagrids/listrenders/DataGridTileItemRender.js";

export class FocusableItemRender extends DataGridTileItemRender {

    init () {
        super.init();
    }

    focus () {
        if (!this.getFocusable()){
            console.warn("FocusableItemRender.focus: focusable is not set");
            return;
        }
        //this.getFocusable().setCss({"background-color":"red"});
        this.getFocusable().setAttribute("tabindex", "-1");
        $("#"+this.getFocusable().getClientId()).focus();
        console.log("focus",this.data);
    }

    defocus () {
        // if (!this.getFocusable()){
        //     console.warn("FocusableItemRender.defocus: focusable is not set");
        //     return;
        // }
        // //this.getFocusable().setAttribute("tabindex", "-1");
        // //this.getFocusable().setCss({"background-color":"green"});
        // console.log("defocus" ,this.data);
    }

    focusNext(isTabKey,isLeftRight,isUpDown){
        if (!this.getNextFocusable()) return false;
        this.getNextFocusable().setAttribute("tabindex", "-1");
        $("#"+this.getNextFocusable().getClientId()).focus();
        return true;
    }

    focusPrevious(isTabKey,isLeftRight,isUpDown){
        if (!this.getPreviousFocusable()) return false;
        this.getPreviousFocusable().setAttribute("tabindex", "-1");
        $("#"+this.getPreviousFocusable().getClientId()).focus();
        return true;
    }

    getNextFocusable() {
        return false;
    }
    getPreviousFocusable() {
        return false;
    }
    getFocusable () {
        return null;
    }
}