localStorage.states = {};
export const StateManager = {

    stateKey : "statemanager12632",

    savePageState : function(pageName,data){
        var states = localStorage.getItem(this.stateKey+pageName);
        if (!states){
            states = {};
        }
        else{
            states = JSON.parse(states);
        }
        states[location.href] = data;
        localStorage.setItem(this.stateKey+pageName,JSON.stringify(states));
    },

    purgeNonImportant : function(){
        console.log("purge non important localstorage items");
        return;
        for (var i = 0; i < localStorage.length; i++){

            var key = localStorage.key(i);
            if (!key) return;

            if (key.search("Match_") != -1
            || key.search("Team_") != -1
            || key.search("League") != -1
            || key.search("Player") != -1
            ){
                console.log("remove ", key);
                localStorage.removeItem(key);
            }
        }
    },

    getCurrentPageState : function(pageName){
        var states = localStorage.getItem(this.stateKey+pageName);
        if (!states) return null;
        var parsed = JSON.parse(states);
        return parsed[location.href];
    },

    flushPageState : function(pageName){
        localStorage.removeItem(this.stateKey+pageName);
    },

    getNavigationState : function(){
        return history.state;
    },

    storeNavigationState : function(data){
        history.replaceState(data,"match",location.href)
    },
}

///window.StateManager = StateManager;