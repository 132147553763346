var leagueLogoRemaps = {
    "InternationalCupsOlympics" : "olympicfootball2024",
    "InternationalCupsOlympicsWomen" : "olympicfootball2024",
}
export const Remapper = {



    remapCountryLogo : (key,original) => {
        if (leagueLogoRemaps[key]){
            return leagueLogoRemaps[key];
        }
        return original;
    }


}