import {DataControl} from "./DataControl.js";

export class Image extends DataControl {
    init() {
        super.init();
    }
};

Image.prototype.setImage = function (image) {
    this.setAttribute('src', image);
    return this;
};

Image.prototype.setTitle = function (title) {
    this.setAttribute('title', title);
    return this;
};

Image.prototype.getTagName = function () {
    return 'img';
};

Image.prototype.setDomData = function (data) {
    jQuery('#' + this.getClientId()).attr({'src': data});
};