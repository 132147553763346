export const ManiaPreferences = {

    defaultListType : '1',

    getShowOnlyFavoritesAgenda : function(){
        var set = localStorage.getItem("showOnlyFavoritesAgenda");
        if (set == 1) return true;
        return false;
    },

    setShowOnlyFavoritesAgenda : function(value){
        localStorage.setItem("showOnlyFavoritesAgenda",value ? 1 : 0);
        jQuery(this).trigger("showOnlyFavoritesAgendaChanged",value ? 1 : 0);
    },

    getUserNewsLocale : function(){
        localStorage.getItem("userpref_newslocale");
    },

    setUserNewsLocale : function(value){
        localStorage.setItem("userpref_newslocale",value);
        jQuery(this).trigger("userpref_newslocaleChanged",value);
    },

    setMatchListType : function(value){
        localStorage.setItem("userpref_matchlisttype",value);
        jQuery(this).trigger("userpref_matchlistTypeChanged",value);
    },

    getMatchListType : function(){
        return localStorage.getItem("userpref_matchlisttype") || AppInstance.getConfig().graphics.defaultMatchListType
    },

    bind : function(name,func,data){
        jQuery(this).bind(name, data, func);
    },

    unbind : function(name,func){
        jQuery(this).unbind(name, func);
    },
};