import {Panel} from "../bananaframework/src/controls/Panel.js";
import {Image} from "../bananaframework/src/controls/Image.js";
import {Label} from "../bananaframework/src/controls/Label.js";
import {Control} from "../bananaframework/src/controls/Control.js";
import {DataGrid} from "../bananaframework/src/controls/listcontrols/datagrids/Datagrid.js";
import {UrlManager} from "../bananaframework/src/util/UrlManager.js";

import {BasePage} from "./basepage.js";

import {Router} from "../util/router.js";
import {Localizer} from "../util/localizer.js";
import {Processors} from "../util/processors.js";

import {ErrorMessage,NoDataMessage2,NoDataMessage} from "../components/errormessage.js";

import {AnimatorPlayer} from "./animators/animatorplayer.js";
import {CoachHeader} from "../components/coach/coachheader.js";
import {DataProvider} from "../data/dataprovider.js";
import {StateManager} from "../data/stateManager.js";

import {TabView,TabViewTab} from "../components/tabview.js";
import {SimpleTileGridRenderer} from "../components/simpletilegridrenderer.js";

import {
    PlayerPersonaListRender,
    PlayerInjuryLegendaItemRender,
    PlayerInjuryItemRender,
    PlayerTransfersItemRender, PlayerClubHistoryItemRender
}
    from "../components/player/listrendersplayer.js";

import {
    TrophiesListrender,
    TrophyHeaderItemRender}
    from "../components/team/listrendersteam.js";

export class Coach extends BasePage{

    init () {

        this.pageName = "Coach";
        this.version = 1;

        super.init();

        this.setStyle("height:100%");

        //this is a bit hacky.
        //the id change event is fired even when we set the id of this page before
        //this logic. The reason is because the onhashchange event is async
        //and altough  we change the url before this logic, the onhashchange event fires
        //afte this logic
        this.bind("renderComplete",this.getProxy(function(){

            //alert("listen")
            //we do this to register back button scenario where page A is match and page B is also match
            setTimeout(function() {
                UrlManager.listenModule('cid', function () {
                    var id = UrlManager.getModule('cid');
                    if (id == undefined || UrlManager.getModule('section') != "Player") {
                        return console.warn("ignore opening league , probably navigating to other screen");
                    }
                    AppInstance.loadPage("Coach", {"cid": id}, false);
                });
            },100);

        }))

        this.pageName = "Coach_"+UrlManager.getModule("cid");

        this.addCssClass("PlayerPage");

        this.isNavigatedFromOutside = Router.navigationIsFromOutside();
        Router.updateCurrentReferalData();

        if (this.isNavigatedFromOutside){
            StateManager.flushPageState(this.pageName);
        }

        console.log("OPENING PAGE "+this.pageName+" VERSION "+this.version+" is from outside "+this.isNavigatedFromOutside)

        this.coordinatoranimator = new AnimatorPlayer();
        this.coordinatoranimator.reducerRatio = 1;
        this.coordinatoranimator.transitionDuration = 2;

        Router.disallowBackButtonQuitApp();
        this.createPageLayout();

        this.wrapper1 = this.onWorkerMessageReceived.bind(this);

        if (AppInstance.workersInitialized) {
            AppInstance.workerHelper1.addEventListener("message", this.wrapper1);
        }
    }

    onWorkerMessageReceived (e){
        if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

        var tabIndex = 0;
        var timeout = 0;
        if (this.tabview.currentActiveTab != this.playerListrender.tabIndex){
            timeout = 400;
        }

        setTimeout(this.getProxy(function(){
            console.log("set dataaaaaaaa",e.data[1]);
            this.playerListrender.containerTarget.hideLoader();

            var listData = [];


            item = {"data": e.data[1], "type": 1};
            listData.push(item);

            if (e.data[1].careerclubs) {
                var item = {"data": e.data[1], "type": 2};
                listData.push(item);
            }

            this.playerListrender.setDataSource(listData);
        }),timeout);

        if (this.sidelinedListrender) {
            tabIndex++;
            timeout = 0;

            if (this.tabview.currentActiveTab != this.sidelinedListrender.tabIndex){
                timeout = 500;
            }

            setTimeout(this.getProxy(function(){

                var listData = [];
                var item = {"data":e.data[1],"type":2};
                listData.push(item);
                item = {"data":e.data[1],"type":1};
                listData.push(item);

                this.sidelinedListrender.containerTarget.hideLoader();
                this.sidelinedListrender.setDataSource(listData);
            }),timeout);
        }
        if (this.transfersListrender) {
            tabIndex++;
            timeout = 0;
            if (this.tabview.currentActiveTab != this.transfersListrender.tabIndex){
                timeout = 700;
            }
            setTimeout(this.getProxy(function(){
                this.transfersListrender.containerTarget.hideLoader();

                var listData = [];
                var item = {"data":e.data[1],"type":1};
                listData.push(item);

                this.transfersListrender.setDataSource(listData);
            }),timeout);
        }
        if (this.trophiesListrender) {
            tabIndex++;
            timeout = 0;
            if (this.tabview.currentActiveTab != this.trophiesListrender.tabIndex){
                timeout = 700;
            }
            setTimeout(this.getProxy(function(){
                this.trophiesListrender.containerTarget.hideLoader();
                this.trophiesListrender.setDataSource(e.data[1].trophies);
            }),timeout);
        }
        this.autoInterval();
    }

    storeState (){
        var d = {
            "tabIndex":this.tabview.currentActiveTab,
            "playerListrenderScrollPosition" : this.playerListrender.getScroll(),
            "headerCollapsed" : this.coordinatoranimator.isCollapsed || this.coordinatoranimator.isVirtuallyCollapsed
        }

        if (this.sidelinedListrender){
            d.sideLinedListrenderScrollPosition = this.sidelinedListrender.getScroll();
        }
        if (this.transfersListrender){
            d.transfersListrenderScrollPosition = this.transfersListrender.getScroll();
        }
        if (this.trophiesListrender){
            d.trophiesListrenderScrollPosition = this.trophiesListrender.getScroll();
        }
        console.log("store state",d);
        StateManager.savePageState(this.pageName,d);
    }

    unload (){
        this.unloaded = true;
        $.xhrPool.abortAll();
        this.coordinatoranimator.unload();
        clearTimeout(this.playerLoadHandler);
        UrlManager.unlistenModule('cid');

        if (AppInstance.workersInitialized) {
            AppInstance.workerHelper1.removeEventListener("message", this.wrapper1);
        }
    }

    onVisibilityChange (){
        //page is becoming visible after hide
        if (this.previousDocumentHidden = true && !document.hidden){
            this.autoInterval(0);
        }
        this.previousDocumentHidden = document.hidden;
    }

    createPageLayout (){
        this.coachHeader = new CoachHeader();
        this.coachHeader.setVisible(false);
        this.coordinatoranimator.addCollapser(this.coachHeader);

        var row2 = new Panel();
        this.addControl(this.coachHeader);
        this.addControl(row2);
        this.contentContainer = row2;
    }

    createComponents (){
        this.getPageTemplate().hideTitle();
    }

    onWindowResize (){

        super.onWindowResize();

        if (this.tabview) {
            this.tabview.onWindowResize();
        }
        if (this.coordinatoranimator) {
            this.coordinatoranimator.onWindowResize();
        }

        if (this.playerListrender) {
            this.playerListrender.onWindowResize();
        }

        if (this.sidelinedListrender){
            this.sidelinedListrender.onWindowResize();
        }

        if (this.trophiesListrender){
            this.trophiesListrender.onWindowResize();
        }
    }

    updateDisplay (){
        var previousState = StateManager.getCurrentPageState(this.pageName);

        if (previousState) {
            this.coordinatoranimator.prerun(previousState.headerCollapsed);
        }
        else{
            this.coordinatoranimator.prerun(false);
        }

        this.runApp();
    }

    runApp (){
        if (!AppInstance.setupAlreadyDone) {
            this.showLoader();
        }
        AppInstance.setup(this.getProxy(function(result){
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

            if (!result){
                this.hideLoader();
                console.error("error app setup");
                return this.showError("",this.getProxy(function(){
                    this.runApp();
                }))
            }
            this.showLoader();
            DataProvider.getCoach(UrlManager.getModule("cid"),this.getProxy(function(response){


                this.hideLoader();
                if (this.unloaded){ return console.warn("page is unloaded abort further actions"); }

                if (!response.isOk()){
                    if (response.getStatus() == 404) {
                        return this.showPageDataDoesNotExist("", this.getProxy(function () {
                            this.runApp();
                        }))
                    }
                    else{
                        return this.showError("", this.getProxy(function () {
                            this.runApp();
                        }))
                    }
                }

                var data = response.getData();

                if (!data.name){
                    return this.showPageDataDoesNotExist("", this.getProxy(function () {
                        this.runApp();
                    }))
                }

                this.createTabs(data);

                this.coachHeader.setPresetData(data,true);
                this.coachHeader.setVisible(true);
                ///this.coordinatoranimator.prerun()

               // return;
                this.getPageTemplate().createMenuPlayer(response.getData());

                this.tabview.bind("tabChanged",this.getProxy(function(){
                    console.log("tab changed!!!!")
                    this.storeState();
                    this.coordinatoranimator.tabChange();
                }));

                var storeHandler = null;
                var storeHandler2 = null;
                var storeHandler3 = null;
                var storeHandler4 = null;
                this.playerListrender.bind("scroll",this.getProxy(function(e,scroll) {
                    clearTimeout(storeHandler);
                    storeHandler = setTimeout(this.getProxy(function () {
                        this.storeState();
                    }),300)
                }));
                //
                if (this.sidelinedListrender) {
                    this.sidelinedListrender.bind("scroll", this.getProxy(function (e, scroll) {
                        clearTimeout(storeHandler2);
                        storeHandler2 = setTimeout(this.getProxy(function () {
                            this.storeState();
                        }), 300)
                    }));
                }
                if (this.transfersListrender) {
                    this.transfersListrender.bind("scroll", this.getProxy(function (e, scroll) {
                        clearTimeout(storeHandler3);
                        storeHandler3 = setTimeout(this.getProxy(function () {
                            this.storeState();
                        }), 300)
                    }));
                }
                if (this.trophiesListrender) {
                    this.trophiesListrender.bind("scroll", this.getProxy(function (e, scroll) {
                        clearTimeout(storeHandler4);
                        storeHandler4 = setTimeout(this.getProxy(function () {
                            this.storeState();
                        }), 300)
                    }));
                }


                var previousState = StateManager.getCurrentPageState(this.pageName);
                if (previousState) {
                    this.tabview.currentActiveTab = previousState.tabIndex;
                    this.coordinatoranimator.currentActiveTab = previousState.tabIndex;
                    this.playerListrender.scrollTo(previousState.playerListrenderScrollPosition || 0);
                    console.log("restore scroll transfers ",previousState.transfersListrenderScrollPosition)

                    if (this.sidelinedListrender) {
                        this.sidelinedListrender.scrollTo(previousState.sideLinedListrenderScrollPosition || 0);
                    }
                    if (this.transfersListrender) {
                        this.transfersListrender.scrollTo(previousState.transfersListrenderScrollPosition || 0);
                    }
                    if (this.trophiesListrender) {
                        this.trophiesListrender.scrollTo(previousState.trophiesListrenderScrollPosition || 0);
                    }
                    this.coordinatoranimator.isCollapsed = previousState.headerCollapsed;
                }

                console.log("----------->",previousState)

                this.contentContainer.invalidateDisplay();
                this.coordinatoranimator.ensureStartPositions(); //we call this to ensure tabview is collapsed without flicker
                //this.coordinatoranimator.run();
                //this.coordinatoranimator.prepare();

                var tabIndex = 0;
                this.processCoachData(data,tabIndex);

            }));
        }))
    }

    createTitle (data){
        if (!data) return;
        var title = new Panel();

        title.addControl(data.playername);
        this.getPageTemplate().topTitleContainer.addControl(title);
        this.getPageTemplate().topTitleContainer.invalidateDisplay();
    }

    createTabs (data){
        var tabview = new TabView();
        tabview.parallaxBg = this.coachHeader.backgroundImage;
        var p = new Panel();
        p.setStyle("background-color:red;height:20px; width:100%;float:left;");
        this.contentContainer.addControl(tabview);

        var tabs = [];

        var tab1;
        var tab2;
        var tab3;
        var tab4;

        var tabIndexUsed = 0;

        if (FootballManiaConfig.isRtl){

            if (data.trophies && data.trophies.length) {
                tab4 = new TabViewTab();
                //tab3.setStyle("width:100%;height: calc(100% + 133px);margin-top:-133px;position:relative;overflow-y: scroll;transform: translate(0,0);");
                tab4.setStyle("width:100%;height:100%;position:relative;");
                tab4.title = Localizer.getString("trophies");
                tabs.push(tab4);
                this.createTrophiesControls(tab4, tabIndexUsed);
                tab4.showLoader();
                tabIndexUsed += 1;
            }
            if (data.transfers && data.transfers.length) {
                tab3 = new TabViewTab();
                //tab3.setStyle("width:100%;height: calc(100% + 133px);margin-top:-133px;position:relative;overflow-y: scroll;transform: translate(0,0);");
                tab3.setStyle("width:100%;height:100%;position:relative;");
                tab3.title = Localizer.getString("transfers");
                tabs.push(tab3);
                this.createTransfersControls(tab3, tabIndexUsed);
                tab3.showLoader();
                tabIndexUsed += 1;
            }
            if (data.sidelined && data.sidelined.length) {
                tab2 = new TabViewTab();
                //tab3.setStyle("width:100%;height: calc(100% + 133px);margin-top:-133px;position:relative;overflow-y: scroll;transform: translate(0,0);");
                tab2.setStyle("width:100%;height:100%;position:relative;");
                tab2.title = Localizer.getString("injuries");
                tabs.push(tab2);

                this.createsidelinedControls(tab2, tabIndexUsed);
                tab2.showLoader();
                tabIndexUsed += 1;
            }

            tab1 = new TabViewTab();
            tab1.setStyle("width:100%;height:100%;position:relative;");
            tab1.title = Localizer.getString("info");
            tabs.push(tab1);
            this.createplayerControls(tab1, tabIndexUsed);

            tabview.currentActiveTab = tabIndexUsed;
        }
        else {
            tab1 = new TabViewTab();
            tab1.setStyle("width:100%;height:100%;position:relative;");
            tab1.title = Localizer.getString("info");
            tabs.push(tab1);
            this.createplayerControls(tab1, tabIndexUsed);
            tabIndexUsed += 1;

            if (data.sidelined && data.sidelined.length) {
                tab2 = new TabViewTab();
                //tab3.setStyle("width:100%;height: calc(100% + 133px);margin-top:-133px;position:relative;overflow-y: scroll;transform: translate(0,0);");
                tab2.setStyle("width:100%;height:100%;position:relative;");
                tab2.title = Localizer.getString("injuries");
                tabs.push(tab2);
                this.createsidelinedControls(tab2, tabIndexUsed);
                tab2.showLoader();
                tabIndexUsed += 1;
            }
            if (data.transfers && data.transfers.length) {
                tab3 = new TabViewTab();
                //tab3.setStyle("width:100%;height: calc(100% + 133px);margin-top:-133px;position:relative;overflow-y: scroll;transform: translate(0,0);");
                tab3.setStyle("width:100%;height:100%;position:relative;");
                tab3.title = Localizer.getString("transfers");
                tabs.push(tab3);
                this.createTransfersControls(tab3, tabIndexUsed);
                tab3.showLoader();
                tabIndexUsed += 1;
            }
            if (data.trophies && data.trophies.length) {
                tab4 = new TabViewTab();
                //tab3.setStyle("width:100%;height: calc(100% + 133px);margin-top:-133px;position:relative;overflow-y: scroll;transform: translate(0,0);");
                tab4.setStyle("width:100%;height:100%;position:relative;");
                tab4.title = Localizer.getString("trophies");
                tabs.push(tab4);
                this.createTrophiesControls(tab4, tabIndexUsed);
                tab4.showLoader();
            }
        }


        this.coordinatoranimator.addTopBar(this.getPageTemplate().topBar);
        //this.coordinatoranimator.addCollapser(this.getPageTemplate().topBar);
        this.coordinatoranimator.addTabView(tabview);

        tabview.setTabs(tabs);

        tabview.bind("onReady",this.getProxy(function(){
            this.coordinatoranimator.run();
        }));

        this.tabview =tabview;

        tab1.showLoader();

        this.bind("stickyBannerLoadFail", () => {
            console.error("sticky banner load fail -> set height to 100%");
            if (tab1) tab1.setCss({'height': '100%'});
            if (tab2) tab2.setCss({'height': '100%'});
            if (tab3) tab3.setCss({'height': '100%'});
            if (tab4) tab4.setCss({'height': '100%'});
        });
        //triggered from page template
        this.bind("stickyBannerLoadSuccess", () => {
            console.log("sticky banner load success -> set height to 100% - 50px");
            if (tab1) tab1.setCss({'height': 'calc(100% - 50px)'});
            if (tab2) tab2.setCss({'height': 'calc(100% - 50px)'});
            if (tab3) tab3.setCss({'height': 'calc(100% - 50px)'});
            if (tab4) tab4.setCss({'height': 'calc(100% - 50px)'});
        });
        this.getPageTemplate().loadStickyBannerAd();
    }

    createplayerControls (tab,index){
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new SimpleTileGridRenderer();
        listRender.activateScrolling = true;
        listRender.containerTarget = tab;
        listRender.tabIndex = index;

        listRender.setItemRender(function(data){

            if (data.type == 2) {
                return {
                    typeId: 2,
                    render: new PlayerClubHistoryItemRender(),
                };
            }
            else if (data.type == 1){
                return {
                    typeId: 1,
                    render: new PlayerPersonaListRender(),
                };
            }
        })

        listRender.emptyTemplate = function(cb){
            return new NoDataMessage({
                cb : function(){
                    history.back();
                },
                message : Localizer.getString("no_data")
            });
        }

        listRender.errorTemplate = function(cb){
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function(){
            return new ErrorMessage();
        }

        listRender.bind('createdList',this.getProxy(function(){
            tab.scrollerContainerReference = listRender.mainContainer; //otherwise wrong scrollpanel is used and on IOS we cannot scroll because transform is breaikig scroll
            this.coordinatoranimator.addScrollerContainer(listRender.mainContainer,index);
            this.coordinatoranimator.recalculateTab(index);
            //this.coordinatoranimator.ensureStartPositions();
        }))

        var previousState = StateManager.getCurrentPageState(this.pageName
        );
        if (previousState) {
            listRender.scrollTo(previousState.playerStatsScrollPosition || 0);
        }

        container.addControl(listRender);

        this.playerListrender = listRender;

        tab.addControl(container);
    }

    createsidelinedControls (tab,index){
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new SimpleTileGridRenderer();
        listRender.activateScrolling = true;
        listRender.containerTarget = tab;
        listRender.tabIndex = index;

        listRender.setItemRender(function(data){

            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new PlayerInjuryItemRender(),
                };
            }
            else if (data.type == 2){
                return {
                    typeId: 2,
                    render: new PlayerInjuryLegendaItemRender(),
                };
            }
        })

        listRender.emptyTemplate = function(cb){
            return new NoDataMessage({
                cb (){
                    history.back();
                },
                message : Localizer.getString("no_data")
            });
        }

        listRender.errorTemplate = function(cb){
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function(){
            return new ErrorMessage();
        }

        listRender.bind('createdList',this.getProxy(function(){
            tab.scrollerContainerReference = listRender.mainContainer; //otherwise wrong scrollpanel is used and on IOS we cannot scroll because transform is breaikig scroll
            this.coordinatoranimator.addScrollerContainer(listRender.mainContainer,index);
            this.coordinatoranimator.recalculateTab(index);
            //this.coordinatoranimator.ensureStartPositions();
        }))

        var previousState = StateManager.getCurrentPageState(this.pageName
        );
        if (previousState) {
            listRender.scrollTo(previousState.sideLinedListrenderScrollPosition || 0);
        }

        container.addControl(listRender);

        this.sidelinedListrender = listRender;

        tab.addControl(container);
    }

    createTransfersControls (tab,index){
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new SimpleTileGridRenderer();
        listRender.containerTarget = tab;
        listRender.activateScrolling = true;
        listRender.tabIndex = index;

        listRender.setItemRender(function(data){

            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new PlayerTransfersItemRender (),
                };
            }
        })

        listRender.errorTemplate = function(cb){
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function(){
            return new ErrorMessage();
        }

        listRender.emptyTemplate = function(cb){
            return new NoDataMessage2({
                message : Localizer.getString("no_transfer_data")
            });
        }

        listRender.bind('createdList',this.getProxy(function(){
            tab.scrollerContainerReference = listRender.mainContainer; //make sure correct scrller container tabview otherwise wrong scrollpanel is used and on IOS we cannot scroll because transform is breaikig scroll
            this.coordinatoranimator.addScrollerContainer(listRender.mainContainer,index);
            this.coordinatoranimator.recalculateTab(index);
        }))

        var previousState = StateManager.getCurrentPageState(this.pageName);
        if (previousState) {
            console.log("restore scroll transfers #2 : ",previousState.transfersListrenderScrollPosition)
            listRender.scrollTo(previousState.transfersListrenderScrollPosition || 0);
        }

        container.addControl(listRender);
        this.transfersListrender = listRender;

        tab.addControl(container);
    }

    createTrophiesControls (tab,index){
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new SimpleTileGridRenderer();
        listRender.activateScrolling = true;
        listRender.containerTarget = tab;
        listRender.tabIndex = index;

        listRender.setItemRender(function(data){

            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new TrophiesListrender(),
                };
            }
            else if (data.type == 2){
                return {
                    typeId: 2,
                    render: new TrophyHeaderItemRender(),
                };
            }
        })

        listRender.emptyTemplate = function(cb){
            return new NoDataMessage({
                cb : function(){
                    history.back();
                },
                message : Localizer.getString("no_data")
            });
        }

        listRender.errorTemplate = function(cb){
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function(){
            return new ErrorMessage();
        }

        listRender.bind('createdList',this.getProxy(function(){
            tab.scrollerContainerReference = listRender.mainContainer; //otherwise wrong scrollpanel is used and on IOS we cannot scroll because transform is breaikig scroll
            this.coordinatoranimator.addScrollerContainer(listRender.mainContainer,index);
            this.coordinatoranimator.recalculateTab(index);
            //this.coordinatoranimator.ensureStartPositions();
        }))

        var previousState = StateManager.getCurrentPageState(this.pageName
        );
        if (previousState) {
            listRender.scrollTo(previousState.trophiesListrenderScrollPosition || 0);
        }

        container.addControl(listRender);

        this.trophiesListrender = listRender;

        tab.addControl(container);
    }

    processCoachData (data,ignoreCache){
        console.log("load coach");
        if (!data){
            return this.autoInterval();
        }

        if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

        if (!FootballManiaConfig.useWebWorkers) {
            var list = Processors.preparePlayerData(data,
                Localizer.getLoadedLocale(),
                Localizer.DefaultTranslations,
                AppInstance.getLocale(),);
            var data = {data:[0, list]};
            this.onWorkerMessageReceived(data);
        }
        else {
            AppInstance.workerHelper1.postMessage({
                methodType: 9,
                data: data,
                translations: Localizer.getLoadedLocale(),
                defaultTranslations: Localizer.DefaultTranslations,
                locale: AppInstance.getLocale(),
            });
        }

        Router.disallowBackButtonQuitApp();
    }

    autoInterval (overrideInterval){
        clearTimeout(this.playerLoadHandler);
        this.playerLoadHandler = setTimeout(this.getProxy(function(){

            if (document.hidden){
                console.warn("no auto refresh because page is hidden");
                return;
            }

            DataProvider.getCoach(UrlManager.getModule("cid"),this.getProxy(function(response) {

                if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

                if (!response.isOk()){
                    this.autoInterval();
                    return;
                }
                //todo error screen

                if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

                if (!FootballManiaConfig.useWebWorkers) {
                    var list = Processors.preparePlayerData(response.getData(),
                        Localizer.getLoadedLocale(),
                        Localizer.DefaultTranslations,
                        AppInstance.getLocale(),);
                    var data = {data:[0, list]};
                    this.onWorkerMessageReceived(data);
                }
                else {
                    AppInstance.workerHelper1.postMessage({
                        methodType: 9,
                        data: response.getData(),
                        translations: Localizer.getLoadedLocale(),
                        defaultTranslations: Localizer.DefaultTranslations,
                        locale: AppInstance.getLocale(),
                    });
                }

            }));
        }),overrideInterval != null ? overrideInterval : AppInstance.getConfig().refreshIntervals.player)
    }
};