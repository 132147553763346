import {Panel} from "../../bananaframework/src/controls/Panel.js"
import {Label} from "../../bananaframework/src/controls/Label.js"
import {Image} from "../../bananaframework/src/controls/Image.js"
import {TableRow, TableHeaderCol, TableCol} from "../../bananaframework/src/controls/Table.js"
import {
    DataGridTileItemRender
} from "../../bananaframework/src/controls/listcontrols/datagrids/listrenders/DataGridTileItemRender.js"

import {ImagePlaceHolder, teamPlaceholder, countryPlaceHolder} from "../imageplaceholder.js"
import {MatchUtil} from "../../util/matchutil.js";
import {Localizer} from "../../util/localizer.js";
import {Router} from "../../util/router.js";
import {DateTimecode} from "../../util/datetime.js";
import {CountryUtil} from "../../util/country.js";
import {RemoveReservedIfNeeded} from "../../util/utils.js";

export class TopTeamListRender extends DataGridTileItemRender {

    init(data) {
        super.init();
        this.data = data;
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");
        //
        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);
        //this.panel.setStyle("margin-top:3px;margin-bottom:-3px;");
        this.card.setStyle("padding-bottom:4px;");
        //
        this.content = new Panel();
        this.content.setStyle("height:40px;")
        this.content.addCssClass("flexVerticalAlign");

        var title = new Panel();
        //title.addControl(Localizer.getString("passport"));
        title.addCssClass("MatchStatsTitleText MatchH2HTitle")

        this.card.addControl(title);
        this.card.addControl(this.content);
        //
        this.addControl(this.panel);
    }

    setData(data) {
        this.data = data.data;
        this.content.clear();
        this.content.addControl("aaap "+data.index);
        this.content.invalidateDisplay();
    }

};
