import {Panel} from "../../bananaframework/src/controls/Panel.js";
import {Checkbox} from "../../bananaframework/src/controls/Checkbox.js";
import {InputControl} from "../../bananaframework/src/controls/InputControl.js";
import {Image} from "../../bananaframework/src/controls/Image.js";

import {Modal} from "../modal.js";
import {Localizer} from "../../util/localizer.js";
import {Favorites} from "../../util/favorites.js";
import {Router} from "../../util/router.js";
import {UrlManager} from "../../bananaframework/src/util/UrlManager.js";

export class LeagueSelectModal extends Modal {

    init(urlIdentifier,disableUrlHistory,data) {
        super.init(urlIdentifier,disableUrlHistory);
        this.data = data;
        this.selectedData = [];
        this.allCountries = [];
    }

    createComponents() {
        super.createComponents();

        var dim = this.getPage().getDimensions();

        var p = new Panel();
        p.setStyle("height:" + (dim.height - 50) + "px;justify-content: flex-start;");
        p.addCssClass("flexVerticalAlign");

        var title = new Panel();
        title.addCssClass("addFavoriteModalTitle");
        title.addControl(Localizer.getString("select_leaguecup"));
        p.addControl(title);

        var selectedContainer = new Panel();
        selectedContainer.addCssClass("selectedCountriesContainer");
        var title = new Panel();
        title.setStyle("font-size: 12px;font-weight: bold;margin-bottom: 10px;");
        this.selectedCount = title;

        selectedContainer.addControl(title);
        p.addControl(selectedContainer);

        var searchResults = new Panel();
        searchResults.addCssClass("addFavoriteModalSuggestList");
        searchResults.setStyle("height:100%;overflow-y:scroll;width:85%;");
        p.addControl(searchResults)

        this.addControl(p)
        this.setVisible(false);

        setTimeout(() => {
            this.setVisible(true);
            this.reValidateSize();
        }, 50);


        this.renderLeagues(searchResults, this.data);
    }

    renderLeagues(target, data) {
        target.clear();

        for (var i = 0; i < data.leagues.length; i++) {
            if (!data.leagues[i].name) continue;
            var resultP = new Panel();
            resultP.addCssClass("suggestionsItemTitle");

            var countryFlag = new Image();
            countryFlag.setStyle("width: 24px; height: 24px; margin-inline-end: 10px;");
            countryFlag.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.countrygs.replaceAll(" ","-").toLowerCase() + ".png");
            resultP.addControl(countryFlag);

            var countryPanel = new Panel();
            countryPanel.setStyle("margin-bottom:3px;width:100%;")

            var subType = "";
            if (data.leagues[i].subtype){
                subType = " - " + data.leagues[i].subtype;
            }

            countryPanel.addControl("<b>" + data.leagues[i].name + subType+"</b>");

            ((resultP, league) => {
                resultP.bind("click", () => {
                   Router.showLeagueScreen(league,{"currentActiveTab":this.currentActiveTab});
                });

            })(resultP, data.leagues[i]);

            resultP.addControl(countryPanel);
            target.addControl(resultP);
        }

        target.invalidateDisplay();
    }
    renderSearchResult(data, target) {
        this.renderTeamItem(data, target, Favorites.getFavoriteTeams()[data.id_gs]);
    }
};
