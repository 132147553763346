import {Control, EventTypes} from "./Control.js";

export class UiControl extends Control {

    init() {
        super.init();
        this.css = {};
        this.style = '';
        this.isRendered = false;
        this.visible = true; //default is visible
        this.enabled = true;
    }

    addControl(c, autoRender) {
        //when a control is added on a already initialized control, we recall the initialization
        //otherwise the control wont have a proper client id/databind and references to various objects
        //TODO is this a performance hit, cause sometimes it is not needed
        if (this.isInitialized) {
            if (autoRender) {
                //the render function also contains initializeControls, but we dont know if our item got sibblings, if so the client id should be amount of sibblings+1
                this.getPage().initializeControl(c, this, false, this.controls.length + 1);
                this.render(c);
            } else {
                this.getPage().initializeControl(c, this, false, this.controls.length + 1);
            }
        }

        return super.addControl(c);
    }

    addControlAt(c, index, autoRender) {
        if (!this.controls[index]) {
            return this.addControl(c, autoRender);
        }

        //when a control is added on a already initialized control, we recall the initialization
        //otherwise the control wont have a proper client id/databind and references to various objects
        //TODO is this a performance hit, cause sometimes it is not needed
        if (this.isInitialized) {
            if (autoRender) {
                //the render function also contains initializeControls, but we dont know if our item got sibblings, if so the client id should be amount of sibblings+1
                this.getPage().initializeControl(c, this, false, this.controls.length + 1);
                this.render(c, index);
            } else {
                this.getPage().initializeControl(c, this, false, this.controls.length + 1);
            }
        }

        return super.addControlAt(c, index);
    }

    unload() {
        this.cachedElement = undefined;
    }

    remove() {
        this.getPage().removeControl(this);
    }

    clear() {
        //console.log("clear ",this);
        if (!this.getPage()) {
            //console.warn("control is not rendered yet, cannot clear");
            return;
        }
        this.getPage().clearControl(this);
        this.controls = [];
    }

    getFirstUiControl() {
        if (!this.markAsNonRender) {
            return this;
        } else {
            if (this.parent) {
                return this.parent.getFirstUiControl();
            }
        }

        return null;
    }

};

/*
 * @return object jquery dom writer
 */
UiControl.prototype.getDomWriter = function () {
    if (!this.cachedElement) {
        //console.log("return dirty element ",this.getClientId())
        this.cachedElement = jQuery('#' + this.getClientId());
    }
    //console.log("return cached element")
    return this.cachedElement;
};

/**
 * @return string tag name of control
 * overwrite this function to use your own tags. ie <div> <a> <p> <input> <label>
 */
UiControl.prototype.getTagName = function () {
    return '';
};

/**
 * Computes widht,height and left right offsets
 *
 * @return {Object} of dimensions
 */
UiControl.prototype.getDimensions = function () {
    var params = {};

    params.height = this.getDomWriter().height();
    params.width = this.getDomWriter().width();
    params.offset = this.getDomWriter().offset();
    params.position = this.getDomWriter().position();

    return params;
};

/**
 * sets client id used to reference dom node. This method is auto called by the page
 * @param {String} cid
 */
UiControl.prototype.setClientId = function (cid) {
    this.clientId = cid;
};

/**
 * @return {String} reference to the dom node id
 */
UiControl.prototype.getClientId = function () {
    return this.clientId;
};

/**
 * Triggers an event which will notify all listeners
 * Optionally data can be send along.
 *
 * @param {String} name of the event to be triggered
 * @param {mixed} data
 */
UiControl.prototype.triggerEvent = function (name, data) {
    if (this.getDomEventTypes().indexOf(name) !== -1) {
        if (this.isRendered) {
            this.getDomWriter().trigger(name, data);
        }
    } else {
        jQuery(this).trigger(name, data);
    }
};

/**
 * Composes attributes for usuage in html tags.
 * @return {Object} of attributes
 */
UiControl.prototype.getAttributes = function () {
    var attributes = {};

    if (this.attributes) {
        attributes = this.attributes;
    }

    attributes['id'] = this.getClientId();
    attributes['style'] = this.getStyle();

    if (this.getCss()) {
        var css = "";
        for (var prop in this.getCss()) {
            css += prop + ':' + this.getCss()[prop] + ';';
        }
        attributes['style'] += css;
    }

    if (this.cssClass && this.cssClass.length > 0) {
        attributes['class'] = this.getCssClass().join(' ');
    }

    return attributes;
};

/**
 * sets attribute
 * depending on the type of key we set prop or attr
 *
 * see http://blog.jquery.com/ for more information
 * @param {String} key
 * @param {String} value
 * @return {this}
 */
UiControl.prototype.setAttribute = function (key, value) {
    if (this.isRendered) {
        this.getDomWriter().prop(key, value);
    }
    var attr = this.getAttributes();
    attr[key] = value;
    this.attributes = attr;
    return this;
};

/**
 * @String key of the attribute which should be removed
 */
UiControl.prototype.removeAttribute = function (key) {
    if (this.isRendered) {
        this.getDomWriter().removeProp(key);
    }

    if (this.attributes && this.attributes[key]) {
        delete this.attributes[key];
    }

    return this;
};

/**
 * @param {String} key of the attribute
 * @return {String} attribute by key
 */
UiControl.prototype.getAttribute = function (key) {
    if (!this.attributes) {
        return null;
    }
    return this.attributes[key];
};

/**
 * NOTE: use setCss() instead to directly apply it to dom
 * @param {String} style of this control. by css definitions
 * @return {this}
 */
UiControl.prototype.setStyle = function (style) {
    if (this.isRendered){
        console.warn("setting style on rendered control wont immediately apply. Use setCss() instead or invalidate control");
    }
    this.style = style;
    return this;
};

/**
 * @return {String}
 */
UiControl.prototype.getStyle = function () {
    return this.style;
};

/**
 * adds css style in object key value style.
 * @param {Object} css example {width:'100px',left:0};
 */
UiControl.prototype.addCss = function (css) {
    var nc = {};
    for (var prop in this.getCss()) {
        nc[prop] = this.getCss()[prop];
    }
    for (var prop in css) {
        nc[prop] = css[prop];
    }
    this.css = nc;
};

/**
 * adds css style and instantly apply it to dom if rendered
 *
 * @param {Object} css
 */
UiControl.prototype.setCss = function (css) {
    this.addCss(css);

    if (this.isRendered) {
        this.getDomWriter().css(this.css);
    }

    return this;
};

/**
 * @return {Object} of css properties in key value format
 */
UiControl.prototype.getCss = function () {
    return this.css;
};

/**
 * @return {String} value of css property
 */
UiControl.prototype.getStyleProperty = function (prop) {
    return this.css[prop] || this.getDomWriter().css(prop);
};

/**
 * @return {Array} of currently added css classes
 */
UiControl.prototype.getCssClass = function () {
    if (!this.cssClass) {
        this.cssClass = [];
    }
    return this.cssClass;
};

/**
 * Adds css class. this will be instantly applied to dom if rendered
 *
 * @param {String} css name of css class
 */
UiControl.prototype.addCssClass = function (css) {
    if (!this.cssClass) {
        this.cssClass = [];
    }

    if (!this.hasCssClass(css)) {
        this.cssClass.push(css);

        if (this.isRendered) {
            this.getDomWriter().addClass(css);
        }
    }

    return this;
};

/**
 * removes css class. this will be instantly applied to dom
 *
 * @param {String} css name of css class
 */
UiControl.prototype.removeCssClass = function (css) {
    if (!this.cssClass) {
        return this;
    }

    var indexOf = this.cssClass.indexOf(css);

    if (indexOf != -1) {
        this.cssClass.splice(indexOf, 1);

        if (this.isRendered) {
            this.getDomWriter().removeClass(css);
        }
    }

    return this;
};

/**
 * Switch the old Css class with a new one
 *
 * @param {String} oldClass Old CSS class to replace
 * @param {String} newClass New CSS class to add
 */
UiControl.prototype.switchCssClass = function (oldClass, newClass) {
    this.removeCssClass(oldClass);
    this.addCssClass(newClass);
    return this;
};

/**
 * @return {boolean}
 */
UiControl.prototype.hasCssClass = function (search) {
    if (this.isRendered) {
        return this.getDomWriter().hasClass(search);
    }

    if (jQuery.inArray(search, this.getCssClass()) >= 0) {
        return true;
    }
    return false;
};

/**
 * return string representation of html attributes. used by renderer
 *
 * @return {String}
 */
UiControl.prototype.getHtmlAttributes = function () {
    var attributes = this.getAttributes();
    var data = "";
    var attr;
    for (attr in attributes) {
        if (attributes[attr]) {
            if (attributes[attr] != 'undefined') {
                data += attr + '="' + attributes[attr] + '" ';
            }
        }
    }

    return data;
};

/**
 * makes control visible or invisible.
 *
 * @param {boolean} v true when visible
 * @param {String} speed animation speed of hiding/ showing
 * @param {String} type of animation
 * @param {Function} callback when finished
 */
UiControl.prototype.setVisible = function (v, speed, type, callback) {
    this.triggerEvent('onSetVisible', v); //trigger this first to prevent flickering
    this.visible = v;
    var ignoreDirectAction = false;
    if (v) {
        if (this.isRendered) {
            if (type === 'fadeIn') {
                this.getDomWriter().fadeIn(speed, () => {
                    this.setCss({'display': ''});
                    if (callback) {
                        callback();
                    }
                });
                ignoreDirectAction = true;
            } else {
                this.getDomWriter().show(speed, () => {
                    this.setCss({'display': ''});
                    if (callback) {
                        callback();
                    }
                });
            }
        }

        if (!ignoreDirectAction) {
            this.setCss({'display': ''});
        }
    } else {
        if (this.isRendered) {
            if (type === 'fadeOut') {
                ignoreDirectAction = true;
                this.getDomWriter().fadeOut(speed, () => {
                    this.setCss({'display': 'none'});
                    if (callback) {
                        callback();
                    }
                });
            } else {
                this.getDomWriter().hide(speed, () => {
                    this.setCss({'display': 'none'});
                    if (callback) {
                        callback();
                    }
                });
            }
        }
        if (!ignoreDirectAction) {
            this.setCss({'display': 'none'});
        }

    }

    return this;
};

/*
 * @return bool true when visible
 */
UiControl.prototype.getVisible = function () {
    if (this.isRendered) {
        return this.getDomWriter().is(":visible");
    } else {
        return this.visible; //note this only works on controls which are directly made invisble not for their childs
    }
};

/**
 * register all binded events
 */
UiControl.prototype.registerEvents = function () {
    if (!this.binds && !this.binds.length) {
        return;
    }
    var i, len, name, func, data, type;
    for (i = 0, len = this.binds.length; i < len; i++) {
        name = this.binds[i].name;
        func = this.binds[i].func;
        data = this.binds[i].data;
        type = this.binds[i].type;

        if (type === EventTypes.DOM_EVENT) {
            if (data) {
                this.getDomWriter().bind(name, data, func);
            } else {
                this.getDomWriter().bind(name, func);
            }
        }
            //there is a difference between dom and data events. dom events can be registered only
            //when the dom elements are rendered. data events can be registered right when object are instantiated
            //if we bind an custom event during construction (before dom render, no rerender has occured) we want it instant to be registered
            //because this function is called after rendering and rerendering we only need to bind the custom events
        //after a RE-rerender, cause rerendering always starts with unbinding ALL events.
        else if (type === EventTypes.CUSTOM_EVENT && this.getPage().isRerendering) {
            ///	console.log('register '+name + ' on '+this.getId())
            if (data) {
                jQuery(this).bind(name, data, func);
            } else {
                jQuery(this).bind(name, func);
            }
        } else {
            //console.log('IGNORE register '+name + ' on '+this.getId())
            //no need to bind this event,
        }
    }
};

/**
 * unregister all binded events.
 * note we dont touch the bind array, cause we might rebind it at a later moment. ie rerender
 * used by framework
 */
UiControl.prototype.unregisterEvents = function () {
    if (!this.binds || !this.binds.length) {
        return;
    }

    this.getDomWriter().unbind(); //unbind all dom events
    jQuery(this).unbind(); //and all custom events

};

/**
 * sets control enabled /disabled
 * we basicly add a css class. so, make sure your css file contains this css classes
 * TODO this is not nice, can it be done otherwise?
 *
 * @param {boolean} e         True when enabled
 * @param {boolean} recursive Also enable/disable child controls
 */
UiControl.prototype.setEnabled = function (e, recursive) {
    this.triggerEvent('onSetEnabled', e);

    if (e) {
        this.enabled = true;

        if (this.isRendered) {
            this.getDomWriter().prop('disabled', false);
        }
    } else {
        this.enabled = false;

        if (this.isRendered) {
            this.getDomWriter().prop('disabled', true);
        }
    }

    if (recursive) {
        var controls = this.getControls();
        var x, len;
        for (x = 0, len = controls.length; x < len; x++) {
            if (controls[x].setEnabled) {
                controls[x].setEnabled(e, recursive);
            }
        }
    }

    return this;
};

UiControl.prototype.getEnabled = function () {
    return this.enabled;
};

/**
 * returns html markup string of control + all child controls
 *
 * @return {String}
 */
UiControl.prototype.getHtml = function (markAsRendered, nocache) {
    //if (nocache) {
    this.cachedElement = undefined;
    //}

    var html = '<' + this.getTagName() + ' ' + this.getHtmlAttributes() + '>';

    var childs = this.getControls();
    var i, len;
    for (i = 0, len = childs.length; i < len; i++) {
        if (childs[i] instanceof Control) {
            html += childs[i].getHtml(markAsRendered, true);
        } else if (typeof (childs[i]) === 'string') {
            html += childs[i];
        }
    }

    if (this instanceof UiControl) {
        html += '</' + this.getTagName() + '>';
    }

    //In the update display all the controls + their children should be marked as rendered
    //if we mark controls in the update display as rendered instead of here. we
    //wont know if a child is rendered or not.
    if (markAsRendered) {
        this.isRendered = true;
    }
    return html;
};
