import {Panel} from "../../bananaframework/src/controls/Panel.js";
import {Checkbox} from "../../bananaframework/src/controls/Checkbox.js";
import {InputControl} from "../../bananaframework/src/controls/InputControl.js";
import {Image} from "../../bananaframework/src/controls/Image.js";

import {Modal} from "../modal.js";
import {Localizer} from "../../util/localizer.js";
import {Favorites} from "../../util/favorites.js";
import {Router} from "../../util/router.js";
import {UrlManager} from "../../bananaframework/src/util/UrlManager.js";

export class SeasonSelectmodal extends Modal {

    init(urlIdentifier,disableUrlHistory,data) {
        super.init(urlIdentifier,disableUrlHistory);
        this.data = data;
        this.selectedData = [];
        this.allCountries = [];
    }

    createComponents() {
        super.createComponents();

        var dim = this.getPage().getDimensions();

        var p = new Panel();
        p.setStyle("height:" + (dim.height - 50) + "px;justify-content: flex-start;");
        p.addCssClass("flexVerticalAlign");

        var title = new Panel();
        title.addCssClass("addFavoriteModalTitle");
        title.addControl(Localizer.getString("select_season"));
        p.addControl(title);

        var selectedContainer = new Panel();
        selectedContainer.addCssClass("selectedCountriesContainer");
        var title = new Panel();
        title.setStyle("font-size: 12px;font-weight: bold;margin-bottom: 10px;");
        this.selectedCount = title;

        selectedContainer.addControl(title);
        p.addControl(selectedContainer);

        var searchResults = new Panel();
        searchResults.addCssClass("addFavoriteModalSuggestList");
        searchResults.setStyle("height:100%;overflow-y:scroll;width:85%;");
        p.addControl(searchResults)

        this.addControl(p)
        this.setVisible(false);

        setTimeout(() => {
            this.setVisible(true);
            this.reValidateSize();
        }, 50);


        this.renderSeasons(searchResults, this.data);
    }

    getYear(date){
        return date[0]+""+date[1]+""+date[2]+""+date[3];
    }

    renderSeasons(target, data) {
        console.log("render saesons ",data);
        target.clear();
        ///data.seasons = data.seasons.reverse();
        var reversed = data.tableHistory.seasons.slice().reverse();

        var nowSelected = null;
        if (UrlManager.getModule("tableSeason")){
            nowSelected = UrlManager.getModule("tableSeason");
        }

        var indexSeleted = 0;
        for (var i = 0; i < reversed.length; i++) {

            var s = reversed[i];

            var resultP = new Panel();
            resultP.addCssClass("suggestionsItemTitle");
            resultP.setStyle("text-align:center");

            var countryFlag = new Image();
            countryFlag.setStyle("width: 24px; height: 24px; margin-inline-end: 10px;");
            //countryFlag.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.countrygs.replaceAll(" ","_").toLowerCase() + ".png");
            //resultP.addControl(countryFlag);

            var countryPanel = new Panel();
            countryPanel.setStyle("margin-bottom:3px;width:100%;")

            var starty = this.getYear(s.start_date);
            var endty = this.getYear(s.end_date);

            if (nowSelected == starty + "-"+endty) {
                countryPanel.addControl("<b style='font-size:18px;'>"+starty + " - " + endty + "</b>");
                indexSeleted = i;
            }
            else{
                countryPanel.addControl(starty + " - " + endty);
            }

            ((resultP, season) => {
                resultP.bind("click", () => {
                   //Router.showLeagueScreen(league,{"currentActiveTab":this.currentActiveTab});
                    this.getPage().triggerEvent("seasonSelect", season);
                    this.destroy();
                });
            })(resultP, s);

            resultP.addControl(countryPanel);
            target.addControl(resultP);
        }

        target.invalidateDisplay();

        setTimeout(() => {
            //TODO 50px is working , but maybe getting the height from some calculated value would be better
            jQuery("#"+target.getClientId()).scrollTop(indexSeleted*50);
        }, 100);

    }

    renderSearchResult(data, target) {
        this.renderTeamItem(data, target, Favorites.getFavoriteTeams()[data.id_gs]);
    }
};
