
//this control runs in combination with collapser (topbar) and tabiew
// when target scroller scrolls down it collapses the topbar and tabview tabs.
export const AnimatorTeam = function(){

    var version = "1.0";

    var tabs = [];
    var scrollerMethods = [];
    var touchStartMethod = null;
    var touchEndMethod = null;
    var collapser = null;
    var tabview = null;
    var topbar = null;
    var ignoreEvents = false;
    var ignoreEventForIndex = [];
    var lastListIndexScrolling = -1
    var unloaded = false;

    this.matchStatus; //we use this for collapser differnt ways
    this.isTouching = false;
    this.reducerRatio = 0.3;
    this.transitionDuration = 100;
    this.alwaysCollapseOnBackScrolling = true;
    this.isCollapsed = false;
    this.lastRecalculateWasCollapsed = null;
    this.isVirtuallyCollapsed = false;
    this.lastScrollPositionAfterTouchStart = -1;
    this.lastYOffsetAfterTouchStart = 0;
    this.currentTranslateY = 0;
    this.currentActiveTab = -1;

    this.addScrollerContainer = function(tab){
        if (tabs) {
            tabs.push(tab);
        }
        else{
            console.warn("cannot add, no scroll container found");
        }
    }

    this.addCollapser = function(c){
        collapser = c;
    }

    this.addTopBar = function(tb){
        topbar = tb;
    }

    this.addTabView = function(tab){
        tabview = tab;
    }

    this.unload = function(){

        unloaded = true;
        if (topbar) {
            topbar.setCss({"transform": "translate(0px,0px)", "transition-duration": "0ms"});
        }
        if (collapser) {
            collapser.setCss({"transform": "translate(0px,0px)", "transition-duration": "0ms"});
        }
        if (tabs) {
            for (var i = 0; i < tabs.length; i++) {
                var scrollEl = jQuery("#" + tabs[i].getClientId());
                if (scrollerMethods[i]) {
                    scrollEl.off("scroll", scrollerMethods[i]);
                }
            }
        }

        scrollerMethods = null;

        $('body').off({
            'touchstart': touchStartMethod,
            'touchend' : touchEndMethod});

        collapser = null;
        tabs = null;
        topbar = null;
    }

    this.onWindowResize = function(){
        this.recalculate(true);
    }

    this.tabChange = function(){

        if (!FootballManiaConfig.graphics.animations) return;

        if (this.currentActiveTab == -1){
            console.log("nononono")
            this.currentActiveTab = tabview.currentActiveTab;
            return;
        }
        var collaped = this.isCollapsed;
        var virtuallyCollapsed = this.isVirtuallyCollapsed;
        if (collapser) {
            var collapserHeight = collapser.getDimensions().height;
        }
        //console.log("RECALCULATE TAB ",i,scrollEl[0].scrollHeight,contentWindowHeight,collapserHeight)

        var height = collapserHeight

        for (var i=0; i < tabs.length;i++) {
           // if (i == tabview.currentActiveTab) continue;
            ignoreEvents = true;
            if (ignoreEventForIndex.indexOf(i) == -1) {
                ignoreEventForIndex.push(i);
            }
            (function (i) {
                setTimeout(function() {
                    var scrollElement = tabs[i];
                    document.querySelector("#" + scrollElement.getClientId()).scrollTo({
                        top: collaped || virtuallyCollapsed ? height : 0,
                        left: 0,
                    });
                    //that.recalculateTab(i)
                },0);
            })(i)
            ignoreEvents = false;
        }
        this.currentActiveTab = tabview.currentActiveTab;
    }

    this.recalculateTab = function(i){

        if (!tabs || !tabs[i]){
            console.warn("cannot recalcuate without tabs");
            return;
        }
        if (!tabview || !tabview.tabContents || !tabview.tabContents.controls[i] || !tabview.tabContents.controls[i].controls[1] || !tabview.tabContents.controls[i].controls[1].controls[0]){
            console.warn("cannot recalcuate without tabview elements");
            return;
        }

        var contentWindowHeight = tabview.tabContents.getDimensions().height;
        var collapserHeight = 0;
        if (collapser) {
            collapserHeight = collapser.getDimensions().height;
        }

        var scrollEl = jQuery("#"+tabs[i].getClientId());
        var top  = collapserHeight;

        if (!scrollEl || !scrollEl[0]){
            console.warn("no scroller")
            return;
        }

        ///console.log("RECALCULATE TAB ",i,scrollEl[0].scrollHeight,contentWindowHeight,collapserHeight)

        var height = contentWindowHeight + collapserHeight;

        if (scrollEl[0].scrollHeight > contentWindowHeight) {
            //console.log("is higher");
            //if scrollheight is higher than screenheight than
            $("#" + tabview.tabContents.controls[i].getClientId()).css({"transform":"translate(0px,0px)","transition-duration": "0ms"});

            $("#" + tabview.tabContents.controls[i].getClientId()).css({"top": "-" +top+"px"});
            $("#" + tabview.tabContents.controls[i].getClientId()).css({"height": height + 0+ "px"});
            $("#" + tabview.tabContents.controls[i].controls[1].getClientId()).css({"height": height+ "px"});

            if (scrollEl.scrollTop() == 0){
                $("#" + tabview.tabContents.controls[i].controls[1].controls[0].getClientId()).css({
                    "position": "absolute",
                    "top": top+"px",
                    "height" : height+"px"
                });
            }

            $("#" + tabview.tabContents.controls[i].controls[0].getClientId()).css({"height": height+"px"});
            //enlarge the scrollbar
        }
        else{
            console.log("tab ",i,' content is not needed for scroll',contentWindowHeight)
            if (this.isCollapsed){
                //if collapsed and scrollheight is smaller than screenheight we put to minus offset
                //because otherwise it canot be scrolled up (towards the topbar)
                $("#" + tabview.tabContents.controls[i].getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "100ms"});
                //$("#" + tabview.tabContents.controls[i].getClientId()).css({"top": "-" +top+"px"});
            }
            else{
                //put the scrolller to the top
                $("#" + tabview.tabContents.controls[i].getClientId()).css({"transform":"translate(0px,0px)","transition-duration": "100ms"})
                $("#" + tabview.tabContents.controls[i].getClientId()).css({"top": "0px"});
                $("#" + tabview.tabContents.controls[i].getClientId()).css({"height": contentWindowHeight + "px"});

                if (scrollEl.scrollTop() == 0){
                    $("#" + tabview.tabContents.controls[i].controls[1].controls[0].getClientId()).css({
                        "position": "absolute",
                        "top": "0px",
                    });
                }
            }
        }
    }

    this.recalculate = function(force,onlyTabIndex){

        if (!FootballManiaConfig.graphics.animations) return;

        //return;
        if (!force && this.lastRecalculateWasCollapsed!= null && this.isCollapsed == this.lastRecalculateWasCollapsed){
            //console.log("NO NEED TO RECALCULATE POSITIONS");
            return;
        }
        console.log("RECALCULATE POSITIONS","isCollapsed",this.isCollapsed);
        this.lastRecalculateWasCollapsed = this.isCollapsed;
        var collapserHeight = collapser.getDimensions().height;

        if (this.isCollapsed){
            $("#"+collapser.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});

            if (topbar){
                $("#"+topbar.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
            }

            if (tabview){
                $("#"+tabview.tabHolder.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
            }
        }

        if (tabs) {
            for (var i = 0; i < tabs.length; i++) {

                var scrollEl = jQuery("#" + tabs[i].getClientId());

                console.log("XXXXXXXX",scrollEl[0].scrollHeight)
                this.recalculateTab(i);

                if (this.isCollapsed || this.isVirtuallyCollapsed) {
                    if (lastListIndexScrolling != i && scrollEl.scrollTop() <= collapserHeight) {
                        console.log("scroll #1 manually tab", i, "to ", collapserHeight)
                        var scrollElement = tabs[i];
                        ignoreEvents = true;
                        if (ignoreEventForIndex.indexOf(i) == -1) {
                            ignoreEventForIndex.push(i);
                        }
                        document.querySelector("#" + scrollElement.getClientId()).scrollTo({
                            top: collapserHeight,
                            left: 0,
                        });
                        ignoreEvents = false;
                    }
                } else {
                    if (lastListIndexScrolling != i && scrollEl.scrollTop() <= collapserHeight) {
                        console.log("scroll #2 manually tab", i, " to ", 0)
                        var scrollElement = tabs[i];
                        ignoreEvents = true;
                        if (ignoreEventForIndex.indexOf(i) == -1) {
                            ignoreEventForIndex.push(i);
                        }
                        document.querySelector("#" + scrollElement.getClientId()).scrollTo({
                            top: 0,
                            left: 0,
                        });
                        ignoreEvents = false;
                    }
                }
            }
        }
    }

    this.autoCollapseOrOpen = function(scrollPosition){
        //return;
        if (this.isAutoCollapsing){
            console.log("dont do twice ");
            return;
        }

        this.isAutoCollapsing = true;

        if (this.currentTranslateY == 0){
            this.isCollapsed = false;
        }
        else if (this.currentTranslateY == -collapser.getDimensions().height){
            this.isCollapsed = true;
        }

        if (this.currentTranslateY == 0 || this.currentTranslateY == -collapser.getDimensions().height){
            console.log("no auto collapse when already open or closed",this.currentTranslateY);
            this.isAutoCollapsing = false;
            this.recalculate();
            return;
        }

        console.log("AUTO COLLAPSE",this.currentTranslateY)

        var collapserHeight = collapser.getDimensions().height;
        var that = this;
        var delay = 300;

        if ((scrollPosition) > (collapserHeight * (1/this.reducerRatio))/2){

            var scrollElement = tabs[tabview.currentActiveTab];

            this.isCollapsed = true;
            document.querySelector("#" + scrollElement.getClientId()).scrollTo({
                top: collapserHeight,
                left: 0,
                behavior: "smooth"
            });
        }
        else {
            var scrollElement = tabs[tabview.currentActiveTab];

            document.querySelector("#" + scrollElement.getClientId()).scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });

            that.currentTranslateY = 0;
            this.isCollapsed = false;
            setTimeout(function(){
                //that.currentTranslateY = 0;
                that.recalculate();
            },delay)
        }
    }

    this.ensureStartPositions = function(){

        if (!FootballManiaConfig.graphics.animations) return;

        if (this.isTouching){
            //console.log("NO PREPARE WHEN TOUCHING");
            return;
        }

        if (!collapser || !tabview || !topbar || !tabs){
            console.error("NO COLLAPSER OR TABVIEW OR TOPBAR");
            return;
        }

        console.log("PREPARE RUN ANIMATOR FOR MATCHSCREEN "+version);
        var collapserHeight = collapser.getDimensions().height;

        // collapser.awayImage.setVisible(true);
        // collapser.homeImage.setVisible(true);
        collapser.homeImage.setVisible(true);

        //make sure we start with a collapsed one before everything gets rendered. causes flickering
        if (this.isCollapsed){
            $("#"+collapser.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
            $("#"+tabview.tabHolder.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
            $("#"+topbar.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});

            var scrollElement = tabs[tabview.currentActiveTab];

            var el = document.querySelector("#" + scrollElement.getClientId());
            //console.log("scroll to 0 from start",el.scrollHeight,el.scrollTop);

            //only scroll to zero when user not scrolled
            if (el.scrollTop < collapserHeight) {
                el.scrollTo({
                    top: collapserHeight,
                    left: 0,
                });
            }
        }
    }

    this.prerun = function(collapsed){

        if (!FootballManiaConfig.graphics.animations) return;

        console.log("PRE RUN ANIMATOR FOR MATCHSCREEN "+version);
        if (collapsed){
            this.currentTranslateY = -collapser.getDimensions().height
            this.autoCollapseOrOpen(-collapser.getDimensions().height)
        }
        else{
            this.autoCollapseOrOpen(0)
        }
        collapser.homeImage.setVisible(false);
    },

    this.run = function(){
        if (!FootballManiaConfig.graphics.animations) return;

        console.log("RUN ANIMATOR (tabs:"+tabs.length+") FOR TEAMSCREEN "+version);
        lastListIndexScrolling = tabview.currentActiveTab;

        $('body').off({
            'touchstart': touchStartMethod,
            'touchend' : touchEndMethod});

        for (var i=0; i < scrollerMethods.length;i++){
            var scrollEl = jQuery("#"+tabs[i].getClientId());
            scrollEl.off("scroll",scrollerMethods[i]);
        }

        this.recalculate();

        var that = this;
        var timeoutHandler = null;
        var timeoutHandler2 = null;
        var homeImage = collapser.homeImage;
        var backgroundImage = collapser.backgroundImage;
        var startOpacityBackground = $("#"+backgroundImage.getClientId()).css("opacity");

        touchStartMethod = function (e) {
            that.isTouching = true;
            var scrollElement = tabs[tabview.currentActiveTab];
            if (!scrollElement){
                console.error("no scroll element in tab "+tabview.currentActiveTab+" found. do nothing?");
                return;
            }
            var topOffset = document.querySelector("#" + scrollElement.getClientId()).scrollTop
            that.lastScrollPositionAfterTouchStart = topOffset;
            that.lastYOffsetAfterTouchStart = that.currentTranslateY;
        };

        touchEndMethod = function(){
            that.isTouching = false;
            if (that.currentTranslateY > -collapser.getDimensions().height){
                that.isCollapsed = false;
            }

            clearTimeout(timeoutHandler);

            timeoutHandler = setTimeout(function() {
                if (unloaded) return;
                if (!tabs){
                    console.error("no tabs found why??"); return;
                }

                that.currentActiveTab = tabview.currentActiveTab;

                var scrollElement = tabs[tabview.currentActiveTab];
                if (!scrollElement){
                    console.error("no scroll element in tab "+tabview.currentActiveTab+" found. do nothing?");
                    return;
                }

                var topOffset = document.querySelector("#" + scrollElement.getClientId()).scrollTop
                that.autoCollapseOrOpen(topOffset);
            },100);
        }

        $('body').on({
        'touchstart': touchStartMethod,
        'touchend' : touchEndMethod});

        //what if dynamic height?
        var collapserHeight = collapser.getDimensions().height;

        function runTabSCrollLogic(index){
            // console.log("run scroll logic ",index);

            var scrollEl = jQuery("#"+tabs[index].getClientId());

            var prevScroll = null;
            var ticking = false;

            scrollerMethods[index] = function(e){
                if (ticking) return;

                clearTimeout(timeoutHandler);
                window.requestAnimationFrame(function() {

                    ticking = true;
                    if (ignoreEvents || index != tabview.currentActiveTab) {
                        console.log("ignore scroll event if not this active tab ", index)
                        ticking = false;
                        return;
                    }
                    if (ignoreEventForIndex.indexOf(index) != -1) {
                        console.log("ignore scroll event this time", index, ignoreEventForIndex);
                        //ignoreEventForIndex = -1;
                        ignoreEventForIndex.splice(ignoreEventForIndex.indexOf(index), 1);
                        ticking = false;
                        return;
                    }

                    var topOffset = e.originalEvent.target.scrollTop;
                    if (prevScroll == topOffset) {
                        ticking = false;
                        console.log("do nothing no scroll change");
                        return;
                    }
                    lastListIndexScrolling = index;
                    clearTimeout(timeoutHandler2);

                    timeoutHandler2 = setTimeout(function () {
                        that.isAutoCollapsing = false;
                        var topOffset = e.originalEvent.target.scrollTop;
                        if (!that.isTouching) {
                            that.autoCollapseOrOpen(topOffset);
                        }
                    }, 150);

                    var topOffset = e.originalEvent.target.scrollTop;

                    if (false && !that.alwaysCollapseOnBackScrolling) {
                        console.log("always collapse");
                        scrollDiff = Math.max(-topOffset, -collapserHeight);

                    } else {

                        if (that.isCollapsed) {
                            //console.log("is collapsed scroll",topOffset,collapserHeight);
                            if (topOffset <= collapserHeight) {
                                ///var t = ((that.lastScrollPositionAfterTouchStart - topOffset) * that.reducerRatio) - collapserHeight;
                                var t = -topOffset;
                                scrollDiff = Math.max(-collapserHeight, Math.min(0, t));
                                //scrollDiff =  t;
                                //console.log("--->",scrollDiff,t,topOffset)
                            }
                            else{
                                //console.log("do other")
                                scrollDiff = Math.max(-topOffset, -collapserHeight);
                            }
                        } else {
                            //console.log("is not collapsed scroll",topOffset,collapserHeight);
                            if (topOffset >= collapserHeight){
                                scrollDiff = -collapserHeight;
                            }
                            else if (that.lastScrollPositionAfterTouchStart !=-1) {
                                //var t = ((that.lastScrollPositionAfterTouchStart - topOffset) * that.reducerRatio) + that.lastYOffsetAfterTouchStart;
                                var t = (((collapserHeight-topOffset)-collapserHeight) * that.reducerRatio);
                                var scrollDiff = Math.min(0, Math.max(-collapserHeight, t));
                                var scrollDiff = t;
                            }
                            else{
                                scrollDiff = 0;
                            }
                        }
                    }
                    that.currentTranslateY = scrollDiff;

                    //this virtualcollapsed value is a little different than iscollapsed
                    //when user is collapsing and no touchend event is fired then the state of
                    //iscollapsed remains the same. the virtualcollapsed one changes
                    //this is needed in the recalculation of positions
                    if (scrollDiff == -collapserHeight) {
                        that.isVirtuallyCollapsed = true;
                    } else {
                        that.isVirtuallyCollapsed = false;
                    }

                    //collapser.setCss({"transform":"translate(0px,"+scrollDiff+"px)","transition-duration": this.transitionDuration+"ms"});
                    collapser.setCss({
                        "transform": "translate(0px," + Math.round(scrollDiff)+ "px)",
                        "transition-duration": "80ms"
                    });
                    tabview.tabHolder.setCss({"transform":"translate(0px,"+scrollDiff+"px)","transition-duration": that.transitionDuration+"100ms"});
                    tabview.tabHolder.setCss({
                        "transform": "translate(0px," + Math.round(scrollDiff)+ "px)",
                        "transition-duration": "80ms"
                    });

                    var progression = -scrollDiff/collapserHeight;

                    homeImage.setCss({
                        "opacity": (1 - (progression*1))
                    });
                    backgroundImage.setCss({
                        "transform": "translateX(" + tabview.cachedParllaxBgXOffset+ "%) translateY(" + (-50 + (progression*30))+ "px)",
                        "transition-duration": "80ms",
                        "opacity": (startOpacityBackground - (progression*startOpacityBackground)),
                    });

                    topbar.setCss({
                        "transform": "translate(0px," + Math.round(scrollDiff)+ "px)",
                        "transition-duration": "80ms"
                    });

                    prevScroll = e.originalEvent.target.scrollTop;
                    ticking = false;
                });
            }

            if (scrollerMethods[index]){
                scrollEl.off("scroll",scrollerMethods[index]);
            }

            scrollEl.scroll(scrollerMethods[index]);
        }

        for (var i =0; i < tabs.length;i++){
            runTabSCrollLogic(i);
        }
    }
}