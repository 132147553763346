import {Panel} from "../bananaframework/src/controls/Panel.js";

export class BouncePanel extends Panel {

    init() {
        super.init();
        this.addCssClass("bouncePanel");
        this.bounceEnabled = true;
        this.cachedElements = [];
        this.calculatedScrollbarItemHeight = 0;
        this.scrollbarHeightCalculated = false;
        this.cachedScreenHeight = 0;
        this.cachedScrollHeight = 0;
        this.cachedScrollContainerElement = null;
        this.scrollBarAnimationHandle = null;
        this.scrollBarSetToVisible = false;

        this.scrollerBarContainer = new Panel();
        this.scrollerBarContainer.addCssClass("listViewScrollerBarContainer");

        //this.scrollerBarContainer.setVisible(false);

        this.scrollerBarItem = new Panel();
        this.scrollerBarItem.addCssClass("listViewScrollerBarItem");
        this.scrollerBarContainer.addControl(this.scrollerBarItem);

        this.indicatorContainer = new Panel();
        this.indicatorContainer.addCssClass("listViewScrollerIndicatorContainer");
        this.addControl(this.indicatorContainer);
        this.indicatorContainer.addControl(this.scrollerBarContainer);

        this.cachedElements = [];
    }

    enableBounce() {
        this.bounceEnabled = true;
    }

    disableBounce() {
        this.bounceEnabled = false;
    }

    unload() {
        var scrollEl = jQuery("#" + this.getClientId());
        scrollEl.off("scroll", this.scrollMethod); //what if others listen to this event
        this.cachedScrollContainerElement = null;
        this.cachedElements = null;
    }

    recalculate() {
        this.cachedScrollContainerElement = null
        this.cachedScreenHeight = null;
        this.cachedScrollHeight = null;
        this.calculatedScrollbarItemHeight = false;
        this.cachedElements = [];
    }

    updateScrollbar(scrollTop,dontshowAnimation) {

        //console.log("update scrollbar", scrollTop, this.scrollbarHeightCalculated, this.cachedScreenHeight, this.cachedScrollHeight, this.calculatedScrollbarItemHeight, this.scrollBarSetToVisible,dontshowAnimation,this.scrollingTicks);
        if (!this.getContentScrollContainer()) {
            console.error("no scrollbar container");
            return;
        }
//        console.log("update scrollbar");
        if (!this.cachedScrollContainerElement) {
            this.cachedScrollContainerElement = document.querySelector("#" + this.getContentScrollContainer().getClientId());
        }
        if (!this.cachedElements[this.getClientId()]) {
            this.cachedElements[this.getClientId()] = document.querySelector("#" + this.getClientId());
        }
        if (!this.cachedElements[this.scrollerBarItem.getClientId()]) {
            this.cachedElements[this.scrollerBarItem.getClientId()] = document.querySelector("#" + this.scrollerBarItem.getClientId());
        }

        this.cachedScreenHeight = this.cachedElements[this.getClientId()].offsetHeight;
        this.cachedScrollHeight = this.cachedScrollContainerElement.scrollHeight;
        this.calculatedScrollbarItemHeight = Math.max(0.05, Math.min(1, this.cachedScreenHeight / this.cachedScrollHeight));

        var scrollRatio = (scrollTop / (this.cachedScrollHeight - this.cachedScreenHeight));
        var topOffset = (this.cachedScreenHeight * (scrollTop / (this.cachedScrollHeight - this.cachedScreenHeight))) - (scrollRatio * (this.calculatedScrollbarItemHeight * this.cachedScreenHeight));

        if (scrollRatio == 0) {
            return;
        }

        ///console.log("scroll ratio", scrollRatio, topOffset, this.calculatedScrollbarItemHeight, this.cachedScreenHeight, this.cachedScrollHeight, scrollTop, this.cachedScrollContainerElement.scrollHeight, this.cachedScreenHeight, this.cachedScrollHeight, this.calculatedScrollbarItemHeight, this.scrollBarSetToVisible, this.scrollbarHeightCalculated, this.cachedElements[this.scrollerBarItem.getClientId()]);

        this.cachedElements[this.scrollerBarItem.getClientId()].style.height = this.calculatedScrollbarItemHeight * 100 + "%";
        this.cachedElements[this.scrollerBarItem.getClientId()].style.transitionDuration = "20ms";
        this.cachedElements[this.scrollerBarItem.getClientId()].style.transform = "translate3d(0px, " + topOffset + "px, 0px)";
        this.scrollbarHeightCalculated = true;

        //jQuery("#"+this.scrollerBarItem.getClientId()).css({"transform": "translateY("+topOffset+"px)"});
        ////jQuery("#"+this.scrollerBarItem.getClientId()).css({"transition-duration":"130ms","transform": "translate3d(0px, "+topOffset+"px, 0px)"})
        //
        clearTimeout(this.scrollBarAnimationHandle)

        //do not show scroller bar animation when page loads or manualy is scrolled in the beginning
        if (dontshowAnimation || this.scrollingTicks < 2) return;

        //this.scrollBarSetToVisible = false;
        if (!this.scrollBarSetToVisible) {
            this.scrollerBarContainer.setVisible(true);
            this.scrollerBarContainer.removeCssClass("scrollbaranimateout")
            this.scrollerBarContainer.addCssClass("scrollbaranimatein")
            this.scrollBarSetToVisible = true;
        }

        this.scrollBarAnimationHandle = setTimeout(() => {
            this.scrollerBarContainer.removeCssClass("scrollbaranimatein")
            this.scrollerBarContainer.addCssClass("scrollbaranimateout")
            this.scrollBarSetToVisible = false;
        }, 100);

    }

    repositionScrollbar() {

        this.scrollingTicks = 0;
        console.log("reposition scrollbar");
        //this.scrollerBarContainer.setCss({"margin-left":this.getDimensions().width-(this.scrollerBarContainer.getDimensions().width)+"px"});
        this.scrollbarHeightCalculated = false;
        this.updateScrollbar(0, true);
    }

    getContentScrollContainer() {
        //console.log("get original content scroll container ",this.controls[1])
        return this.scrollElement || this.controls[1]; //hack assumption. for now ok
    }

    updateDisplay() {
        if (!this.getContentScrollContainer()) {
            console.error("no scroll container found for bouncepanel");
            return;
        }

        var scrollEl = jQuery("#" + this.getContentScrollContainer().getClientId());

        var lastPos, newPos, timer, timer2, delta,
            delay = 30;

        var that = this;
        var height = this.getDimensions().height;

        //console.log("xxxxxxxxx",document.getElementById(this.getContentScrollContainer().getClientId()).scrollHeight);
        this.repositionScrollbar();

        if (this.scrollMethod) {
            scrollEl.off("scroll", this.scrollMethod);
        }

        this.scrollerBarContainer.setVisible(false);
        this.scrollBarSetToVisible = false;
        this.scrollingTicks = 0;

        var ticking = false;
        var isBouncing = false;
        this.scrollMethod = () => {

            if (!ticking) {
                ticking = true;
                window.requestAnimationFrame(() => {
                    newPos = scrollEl.scrollTop();

                    that.updateScrollbar(newPos);
                    clearTimeout(timer2);
                    timer2 = setTimeout(() => {
                        this.scrollingTicks = 0;
                    }, 1000);
                    this.scrollingTicks++;

                    if (!that.bounceEnabled) {
                        ticking = false;
                        console.log('disabled');
                        return;
                    }

                    if (lastPos != null) { // && newPos < maxScroll
                        delta = newPos - lastPos;
                    }
                    //console.log(height,delta,newPos,document.getElementById(this.getContentScrollContainer().getClientId()).scrollHeight);
                    lastPos = newPos;
                    clearTimeout(timer);
                    //console.log("-->scroll->>",scrollEl.scrollTop(),delta)
                    if (!isBouncing) {
                        timer = setTimeout(function () {
                            if (newPos == 0 && Math.abs(delta) > 1) {
                                isBouncing = true;
                                $("#" + that.getClientId()).addClass("bouncePanelAnimation").one("webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend", function () {
                                    isBouncing = false;
                                    $("#" + that.getClientId()).removeClass("bouncePanelAnimation");
                                });
                                lastPos = null;
                                delta = 0;
                            } else if (Math.abs(delta) > 1 && ((newPos + height) >= document.getElementById(that.getContentScrollContainer().getClientId()).scrollHeight)) {
                                console.log("the end of scrolling");
                            }
                        }, delay);
                    }
                    ticking = false;
                });
            }
        };
        scrollEl.scroll(this.scrollMethod);
    }
};