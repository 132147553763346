import {Control} from "./bananaframework/src/controls/Control.js";
import {UiControl} from "./bananaframework/src/controls/UiControl.js";
import {Panel} from "./bananaframework/src/controls/Panel.js";
import {Page} from "./bananaframework/src/controls/Page.js";
import {PageTemplate} from "./bananaframework/src/controls/PageTemplate.js";


import {Application} from "./bananaframework/src/core/Banana.js";
import {BasePage} from "./pages/basepage.js";
import {ManiaTemplate} from "./pages/template.js";
import {Home} from "./pages/home.js";
import {News} from "./pages/news.js";
import {Match} from "./pages/match.js";
import {Agenda} from "./pages/agenda.js";
import {League} from "./pages/league.js";
import {Team} from "./pages/team.js";
import {Player} from "./pages/player.js";
import {Coach} from "./pages/coach.js";
import {CountryLeagues} from "./pages/countryleagues.js";
import {PlayerValuesRanking} from "./pages/playervalueranking.js";
import {Fifaranking} from "./pages/fifaranking.js";
import {ForumIndex} from "./pages/forumindex.js";
import {ForumChat} from "./pages/forumchat.js";
import {TopVoters} from "./pages/topvoters.js";
import {TopTeamsLeagues} from "./pages/topTeamsLeagues.js";

import {DataProvider}  from "./data/dataprovider.js";
import {ThemeManager}  from "./util/theme.js";
import {Localizer}  from "./util/localizer.js";
import {PushMessaging}  from "./util/pushmessaging.js";
import {CountryUtil}  from "./util/country.js";
import {Favorites}  from "./util/favorites.js";
import {Voter}  from "./util/voter.js";
import {Subscriber}  from "./util/subscriber.js";
//import {Workbox} from 'https://storage.googleapis.com/workbox-cdn/releases/6.0.0/workbox-sw.js';

import {FootballManiaConfig}  from "./config_build.js";
import {ManiaPreferences} from "./util/preferences.js";
// import {DefaultTranslations}  from "./translations/default_translations.js";
window.FootballManiaConfig = FootballManiaConfig; //required by many modules TODO: make nicer




ThemeManager.autoApplyTheme();
ThemeManager.autoApplySize();

console.log("=========================> Build version: ["+FootballManiaConfig.clientVersion+"]");


$.xhrPool = [];


// if (navigator.serviceWorker) {
//     navigator.serviceWorker.register(new URL('/sw.js', import.meta.url),
//         //{ scope:"./",enabled: true, registrationStrategy: 'registerImmediately' }
//         { scope:"/"}
//     ).then(function(registration) {
//         console.log('ServiceWorker registration successful with scope:',  registration.scope);
//     }).catch(function(error) {
//         console.log('ServiceWorker registration failed:', error);
//     });
//
//     navigator.serviceWorker
// }


//some polyfill for older browsers
if (!String.prototype.replaceAll) {
    console.log("polyfilling replaceAll");
    String.prototype.replaceAll = function(str, newStr){

        // If a regex pattern
        if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
            return this.replace(str, newStr);
        }

        // If a string
        return this.replace(new RegExp(str, 'g'), newStr);
    };
}

if (navigator.serviceWorker) {
    navigator.serviceWorker.register(
        new URL('/service-worker.js', import.meta.url),
        {type: 'module'}
    );
}
else{
    console.error("Service worker not supported");
}

$.ajaxSetup({
    beforeSend: function (jqXHR) {
        $.xhrPool.push(jqXHR);
    },
    complete: function (jqXHR) {
        var i = $.xhrPool.indexOf(jqXHR);
        if (i > -1)
            $.xhrPool.splice(i, 1);
    }
});

$.xhrPool.abortAll = function () {
    $(this).each(function (i, jqXHR) {
        jqXHR.abort();
        $.xhrPool.splice(0, 1);
    });
};

export class FootballManiaApp extends Application{

    init (settings){
        super.init(settings);
        window.AppInstance = this;
        this.preSetup();

        if (this.workersSupported()){
            console.log("registering workers");
            try {
                this.workerHelper1 = new window.Worker(new URL('./util/workers.js', import.meta.url), {type: 'module'});
                this.workerHelper2 = new window.Worker(new URL('./util/workers.js', import.meta.url), {type: 'module'});
                this.workerHelper3 = new window.Worker(new URL('./util/workers.js', import.meta.url), {type: 'module'});
                this.workersInitialized = true;
            }
            catch (e){
                console.error("Error registering workers",e);
            }
        }
        $.fn.isVScrollable = function () {
            return this[0].scrollHeight > this[0].clientHeight;
        };
    }

    workersSupported(){
        return undefined != window.Worker;
    }

    getWorker(i){
        if (i == 0) return this.workerHelper1;
        if (i == 1) return this.workerHelper2;
        if (i == 2) return this.workerHelper3;
    }

    getLocale(){
        return FootballManiaConfig.userLocale;
    }

    loadJsScript (src,loadCallback,errorCallback,type){
        const script = document.createElement('script');
        script.type = type || 'text/javascript';
        script.src = src;
        script.onload = function(){
            loadCallback();
        };
        script.onerror = function(){
            errorCallback();
        }
        document.head.appendChild(script)
        //renderer.appendChild(this.document.body, script);
        return script;
    }

    getStrippedConfig(){
        return FootballManiaConfig;
    }

    getConfig(){
        return FootballManiaConfig;
    }

    enableRtl(enable){
        console.log("enable RTL ",enable);
        if (enable) {
            document.documentElement.setAttribute('data-textdirection', "rtl");
            FootballManiaConfig.isRtl = true;
        }
        else{
            document.documentElement.setAttribute('data-textdirection', "ltr");
            FootballManiaConfig.isRtl = false;
        }
    }

    //TODO use config. but config needs to load first before this is called
    useWebAds(){
        return FootballManiaConfig.isWebStandalone;
    }

    /**
     * presetup happens automatically before any page /template is initialized
     * @param cb
     */
    preSetup(cb){
        console.log("preSetup",window.iOSConfig);

        if (FootballManiaConfig.autoSetLocale) {
            try {
                FootballManiaConfig.userLocaleFull = navigator.language;
                FootballManiaConfig.userLocale = navigator.language.slice(0, 2);
                FootballManiaConfig.userIntlLocale = new Intl.Locale(navigator.language);
                FootballManiaConfig.userRegionLocale = CountryUtil.getRegionByLocale();
                console.log("auto set locale",FootballManiaConfig.userLocale,FootballManiaConfig.userLocaleFull)
            }
            catch (e) {
                FootballManiaConfig.userLocale = FootballManiaConfig.defaultLocale;
                console.error("failed to set locale, use default", e);
            }
        }
        else{
            try {
                FootballManiaConfig.userIntlLocale = new Intl.Locale(FootballManiaConfig.userLocaleFull);
                FootballManiaConfig.userRegionLocale = CountryUtil.getRegionByLocale();
                console.log("manual set locale", FootballManiaConfig.userLocale, FootballManiaConfig.userLocaleFull)
            }
            catch (e) {
                console.error("failed to set locale, use default", e);
                FootballManiaConfig.userLocale = FootballManiaConfig.defaultLocale;
                console.log("manual set locale", FootballManiaConfig.userLocale, FootballManiaConfig.userLocaleFull)

            }
        }

        document.documentElement.setAttribute("lang", FootballManiaConfig.userLocale);

        if (window.JSAndroidBridge){
            console.log("detected android");
            if (FootballManiaConfig.autoDetectAppMode){
                FootballManiaConfig.appmode = "android";
            }

            FootballManiaConfig.isWebStandalone = false;
            FootballManiaConfig.enableWebAds = false;
            FootballManiaConfig.isAndroid = true;
            console.log("retreiving RTL from android");
            FootballManiaConfig.isRtl = window.JSAndroidBridge.isRTL();
            this.enableRtl(FootballManiaConfig.isRtl);
        }
        else if (window.isIOS || window.iOSConfig || (navigator && navigator.platform && navigator.platform.toLowerCase().indexOf("iphone") > -1)){
            console.log("detected iphone with config ",window.iOSConfig);
            if (FootballManiaConfig.autoDetectAppMode) {
                FootballManiaConfig.appmode = "ios";
            }
            FootballManiaConfig.isWebStandalone = false;
            FootballManiaConfig.useWebWorkers = false;
            FootballManiaConfig.isIphone = true;
        }
        else {
            console.log("detected web");
            if (FootballManiaConfig.autoDetectAppMode) {
                FootballManiaConfig.appmode = "web";
            }
            FootballManiaConfig.isWebStandalone = true;
            FootballManiaConfig.isAndroid = false;
            if (!FootballManiaConfig.autoSetRtl) {
                console.log("retreiving RTL from config. Auto detect disabled");
                this.enableRtl(FootballManiaConfig.isRtl);
            } else {
                try {
                    const loc = new Intl.Locale(FootballManiaConfig.userLocale);
                    if (loc.textInfo) {
                        console.log("detecting RTL from browser: ", loc.textInfo.direction);
                        this.enableRtl(loc.textInfo.direction === "rtl");
                    } else {
                        console.error("failed to detect RTL from browser. Use default");
                    }
                }
                catch (e) {
                    console.error("failed to detect RTL from browser. Use default");
                    this.enableRtl(false);
                    FootballManiaConfig.userLocale = FootballManiaConfig.defaultLocale;
                }
            }

            if (navigator.serviceWorker) {
                navigator.serviceWorker.register(
                    new URL('/firebase-messaging-service-worker.js', import.meta.url),
                    {type: 'module'}
                ).then(function(registration) {
                    console.log('ServiceWorker registration successful with scope:',  registration.scope);
                    window.firebaseServiceWorker = registration;
                });
            }
            else{
                console.error("Service worker not supported");
            }
        }


        if (FootballManiaConfig.appmode == "ios"){
            // FootballManiaConfig.enabledFeatures.news = false;
            // FootballManiaConfig.enabledFeatures.search = false;
            FootballManiaConfig.enabledFeatures.forum = false;
            FootballManiaConfig.enabledFeatures.chat = false;
            FootballManiaConfig.enabledFeatures.matchChat = false;
            // FootballManiaConfig.enabledFeatures.playerImages = false;
            // FootballManiaConfig.enabledFeatures.teamImages = false;
            // FootballManiaConfig.enabledFeatures.userPredictions = false;
            // FootballManiaConfig.enabledFeatures.entertainment = false;
            // FootballManiaConfig.enabledFeatures.showHiddenLeagues = false;
            // FootballManiaConfig.enabledFeatures.housead = false;
            // FootballManiaConfig.enabledFeatures.showCompleteLeagueNames = false;
            FootballManiaConfig.graphics.defaultMatchListType = 3;
        }
    }

    /**
     * setup is manually called from within a page.
     * @param cb
     */
    setup(cb){

        if (this.setupAlreadyDone){
            setTimeout(()=>{
                return cb(this.setupAlreadyDone);
            },20)
            return;
        }

        DataProvider.getConfig((response)=>{
            if (!response.isOk()){
                console.error("failed to load config");
                return cb(false);
            }

            if (!this.workersInitialized){
                console.log("loaded config. workers were never initialized. disable workers");
                FootballManiaConfig.useWebWorkers = false;
            }

            this.doUserPreferenceStuff(response.getData());


            DataProvider.getInfo((response)=> {

                if (response.isOk()) {
                    try{
                        const info = JSON.parse(atob(response.getData()));
                        console.log("info response ",info);
                        FootballManiaConfig.detectedUserCountryCode = info.countryCode;
                        FootballManiaConfig.detectedUserCountry = CountryUtil.getCountryByLocale(info.countryCode,"EN");
                    }
                    catch (e) {
                        console.error("failed to parse info",e);
                    }
                }
                this.loadPlatformSpecifcStuff(() => {
                    //var config = response.getData();
                    Localizer.loadLocale(AppInstance.getLocale(), (result) => {
                        this.setupAlreadyDone = result;
                        cb(result);

                        setTimeout(() => {
                            PushMessaging.init(this);
                        }, 1000)
                    });
                });
            });
        });
    }

    doUserPreferenceStuff(config){


        //when user disabled animations
        const isReduced = window.matchMedia(`(prefers-reduced-motion: reduce)`) === true || window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

        FootballManiaConfig.size = ThemeManager.getCurrentSize();
        FootballManiaConfig.theme = ThemeManager.getCurrentTheme();

        if (!!isReduced) {
            FootballManiaConfig.graphics.animations = false;
        } else {
            FootballManiaConfig.graphics.animations = true;
        }
    }

    loadPlatformSpecifcStuff(cb){

        if (window.JSAndroidBridge){

            var defaultHomeScreen = window.JSAndroidBridge.getDefaultHomeScreen();
            FootballManiaConfig.defaultHomeScreen = defaultHomeScreen;
            //console.log("default home screen ",FootballManiaApp.defaultHomeScreen);

            var isMigratedFromAndroid = localStorage.getItem("android_native_migration_done");

            //isMigratedFromAndroid = false;
            if (!isMigratedFromAndroid){

                //load subscribed matches
                var subscribedMatches = window.JSAndroidBridge.getSubscribedMatches();
                var subscribedTeams = window.JSAndroidBridge.getSubscribedTeams();
                var subscribedLeagues = window.JSAndroidBridge.getSubscribedLeagues();
                //load all favorite teams and leagues
                var favoriteTeams =  window.JSAndroidBridge.getTeamFavorites();
                var favoriteLeagues =  window.JSAndroidBridge.getLeagueFavorites();
                var votedMatches = window.JSAndroidBridge.getAllVotedMatches();

                Favorites.migrateAndroidFavoriteTeams(favoriteTeams);
                Favorites.migrateAndroidFavoriteLeagues(favoriteLeagues);

                Subscriber.migrateAndroidMatchSubscriptions(subscribedMatches);
                Subscriber.migrateAndroidTeamSubscriptions(subscribedTeams);
                Subscriber.migrateAndroidLeagueSubscriptions(subscribedLeagues);

                Voter.migrateAndroidMatchVotes(votedMatches);

                localStorage.setItem("android_native_migration_done",1);
                //load other settings
            }
            return cb();
        }
        else{
            //some logic to disable context menu
            window.oncontextmenu = function (event) {
                // eslint-disable-next-line no-console
                const pointerEvent = event;
                // eslint-disable-next-line no-console
                if (pointerEvent.pointerType === 'touch') {
                    // context menu was triggerd by long press
                    return false;
                }
                // just to show that pointerEvent.pointerType has another value 'mouse' aka right click
                if (pointerEvent.pointerType === 'mouse') {
                    // context menu was triggered by right click
                    return true;
                }
                // returning true will show a context menu for other cases
                return true;
            };
            cb();
        }
    }

    isOnline(){
        return window.navigator.onLine;
    }
};



//** start application logic */

$(document).ready(function(){

    // jQuery.event.special.touchstart = {
    //     setup: function( _, ns, handle ) {
    //         this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    //     }
    // };
    // jQuery.event.special.touchmove = {
    //     setup: function( _, ns, handle ) {
    //         this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    //     }
    // };
    // jQuery.event.special.wheel = {
    //     setup: function( _, ns, handle ){
    //         this.addEventListener("wheel", handle, { passive: true });
    //     }
    // };
    // jQuery.event.special.mousewheel = {
    //     setup: function( _, ns, handle ){
    //         this.addEventListener("mousewheel", handle, { passive: true });
    //     }
    // };


    const applicationParameters = {};
    applicationParameters.applicationName = "fm"
    applicationParameters.pages = {
        "Home": Home,
        "News" : News,
        "Match" : Match,
        "Team" : Team,
        "Agenda" : Agenda,
        "Player" : Player,
        "Coach" : Coach,
        "League" : League,
        "CountryLeagues" : CountryLeagues,
        "PlayerValuesRanking" : PlayerValuesRanking,
        "Fifaranking" : Fifaranking,
        "ForumIndex" : ForumIndex,
        "ForumChat" : ForumChat,
        "TopVoters" : TopVoters,
        "TopTeamsLeagues" : TopTeamsLeagues,
    }
    applicationParameters.defaultPage = "Home";
    applicationParameters.pageTemplate = ManiaTemplate;
    applicationParameters.renderTarget = 'target';

    var app = new FootballManiaApp(applicationParameters);
    app.run();
});
