import {Panel} from "../../bananaframework/src/controls/Panel.js"
import {Label} from "../../bananaframework/src/controls/Label.js"
import {Image} from "../../bananaframework/src/controls/Image.js"
import {TableRow, TableHeaderCol, TableCol} from "../../bananaframework/src/controls/Table.js"
import {
    DataGridTileItemRender
} from "../../bananaframework/src/controls/listcontrols/datagrids/listrenders/DataGridTileItemRender.js"

import {ImagePlaceHolder, teamPlaceholder, countryPlaceHolder} from "../imageplaceholder.js"
import {MatchUtil} from "../../util/matchutil.js";
import {Localizer} from "../../util/localizer.js";
import {Router} from "../../util/router.js";
import {DateTimecode} from "../../util/datetime.js";
import {CountryUtil} from "../../util/country.js";
import {RemoveReservedIfNeeded} from "../../util/utils.js";

import {MatchItemRender} from "../home/listrendershome.js";

export class PlayerPersonaListRender extends DataGridTileItemRender {

    init(data) {
        super.init();
        this.data = data;
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");
        this.panel.setStyle("margin-bottom:40px;");
        //
        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);
        this.panel.setStyle("margin-top:3px;margin-bottom:-3px;");
        this.card.setStyle("padding-bottom:4px;");
        //
        this.content = new Panel();
        this.content.addCssClass("flexVerticalAlign");

        var title = new Panel();
        //title.addControl(Localizer.getString("passport"));
        title.addCssClass("MatchStatsTitleText MatchH2HTitle")

        this.card.addControl(title);
        this.card.addControl(this.content);
        //
        this.addControl(this.panel);
    }

    setData(data) {

        this.data = data.data;

        this.content.addControl("<table class='tableInfoList' style='width:100%;'>");

        this.createInfo(Localizer.getString("name"), this.data.name);
        this.createInfo(Localizer.getString("firstname"), this.data.firstname);
        this.createInfo(Localizer.getString("lastname"), this.data.lastname);

        if (this.data.birthdate) {
            var date = new DateTimecode();
            //this.data.birthdate = "17/03/2018";
            var split = this.data.birthdate.split("/");
            //this.tempDateTime.clear()
            date.setYear(split[2]);
            date.setMonth(split[1] - 1);
            date.setDate(split[0]);
            this.createInfo(Localizer.getString("birthdate"), date.toLocaleDateStringLong());
            this.createInfo(Localizer.getString("age"), date.getAgeInYearsTillNow() + "");
        }


        this.createInfo(Localizer.getString("birthplace"), this.data.birthplace);
        this.createInfo(Localizer.getString("nationality"), CountryUtil.getLocalizedCountryByName(this.data.nationality));
        this.createInfo(Localizer.getString("height"), this.data.height);
        this.createInfo(Localizer.getString("weight"), this.data.weight);
        this.createInfo(Localizer.getString("team"), this.data.team);

        if (this.data.preferredFoot && this.data.preferredFoot == "Left") {
            this.createInfo(Localizer.getString("preferred_foot"), Localizer.getString("left_foot"));
        } else if (this.data.preferredFoot && this.data.preferredFoot == "Right") {
            this.createInfo(Localizer.getString("preferred_foot"), Localizer.getString("right_foot"));
        }

        if (this.data.position) {
            this.createInfo(Localizer.getString("position"), Localizer.getString(this.data.position.toLowerCase()))
        }

        if (this.data.marketvalue) {
            var value = Intl.NumberFormat('en-EN', {style: 'currency', currency: 'EUR'}).format(this.data.marketvalue);
            var valueRank = "";
            if (this.data.valuerank){
                valueRank = " (#" + this.data.valuerank+")";
            }
            var row = this.createInfo(Localizer.getString("market_value"), (value+valueRank),true);
            //row.controls[1].setCss({"text-decoration": "underline"});
            row.bind("click", ()=>{
                Router.showPlayerMarketValues(this.data.id);
            });
        }

        this.content.addControl("</table>");

        if (this.data.isPlayer){
            var buttonLink = new Panel();
            buttonLink.addCssClass("button");
            buttonLink.setStyle("margin-top:10px;min-width:150px;background-color:var(--match-votebutton-background-color);");
            buttonLink.addControl(Localizer.getString("playerstats"));
            this.content.addControl(buttonLink);

            buttonLink.bind("click", ()=>{
                Router.showPlayerScreen(this.data.id);
            });
        }

    }

    createInfo(title, data,isLink) {

        if (data == null || data == "" || data == undefined) return;
        if (data.trim() == "") return;

        var row = new TableRow();
        row.setStyle("height: 20px;cursor:none;");
        var th1 = new TableCol();
        th1.addCssClass("tableInfoTitle");
        th1.addControl(title + " : ");
        var th2 = new TableCol();

        if (isLink){
            th2.addControl("<a style='text-decoration:underline;'>" + data + "</a>");
        }
        else {
            th2.addControl(data);
        }
        th2.addCssClass("tableInfoValue");

        row.addControl(th1);
        row.addControl(th2);
        this.content.addControl(row);
        return row;
    }
};


export class PlayerInuryStats extends PlayerPersonaListRender {

    createComponents(data) {

        this.content.addControl("<table class='tableStandings' style='width:100%;'>");

        // this.createInfo(Localizer.getString("total days injured this year"), "1 day");
        // this.createInfo(Localizer.getString("total days injured last year"), "1 day");
        //this.createInfo(Localizer.getString("total days not available"), "1 day");
        this.createInfo(Localizer.getString("status"), "active");

        this.content.addControl("</table>");
    }
};

export class PlayerStatsLegendaItemRender extends DataGridTileItemRender {

    updateDisplay() {
        this.parent.addCssClass("playerStatsStickyLegenda")
    }

    setData(data) {

        this.panel = new Panel();
        this.addControl(this.panel);

        this.data = data.data
        this.panel.addCssClass("playerStatsLegenda");
        //this.panel.addCssClass("playerStatsStickyLegenda")

        this.panel.addControl("<table class='playerStatsTable'>");

        var row = new TableRow();
        this.panel.addControl(row);
        row.setStyle("height:20px;")
        var c1 = new TableCol();
        c1.setStyle("text-align: start;");
        row.addControl(c1);
        var c2 = new TableCol();
        c2.addCssClass("playerStatsItem");
        row.addControl(c2);
        var c3 = new TableCol();
        c3.addCssClass("playerStatsItem");
        row.addControl(c3);
        var c4 = new TableCol();
        c4.addCssClass("playerStatsItem");
        row.addControl(c4);
        var c5 = new TableCol();
        c5.addCssClass("playerStatsItem");
        row.addControl(c5);
        var c6 = new TableCol();
        c6.addCssClass("playerStatsItem");
        row.addControl(c6);
        var c7 = new TableCol();
        c7.addCssClass("playerStatsItem");
        row.addControl(c7);
        var c8 = new TableCol();
        c8.addCssClass("playerStatsItem");
        row.addControl(c8);
        var c9 = new TableCol();
        c9.addCssClass("playerStatsItemMinutes");
        row.addControl(c9);

        var i2 = new Image();
        i2.setImage(new URL(
            "/images/redcard.png",
            import.meta.url
        ));

        c2.addControl(new Image().setImage(new URL("/images/football.png", import.meta.url)).setStyle("width:20px;"));
        c3.addControl(new Image().setImage(new URL("/images/yellowcard.png", import.meta.url)).setStyle("width:20px;"));
        c4.addControl(new Image().setImage(new URL("/images/redcard.png", import.meta.url)).setStyle("width:20px;"));
        c5.addControl(new Image().setImage(new URL("/images/subin.png", import.meta.url)).setStyle("width:20px;"));
        c6.addControl(new Image().setImage(new URL("/images/subout.png", import.meta.url)).setStyle("width:20px;"));
        c7.addControl(new Image().setImage(new URL("/images/lineups.png", import.meta.url)).setStyle("width:20px;"));
        c8.addControl(new Image().setImage(new URL("/images/bench.png", import.meta.url)).setStyle("width:20px;"));
        c9.addControl(new Image().setImage(new URL("/images/time_clock.png", import.meta.url)).setStyle("width:20px;"));

        this.panel.addControl("</table>");
    }
};

export class PlayerClubHistoryItemRender extends DataGridTileItemRender {

    init() {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");
        this.panel.setStyle("margin-top:0px;");

        this.addControl(this.panel);
    }

    setData(data) {

        for (var i = 0; i < data.data.careerclubs.length; i++) {

            this.card = new Panel();
            this.card.addCssClass("MatchStatsContainerCard");

            //this.panel.setStyle("margin-top:3px;");
            this.card.setStyle("padding-bottom:4px;padding-top:0px;margin-top:0px;margin-bottom:4px;");

            var container = new Panel();
            container.setStyle("align-items: center;justify-content: space-between;display:flex;width:100%;height:50px;");

            this.createClubCareerItem(data.data.careerclubs[i],container);
            this.card.addControl(container);

            this.panel.addControl(this.card);
        }

    }

    createClubCareerItem(data,target) {

        var left = new Panel();
        left.setStyle("height:40px;display:flex;align-items: center")
        var imageBox = new Image();
        imageBox.addCssClass("listViewCountryFlagItem");

        if (AppInstance.getConfig().enabledFeatures.teamImages) {
            imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/" + data.id + "_small_rnd.png", 40, 40);
            left.addControl(imageBox);
        }

        var team = new Label();
        team.setStyle("margin-inline-start:8px;")
        team.setData(data.name);

        left.addControl(team);
        target.addControl(left);

        var period = new Panel();
        period.setStyle("float:right");

        period.addControl(new Label().setData(this.translateDateString(data.from) + (data.to ? " - "+this.translateDateString(data.to) : "")));

        target.addControl(period);
    }

    translateDateString(date){
        if (!date){
            return "";
        }

        try {
            var split = date.split(" ");
            var monthName = split[0];
            var year = split[1];
            var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            var d = new Date();
            d.setMonth(monthNames.indexOf(monthName));
            d.setFullYear(year);

            var formatter = Intl.DateTimeFormat(FootballManiaConfig.userLocale, {
                year: 'numeric', month: 'short'
            })

            return formatter.format(d);
        }
        catch (e) {
            return date;
        }

    }

};

export class PlayerInjuryLegendaItemRender extends DataGridTileItemRender {
    updateDisplay() {
        this.parent.addCssClass("playerStatsStickyLegenda")
    }

    setData(data) {

        this.panel = new Panel();
        this.addControl(this.panel);

        this.data = data.data
        this.panel.addCssClass("playerStatsLegenda");

        this.panel.addCssClass("playerStatsLegenda");

        this.panel.addControl("<table class='tableInjuries'>");

        var row = new TableRow();
        var c1 = new TableHeaderCol();
        c1.setStyle("width:50%;")
        var c2 = new TableHeaderCol();
        c2.setStyle("width:100px;");
        var c3 = new TableHeaderCol();
        c3.setStyle("width:100px;");
        var c4 = new TableHeaderCol();
        c4.setStyle("text-align:end;margin-inline-end:10px;")

        row.addControl(c1);
        row.addControl(c2);
        row.addControl(c3);
        row.addControl(c4);

        c1.addControl(Localizer.getString("injury_type"));
        c2.addControl(Localizer.getString("period_from"));
        c3.addControl(Localizer.getString("period_to"));
        c4.addControl(Localizer.getString("days"));

        this.panel.addControl(row);
        this.panel.addControl("</table>");
    }
};

export class PlayerInjuryItemRender extends DataGridTileItemRender {

    init(hideBell) {
        super.init();

        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");
        this.panel.setStyle("margin-top:0px;font-weight:bold;");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);
        //this.panel.setStyle("margin-top:3px;");
        this.card.setStyle("justify-content: space-around;flex-direction:column;display:flex;padding-bottom:4px;padding-top:0px;margin-top:0px;");

        this.addControl(this.panel);
    }

    setData(data) {

        this.card.clear();
        this.card.addControl("<table class='tableInjuries' style='width:100%;'>");
        for (var i = 0; i < data.data.sidelined.length; i++) {
            this.createSidelined(data.data.sidelined[i]);
        }
        this.card.addControl("</table>");
    }

    createSidelined(data) {
        var row = new TableRow();

        row.setStyle("width:100%;border-bottom: 1px solid var(--match-fixture-divider-color);")
        var c1 = new TableCol();
        c1.setStyle("width:50%;")
        var c2 = new TableCol();
        c2.setStyle("width:100px;");
        var c3 = new TableCol();
        c3.setStyle("width:100px;");
        var c4 = new TableCol();
        //c4.setStyle("margin-inline-end:10px;")

        row.addControl(c1);
        row.addControl(c2);
        row.addControl(c3);
        row.addControl(c4);

        var injuryType = data.injurytype.replaceAll(" ", "_").replaceAll("/", "_").toLowerCase();

        if (injuryType == "suspended") {
            injuryType = Localizer.getString("injury_suspended");
        } else if (injuryType == "groin_pelvis_injury") {
            injuryType = Localizer.getString("groin_pelvis_injury");
        } else {
            if (Localizer.hasString(injuryType)) {
                injuryType = Localizer.getString(injuryType);
            } else {
                console.log(injuryType);
                injuryType = data.injurytype;
            }
        }

        c1.addControl(this.camelize(injuryType));

        var onGoing = false;
        if (data.date_start != "" && data.date_start != null) {
            c2.addControl(this.getNiceDate(data.date_start));
            onGoing = true;
        }

        if (data.date_end != "" && data.date_end != null) {
            c3.addControl(this.getNiceDate(data.date_end));
            onGoing = false;
        }

        if (data.date_start != "" && data.date_end != "" && data.date_start != null && data.date_end != null) {

            var dayLength = this.getDayLength(data.date_start, data.date_end);
            // const rtf = new Intl.RelativeTimeFormat(FootballManiaConfig.userLocale, { numeric: "always", // other values: "auto"
            //     style: "long" });
            // var parts = rtf.formatToParts(dayLength, "day");
            // //c4.addControl(parts[1].value + " "+parts[2].value);
            c4.addControl(dayLength + "");
        } else if (onGoing) {
            var date = new Date();
            var split = data.date_start.split("/");
            date.setYear("20" + split[2]);
            date.setMonth(split[1] - 1);
            date.setDate(split[0]);
            var diffMs = Math.abs(new Date().getTime() - date.getTime());
            var dayLength = Math.ceil(diffMs / (1000 * 60 * 60 * 24));
            c4.addControl(dayLength + "");
        }
        this.card.addControl(row);
    }

    camelize(str) {
        let words = str.split(' ');
        let capitalizedWords = words.map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        return capitalizedWords.join(' ');
    }

    getNiceDate(a) {
        var date = new DateTimecode();
        var split = a.split("/");
        date.setYear(split[2]);
        date.setMonth(split[1] - 1);
        date.setDate(split[0]);
        return date.toLocaleDateStringAlt();
    }

    getDayLength(a, b) {
        var date = new DateTimecode();
        var split = a.split("/");
        date.setYear(split[2]);
        date.setMonth(split[1] - 1);
        date.setDate(split[0]);

        var date2 = new DateTimecode();
        var split2 = b.split("/");
        date2.setYear(split2[2]);
        date2.setMonth(split2[1] - 1);
        date2.setDate(split2[0]);
        const diffMs = Math.abs(date2.getTimecode() - date.getTimecode());
        return Math.ceil(diffMs / (1000 * 60 * 60 * 24));
    }
};

export class PlayerStatsItemRender extends DataGridTileItemRender {

    init() {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");
        this.panel.setStyle("margin-top:0px;");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);
        //this.panel.setStyle("margin-top:3px;");
        this.card.setStyle("padding-bottom:4px;padding-top:0px;margin-top:0px;");

        this.addControl(this.panel);
    }

    setData(data) {
        this.createStats(data);
    }

    createStats(data) {
        this.data = data;

        this.card.addControl("<table class='playerStatsTable'>");

        for (var x = 0; x < data.data.allStatistics.length; x++) {

            //console.log("stats",data.data.allStatistics[x]);
            var stats = data.data.allStatistics[x];

            var row = new TableRow();
            row.setStyle("")
            this.card.addControl(row);
            var c1 = new TableCol();
            c1.setStyle("text-align: start;");
            row.addControl(c1);
            var c2 = new TableCol();
            c2.addCssClass("playerStatsItem");
            row.addControl(c2);
            var c3 = new TableCol();
            c3.addCssClass("playerStatsItem");
            row.addControl(c3);
            var c4 = new TableCol();
            c4.addCssClass("playerStatsItem");
            row.addControl(c4);
            var c5 = new TableCol();
            c5.addCssClass("playerStatsItem");
            row.addControl(c5);
            var c6 = new TableCol();
            c6.addCssClass("playerStatsItem");
            row.addControl(c6);
            var c7 = new TableCol();
            c7.addCssClass("playerStatsItem");
            row.addControl(c7);
            var c8 = new TableCol();
            c8.addCssClass("playerStatsItem");
            row.addControl(c8);
            var c9 = new TableCol();
            c9.addCssClass("playerStatsItemMinutes");
            row.addControl(c9);
            var c10 = new TableCol();
            c10.addCssClass("playerStatsItemMinutes");
            //row.addControl(c10);

            c1.addControl(stats.season);
            c2.addControl(stats.goals);
            c3.addControl(stats.yellowcards);

            if (stats.yellowred != "" && stats.redcards != "") {
                c4.addControl(parseInt(stats.yellowred) + parseInt(stats.redcards) + "");
            }
            c5.addControl(stats.substitute_in);
            c6.addControl(stats.substitute_out);
            c7.addControl(stats.lineups);
            c8.addControl(stats.substitutes_on_bench);
            c9.addControl(stats.minutes);

            var row2 = new TableRow();
            row2.addCssClass("tablePlayerStatsDivider");
            this.card.addControl(row2);
            var c11 = new TableCol();

            c11.setAttribute("colspan", 9)
            ///c1.addCssClass("flexHorizontal");
            row2.addControl(c11);
            var con = new Panel();
            con.addCssClass("tablePlayerStatsItemContainer");
            c11.addControl(con);

            if (AppInstance.getConfig().enabledFeatures.teamImages) {
                var image = new Image();
                image.setStyle("width:40px;height:40px;")
                image.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/" + stats.teamid + "_small_rnd.png");
                con.addControl(image);
            }

            var subCon = new Panel();
            subCon.addCssClass("flexVerticalAlign");
            subCon.setStyle("align-items: flex-start;height: 35px;");

            stats.league = RemoveReservedIfNeeded(stats.league);
            subCon.addControl("<p style='margin-inline-start: 10px;'>" + stats.name + "</p>");
            subCon.addControl("<p style='margin-inline-start: 10px;'>" + stats.league + "</p>");
            con.addControl(subCon);
            (function (stats) {
                con.bind("click", function () {
                    Router.showTeamScreen(stats.teamid);
                });
            })(stats);
        }
        this.card.addControl("</table>");
    }
};

export class PlayerTransfersItemRender extends DataGridTileItemRender {

    init() {
        super.init();

        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");
        this.panel.setStyle("margin-top:0px;font-weight:bold;");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);
        //this.panel.setStyle("margin-top:3px;");
        this.card.setStyle("justify-content: space-around;flex-direction:column;display:flex;padding-bottom:4px;padding-top:0px;margin-top:0px;");

        this.addControl(this.panel);
    }

    setData(data) {

        this.card.clear();
        this.card.addControl("<table class='tableTransfers' style='width:100%;'>");
        for (var i = 0; i < data.data.transfers.length; i++) {
            this.createTransfers(data.data.transfers[i]);
        }
        this.card.addControl("</table>");
    }

    createTransfers(data) {
        var row = new TableRow();

        row.setStyle("height:50px;width:100%;border-bottom: 1px solid var(--match-fixture-divider-color);")
        var c1 = new TableCol();
        c1.setStyle("width:100px;")
        var c2 = new TableCol();
        c2.setStyle("width:100px;text-align:end;");
        // var c3 = new TableCol();
        // c3.setStyle("width:100px;");
        var c4 = new TableCol();
        ///c4.setStyle("width:40px;");
        var c5 = new TableCol();
        c5.setStyle("width:100px;");
        //c4.setStyle("margin-inline-end:10px;")
        var c6 = new TableCol();
        c6.setStyle("width:100px;");
        var c7 = new TableCol();
        c7.setStyle("width:80px;");


        row.addControl(c1);
        row.addControl(c2);
        //row.addControl(c3);
        row.addControl(c4);
        row.addControl(c5);
        //row.addControl(c6);
        row.addControl(c7);

        const date = this.getNiceDate(data.date);
        c1.addControl(date);

        // var image = new Image();
        // image.setStyle("width:40px;height:40px;")
        // image.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/teams_gs/" + data.from_id + "_rnd.png");
        c2.addControl(data.from);
        // c3.addControl(image);

        var arrow = new Panel();
        arrow.addCssClass("icon-arrow-right");
        var rotation = "";
        if (FootballManiaConfig.isRtl) {
            rotation = "transform: rotateY(180deg);";
        }
        arrow.setStyle("margin-inline-start: 10px;margin-inline-end: 10px;width:30px;height:30px;" + rotation);
        c4.addControl(arrow);

        // var image = new Image();
        // image.setStyle("width:40px;height:40px;")
        // image.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/teams_gs/" + data.to_id + "_rnd.png");
        c5.addControl(data.to);
        // c6.addControl(image);

        //console.log(data);
        if (data.transferType == "Loan") {
            c7.addControl(Localizer.getString("loan"));
        } else if (data.transferType == "Free") {
            c7.addControl(Localizer.getString("free"));
        } else {
            c7.addControl(data.transferType);
        }

        this.card.addControl(row);
    }

    getNiceDate(a) {
        var date = new DateTimecode();
        var split = a.split("/");
        date.setYear(split[2]);
        date.setMonth(split[1] - 1);
        date.setDate(split[0]);


        return Intl.DateTimeFormat(FootballManiaConfig.userLocale, {
            year: '2-digit', month: '2-digit', day: '2-digit'
        }).format(date.getDateObject());
    }
};

