import {Panel} from "../bananaframework/src/controls/Panel.js";
import {UrlManager} from "../bananaframework/src/util/UrlManager.js";

import {BasePage} from "./basepage.js";

import {Router} from "../util/router.js";
import {Localizer} from "../util/localizer.js";
import {Processors} from "../util/processors.js";

import {ErrorMessage,NoDataMessage2,NoDataMessage} from "../components/errormessage.js";

import {AnimatorLeague} from "./animators/animatorleague.js";
import {DataProvider} from "../data/dataprovider.js";
import {StateManager} from "../data/stateManager.js";

import {TabView,TabViewTab} from "../components/tabview.js";
import {LazyTileGridRenderer} from "../components/lazytilegridrenderer.js";

import {TopVoterItemRender} from "../components/topvoters/listrenders.js";
import {ManiaCache} from "../util/cache.js";
import {Uservotesmodal} from "../components/topvoters/uservotesmodal.js";

export class TopVoters extends BasePage{
    init () {
        this.pageName = "TopVoters";
        this.version = 1;
        super.init();
        this.setStyle("height:100%");

        this.addCssClass("TopVotersPage");

        this.isNavigatedFromOutside = Router.navigationIsFromOutside();
        Router.updateCurrentReferalData();

        if (this.isNavigatedFromOutside){
            StateManager.flushPageState(this.pageName);
        }

        console.log("OPENING PAGE "+this.pageName+" VERSION "+this.version+" is from outside "+this.isNavigatedFromOutside)

        this.coordinatoranimator = new AnimatorLeague();

        Router.disallowBackButtonQuitApp();

        this.createPageLayout();

        if (AppInstance.workersInitialized) {
            this.wrapper1 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper1.addEventListener("message", this.wrapper1);
            this.wrapper2 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper2.addEventListener("message", this.wrapper2);
            this.wrapper3 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper3.addEventListener("message", this.wrapper3);
        }
    }

    findListRenderById (id){
        for (var i = 0; i < this.tabview.tabContents.controls.length; i++) {
            try {
                if (this.tabview.tabContents.controls[i].controls[1].controls[0].controls[0].getId() == id) {
                    return this.tabview.tabContents.controls[i].controls[1].controls[0].controls[0];
                }
            }
            catch (e) {
                console.error(e);
            }
        }
    }

    getActiveListRender (){
        return this.tabview.tabContents.controls[this.tabview.currentActiveTab].controls[1].controls[0].controls[0];
    }

    onWorkerMessageReceived (e){
        if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

        var listRender = this.findListRenderById(e.data[0]);

        if (!listRender){
            console.error("no listrender found for ",e.data[0]);
            return;
        }

        listRender.parent.parent.hideLoader();
        listRender.setDataSource(e.data[1]);
    }

    storeState (){

        var d = {
            "tabIndex":this.tabview.currentActiveTab,
            "scrollPosition" : this.getActiveListRender().getScroll(),
            "headerCollapsed" : this.coordinatoranimator.isCollapsed
        }

        if (this.userVotesModal) {
            d.userVoteModalOpen = this.userVotesModal.urlIdentifier;
        }
        
        StateManager.savePageState(this.pageName,d);
    }

    unload (){
        this.unloaded = true;
        $.xhrPool.abortAll();
        this.coordinatoranimator.unload();
        clearTimeout(this.topValueLoadHandler);
        if (AppInstance.workersInitialized) {
            AppInstance.workerHelper1.removeEventListener("message", this.wrapper1);
        }
    }

    onVisibilityChange (){
        //page is becoming visible after hide
        if (this.previousDocumentHidden = true && !document.hidden){
            //this.autoInterval(0);
            console.warn("no autorefresh implementation")
        }
        this.previousDocumentHidden = document.hidden;
    }

    createPageLayout (){
        var row2 = new Panel();
        this.addControl(row2);
        this.contentContainer = row2;
    }

    createComponents (){
        this.getPageTemplate().showTitle();
    }

    onWindowResize (){
        super.onWindowResize();
        if (this.tabview) {
            this.tabview.onWindowResize();
        }
        if (this.coordinatoranimator) {
            this.coordinatoranimator.onWindowResize();
        }

        if (this.allTimeTopVoterListrender) {
            this.allTimeTopVoterListrender.onWindowResize();
        }
    }

    updateDisplay (){

        this.runApp();
    }

    runApp (){

        console.log("run app")

        this.showLoader();

        if (!AppInstance.setupAlreadyDone) {
            this.showLoader();
        }

        AppInstance.setup(this.getProxy(function(result){

            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

            if (!result){
                this.hideLoader();
                console.error("error app setup");
                return this.showError("",this.getProxy(function(){
                    this.runApp();
                }))
            }

            this.hideLoader();
            this.createTitle();
            this.createTabs();

            this.getPageTemplate().createMenuTopVoters();

            this.tabview.bind("tabChanged",this.getProxy(function(){
                this.storeState();
                this.coordinatoranimator.tabChange();
            }));

            var previousState = StateManager.getCurrentPageState(this.pageName);
            if (previousState) {
                this.tabview.currentActiveTab = previousState.tabIndex;
                this.coordinatoranimator.isCollapsed = previousState.headerCollapsed;
            }
            this.contentContainer.invalidateDisplay();

            //TODO: find out why we cannot set scroll before invalidateDisplay
            if (previousState) {
                this.getActiveListRender().scrollTo(previousState.scrollPosition || 0);
            }

            this.bind("onModalOpen", (e, modal) => {
                console.log("onModalOpen", modal);
                this.userVotesModal = modal;
                this.storeState();
            });
            this.bind("onModalClose", () => {
                console.log("onModalClose");
                this.userVotesModal = null;
                this.storeState();
            });
            if (previousState && previousState.userVoteModalOpen) {
                setTimeout(() => {
                    var urlIdentifier = previousState.userVoteModalOpen;
                    var modal = new Uservotesmodal(urlIdentifier);
                    this.addControl(modal, true);
                });
            }

            this.coordinatoranimator.prepare();
        }))
    }

    createTitle (data){
        this.getPageTemplate().topTitleContainer.setVisible(false);
    }

    createTabs (){
        var tabview = new TabView();
        this.tabview =tabview;
        this.contentContainer.addControl(tabview);

        var tabs = [];

        var tab1;
        var tab2;

        tab1 = new TabViewTab();
        tab1.setStyle("width:100%;height:100%;position:relative;overflow-y: scroll;transform: translate(0,0);");
        tab1.title = Localizer.getString("all_time");
        tabs.push(tab1);

        tab2 = new TabViewTab();
        tab2.setStyle("width:100%;height:100%;position:relative;overflow-y: scroll;transform: translate(0,0);");
        tab2.title = Localizer.getString("last_month");
        tabs.push(tab2);

        var listRender1 = this.createTopVotersControls(tab1,0);
        var listRender2 = this.createTopVotersControls(tab2,1);

        this.coordinatoranimator.addScrollerContainer(tab1);
        this.coordinatoranimator.addScrollerContainer(tab2);

        this.coordinatoranimator.addCollapser(this.getPageTemplate().topBar);
        this.coordinatoranimator.addTabView(tabview);

        tabview.setTabs(tabs);

        tabview.bind("onReady",this.getProxy(function(){
            this.coordinatoranimator.run();
        }));

        this.loadTopVoters("alltime",listRender1,false, false, false, false);
        this.loadTopVoters("monthly",listRender2,false, false, false, false);

        tab1.showLoader();

        this.bind("stickyBannerLoadFail", () => {
            console.error("sticky banner load fail -> set height to 100%");
            if (tab1) tab1.setCss({'height': '100%'});
            if (tab2) tab2.setCss({'height': '100%'});
        });
        //triggered from page template
        this.bind("stickyBannerLoadSuccess", () => {
            console.log("sticky banner load success -> set height to 100% - 50px");
            if (tab1) tab1.setCss({'height': 'calc(100% - 50px)'});
            if (tab2) tab2.setCss({'height': 'calc(100% - 50px)'});
        });
        this.getPageTemplate().loadStickyBannerAd();
    }

    createTopVotersControls (tab,index){
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");
        var top = new Panel();
        top.setStyle("width:100%;height:50px;background-color:red;");

        var listRender = new LazyTileGridRenderer();
        listRender.containerTarget = tab;
        listRender.enableFastScroll = true;

        listRender.setItemRender(function(data){
            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new TopVoterItemRender(),
                };
            }
        })

        listRender.emptyTemplate = function(cb){
            return new NoDataMessage({
                cb : function(){
                    history.back();
                },
                message : Localizer.getString("no_data")
            });
        }

        listRender.errorTemplate = function(cb){
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function(){
            return new ErrorMessage();
        }

        listRender.bind('createdList',this.getProxy(function(){
            this.coordinatoranimator.recalculateTab(index);
        }))

        var storeHandler = null;
        listRender.bind("scroll",(e,scroll)=> {
            clearTimeout(storeHandler);
            storeHandler = setTimeout(()=> {
                this.storeState();
            },200)
        });

        listRender.setPlaceHolderWidth("100%;");

        var previousState = StateManager.getCurrentPageState(this.pageName
        );
        if (previousState) {
            listRender.scrollTo(previousState.allTimeTopVoterScrollPosition || 0);
        }

        container.addControl(listRender);

        tab.addControl(container);

        return listRender;
    }

    loadTopVoters (period,listRenderTarget,ignoreCache, dontShowLoader, isFromAutoRefresh, ignoreMemoryCache,forceWorkers) {

        if (period == "alltime") {
            var tabIndex = 0;
            var cacheKey = "allTimeTopVoters";
        }
        else{
            tabIndex = 1;
            cacheKey = "monthlyTopVoters";
        }
        
        var timeout = 0;
        if (this.tabview.currentActiveTab != tabIndex) {
            forceWorkers = true;
            timeout = 300;
        }
        var extraTimeout = 0;
        if (!forceWorkers && !ignoreMemoryCache && ManiaCache.getMemoryCache(cacheKey)) {
            if (this.tabview.currentActiveTab != tabIndex) {
                if (!dontShowLoader) this.tabview.tabs[tabIndex].showLoader();
            }
            setTimeout(() => {
                if (this.unloaded) {
                    return console.warn("page is unloaded abort further actions");
                }

                //only set memory cache when we are on the live tab
                if (this.tabview.currentActiveTab == tabIndex) {
                    console.log("use mem cache");
                    listRenderTarget.setDataSource(ManiaCache.getMemoryCache(cacheKey));
                    if (!dontShowLoader) this.tabview.tabs[tabIndex].hideLoader();
                    console.log("use mem cache complete");
                    extraTimeout = 100;
                }
                return this.autoRefreshAllTimeTopVoters(100 + extraTimeout, true);
            }, timeout);
            return;
        }

        if (!dontShowLoader) this.tabview.tabs[tabIndex].showLoader();
        setTimeout(() => {
            DataProvider.getTopVoters(response => {
                if (this.unloaded) {
                    return console.warn("page is unloaded abort further actions");
                }
                ;
                if (!response.isOk()) {

                    this.tabview.tabs[tabIndex].hideLoader();
                    var str = Localizer.getString("nointernet") + "<br>" + Localizer.getString("taptotryagain");
                    listRenderTarget.showError(str, () => {
                        this.loadTopVoters(period,listRenderTarget,ignoreCache, dontShowLoader, isFromAutoRefresh, ignoreMemoryCache,forceWorkers);
                    });
                    return;
                }

                console.log("get top voters ",response.getData());
                var options = {
                    methodType: 13,
                    responseData: response.getData(),
                    listRenderId: listRenderTarget.getId()
                };
                if (!forceWorkers && !FootballManiaConfig.useWebWorkers) {
                    var list = Processors.prepareTopVotersData(options);
                    var data = {data:[listRenderTarget.getId(),list]};
                    this.onWorkerMessageReceived(data);
                } else {
                    AppInstance.workerHelper2.postMessage(options);
                }
            }, ignoreCache,period);
        }, timeout);
    }
};