import {CustomListControl} from "../bananaframework/src/controls/listcontrols/CustomListControl.js";
import {
    DataGridTileItemRender
} from "../bananaframework/src/controls/listcontrols/datagrids/listrenders/DataGridTileItemRender.js";
import {Panel} from "../bananaframework/src/controls/Panel.js";

export class SimpleTileGridRenderer extends CustomListControl {

    init() {
        super.init();
        this.addCssClass("BDataGridTileListRender")
        this.defaultContentItemRender = DataGridTileItemRender;
    }

    createComponents() {
        super.createComponents();

        //when we know the tabview we can know when user swipes and apply data when user is finished swiping
        if (this.tabview) {
            this.swipeStartMethod = (e) => {
                this.isSwiping = true;
            };
            //
            this.swipeEndMethod = (e) => {
                this.isSwiping = false;
                if (this.callSetDataSourceLater) {
                    var ds = this.callSetDataSourceLater;
                    this.callSetDataSourceLater = null;
                    this.setDataSource(ds);
                }
            };

            //prevent the data to load when touching. usually when scrolling.
            //it creates an annoying flicker. on touch up we apply the data
            $(this.tabview).on('swipeStart', this.swipeStartMethod);
            $(this.tabview).on('swipeEnd ', this.swipeEndMethod);
        } else {
            console.warn("no tabview found, so no swipe events can be detected. please add it for better user experience")
        }
    }

    unload() {
        var scrollEl = jQuery("#" + this.containerTarget.getClientId());

        if (this.tabview) {
            $(this.tabview).off('swipeStart', this.swipeStartMethod);
            $(this.tabview).off('swipeEnd ', this.swipeEndMethod);
        }
        scrollEl.off("scroll", this.scrollMethod); //what if others listen to this event
        this.indexTilePlaceHolderMap = null;
        this.datasourceByType = null;
    }

    setDataSource(ds, allowFlicker) {
        if (this.isSwiping) {
            console.warn("user is swiping, so we dont apply the data now, but later when user is finished swiping");
            this.callSetDataSourceLater = ds;
            return;
        }

        if (!this.datasource) {
            this.datasource = ds; //we dont need to clone this, cause do we change and save the datasource?
            this.clear();
            //this.createItems();
            this.createControls();
            this.invalidateDisplay();
            //this.triggerEvent('onSetDataSource');
            return this;
        } else {
            this.datasource = ds;
            var scroll = this.getScroll();
            this.createItems(allowFlicker);
            this.scrollTo(scroll);
        }
    }

    getDefaultItemRender() {
        return this.defaultContentItemRender;
    }

    setItemRender(render) {
        this.defaultContentItemRender = render;
        this.triggerEvent('itemRenderChanged');
    }

    createControls() {
        super.createControls();

        this.mainContainer = new Panel();
        //this.mainContainer.addCssClass("listViewMainContainer");
        //this.mainContainer.addCssClass("listViewMainContainer")
        if (this.activateScrolling) {
            this.mainContainer.addCssClass("scrollerNoOverscroll");
            this.mainContainer.setStyle("overflow-y:scroll;position:absolute;top:0px;left:0px;right:0px;bottom:0px;");
        }

        this.addControl(this.mainContainer);

    }

    onWindowResize() {

        console.error("onWindowResize not implemented");
        // setTimeout(()=>{
        //     this.scrollTo(9999999);
        // },2000)
        return;
        var delay = 20;
        if (!this.windowResizeHandler) delay = 0;

        clearTimeout(this.windowResizeHandler);

        this.windowResizeHandler = setTimeout(() => {
            if (!this.mainContainer) return;
            var scroll = this.getScroll();
            this.createItems(false);
            this.scrollTo(scroll);
        }, delay);
    }

    updateDisplay() {
        if (!this.datasource) return;
        this.createItems(true);
    }

    scrollTo(y) {
        if (this.containerTarget.getParent()) {
            //console.log("really scroll to man",y,this.containerTarget.getClientId());
            this.scrollLater = y;
            return this.getScrollElement().scrollTop(y);
        }
        this.scrollLater = y;
    }

    getScroll() {
        var scroll = this.getScrollElement();
        if (scroll) return scroll.scrollTop();
        else {
            console.error("no scroll element return 0");
            return 0;
        }

    }

    getScrollElement() {
        if (this.activateScrolling) {
            if (!this.mainContainer) return null;
            return jQuery("#" + this.mainContainer.getClientId());
        }
        return jQuery("#" + this.containerTarget.getClientId());
    }

    itemHeight =  60;
    overlapItems = 3;
    minItemsRenderAtOnce = 2;
    scrollMethod=  null;

    autoMove() {

        var requireRerender;
        if (!this.datasource.length) return;
        this.indexTilePlaceHolderMap = [];
        this.indexRenderedItemRenderMap = [];
        var scrollTop;

        for (var i = 0; i < this.datasource.length; i++) {
            if (!this.indexTilePlaceHolderMap[i]) {
                this.createDivPlaceHolder(i, false);
                this.createItemRenderByIndex(i, this.datasource[i], false);
                requireRerender = true;
            }
        }

        var scrollEl = this.getScrollElement();

        this.scrollMethod = () => {
            var ticking = false;
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    scrollTop = scrollEl.scrollTop();
                    this.triggerEvent("scroll", scrollTop);
                    ticking = false;
                });
            }
            ticking = true;
        };

        if (this.scrollMethod) {
            scrollEl.off("scroll", this.scrollMethod);
        }
        //scrollEl.off("scroll");
        scrollEl.scroll(this.scrollMethod);
    }

    datasourceByType =  null;
    renderListContainers = null;
 //strange when defined as empty array, the variable is shared across multiple instances. why?
    datasourceTypeCount = 0;
    previousWasEmptyDataSource =  false;

    createItems(allowFlickerBetweenRender) {
        if (!this.datasource || !this.datasource.length) {

            this.mainContainer.clear();
            if (this.emptyTemplate) {
                var control = this.emptyTemplate();
                // this.mainContainer.setCss({'height':"100%"})
                this.mainContainer.addControl(control);
                this.mainContainer.invalidateDisplay();
                this.previousWasEmptyDataSource = true;
            }
            return;
        }

        this.calculatedScreenHeight = 0;
        this.calculatedItemsHeight = 0;


        if (this.previousWasEmptyDataSource) {
            this.mainContainer.clear();
            this.previousWasEmptyDataSource = false;
        }

        if (!this.renderListContainers) this.renderListContainers = [];

        var renderListContainer = new Panel();
        renderListContainer.addCssClass("maincontainer2");

        ///we do some optimization here to prevent flickering whne new list items
        ///are created. we create a container which we render hidden, we wait
        //for x ms and then simultanously hide and show the old and new one.
        if (this.renderListContainers.length) {
            //console.log("we have a previous render",this.renderListContainers.length);
            //renderListContainer.setVisible(false);
            renderListContainer.setCss({"display": "none"});
        }
        this.renderListContainers.push(renderListContainer);

        var dimensions = this.getDimensions();
        this.calculatedScreenHeight = dimensions.height;

        // console.log("set height of container ",this.calculatedScreenHeight)
        ///TODO: this might have performance issues. better to add dummy item at the end
        //this.mainContainer.setCss({'height':this.calculatedScreenHeight+"px"});
        this.mainContainer.addControl(renderListContainer, true);


        // console.log("max items to render ",this.maxItemsToRender)
        this.autoMove();
        renderListContainer.invalidateDisplay();
        //renderListContainer.setVisible("false");

        var delay = 300;
        if (allowFlickerBetweenRender) delay = 0;
        //console.log("renderlist container length",this.renderListContainers.length);
        clearTimeout(this.rerenderTimeoutHandler);
        if (this.renderListContainers.length > 1) {
            this.rerenderTimeoutHandler = setTimeout(() => {
                if (this.renderListContainers.length > 1) {
                    this.renderListContainers[this.renderListContainers.length - 1].setCss({"display": ""});

                    for (var i = 0; i < this.renderListContainers.length - 1; i++) {
                        //console.log("remove list container ",i)
                        this.getPage().removeControl(this.renderListContainers[i]);
                    }
                    //this.renderListContainers[1].setCss({"display": ""});
                    this.renderListContainers = [];
                    this.renderListContainers.push(renderListContainer);
                }
                this.triggerEvent("createdList")
                //this.scrollTo(this.scrollLater);
            }, delay)
        } else {
            this.triggerEvent("createdList")
            //console.log("Scrol later",this.scrollLater);
            this.scrollTo(this.scrollLater);
        }


    }

    createDivPlaceHolder(index, instantRender) {
        //console.log("create div placeholder ",index,this.renderListContainers[this.renderListContainers.length-1].getClientId());
        var tileplaceholder = new Panel();
        tileplaceholder.addCssClass("BDataGridTilePlaceHolder");

        if (this.placeHolderWidth) {
            tileplaceholder.setCss({width: this.placeHolderWidth});
        }

        this.renderListContainers[this.renderListContainers.length - 1].addControl(tileplaceholder, instantRender);

        if (!this.indexTilePlaceHolderMap) this.indexTilePlaceHolderMap = [];
        this.indexTilePlaceHolderMap[index] = tileplaceholder;
    }

    setPlaceHolderWidth(width) {
        this.placeHolderWidth = width;
    }

    createItemRenderByIndex(index, data, instantRerender) {
        //console.log("create item render ",index)
        var itemRenderFactory = this.defaultContentItemRender;

        var itemRender = itemRenderFactory(data).render;
        //console.log(itemRender);
        //data.index = index;
        this.indexRenderedItemRenderMap[index] = itemRender;
        //itemRender.setData(data,true); //no instant render needed
        itemRender.setData(data, false); //no instant render needed

        itemRender.setListRender(this);
        this.indexTilePlaceHolderMap[index].clear();
        this.indexTilePlaceHolderMap[index].addControl(itemRender, instantRerender);
    }

    onRowMouseOver(e) {
    }

    onRowMouseOut(e) {
    }

    onRowMouseClick(e) {
    }

    selectIndex(index) {
    }

    deSelectIndex(index) {
    }

};

