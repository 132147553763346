import {DataControl} from "./DataControl.js";
import {DomHelper} from "../util/DomHelper.js";

export class Label extends DataControl {
    init() {
        super.init();
        this.addCssClass('BLabel');
        this.tagName = 'label';
    }

    setData(data, ignoreEvent, ignoreDom) {
        super.setData(data, true, ignoreDom);

        this.addControl(data);
        return this;
    }

    setTagName(tag) {
        this.tagName = tag;
        return this;
    }

    getTagName() {
        return this.tagName;
    }
};

Label.prototype.setForControl = function (control) {
    this.setAttribute('for', control.clientId);
}

Label.prototype.setDomData = function (data) {
    if (this.isRendered) {
        if (data == undefined) {
            data = '';
        }

        DomHelper.setTextData(data, this)
    }
};