export const ThemeManager = {

    applyTheme: function (theme) {
        document.documentElement.setAttribute('data-theme', theme)
        localStorage.setItem("mtheme", theme);
    },

    autoApplyTheme: function () {

        console.log("auto apply theme");
        if (FootballManiaConfig.useAndroidThemeAndSizes && window.JSAndroidBridge){
            let androidTheme = window.JSAndroidBridge.getTheme();
            var theme;
            if (androidTheme == 3){
                theme = "light";
            }
            else if (androidTheme == 2) {
                theme = "dark";
            }
            else if (androidTheme == 3){
                theme = "blue";
            }
            else {
                theme = "dark";
            }
            document.documentElement.setAttribute('data-theme', theme)
        }
        else {
            let theme = localStorage.getItem("mtheme");
            //let theme = false;
            if (!theme) {
                console.log("no user defined theme found");
                if (window.matchMedia) {
                    console.log("use system prefers-color-scheme");
                    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                        theme = "dark";
                        console.log("found system prefers-color-scheme: dark -> set to ", theme);
                    }
                    else if (window.matchMedia('(prefers-color-scheme: light)').matches) {
                        theme = "dark";
                        console.log("found NO system prefers-color-scheme: -> set to ", theme);
                    }
                    else{
                        console.log("no system prefers-color-scheme found -> set to default", FootballManiaConfig.defaultTheme);
                        theme = FootballManiaConfig.defaultTheme;
                        this.applyTheme(FootballManiaConfig.defaultTheme);
                    }

                    //add a listener to change the theme if the system changes the theme
                    if (!this.mediaChangeListener) {
                        this.mediaChangeListener = (event) => {
                            console.log("changed event", event);
                            this.autoApplyTheme();
                        }
                        window.matchMedia('(prefers-color-scheme:dark)').addEventListener('change', this.mediaChangeListener);
                    }

                }
                else{
                    console.log("no system prefers-color-scheme supported -> set to default", FootballManiaConfig.defaultTheme);
                    theme = FootballManiaConfig.defaultTheme;
                    this.applyTheme(FootballManiaConfig.defaultTheme);
                }
                document.documentElement.setAttribute('data-theme', theme)
            } else {
                //console.log("auto apply theme", theme);
                document.documentElement.setAttribute('data-theme', theme)
            }
        }
    },

    getCurrentTheme: function(){
        return document.documentElement.getAttribute('data-theme');
    },

    getCurrentSize : function(){
        return document.documentElement.getAttribute('data-sizes') || "medium";
    },

    applySizes : function(size){
        document.documentElement.setAttribute('data-sizes', size)
        localStorage.setItem("msizes", size);
    },

    autoApplySize: function () {
        if (FootballManiaConfig.useAndroidThemeAndSizes && window.JSAndroidBridge){
            let androidFontSize = window.JSAndroidBridge.getFontSizes();
            //console.log("use android sizes",androidFontSize);
            var size;
            if (androidFontSize == 1){
                size = "small";
            }
            else if (androidFontSize == 2) {
                size = "medium";
            }
            else if (androidFontSize == 3) {
                size = "large";
            }
            else if (androidFontSize == 4) {
                size = "xlarge";
            }
            else{
                size = "medium";
            }
            document.documentElement.setAttribute('data-sizes', size)
        }
        else {
            let size = localStorage.getItem("msizes");
            if (!size) {
                this.applySizes(FootballManiaConfig.defaultSize);
            } else {
                document.documentElement.setAttribute('data-sizes', size)
            }
        }
    },
};

if (window != undefined){
    window.ThemeManager = ThemeManager;
}