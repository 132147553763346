import {DataProvider } from '../data/dataprovider.js';
import {MatchUtil} from "./matchutil.js";

//get info from cache
export const LiveStatsHelper = {
    teamStatCache : {},

    getTeamMatchInfo : function(teamid,cb) {

        if (this.teamStatCache[teamid]) {
            var teamStat = this.teamStatCache[teamid];
            if (teamStat.lastUpdate && teamStat.lastUpdate > new Date().getTime() - 60000) {
                //console.log("returning cached team info",teamid," - ",teamStat.match.id," - ",teamStat.lastUpdate," - ",new Date().getTime()," - ",teamStat.lastUpdate > new Date().getTime() - 60000," - ",teamStat.lastUpdate - new Date().getTime() - 60000)
                return cb(teamStat.match);
            }
        }

        setTimeout(()=> {

            this._getInfoFromLiveMatches(teamid, (match) => {

                if (match) {
                    //console.log(teamid,"found match from live ",match);
                    this.teamStatCache[teamid] = {
                        match: match,
                        lastUpdate: new Date().getTime()
                    }
                    return cb(match);
                }

                this._getInfoFromTodaysMatches(teamid, (match) => {

                    if (match) {
                        //console.log(teamid,"found match from today ",match);
                        this.teamStatCache[teamid] = {
                            match: match,
                            lastUpdate: new Date().getTime()
                        }
                        return cb(match);
                    }

                    return this._getTeamInfo(teamid, (match) => {
                        if (match) {
                            //console.log(teamid,"found match from team ",match);
                            this.teamStatCache[teamid] = {
                                match: match,
                                lastUpdate: new Date().getTime()
                            }
                            return cb(match);
                        }
                        return cb(null);
                    });
                });

            });
        },20);
    },

    _getTeamInfo : function(teamid,cb) {
        DataProvider.getTeam(teamid, (response) => {
            try {
                if (!response.isOk()) {
                    return cb(null);
                }
                var team = response.getData();
                if (!team.fixtures || !team.fixtures.length) {
                    return cb(null);
                }

                var foundMatch = null;
                for (var i = 0; i < team.fixtures.length; i++) {
                    if (MatchUtil.isFinished(team.fixtures[i]) || MatchUtil.isPostponed(team.fixtures[i])) {
                        continue;
                    }
                    foundMatch = team.fixtures[i];
                    break;
                }

                return cb(foundMatch);

            } catch (e) {
                console.warn("error getting team info ", e);
            }

            return cb(null);

        }, false, 1000*60*2,true);
    },



    _getInfoFromLiveMatchesBusy : false,
    _getInfoFromLiveMatchesQueue : [],

    _continueBusyLiveMatches : function() {

        if (this._getInfoFromLiveMatchesQueue.length) {
            //reverse loop
            for (var i = this._getInfoFromLiveMatchesQueue.length - 1; i >= 0; i--) {
                var teamid = this._getInfoFromLiveMatchesQueue[i].teamid;
                var cb = this._getInfoFromLiveMatchesQueue[i].cb;

                this._getInfoFromLiveMatches(teamid,cb,true);
            }
        }
        this._getInfoFromLiveMatchesQueue = [];
    },

    _getInfoFromLiveMatches : function(teamid,cb,ignoreBusy){

        if (!ignoreBusy && this._getInfoFromLiveMatchesBusy) {
            //console.log("get live. queueing",teamid);
            return this._getInfoFromLiveMatchesQueue.push({teamid:teamid,cb:cb});
        }
        //console.log("get today info. not live",teamid);

        if (!ignoreBusy) {
            this._getInfoFromLiveMatchesBusy = true;
        }
        DataProvider.getLiveNow((response) => {

            try {
                if (!response.isOk()) {
                    if (!ignoreBusy) {
                        this._getInfoFromLiveMatchesBusy = false;
                        this._continueBusyLiveMatches();
                    }
                    return cb(null);
                }
                var data = response.getData();
                for (var i = 0; i < data.length; i++) {
                    for (var j = 0; j < data[i].leagues.length; j++) {
                        for (var k = 0; k < data[i].leagues[j].matches.length; k++) {
                            var match = data[i].leagues[j].matches[k];
                            if (match.gs_localteamid == teamid || match.gs_visitorteamid == teamid) {
                                match.playedToday = true;
                                if (!ignoreBusy) {
                                    this._getInfoFromLiveMatchesBusy = false;
                                    this._continueBusyLiveMatches();
                                }
                                return cb(match);
                            }
                        }
                    }
                }
            } catch (e) {
                console.warn("error getting live matches ", e);
            }

            if (!ignoreBusy) {
                this._getInfoFromLiveMatchesBusy = false;
                this._continueBusyLiveMatches();
            }
            return cb(null);

        }, false, 60000);
    },

    _getInfoFromTodaysMatchesBusy : false,
    _getInfoFromTodaysMatchesQueue : [],

    _continueBusyTodaysMatches : function() {

        if (this._getInfoFromTodaysMatchesQueue.length) {
            //reverse loop
            for (var i = this._getInfoFromTodaysMatchesQueue.length - 1; i >= 0; i--) {
                var teamid = this._getInfoFromTodaysMatchesQueue[i].teamid;
                var cb = this._getInfoFromTodaysMatchesQueue[i].cb;

                this._getInfoFromTodaysMatches(teamid,cb,true);
            }
        }
        this._getInfoFromTodaysMatchesQueue = [];
    },

    _getInfoFromTodaysMatches : function(teamid,cb,ignoreBusy){

        if (!ignoreBusy && this._getInfoFromTodaysMatchesBusy) {
            //console.log("get today. queueing",teamid);
            return this._getInfoFromTodaysMatchesQueue.push({teamid:teamid,cb:cb});
        }
        //console.log("get today info. not busy",teamid);

        if (!ignoreBusy) {
            this._getInfoFromTodaysMatchesBusy = true;
        }
        DataProvider.getToday((response) => {

            try {
                if (!response.isOk()) {
                    if (!ignoreBusy) {
                        this._getInfoFromTodaysMatchesBusy = false;
                        this._continueBusyTodaysMatches();
                    }
                    return cb(null);
                }
                var data = response.getData();
                for (var i = 0; i < data.length; i++) {
                    for (var j = 0; j < data[i].leagues.length; j++) {
                        for (var k = 0; k < data[i].leagues[j].matches.length; k++) {
                            var match = data[i].leagues[j].matches[k];
                            if (match.gs_localteamid == teamid || match.gs_visitorteamid == teamid) {
                                match.playedToday = true;
                                if (!ignoreBusy) {
                                    this._getInfoFromTodaysMatchesBusy = false;
                                    this._continueBusyTodaysMatches();
                                }
                                return cb(match);
                            }
                        }
                    }
                }
            } catch (e) {
                console.warn("error getting today matches ", e);
            }

            if (!ignoreBusy) {
                this._getInfoFromTodaysMatchesBusy = false;
                this._continueBusyTodaysMatches();
            }
            return cb(null);

        }, false, 60000);
    }
}