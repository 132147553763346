import {Panel} from "../bananaframework/src/controls/Panel.js";
import {Image} from "../bananaframework/src/controls/Image.js";
import {Label} from "../bananaframework/src/controls/Label.js";
import {Control} from "../bananaframework/src/controls/Control.js";
import {DataGrid} from "../bananaframework/src/controls/listcontrols/datagrids/Datagrid.js";
import {UrlManager} from "../bananaframework/src/util/UrlManager.js";

import {BasePage} from "./basepage.js";

import {Router} from "../util/router.js";
import {Localizer} from "../util/localizer.js";
import {Processors} from "../util/processors.js";
import {ManiaCache} from "../util/cache.js";
import {Favorites} from "../util/favorites.js";
import {ManiaPreferences} from "../util/preferences.js";
import {Subscriber} from "../util/subscriber.js";

import {ErrorMessage,NoDataMessage2,NoDataMessage} from "../components/errormessage.js";

import {AnimatorAgenda} from "./animators/animatoragenda.js";
import {TeamHeader} from "../components/team/teamheader.js";
import {DataProvider} from "../data/dataprovider.js";
import {StateManager} from "../data/stateManager.js";

import {TabView2,TabViewTab2} from "../components/tabview2.js";
import {LazyTileGridRenderer} from "../components/lazytilegridrenderer.js";

import {RankingItemRender} from "../components/home/listrendershome.js";

export class Fifaranking extends BasePage{

    init () {
        this.pageName = "FifaRanking";
        this.version = 1;
        super.init();

        this.addCssClass("FifaRankingPage");
        this.listRenders = [];
        this.workers = [];
        this.allowableAutoRefreshDates = [];
        this.autoRefreshTimeoutHandlers = [];


            //here we know if we come from the same page or another.
        //if we navigate from back history or refresh we are not from the outside.
        //if we navigate to this page from another and the referaldata is passed in the url params
        //then we are from the outside.
        this.isNavigatedFromOutside = Router.navigationIsFromOutside();
        Router.updateCurrentReferalData();

        if (this.isNavigatedFromOutside){
            StateManager.flushPageState(this.pageName);
            this.requestedDateToShow = UrlManager.getModule("date");
        }

        console.log("OPENING PAGE "+this.pageName+" VERSION "+this.version+" is from outside "+this.isNavigatedFromOutside)

        this.createPageLayout();
        this.coordinatoranimator = new AnimatorAgenda();
        Router.disallowBackButtonQuitApp();

        if (AppInstance.workersInitialized) {
            this.wrapper1 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper1.addEventListener("message", this.wrapper1);

            this.wrapper2 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper2.addEventListener("message", this.wrapper2);

            this.wrapper3 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper3.addEventListener("message", this.wrapper3);
        }
    }

    findListRenderById (id){
        for (var i = 0; i < this.tabview.tabContents.controls.length; i++) {
            try {
                if (this.tabview.tabContents.controls[i].controls[1].controls[0].controls[0].controls[0].getId() == id) {
                    return this.tabview.tabContents.controls[i].controls[1].controls[0].controls[0].controls[0];
                }
            }
            catch (e) {}
        }
    }

    findIndexForRenderById (id){
        for (var i=0; i < this.tabview.tabContents.controls.length;i++){
            try {
                if (!this.tabview.tabContents.controls[i].controls[1]) {
                    continue;
                }
                if (this.tabview.tabContents.controls[i].controls[1].controls[0].controls[0].controls[0].getId() == id) {
                    return i;
                }
            }
            catch (e) {}
        }
        return -1;
    }

    onWorkerMessageReceived (e){
        console.log("receive message",e.data[0]);

        if (this.unloaded) {
            return console.warn("page is unloaded abort further actions");
        }

        var listRender = this.findListRenderById(e.data[0]);

        if (!listRender){
            console.error("no listrender found for ",e.data[0]);
            return;
        }
        listRender.parent.parent.hideLoader();
        listRender.setDataSource(e.data[1]);
    }

    storeState (){

        var activeScrollPosition = 0;
        if (this.visibleListRender){
            activeScrollPosition = this.visibleListRender.getScroll();
        }

        var d = {
            "tabIndex":this.tabview.currentActiveTab,
            "activeScrollPosition" : activeScrollPosition,
            "headerCollapsed" : this.coordinatoranimator.isCollapsed
        }
        console.log("store state",d)
        StateManager.savePageState(this.pageName,d);
    }

    unload (){
        //alert("unload")
        this.stopAutoRefreshes();
        this.unloaded = true;
        $.xhrPool.abortAll();
        this.coordinatoranimator.unload();

        for (var key in this.autoRefreshTimeoutHandlers){
            clearTimeout(this.autoRefreshTimeoutHandlers[key]);
        }

        this.autoRefreshTimeoutHandlers = null;

        this.getPageTemplate().topTitleContainer.clear();
        ManiaPreferences.unbind("showOnlyFavoritesAgendaChanged");

        if (AppInstance.workersInitialized) {
            AppInstance.workerHelper1.removeEventListener("message", this.wrapper1);
            AppInstance.workerHelper2.removeEventListener("message", this.wrapper2);
            AppInstance.workerHelper3.removeEventListener("message", this.wrapper3);
        }
    }

    createPageLayout (){
        var row1 = new Panel();
        this.addControl(row1);
        this.contentContainer = row1;
    }

    createComponents (){
        this.getPageTemplate().createMenuFifaRanking();
        //this.getPageTemplate().hideTitle();
        this.getPageTemplate().showTitle();
        this.createTitle();
    }

    createTitle (){

        var image = new Image();
        image.setStyle("width:22px;")
       // image.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/images/flags/ranking.png");
        var title = new Panel();
        this.titleControl = title;
        //this.getPageTemplate().topTitleContainer.addControl(image);
        this.getPageTemplate().topTitleContainer.addControl(title);
        this.getPageTemplate().topTitleContainer.invalidateDisplay();
        //this.getPageTemplate().topTitleContainer.setVisible(false);
    }

    onVisibilityChange (){
        //page is becoming visible after hide
        if (this.previousDocumentHidden = true && !document.hidden){
            this.stopAutoRefreshes();
            this.startAutoRefreshes(); //note that this will not instantly load new data. but that doesnt matter for this page
        }
        this.previousDocumentHidden = document.hidden;

        if (document.hidden){
            this.stopAutoRefreshes();
        }
    }

    onWindowResize (){
        if (!this.i) this.i= 1;
        //this.getPageTemplate().topBar.addControl(this.i,true);
        console.log("onwindow resize")
        super.onWindowResize();
        if (this.tabview) {
            this.tabview.onWindowResize();
        }
        if (this.coordinatoranimator) {
            this.coordinatoranimator.onWindowResize();
        }

        //TODO onwindow resize for active listrenders???
    }

    onOffline (){
        console.log("on offline")
    }

    onOnline (){
        console.log("on online")
    }

    updateDisplay (){
        this.runApp();
    }

    runApp (){
        console.log("run app")
        this.showLoader();
        AppInstance.setup(this.getProxy(function(result){

            console.log("setup complete")
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

            if (!result){
                this.hideLoader();
                console.error("error app setup");
                return this.showError("",this.getProxy(function(){
                    this.runApp();
                }))
            }

            this.titleControl.clear();
            this.titleControl.addControl(Localizer.getString("world_ranking_list"),true);

            this.hideLoader();
            this.createTabs();

            this.tabview.bind("tabChanged",this.getProxy(function(){
                console.log("tab changed  activetab",this.tabview.currentActiveTab,"active element",this.tabview.elementIndex)
                this.storeState();
                this.coordinatoranimator.tabChange();
            }));

            this.tabview.currentActiveTab = FootballManiaConfig.isRtl ? this.tabview.tabs.length-1 : 0;

            var previousState = StateManager.getCurrentPageState(this.pageName);
            if (previousState) {
                this.tabview.currentActiveTab = previousState.tabIndex;
                this.coordinatoranimator.isCollapsed = previousState.headerCollapsed;
            }

            this.contentContainer.invalidateDisplay();

            this.coordinatoranimator.prepare();
            this.coordinatoranimator.onCollapseChanged = this.getProxy(function(){
                console.log("oncollapserchanged")
                this.storeState();
            })
        }));
    }

    createTabs (){

        var tabview = new TabView2();
        this.tabview =tabview;
        this.tabview.maxVisibleTabs = 5;

        this.contentContainer.addControl(tabview);

        this.coordinatoranimator.addCollapser(this.getPageTemplate().topBar);
        this.coordinatoranimator.addTabView(tabview);

        var d = new Date();
        d.setMinutes(0);
        d.setHours(0);
        d.setSeconds(0);
        d.setMilliseconds(0);

        this.allowableAutoRefreshDates.push(new Date().getFullYear());
        var totalItems = 13;
        //var totalItems = 10;
        var startTC = d.getTime();
        var dot = new Date();
        dot.setTime(startTC);

        var tabTitles = [];
        var dateIndixes = [];
        var dataFeedExtendedVersion = []; //for far future or past data feeds we use different source

        for (var i=0; i < totalItems;i++){

            var day = Intl.DateTimeFormat(FootballManiaConfig.userLocale, {
                year: 'numeric'
            }).format(dot);

            var dateIntl = Intl.DateTimeFormat('en-UK', {
                year: 'numeric'
            }).format(dot);
            dateIndixes.push(dateIntl);

            var tab1 = {};
            tab1.title = day

            //when page is from outsided we use this property
            if (dateIntl == this.requestedDateToShow){
                tab1.initialSelected = true;
                tabview.currentActiveTab = i;
                this.storeState();
            }

            tabTitles.push(tab1);

            dot.setFullYear(dot.getFullYear()-1)
        }

        var storeHandler;
        var listRenderScrollMethod = this.getProxy(function(){
            clearTimeout(storeHandler);
            storeHandler = setTimeout(this.getProxy(function () {
                this.storeState();
            }),500)
        });

        //called when tab content is changed
        tabview.bind("newContentTabVisible",this.getProxy(function(e,tabContentElement){

            if (this.visibleListRender) this.visibleListRender.unbind("scroll",listRenderScrollMethod);
            this.visibleListRender = tabContentElement.controls[1].controls[0].controls[0].controls[0];
            console.log("new content tab visible",this.visibleListRender.date)

            this.coordinatoranimator.setActiveScrollContainer(tabContentElement.controls[1].controls[0]);
            this.visibleListRender.bind("scroll",listRenderScrollMethod);

            this.stopAutoRefreshes();
            this.startAutoRefreshes();
            this.coordinatoranimator.run();
        }));

        //called everytime when tabview wants to get content for a tab
        //date contains element index and data index.
        //usually when 3 tabs are rendered element index goes from 0,1,2
        //where 1 is the center one. data index depends on the tab titles
        tabview.bind("requestTabContent",this.getProxy(function(e,data){
            data.target.clear();

            //out of bounds when most left tab or most right tab is selected
            if (data.dataIndex < 0 || data.dataIndex > dateIndixes.length-1) return;

            console.log("request content",data,"index",data.elementIndex,"old index",data.oldIndex);

            var tabContent = new TabViewTab2();
            tabContent.addCssClass("tabviewTabContent")
            tabContent.addCssClass("noscrollbar")
            data.target.addControl(tabContent);

            var listRender = this.createYear(tabContent,data.elementIndex);
            listRender.date = dateIndixes[data.dataIndex];

            if (data.dataIndex == tabview.currentActiveTab){

                ((listRender, data) => {
                    setTimeout(() => {
                        this.loadYear(dateIndixes[data.dataIndex], listRender, dataFeedExtendedVersion[data.dataIndex]);
                        var previousState = StateManager.getCurrentPageState(this.pageName);
                        if (previousState) {
                            listRender.scrollTo(previousState.activeScrollPosition || 0);
                        }
                    }, 50);
                })(listRender, data);
            }
            else{
                (this.getProxy(function (listRender, data) {
                    setTimeout(this.getProxy(function() {
                        this.loadYear(dateIndixes[data.dataIndex], listRender, dataFeedExtendedVersion[data.dataIndex]);
                    }),200);

                }))(listRender,data);
            }
            data.target.invalidateDisplay();
        }));

        if (FootballManiaConfig.isRtl){
            tabTitles.reverse();
        }

        tabview.setTabTitles(tabTitles);

        tabview.bind("onReady",this.getProxy(function(){
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };
            this.contentContainer.addCssClass("fadeIn").setStyle("margin-top:0;");
            this.coordinatoranimator.run();
        }));

        this.bind("stickyBannerLoadFail", () => {
            console.error("sticky banner load fail -> set height to 100%");
            if (tab1) this.coordinatoranimator.setScrollerBottomMargin(0);
            this.coordinatoranimator.recalculateTab(1);

        });
        //triggered from page template
        this.bind("stickyBannerLoadSuccess", () => {
            console.log("sticky banner load success -> set height to 100% - 50px",this.tabview.currentActiveTab);
            if (tab1) {
                this.coordinatoranimator.setScrollerBottomMargin(50);
                this.coordinatoranimator.recalculateTab(1);
            }
        });
        this.getPageTemplate().loadStickyBannerAd();
    }

    createYear (tab,elementIndex){
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new LazyTileGridRenderer();
        listRender.containerTarget = tab;
        listRender.enableFastScroll = true;
        listRender.setItemRender(function(data){

            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new RankingItemRender(),
                };
            }
        })

        listRender.emptyTemplate = function(){
            var empty = new Panel()
            empty.addCssClass("listViewEmptyData");

            var attention = new Panel();
            attention.addCssClass("icon-attention");
            attention.setStyle("width:60px;height:60px;")
            empty.addControl(attention);

            empty.addControl(Localizer.getString("no_data"));
            return empty;
        }

        listRender.errorTemplate = function(cb){
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function(){
            return new ErrorMessage();
        }

        listRender.bind('createdList',this.getProxy(function(){
            this.coordinatoranimator.recalculateTab(this.findIndexForRenderById(listRender.getId()));
        }))

        listRender.setPlaceHolderWidth("100%;");

        container.addControl(listRender);

        tab.addControl(container);

        return listRender;
    }

    loadYear (date,listRenderTarget,extendedDataFeed,allowAutoRefresh, ignoreCache,dontShowLoader,isFromAutoRefresh){

        //when page is already loaded then we dont load when page is hidden. but not first time, because it can happen that interstitial hides the screen first time
        if (this["firstTimeLoading"+date] && document.hidden){
            return this.autoRefresh(listRenderTarget);
        }
        this["firstTimeLoading"+date] = true;

        if (ManiaCache.getMemoryCache("rankings"+date)){
            //this.listRenders[elementIndex].setDataSource(ManiaCache.getMemoryCache("fixtureDate"+date));
            //return this.autoRefreshToday(2000);
        }

        if (!dontShowLoader) {
            listRenderTarget.parent.parent.showLoader();
        }
        listRenderTarget.date = date;

        DataProvider.getRanking(date,this.getProxy(function(response){

            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

            if (!response.isOk()){
                listRenderTarget.parent.parent.hideLoader();
                if (isFromAutoRefresh){ //dont show error when auto refreshing and i.e no internet
                    return;
                }

                var str = Localizer.getString("nointernet")+"<br>"+Localizer.getString("taptotryagain");
                listRenderTarget.showError(str,this.getProxy(function(){
                    this.loadYear(date,listRenderTarget,allowAutoRefresh,true);
                }))
                return;
            }

            listRenderTarget.loadedDatasource = response.getData();

            if (!FootballManiaConfig.useWebWorkers) {

                var listData = Processors.prepareRankingData(
                    response.getData(),
                    AppInstance.getConfig().fixedRegions,
                    AppInstance.getConfig().fixedLeaguesOrder,
                    Localizer.getLoadedLocale(),
                    Favorites.getFavoriteTeams(),
                    Favorites.getFavoriteLeagues(),
                    FootballManiaConfig.hideCountryTitleFor,
                    AppInstance.getLocale(),
                    Localizer.DefaultTranslations,
                    ManiaPreferences.getShowOnlyFavoritesAgenda(),
                    Subscriber.getSubscribedTeams(),
                    Subscriber.getSubscribedLeagues(),
                    Subscriber.getSubscribedMatches()
                )
                var data = {data:[listRenderTarget.getId(), listData]};
                this.onWorkerMessageReceived(data);
            }
            else{
                //some round robin logic to get one of the X workers.
                if (this.usedWorker == undefined) {
                    this.usedWorker = 0;
                } else if (this.usedWorker >= this.workers.length - 1) {
                    this.usedWorker = 0;
                } else {
                    this.usedWorker += 1;
                }

                AppInstance.getWorker(this.usedWorker).postMessage({
                    methodType: 7,
                    data: response.getData(),
                    fixedRegions: AppInstance.getConfig().fixedRegions,
                    fixedLeagueOrder: AppInstance.getConfig().fixedLeaguesOrder,
                    favoriteLeagues: Favorites.getFavoriteLeagues(),
                    favoriteTeams: Favorites.getFavoriteTeams(),
                    translations: Localizer.getLoadedLocale(),
                    locale: AppInstance.getLocale(),
                    hiddenTitles: FootballManiaConfig.hideCountryTitleFor,
                    listRenderId: listRenderTarget.getId(),
                    showOnlyFavorites: ManiaPreferences.getShowOnlyFavoritesAgenda(),
                    subscribedTeams : Subscriber.getSubscribedTeams(),
                    subscribedLeagues : Subscriber.getSubscribedLeagues(),
                    subscribedMatches : Subscriber.getSubscribedMatches()
                });
            }

            if (allowAutoRefresh && this.findListRenderById(listRenderTarget.getId())) {
                this.autoRefresh(listRenderTarget);
            }

        }),ignoreCache)
    }

    autoRefresh (listRender,overrideInterval){

        if (this.unloaded){
            return console.warn("no auto refresh because page is unloaded");
        };
        if (!listRender || !listRender.date){
            console.error("no listrender or date",listRender);
            return;
        }
        clearTimeout(this.autoRefreshTimeoutHandlers[listRender.date]);
        this.autoRefreshTimeoutHandlers[listRender.date] = setTimeout(this.getProxy(function(){

            if (document.hidden){
                console.warn("no auto refresh because page is hidden");
                return;
            }
            console.log("auto refresh now",listRender.date);
            this.loadYear(listRender.date,listRender,true,true,true,true)

        }),overrideInterval != null ? overrideInterval : AppInstance.getConfig().refreshIntervals.ranking)
    }

    stopAutoRefreshes (){
        for (var i=0; i <this.autoRefreshTimeoutHandlers.length;i++){
            clearTimeout(this.autoRefreshTimeoutHandlers[this.autoRefreshTimeoutHandlers[i]]);
        }
        this.autoRefreshTimeoutHandlers = [];
    }

    startAutoRefreshes (listRender){
        this.stopAutoRefreshes();
        console.log("start auto refreshes");
        for (var i = 0; i < this.tabview.tabContents.controls.length; i++) {
            try {
                //ugly way of finding active listrender
                var listRender = this.tabview.tabContents.controls[i].controls[1].controls[0].controls[0].controls[0];

                var allowRefresh = this.allowableAutoRefreshDates.indexOf(Number.parseInt(listRender.date)) != -1 ? true : false;

                console.log("loop listrender",this.allowableAutoRefreshDates.indexOf(2023),this.allowableAutoRefreshDates,listRender.date,allowRefresh);
                if (allowRefresh) {
                    console.log("allow auto refresh");
                    this.autoRefresh(listRender)
                }
                console.log(listRender,listRender.date);

            }
            catch (e) {
                console.error("error reloading listrender. are you at beginning or end. or some other error ",e);
            }
        }
    }

    /**
     * call this when you want to set datasource in listrender again
     * for example after you enabled filter. worker is using filter data
     */
    reApplyDataSourceInListRenders (){
        console.log("reapply datasource in listrender")
        for (var i = 0; i < this.tabview.tabContents.controls.length; i++) {
            try {

                if (this.usedWorker == undefined){
                    this.usedWorker = 0;
                }
                else if (this.usedWorker >= this.workers.length-1){
                    this.usedWorker = 0;
                }
                else{
                    this.usedWorker+=1;
                }

                //ugly way of finding active listrender
                var listRender = this.tabview.tabContents.controls[i].controls[1].controls[0].controls[0].controls[0];
                listRender.scrollTo(0);
                AppInstance.getWorker(this.usedWorker).postMessage({
                    methodType:7,
                    data:listRender.loadedDatasource,
                    fixedRegions : AppInstance.getConfig().fixedRegions,
                    fixedLeagueOrder : AppInstance.getConfig().fixedLeaguesOrder,
                    favoriteLeagues : Favorites.getFavoriteLeagues(),
                    favoriteTeams : Favorites.getFavoriteTeams(),
                    translations : Localizer.getLoadedLocale(),
                    locale : AppInstance.getLocale(),
                    defaultLocale : Localizer.DefaultTranslations,
                    hiddenTitles : FootballManiaConfig.hideCountryTitleFor,
                    listRenderId : listRender.getId(),
                    showOnlyFavorites : ManiaPreferences.getShowOnlyFavoritesAgenda(),
                    subscribedTeams : Subscriber.getSubscribedTeams(),
                    subscribedLeagues : Subscriber.getSubscribedLeagues(),
                    subscribedMatches : Subscriber.getSubscribedMatches()
                });
            }
            catch (e) {
                console.error("error reapplying datasource in listrender ",e);
            }
        }
    }
};