import {Panel} from "../../bananaframework/src/controls/Panel.js"
import {Image} from "../../bananaframework/src/controls/Image.js"
import {TableRow, TableHeaderCol, TableCol} from "../../bananaframework/src/controls/Table.js"
import {
    DataGridTileItemRender
} from "../../bananaframework/src/controls/listcontrols/datagrids/listrenders/DataGridTileItemRender.js"

import {ImagePlaceHolder, teamPlaceholder, countryPlaceHolder} from "../imageplaceholder.js"
import {MatchUtil} from "../../util/matchutil.js";
import {Localizer} from "../../util/localizer.js";
import {Router} from "../../util/router.js";

import {MatchItemRender} from "../home/listrendershome.js";
import {WordShorthand,RemoveReservedIfNeeded} from "../../util/utils.js";

export class TeamGeneralInfoItemRender extends DataGridTileItemRender {

    init() {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);
        this.panel.setStyle("margin-top:3px;");
        this.card.setStyle("padding-bottom:4px;");

        this.content = new Panel();
        this.content.addCssClass("flexHorizontalAlign");
        this.content.setStyle("align-items: flex-start;");

        var title = new Panel();
        title.addControl(Localizer.getString("info"));
        title.addCssClass("MatchStatsTitleText MatchH2HTitle")

        this.card.addControl(title);

        this.card.addControl(this.content);

        this.addControl(this.panel);
    }

    setData(data) {

        this.content.addControl("<table class='tableStandings' style='width:100%;'>");

        if (data.data.founded) {
            this.createInfo(Localizer.getString("founded"), data.data.founded);
        }
        // if (data.data.city) {
        //     this.createInfo(Localizer.getString("city"), data.data.city);
        // }
        if (data.data.venuecity) {
            this.createInfo(Localizer.getString("city"), data.data.venuecity);
        }

        this.createInfo(Localizer.getString("country"), data.data.country);
        if (data.data.url) {
            var element = this.createInfo(Localizer.getString("website"), data.data.url);
            element.bind("click", function () {
                Router.openUrl(data.data.url, true);
            });
            element.setStyle("height: 30px;cursor:pointer;");

        }
        if (data.data.coach) {
            this.createInfo(Localizer.getString("coach"), data.data.coach);
        }

        //this.content.addControl("</table>");

        //this.content.addControl("<table class='tableStandings' style='width:100%;'>");

        if (data.data.venue) {
            this.createInfo(Localizer.getString("venue"), data.data.venue);
        }
        if (data.data.venuecapacity) {
            this.createInfo(Localizer.getString("capacity"), data.data.venuecapacity);
        }
        if (data.data.venuesurface) {
            this.createInfo(Localizer.getString("surface"), Localizer.getString(data.data.venuesurface.replaceAll(" ", "_")));
        }
        if (data.data.venueaddress) {
            this.createInfo(Localizer.getString("address"), data.data.venueaddress);
        }

        //this.content.addControl("</table>");
    }

    createInfo(title, data) {
        var row = new TableRow();
        row.setStyle("height: 30px;cursor:none;");
        var th1 = new TableCol();
        th1.setStyle("padding-inline-end:8px;padding-inline-start:8px;text-align:end;vertical-align: top;font-variant:all-small-caps;font-size:14px;");
        th1.addControl(title + " : ");
        var th2 = new TableCol();
        th2.addControl(data);
        th2.setStyle("width:50%;text-align:start;font-weight:bold;vertical-align: top;font-variant:all-small-caps;font-size:14px;");

        row.addControl(th1);
        row.addControl(th2);
        this.content.addControl(row);
        return row;
    }
};

export class TopscorersListRender extends DataGridTileItemRender {

    init() {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("listViewItem")
        this.panel.setStyle("flex-direction:inherit;");
        this.panel.addCssClass("flexHorizontalAlign");

        var startContainer = new Panel();
        startContainer.addCssClass("flexHorizontalAlign");

        var playerinfoCon = new Panel();
        playerinfoCon.addCssClass("flexVerticalAlign");
        playerinfoCon.setStyle("align-items: flex-start;");

        this.imageBox = new ImagePlaceHolder();
        this.imageBox.setStyle("margin-inline-start:4px;width:40px; height:40px;align-self: center;border-radius:30px; overflow:hidden;");
        //this.imageBox.placeholderData = countryPlaceHolder

        this.pos = new Panel();
        this.pos.addCssClass("flexHorizontalAlign");
        this.pos.setStyle("height:50px;width:18px;margin-inline-start:4px;");

        this.playerName = new Panel();
        this.playerName.setStyle("margin-inline-start:8px;display: flex;align-content: center;align-items: center;");
        //this.playerName.addCssClass("flexVerticalAlign");
        this.playerTeam = new Panel();
        this.playerTeam.setStyle("font-size:11px;font-weight:200;margin-inline-start:8px;display: flex;align-content: center;align-items: center;");
        //this.playerTeam.addCssClass("flexVerticalAlign");

        this.goals = new Panel();
        this.goals.addCssClass("flexHorizontalAlign");
        this.goals.setStyle("height:50px;width:30px;");

        playerinfoCon.addControl(this.playerName);
        playerinfoCon.addControl(this.playerTeam);

        startContainer.addControl(this.pos);
        startContainer.addControl(this.imageBox);
        startContainer.addControl(playerinfoCon);

        this.panel.addControl(startContainer);
        this.panel.addControl(this.goals);

        this.addControl(this.panel);
    }

    setData(data) {
        this.pos.addControl(data.pos + "");
        this.goals.addControl(data.goals + "");
        this.playerName.addControl(data.name);
        this.playerTeam.addControl(data.team);

        if (AppInstance.getConfig().enabledFeatures.playerImages) {
            this.imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/playerimages/" + data.id + "_small_rnd.png", 40, 40);
        } else {
            this.imageBox.imageObjectStyle = "margin-top:-16px;margin-left:-16px;";
            if (data.nationality) {
                this.imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.nationality.replace(/\s|\'/g, '-').toLowerCase() + ".png", 72, 72);
            } else {
                this.imageBox.setVisible(false);
            }
        }

        this.panel.bind("click", function () {
            Router.showPlayerScreen(data.id);
        });
    }
};

export class FutureTeamFixturesRender extends DataGridTileItemRender {

    init() {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);
        this.panel.setStyle("margin-top:3px;");

        this.addControl(this.panel);
    }

    setData(data) {
        super.setData(data);

        var title = new Panel();
        title.addControl(Localizer.getString("upcoming_matches"));
        title.addCssClass("MatchStatsTitleText MatchH2HTitle")

        this.card.addControl(title);

        var matchList = new Panel();

        for (var i = 0; i < data.data.futureMatches.length; i++) {

            data.data.futureMatches[i].leaguename = RemoveReservedIfNeeded(data.data.futureMatches[i].leaguename);
            var info = new Panel();
            info.addCssClass("MatchH2HMatchTitleContainer")
            info.addControl("<p>" + MatchUtil.getLocalizedDate(data.data.futureMatches[i]) + "</p>");
            info.addControl("<p>" + data.data.futureMatches[i].leaguename + "</p>");
            matchList.addControl(info);

            var itemRender = new MatchItemRender();
            itemRender.setData(data.data.futureMatches[i]);
            matchList.addControl(itemRender);
        }

        this.card.addControl(matchList);
    }
};

export class NowPlayingTeamFixturesRender extends FutureTeamFixturesRender {

    init() {
        super.init();
    }

    setData(data) {
        var title = new Panel();
        title.addControl(Localizer.getString("nowlive"));
        title.addCssClass("MatchStatsTitleText MatchH2HTitle")

        this.card.addControl(title);

        var matchList = new Panel();

        data.data.nowPlaying.leaguename = RemoveReservedIfNeeded(data.data.nowPlaying.leaguename);
        var info = new Panel();
        info.addCssClass("MatchH2HMatchTitleContainer")
        info.addControl("<p>" + MatchUtil.getLocalizedDate(data.data.nowPlaying) + "</p>");
        info.addControl("<p>" + data.data.nowPlaying.leaguename + "</p>");
        matchList.addControl(info);

        var itemRender = new MatchItemRender();
        itemRender.setData(data.data.nowPlaying);
        matchList.addControl(itemRender);

        this.card.addControl(matchList);
    }
};

export class TodayPlayingTeamFixturesRender extends FutureTeamFixturesRender {

    init() {
        super.init();
    }

    setData(data) {
        var title = new Panel();
        if (data.data.todayPlaying.isfinished) {
            title.addControl(Localizer.getString("todays_played_match"));
        } else {
            title.addControl(Localizer.getString("today"));
        }
        title.addCssClass("MatchStatsTitleText MatchH2HTitle")

        this.card.addControl(title);

        var matchList = new Panel();

        data.data.todayPlaying.leaguename = RemoveReservedIfNeeded(data.data.todayPlaying.leaguename);

        var info = new Panel();
        info.addCssClass("MatchH2HMatchTitleContainer")
        info.addControl("<p>" + MatchUtil.getLocalizedDate(data.data.todayPlaying) + "</p>");
        info.addControl("<p>" + data.data.todayPlaying.leaguename + "</p>");
        matchList.addControl(info);

        var itemRender = new MatchItemRender();
        itemRender.setData(data.data.todayPlaying);
        matchList.addControl(itemRender);

        this.card.addControl(matchList);
    }
};

export class PastTeamFixturesRender extends FutureTeamFixturesRender {

    init() {
        super.init();
    }

    setData(data) {
        var title = new Panel();
        title.addControl(Localizer.getString("previous_matches"));
        title.addCssClass("MatchStatsTitleText MatchH2HTitle")

        this.card.addControl(title);

        var matchList = new Panel();

        for (var i = 0; i < data.data.pastMatches.length; i++) {

            data.data.pastMatches[i].leaguename = RemoveReservedIfNeeded(data.data.pastMatches[i].leaguename);

            var info = new Panel();
            info.addCssClass("MatchH2HMatchTitleContainer")
            info.addControl("<p>" + MatchUtil.getLocalizedDate(data.data.pastMatches[i]) + "</p>");
            info.addControl("<p>" + data.data.pastMatches[i].leaguename + "</p>");
            matchList.addControl(info);

            var itemRender = new MatchItemRender();
            itemRender.setData(data.data.pastMatches[i]);
            matchList.addControl(itemRender);
        }

        this.card.addControl(matchList);
    }
};


export class LastPlayedTeamFixturesRender extends FutureTeamFixturesRender {

    init() {
        super.init();
    }

    setData(data) {

        var title = new Panel();
        title.addControl(Localizer.getString("last_played_match"));
        title.addCssClass("MatchStatsTitleText MatchH2HTitle")
        console.log("--------", data.data.lastPlayedMatch)

        this.card.addControl(title);

        var matchList = new Panel();

        data.data.lastPlayedMatch.leaguename = RemoveReservedIfNeeded(data.data.lastPlayedMatch.leaguename);

        var info = new Panel();
        info.addCssClass("MatchH2HMatchTitleContainer")
        info.addControl("<p>" + MatchUtil.getLocalizedDate(data.data.lastPlayedMatch) + "</p>");
        info.addControl("<p>" + data.data.lastPlayedMatch.leaguename + "</p>");
        matchList.addControl(info);

        var itemRender = new MatchItemRender();
        itemRender.setData(data.data.lastPlayedMatch);
        matchList.addControl(itemRender);

        this.card.addControl(matchList);

    }
};

export class NextPlayingTeamFixturesRender extends FutureTeamFixturesRender {

    init() {
        super.init();
    }

    setData(data) {

        var title = new Panel();
        title.addControl(Localizer.getString("next_playing_match"));
        title.addCssClass("MatchStatsTitleText MatchH2HTitle")

        this.card.addControl(title);

        var matchList = new Panel();

        data.data.nextMatch.leaguename = RemoveReservedIfNeeded(data.data.nextMatch.leaguename);

        var info = new Panel();
        info.addCssClass("MatchH2HMatchTitleContainer")
        info.addControl("<p>" + MatchUtil.getLocalizedDate(data.data.nextMatch) + "</p>");
        info.addControl("<p>" + data.data.nextMatch.leaguename + "</p>");
        matchList.addControl(info);

        var itemRender = new MatchItemRender();
        itemRender.setData(data.data.nextMatch);
        matchList.addControl(itemRender);

        this.card.addControl(matchList);

    }
};

export class TeamLeagueStandingsItemRender extends DataGridTileItemRender {

    init() {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);
        this.panel.setStyle("margin-top:3px;");
        this.card.setStyle("padding-bottom:4px;");

        this.addControl(this.panel);
    }

    setData(data) {

        var title = new Panel();
        title.addControl(Localizer.getString("standen"));
        title.addCssClass("MatchStatsTitleText MatchH2HTitle")

        this.card.addControl(title);

        this.createTables(data);
    }

    createTables(data) {
        this.data = data;
        this.card.addControl("<table class='tableStandings'>");

        console.log("create tables for team standings", data);
        for (var x = 0; x < data.data.leagues.length; x++) {

            var tableData = data.data.leagues[x].table;

            for (var i = 0; i < tableData.groups.length; i++) {

                var row = new TableRow();
                var groups = tableData.groups[i];
                //var title1 = Localizer.getString("round") + " " + groups.round;

                row.setStyle("height:24px;")
                var th3 = new TableHeaderCol();
                th3.addCssClass("teamTableStandingsHeader");
                th3.setAttribute("colspan", 9)

                var newGroupName = groups.group.replace(tableData.leaguename, "").replace(":", "");
                if (newGroupName) {
                    //th3.addControl(data.data.leagues[x].league + " - " + newGroupName + " - " + title1);
                    //th3.addControl(data.data.leagues[x].league+ " - " + title1);
                    //th3.addControl(data.data.leagues[x].league);
                } else {
                    //th3.addControl(data.data.leagues[x].league+ " - " + title1);
                    //th3.addControl(data.data.leagues[x].league);
                }
                row.addControl(th3);
                //this.card.addControl(row);


                var row = new TableRow();
                row.setStyle("height:24px;")
                var th1Arrow = new TableHeaderCol();
                th1Arrow.setAttribute("colspan", 2)

                //th1Arrow.addControl(data.data.leagues[x].league);
                th1Arrow.setStyle("text-align:left;padding-left:4px;")
                var th3 = new TableHeaderCol();
                th3.addControl(Localizer.getString("position"))
                th3.setStyle("width:20px;")
                var th4 = new TableHeaderCol();
                th4.addControl(Localizer.getString("table_gamesplayed"))
                th4.setStyle("width:20px;")
                var th5 = new TableHeaderCol();
                th5.addControl(Localizer.getString("table_wins"))
                th5.setStyle("width:20px;")
                var th6 = new TableHeaderCol();
                th6.addControl(Localizer.getString("table_draw"))
                th6.setStyle("width:20px;")
                var th7 = new TableHeaderCol();
                th7.addControl(Localizer.getString("table_lost"))
                th7.setStyle("width:20px;")
                var th8 = new TableHeaderCol();
                th8.addControl(Localizer.getString("table_goalsforagainst"))
                th8.setStyle("width:48px;")
                var th9 = new TableHeaderCol();
                th9.addControl(Localizer.getString("table_goaldiff"))
                th9.setStyle("width:20px;")
                var th10 = new TableHeaderCol();
                th10.addControl(Localizer.getString("table_points"))
                th10.setStyle("width:20px;")

                row.addControl(th1Arrow);
                //row.addControl(th2);
                row.addControl(th3);
                row.addControl(th4);
                row.addControl(th5);
                row.addControl(th6);
                row.addControl(th7);
                row.addControl(th8);
                row.addControl(th9);
                row.addControl(th10);

                this.card.addControl(row);

                for (var j = 0; j < groups.teams.length; j++) {

                    var team = groups.teams[j];

                    var row = new TableRow();
                    row.setStyle("height:34px;")

                    //colArrow.

                    data.data.leagues[x].league = RemoveReservedIfNeeded(data.data.leagues[x].league);
                    var col1 = new TableCol();
                    col1.addCssClass("TeamStandingsTeamName");
                    if (FootballManiaConfig.isRtl) {
                        //col1.addControl("<p style='float:right;'>"+Localizer.getString("position") + ": " + team.position+"</p>");
                        col1.addControl("<p style=''>" +data.data.leagues[x].league + "</p>");
                    } else {
                        //col1.addControl("<p style='float:left;'>"+Localizer.getString("position") + ": " + team.position+"</p>");
                        col1.addControl("<p style=''>" + WordShorthand(data.data.leagues[x].league) + "</p>");
                    }

                    if (team.isLive) {
                        col1.addCssClass("standingsLive");
                    }

                    var colArrow = new TableCol();
                    if (team.position_change > 0) {
                        col1.addControl("<p style='float:left;margin-left:8px;margin-top: 4px;' class='arrow-up'></p>");
                    } else if (team.position_change < 0) {
                        col1.addControl("<p  style='float:left;margin-left:8px;margin-top: 4px;' class='arrow-down'></p>");
                    }

                    var p = new TableCol();
                    p.addControl(team.position + "");
                    var gp = new TableCol();
                    gp.addControl(team.matchPoints + "");
                    var w = new TableCol();
                    w.addControl(team.totalWon + "");
                    var d = new TableCol();
                    d.addControl(team.totalDraw + "");
                    var l = new TableCol();
                    l.addControl(team.totalLost + "");
                    var gf = new TableCol();
                    gf.addControl(team.totalGoalsFor + "-" + team.totalGoalsAgainst);
                    var gd = new TableCol();
                    gd.addControl(team.totalGoalsFor - team.totalGoalsAgainst + "");
                    var points = new TableCol();
                    points.addControl(team.points + "");

                    row.addControl(col1);
                    row.addControl(colArrow);
                    row.addControl(p);
                    row.addControl(gp);
                    row.addControl(w);
                    row.addControl(d);
                    row.addControl(l);
                    row.addControl(gf);
                    row.addControl(gd);
                    row.addControl(points);

                    this.card.addControl(row);
                    (function (team, league, alternativeRender) {
                        row.bind("click", function () {
                            Router.showLeagueScreen(league, {"action": "showStandings"});
                        })
                    })(team, data.data.leagues[x], this.alternativeRender);
                }
            }
        }
        this.card.addControl("</table>");
    }
};

export class SquadHeaderItemRender extends DataGridTileItemRender {

    init() {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("listViewHeader");

        this.addControl(this.panel);

        this.contents = new Panel();

        this.panel.addControl(this.contents)
    }

    setData(data) {
        super.setData(data);
        this.contents.clear();
        this.panel.setCss({"height": data.style.height + "px"});
        this.contents.addControl(this.data.title);
        this.invalidateDisplay();
    }
};

export class SquadListrender extends DataGridTileItemRender {

    init() {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("listViewItem")
        this.panel.addCssClass("flexHorizontalAlign");
        this.panel.addCssClass("noDirection"); //sub childs already have direction

        var startContainer = new Panel();
        startContainer.addCssClass("flexHorizontalAlign");

        var playerinfoCon = new Panel();
        playerinfoCon.addCssClass("flexVerticalAlign");
        playerinfoCon.setStyle("align-items: flex-start;");

        this.imageBox = new ImagePlaceHolder();
        this.imageBox.addCssClass("");
        this.imageBox.setStyle("margin-inline-start:4px;width:40px; height:40px;align-self: center;border-radius:30px; overflow:hidden;");

        this.redCross = new Panel();
        this.redCross.addCssClass("cross");
        this.redCross.setStyle("margin-inline-start:-13px;margin-top:-10px;position:absolute;width:20px;height:20px;");
        this.redCross.setVisible(false);

        this.captain = new Panel();
        this.captain.addCssClass("captain");
        this.captain.setStyle("margin-inline-start:-47px;margin-top:-10px;position:absolute;width:10px;height:10px;");
        this.captain.setVisible(false);


        this.number = new Panel();
        this.number.addCssClass("number");
        this.number.setStyle("margin-inline-start:-3px;margin-top:20px;position:absolute;width:10px;height:10px;font-size:11px;");
        this.number.setVisible(false);

        this.pos = new Panel();
        this.pos.addCssClass("flexHorizontalAlign");
        this.pos.setStyle("height:50px;min-width:28px;margin-inline-start:4px;");

        this.playerNameContainer = new Panel();
        this.playerNameContainer.addCssClass("flexVerticalAlign");
        this.playerNameContainer.setStyle("margin-inline-start:12px;display: flex;align-content: center;align-items: flex-start;");

        this.playerName = new Panel();
        this.playerName.addCssClass("TeamSquadPlayerNameContainer");
        this.playerInfo = new Panel();
        this.playerInfo.setStyle("font-weight:normal;font-size:11px;")

        this.playerNameContainer.addControl(this.playerName);
        this.playerNameContainer.addControl(this.playerInfo);
        //this.playerTeam.addCssClass("flexVerticalAlign");

        var stats = new Panel();
        stats.addCssClass("flexVerticalAlign");


        var minifiedStats = new Panel();
        minifiedStats.addCssClass("flexHorizontalAlign");
        var extendedStats = new Panel();
        extendedStats.addCssClass("verticalAlign");

        playerinfoCon.addControl(this.redCross);
        playerinfoCon.addControl(this.captain);
        playerinfoCon.addControl(this.number);
        playerinfoCon.addControl(this.playerNameContainer);
        playerinfoCon.addControl(this.playerTeam);

        startContainer.addControl(this.imageBox);
        startContainer.addControl(playerinfoCon);
        startContainer.addControl(this.pos);

        this.panel.addControl(startContainer);
        this.panel.addControl(stats);

        stats.addControl(minifiedStats);
        stats.addControl(extendedStats);
        this.minifiedStats = minifiedStats;
        this.extendedStats = extendedStats;
        //stats.addControl(bottomStats);

        this.addControl(this.panel);
    }

    createMinifiedItem(iconclass, value) {
        var container = new Panel();
        container.addCssClass("flexHorizontalAlign");
        container.setStyle("align-items: center;justify-content: flex-start;margin-inline-start:4px;width:35px;")
        var icon = new Panel();
        icon.addCssClass("icon " + iconclass);
        var text = new Panel();
        text.setStyle("margin-inline-start:2px;");
        text.addCssClass("teamSquadPlayerStatsText")
        text.addControl(value + "");
        container.addControl(icon);
        container.addControl(text);
        return container;
    }

    createExtendedItem(title, value) {
        var container = new Panel();
        container.addCssClass("flexHorizontalAlign");
        container.setStyle("margin-inline-start:4px;width:150px;line-height:25px;")
        var icon = new Panel();
        icon.addControl(title)
        var text = new Panel();
        text.setStyle("margin-inline-start:2px;");
        text.addCssClass("teamSquadPlayerStatsText")
        text.addControl(value + "");
        container.addControl(icon);
        container.addControl(text);
        return container;
    }

    setData(data) {

        if (data.number) {
            //this.pos.addControl("<p style=font-size:10px;position:absolute;>(" + data.number + ")</p>");
            this.number.setVisible(true);
            this.number.addControl(data.number, true);
        }
        //this.goals.addControl(data.goals);
        this.playerName.addControl(data.name);

        if (AppInstance.getConfig().enabledFeatures.playerImages) {
            this.imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/playerimages/" + data.id + "_small_rnd.png", 40, 40);
        } else {
            this.imageBox.imageObjectStyle = "margin-top:-16px;margin-left:-16px;";
            if (data.nationality) {
                this.imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.nationality.replace(/\s|\'/g, '-').toLowerCase() + ".png", 72, 72);
            } else {
                this.imageBox.setVisible(false);
            }
        }


        if (data.injured == "True") {
            this.redCross.setVisible(true);
            if (data.injuryDescription) {
                this.playerInfo.addControl(Localizer.getString(data.injuryDescription.toLowerCase().replace("/", "").replaceAll(" ", "")));
                this.playerInfo.addControl(" (" + data.injuryFrom + ")");
            }
        }
        if (data.isCaptain && data.isCaptain != "0" && data.isCaptain != "") {
            this.captain.setVisible(true);
            ///this.playerName.addControl(" <z style='font-size:9px;'>(capt.)</z>");
        }

        if (!this.showExtendedStats) {
            this.extendedStats.setVisible(false);
            this.minifiedStats.setVisible(true);
            this.minifiedStats.addControl(this.createMinifiedItem("ball", data.goals));
            this.minifiedStats.addControl(this.createMinifiedItem("soccercard yellow", parseInt(data.yellowcards || 0)));
            this.minifiedStats.addControl(this.createMinifiedItem("soccercard red", parseInt(data.redcards || 0) + parseInt(data.yellowred || 0)));
        } else {
            this.extendedStats.setVisible(true);
            this.minifiedStats.setVisible(false);
            this.extendedStats.addControl(this.createExtendedItem("goals", data.goals));
            // this.extendedStats.addControl(this.createExtendedItem("yellowcards", data.goals));
            // this.extendedStats.addControl(this.createExtendedItem("redcards", data.goals));
            // this.extendedStats.addControl(this.createExtendedItem("ball", data.goals));
        }

        this.panel.bind("click", function () {
            Router.showPlayerScreen(data.id);
        });

    }
};


export class SquadCoachItemRender extends DataGridTileItemRender {

    init() {
        super.init();
        this.panel = new Panel();

        this.panel.addCssClass("listViewItem")
        this.panel.addCssClass("flexHorizontalAlign");
        this.panel.addCssClass("noDirection"); //sub childs already have direction

        var startContainer = new Panel();
        startContainer.addCssClass("flexHorizontalAlign");

        var playerinfoCon = new Panel();
        playerinfoCon.setStyle("align-items: flex-start;");

        this.imageBox = new ImagePlaceHolder();
        this.imageBox.setStyle("margin-inline-start:4px;width:40px; height:40px;align-self: center;border-radius:30px; overflow:hidden;");

        this.pos = new Panel();
        this.pos.addCssClass("flexHorizontalAlign");
        this.pos.setStyle("height:50px;min-width:24px;margin-inline-start:8px;");

        this.playerNameContainer = new Panel();
        this.playerNameContainer.setStyle("margin-inline-start:8px;display: flex;align-content: center;align-items: flex-start;");

        this.playerName = new Panel();
        this.playerInfo = new Panel();
        this.playerInfo.setStyle("font-weight:200;font-size:11px;")

        this.playerNameContainer.addControl(this.playerName);
        this.playerNameContainer.addControl(this.playerInfo);
        //this.playerTeam.addCssClass("flexVerticalAlign");

        var stats = new Panel();
        stats.addCssClass("flexVerticalAlign");


        var minifiedStats = new Panel();
        minifiedStats.addCssClass("flexHorizontalAlign");

        playerinfoCon.addControl(this.playerNameContainer);
        playerinfoCon.addControl(this.playerTeam);

        startContainer.addControl(this.imageBox);
        //startContainer.addControl(this.pos);
        startContainer.addControl(playerinfoCon);

        this.panel.addControl(startContainer);
        this.panel.addControl(stats);

        stats.addControl(minifiedStats);
        this.minifiedStats = minifiedStats;

        this.addControl(this.panel);

        this.panel.bind("click", ()=>{
           Router.showCoachScreen(this.data.coachid);
        });
    }

    createMinifiedItem(iconclass, value) {
        var container = new Panel();
        container.addCssClass("flexHorizontalAlign");
        container.setStyle("align-items: center;justify-content: flex-start;margin-inline-start:4px;width:35px;")
        var icon = new Panel();
        icon.addCssClass("icon " + iconclass);
        var text = new Panel();
        text.setStyle("margin-inline-start:2px;");
        text.addCssClass("teamSquadPlayerStatsText")
        text.addControl(value + "");
        container.addControl(icon);
        container.addControl(text);
        return container;
    }

    setData(data) {

        this.data = data.data;
        this.playerName.addControl(data.data.coach);
        this.panel.setCss({"height": data.style.height + "px"});
        //this.panel.setCss({"height":data.style.height+"px"});

        if (AppInstance.getConfig().enabledFeatures.playerImages) {
            this.imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/coachimages/" + data.data.coachid + "_small_rnd.png", 40, 40);
        } else {
            this.imageBox.imageObjectStyle = "margin-top:-16px;margin-left:-16px;";
            if (data.data.coachnationality) {
                this.imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.data.coachnationality.replace(/\s|\'/g, '-').toLowerCase() + ".png", 72, 72);
            } else {
                console.log()
                this.imageBox.setVisible(false);
            }
        }
    }
};


export class TransferHeaderItemRender extends DataGridTileItemRender {

    init() {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("listViewHeader");

        this.addControl(this.panel);

        this.contents = new Panel();

        this.panel.addControl(this.contents)
    }

    setData(data) {
        super.setData(data);
        this.contents.clear();
        this.panel.setCss({"height": data.style.height + "px"});
        this.contents.addControl(this.data.title);
        this.invalidateDisplay();
    }
};

export class TransfersListrender extends DataGridTileItemRender {

    init() {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("")
        this.panel.addCssClass("listViewItem")
        this.panel.addCssClass("flexHorizontalAlign");
        this.panel.addCssClass("noDirection"); //sub childs already have direction

        var startContainer = new Panel();
        startContainer.addCssClass("flexHorizontalAlign");

        var endContainer = new Panel();
        endContainer.addCssClass("flexVerticalAlign");
        endContainer.setStyle("font-size:12px;height:40px;flex-flow:column;flex:auto;margin-inline-end:10px;");

        var typeContainer = new Panel();
        typeContainer.addCssClass("flexHorizontalAlign");
        //typeContainer.setStyle("width:70px;font-size:11px;height:30px;");

        var topCon = new Panel();
        topCon.addCssClass("flexHorizontalAlign");

        var bottomCon = new Panel();
        bottomCon.addCssClass("flexHorizontalAlign");

        endContainer.addControl(topCon);
        endContainer.addControl(bottomCon);
        endContainer.addControl(typeContainer);

        this.dateContainer = topCon
        this.dateContainer.setStyle("align-self: flex-end;");

        this.teamContainer = bottomCon;
        this.teamContainer.setStyle("align-self: flex-end;");

        this.typeContainer = typeContainer;
        this.typeContainer.setStyle("align-self: flex-end;");
        //this.typeContainer.setStyle("");
        ///this.dateContainer = topCon

        this.imageBox = new Image();
        this.imageBox.addCssClass("MatchStatsEventLocalPlayer");
        this.imageBox.setStyle("margin-inline-start:4px;")

        this.playerNameContainer = new Panel();
        this.playerNameContainer.setStyle("margin-inline-start:8px;display: flex;align-content: center;align-items: flex-start;");

        this.playerName = new Panel();

        this.playerNameContainer.addControl(this.playerName);

        startContainer.addControl(this.imageBox);
        startContainer.addControl(this.playerNameContainer);

        this.panel.addControl(startContainer);
        this.panel.addControl(endContainer);
        this.addControl(this.panel);

        this.bind("click", () => {
            Router.showPlayerScreen(this.data.data.id);
        });

    }

    setData(data) {

        this.data = data;

        this.playerName.addControl(data.data.name);
        this.panel.setCss({"height": data.style.height + "px"});

        if (AppInstance.getConfig().enabledFeatures.playerImages) {
            this.imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/playerimages/" + data.data.id + "_small_rnd.png");
        } else {
            this.imageBox.setVisible(false);
        }

        this.dateContainer.addControl(data.data.date);
        this.teamContainer.addControl(data.data.team);

        if (data.data.type == "Unknown") {
            this.typeContainer.addControl("-");
        } else if (data.data.type == "Loan") {
            this.typeContainer.addControl(Localizer.getString("loan"));
        } else if (data.data.type == "Free") {
            this.typeContainer.addControl(Localizer.getString("free_exchange"));
        } else {
            this.typeContainer.addControl(data.data.type);
        }

        this.panel.bind("click", function () {
            Router.showPlayerScreen(data.data.id);
        });
    }
};

export class TrophyHeaderItemRender extends DataGridTileItemRender {

    init() {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("listViewMatchHeader");
        this.panel.addCssClass("flexHorizontalAlign flexAlignStart");

        this.addControl(this.panel);

        this.imageBox = new ImagePlaceHolder();
        this.imageBox.addCssClass("listViewCountryFlagItem");
        this.imageBox.placeholderData = countryPlaceHolder;

        this.contents = new Panel();
        this.contents.addCssClass("listViewCountryTitle");

        this.panel.addControl(this.imageBox)
        this.panel.addControl(this.contents)
    }

    setData(data) {
        super.setData(data);
        this.contents.clear();
        this.panel.setCss({"height": data.style.height + "px"});
        this.imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + this.data.country.replaceAll(" ", "-").toLowerCase() + ".png");

        this.data.title = RemoveReservedIfNeeded(this.data.title);

        this.contents.addControl(this.data.title);
        this.invalidateDisplay();
    }
};

export class TrophiesListrender extends DataGridTileItemRender {

    init() {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("")
        this.panel.addCssClass("noDirection"); //sub childs already have direction
        this.panel.setStyle("justify-content: flex-start;margin-bottom:10px;");
        // }");
        this.panel.addCssClass("flexHorizontalAlign");

        var startContainer = new Panel();
        startContainer.addCssClass("flexHorizontalAlign");
        ///startContainer.setStyle("min-width:100px;");

        var endContainer = new Panel();
        endContainer.addCssClass("flexVerticalAlign");
        endContainer.setStyle("font-size:13px;min-height:50px;padding-top:2px;padding-right:4px;justify-content: center");

        var seasons = new Panel();
        seasons.setStyle("text-align:start; margin-inline-start:16px;")

        this.seasons = seasons;

        this.imageBoxContainer = new Panel();
        this.imageBoxContainer.setStyle("color:black;height:20px;width:20px;margin-inline-start:10px;margin-inline-end:10px;");
        this.imageBox = new Panel();
        this.imageBox.setStyle("height:20px;width:20px;");

        this.position = new Panel();
        this.position.setStyle("font-size: 15px;margin-inline-start:4px;");
        this.imageBoxContainer.addControl(this.imageBox);

        this.trophycount = new Panel();
        this.trophycount.setStyle("width:20px;");

        startContainer.addControl(this.position);
        startContainer.addControl(this.imageBoxContainer);
        startContainer.addControl(this.trophycount);
        endContainer.addControl(seasons);
        this.panel.addControl(startContainer);
        this.panel.addControl(endContainer);
        this.addControl(this.panel);
    }

    setData(data) {
        this.imageBox.addCssClass("icon-trophy");

        if (data.status == "Winner") {
            this.position.addControl("1")
            //this.imageBox.setCss({"background-color":"#FFD700"});
        } else if (data.status == "2nd Place") {
            this.position.addControl("2")
        }
        this.trophycount.addControl(data.count + "x");
        for (var i = 0; i < data.seasons.length; i++) {
            this.seasons.addControl(data.seasons[i]);
            if (i == data.seasons.length - 1) {

            } else if (i % 2 == 0 && i != data.seasons.length - 1) {
                this.seasons.addControl(",  ");
            } else {
                this.seasons.addControl(",  ");
            }
        }
    }
};