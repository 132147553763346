export const Accessibility = {

    setTitle : function(title){
        document.title = title;
    },

    unload : function(){
        return;
        window.removeEventListener("keydown", this.listRender);
    },

    init : function(page){

        return;
        var activeElement = null;

        var sectionIndex = -1;
        var sectionIndexCount = 2;

        var getFocusSection = ()=>{
            if (sectionIndex == -1){
                sectionIndex = 0;
            }
            if (sectionIndex == 0){
                return page.tabview;
            }
            else if (sectionIndex == 1){
                return page.getActiveListRender()
            }
            else{
                console.error("sectionIndex not supported",sectionIndex);
            }
        }

        var goNext = (e,isTabKey,isLeftRight,isUpDown) => {
            if (!activeElement){
                sectionIndex = 0;
            }
            var newActiveElement = getFocusSection();
            var succefullyFocused = newActiveElement.focusNext(false,isTabKey,isLeftRight,isUpDown);
            if (!succefullyFocused){
                if (activeElement){
                    activeElement.defocus();
                }
                sectionIndex++;
                if (sectionIndex >= sectionIndexCount){
                    sectionIndex = 0;
                }

                newActiveElement = getFocusSection();
                newActiveElement.focusNext(true,isTabKey,isLeftRight,isUpDown);
            }

            activeElement = newActiveElement;
        }
        var goPrevious= (e,isTabKey,isLeftRight,isUpDown) => {
            if (!activeElement){
                sectionIndex = 0;
            }
            var newActiveElement = getFocusSection();
            var succefullyFocused = newActiveElement.focusPrevious(false,isTabKey,isLeftRight,isUpDown);
            if (!succefullyFocused){
                if (activeElement){
                    activeElement.defocus();
                }
                sectionIndex--;
                if (sectionIndex >= sectionIndexCount){
                    sectionIndex = 0;
                }

                newActiveElement = getFocusSection();
                console.log("go preious -----",newActiveElement);
                newActiveElement.focusPrevious(true,isTabKey,isLeftRight,isUpDown);
            }
            activeElement = newActiveElement;
        }
        this.listRender =(e) => {
            if (e.code == "Tab"){

                if (e.shiftKey){
                    goPrevious(e, true, false, false);
                }
                else {
                    goNext(e, true, false, false);
                }
                e.preventDefault();
            }
            if (e.code == "Enter"){
                jQuery(document.activeElement).click();
            }
            if (e.code == "ArrowDown") {
                goNext(e,false,false,true);
                e.preventDefault();
            }
            if (e.code == "ArrowUp") {
                goPrevious(e,false,false,true);
                e.preventDefault();
            }
            if (e.code == "ArrowRight") {
                goNext(e,false,true,false);
                e.preventDefault();
            }
            if (e.code == "ArrowLeft") {
                goPrevious(e,false,true,false);
                e.preventDefault();
            }
        };

        window.addEventListener("keydown", this.listRender);
    }
}